import React, { useCallback, useMemo, useState } from 'react';
import { FreelancerModel } from '@/resources/models';
import { Skeleton, Table } from 'antd';
import { TRANSACTION_METHOD } from '@/resources/enums';
import { useSelector } from 'react-redux';
import { getCountries } from '@/selectors';
import BankStatus from '@/components/pages/BankStatus';
import IconButton from '@/components/common/IconButton';
import { IBank } from '@/resources/interfaces';
import ReviewBankAccountModal from '@/pages/Admin/FreelancerDetails/FreelancerBankAccounts/ReviewBankAccountModal';

interface IFreelancerBankAccountsProps {
  freelancer?: FreelancerModel;
  onRefresh: () => void;
  loading?: boolean;
}

export const FreelancerBankAccounts = ({
  freelancer,
  onRefresh,
  loading
}: IFreelancerBankAccountsProps) => {
  const countries = useSelector(getCountries);
  const [selectedBankAccount, setSelectedBankAccount] = useState<IBank>();
  const [open, setOpen] = useState(false);

  const openReviewModal = useCallback((bankAccount: IBank) => {
    setSelectedBankAccount(bankAccount);
    setOpen(true);
  }, []);

  const columns = useMemo(
    () => [
      {
        title: 'Account Name',
        dataIndex: 'receiverName',
        key: 'receiverName'
      },
      {
        title: 'Bank Name',
        dataIndex: 'bankName',
        key: 'bankName'
      },
      {
        title: 'Bank Country',
        dataIndex: 'bankCountry',
        key: 'bankCountry'
      },
      {
        title: 'Account Number',
        dataIndex: 'receivingAccount',
        key: 'receivingAccount'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status: string) => <BankStatus status={status} />
      },
      {
        title: '',
        dataIndex: 'actions',
        key: 'actions',
        render: (value: any, row: any) => (
          <IconButton icon="eye" onClick={() => openReviewModal(row)} />
        )
      }
    ],
    [openReviewModal]
  );

  const data = useMemo(() => {
    return freelancer?.paymentMethods
      ?.filter((item) => item.type === TRANSACTION_METHOD.KLARPAY)
      ?.map((item) => item.bank)
      ?.map((item) => ({
        ...item,
        bankCountry: countries.find((el) => el.code === item.bankCountry)?.name
      }));
  }, [freelancer]);

  return (
    <div className="custom-card">
      <h2 className="cmb-12">Bank Accounts</h2>

      <Skeleton loading={loading} active={true} paragraph={{ rows: 10 }}>
        <Table
          className="custom-ant-table"
          dataSource={data}
          columns={columns}
          pagination={false}
          rowKey="id"
        />
      </Skeleton>

      <ReviewBankAccountModal
        open={open}
        onClose={() => setOpen(false)}
        bank={selectedBankAccount}
        onConfirm={onRefresh}
      />
    </div>
  );
};
