import { Skeleton } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { AccountApi } from 'src/apis';
import BarChart from 'src/components/charts/BarChart';
import Select from 'src/components/forms/Select';
import { STATIC_PERIOD, TRANSACTION_TYPE } from 'src/resources/enums';
import { getDateFormat, getInitialData } from 'src/utils';

const RevenueStatistics = () => {
  const [loading, setLoading] = useState(false);
  const [statistics, setStatistics] = useState<any[]>([]);
  const [type, setType] = useState('');
  const [period, setPeriod] = useState('daily');

  const typeOptions = [
    { title: 'All', value: '' },
    { title: 'Employer Fee', value: TRANSACTION_TYPE.PAID_EMPLOYER_FEE },
    { title: 'Freelancer Fee', value: TRANSACTION_TYPE.PAID_FREELANCER_FEE }
  ];

  const periodOptions = [
    { title: 'Daily', value: STATIC_PERIOD.DAILY },
    { title: 'Weekly', value: STATIC_PERIOD.WEEKLY },
    { title: 'Monthly', value: STATIC_PERIOD.MONTHLY }
  ];

  useEffect(() => {
    setLoading(true);
    AccountApi.getDashboardRevenueStatistics({
      type,
      period
    })
      .then((res) => {
        setStatistics(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [type, period]);

  const chartData = useMemo(() => {
    const data = getInitialData(period);
    statistics.forEach((item) => {
      data[getDateFormat(item._id, period)] = item.sum;
    });

    return data;
  }, [statistics, period]);

  return (
    <div className="custom-card">
      <h3 className="cmb-9">Revenue Statistics</h3>

      <div className="d-flex justify-content-between cmb-12">
        <Select value={type} onChange={setType} options={typeOptions} />

        <Select value={period} onChange={setPeriod} options={periodOptions} />
      </div>
      <Skeleton active={true} loading={loading} paragraph={{ rows: 5 }}>
        <BarChart
          title="Revenue Statistics"
          categories={Object.keys(chartData)}
          data={Object.values(chartData)}
          tooltipPrefix="$"
        />
      </Skeleton>
    </div>
  );
};

export default RevenueStatistics;
