import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import './styles.scss';

const NotFound: FC = () => {
  const history = useHistory();

  return (
    <div className="public-page not-found">
      <div className="image-wrapper">
        <img
          className="not-found-img"
          src="/images/common/not-found.png"
          alt=""
        />
      </div>
      <p className="page-title">Page Not Found</p>
      <Button
        className="d-flex align-items-center"
        icon={<ArrowLeftOutlined />}
        size="large"
        onClick={() => history.goBack()}
      >
        Back
      </Button>
    </div>
  );
};

export default NotFound;
