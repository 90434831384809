import Http from '@/apis/http';
import { ISearchApiResult, IUser } from '@/resources/interfaces';
import { UserModel } from '@/resources/models';

export class UserApi {
  static search(query: any = {}): Promise<ISearchApiResult<UserModel>> {
    return Http.get('/admin/users', query).then(
      (res: ISearchApiResult<IUser>) => ({
        totalCount: res?.totalCount,
        data: res?.data?.map((item) => new UserModel(item))
      })
    );
  }

  static getRegistrationStatistics() {
    return Http.get('/admin/users/statistics/registration-month');
  }

  static getUserExemptions(userId: string) {
    return Http.get(`/admin/users/${userId}/fee-exemptions`);
  }

  static getUserReferral(userId: string) {
    return Http.get(`/admin/users/${userId}/referral`);
  }
}
