import { Table } from 'antd';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { FreelancerApi } from 'src/apis';
import Badge from 'src/components/common/Badge';
import Pagination from 'src/components/common/Pagination';
import Search from 'src/components/forms/Search';
import TransactionStatus from 'src/components/pages/Transaction/TransactionStatus';
import { DateHelper, IQueryField, NameHelper, QueryHelper } from 'src/helpers';
import { TRANSACTION_METHOD } from 'src/resources/enums';
import { IFreelancer, ITransaction } from 'src/resources/interfaces';
import './styles.scss';

const columns = [
  {
    title: 'Date Time',
    dataIndex: 'createdAt',
    sorter: true,
    render: (date: string) => (
      <div>{DateHelper.formatDate(date, 'DD MMM YYYY, hh:mm:ss A')}</div>
    )
  },
  {
    title: 'Payment Method',
    dataIndex: 'method',
    sorter: true,
    render: (method: TRANSACTION_METHOD) => (
      <Badge title={NameHelper.getTransactionMethodLabel(method)} />
    )
  },
  {
    title: 'Email/PayeeId',
    dataIndex: 'emailOrPayeeId'
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    sorter: true
  },
  {
    title: 'Status',
    dataIndex: 'status',
    sorter: true,
    showSorterTooltip: false,
    render: (status: string) => <TransactionStatus status={status} />
  }
];

const filterFields: IQueryField[] = [
  { name: 'search', type: 'string', default: '' },
  { name: 'page', type: 'number', default: 1 },
  { name: 'perPage', type: 'number', default: 10 },
  { name: 'sort', type: 'string', default: '-createdAt' }
];

const WithdrawHistories = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const history = useHistory();
  const [transactions, setTransactions] = useState<ITransaction[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const filter = useMemo(() => {
    return QueryHelper.parseQuery(location.search, filterFields);
  }, [location.search]);

  const onChangeFilter = useCallback(
    (field: string, value: any) => {
      const newFilter = QueryHelper.getFilterToQuery(filter, field, value);

      const query = QueryHelper.stringifyQuery(newFilter);
      history.replace(`${location.pathname}?${query}`);
    },
    [filter, location.pathname]
  );

  useEffect(() => {
    setLoading(true);

    FreelancerApi.getWidthdrawHistories(id, {
      ...filter,
      expands: ['freelancer']
    })
      .then((res) => {
        setTransactions(res.data);
        setTotalCount(res.totalCount);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [filter]);

  const handleTableChange = (
    pagination: any,
    filters: any,
    sortColumns: any
  ) => {
    if (Array.isArray(sortColumns)) {
      const sort = sortColumns
        .reduce(
          (acc, column) => [
            ...acc,
            `${column.order === 'descend' ? '-' : ''}${column.field}`
          ],
          []
        )
        .join(',');
      onChangeFilter('sort', sort);
    } else {
      onChangeFilter(
        'sort',
        `${sortColumns.order === 'descend' ? '-' : ''}${sortColumns.field}`
      );
    }
  };

  const dataSource = useMemo(
    () =>
      transactions.map((transaction) => {
        const paymentMethod = (transaction.freelancer as IFreelancer)?.paymentMethods?.find(
          (method) => method.type === transaction.method
        );

        return {
          ...transaction,
          key: transaction.id,
          emailOrPayeeId:
            paymentMethod?.type === TRANSACTION_METHOD.PAYPAL
              ? paymentMethod.email
              : paymentMethod?.payeeId
        };
      }),
    [transactions]
  );

  return (
    <div className="custom-card withdraw-histories">
      <h2 className="cmb-12">Withdraw Histories</h2>

      <div className="cmb-12">
        <Search
          className="page-search-box"
          value={filter.search}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChangeFilter('search', e.target.value)
          }
          placeholder="Search for users by name"
        />
      </div>
      <Table
        className="custom-ant-table"
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        onChange={handleTableChange}
      />

      <div className="d-flex-center cmt-10">
        <Pagination
          total={totalCount}
          current={filter.page}
          pageSize={filter.perPage}
          onChange={(page) => onChangeFilter('page', page)}
        />
      </div>
    </div>
  );
};

export default WithdrawHistories;
