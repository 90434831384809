import { notification, Skeleton, Table } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { UserApi } from 'src/apis';
import { DateHelper } from 'src/helpers';
import { IFeeExemption } from 'src/resources/interfaces';

interface IFeeExemptionsProps {
  userId?: string;
}

const FeeExemptions: React.FC<IFeeExemptionsProps> = ({ userId }) => {
  const [loading, setLoading] = useState(false);
  const [feeExemptions, setFeeExemptions] = useState<IFeeExemption[]>([]);
  const [referral, setReferral] = useState<any>();

  const columns = useMemo(
    () => [
      {
        title: 'Month Range',
        dataIndex: 'monthRange',
        key: 'monthRange'
      },
      {
        title: 'Points Discount',
        dataIndex: 'pointDiscount',
        key: 'pointDiscount'
      },
      {
        title: 'Points Balance',
        dataIndex: 'pointBalance',
        key: 'pointBalance'
      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt'
      }
    ],
    []
  );

  useEffect(() => {
    if (!userId) return;

    (async () => {
      try {
        setLoading(true);

        const res = await UserApi.getUserExemptions(userId);
        setFeeExemptions(res.data);
        const referralRes = await UserApi.getUserReferral(userId);
        setReferral(referralRes);
        setLoading(false);
      } catch (err: any) {
        notification.error({ message: err.message });
      }
    })();
  }, [userId]);

  const data = useMemo(
    () =>
      feeExemptions.map((item) => ({
        ...item,
        pointDiscount: -item.pointDiscount,
        monthRange: `${DateHelper.formatDate(
          item.start,
          'MM.YYYY'
        )}-${DateHelper.formatDate(item.end, 'MM.YYYY')}`,
        createdAt: DateHelper.formatDate(item.createdAt, 'DD.MM.YYYY')
      })),
    [feeExemptions]
  );

  return (
    <div className="custom-card">
      <h2 className="cmb-12">Fee Exemptions ({referral?.totalPoint})</h2>

      <Skeleton
        loading={loading || !userId}
        active={true}
        paragraph={{ rows: 10 }}
      >
        <Table
          className="custom-ant-table"
          dataSource={data}
          columns={columns}
          pagination={false}
          rowKey="id"
        />
      </Skeleton>
    </div>
  );
};

export default FeeExemptions;
