import React, { FC } from 'react';
import { Divider, Rate } from 'antd';
import { EmployerModel } from '@/resources/models';
import Avatar from '@/components/common/Avatar';
import Icon from '@/components/common/Icon';
import { DateHelper, StringHelper } from '@/helpers';
import { USER_STATUS } from '@/resources/enums';
import UserStatusRibbon from '@/components/pages/UserStatus/UserStatusRibbon';
import './styles.scss';

interface EmployerInfoProps {
  employer: EmployerModel;
  status?: USER_STATUS;
}

const EmployerInfo: FC<EmployerInfoProps> = ({ employer, status }) => {
  return (
    <div className="employer-info-container custom-card">
      <UserStatusRibbon status={status || employer.status} />

      <div className="d-flex align-items-center cmb-12">
        <Avatar
          src={employer?.user?.avatar}
          name={employer?.user?.fullName}
          size="7rem"
          className="cmr-12"
          round={true}
        />

        <div>
          <p className="text-lg text-bold cmb-1">{employer?.user?.fullName}</p>
          <p className="text-lg text-blue cmb-1">{employer?.user?.email}</p>

          <Rate
            className="cmb-7"
            disabled={true}
            allowHalf={true}
            value={employer?.rating}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap">
        <div className="d-flex align-items-center cmr-12">
          <Icon name="map-marker" className="employer-info-icon cmr-3" />
          <span className="text-sm">{employer?.user?.country?.name}</span>
        </div>

        <div className="d-flex align-items-center">
          {employer?.paymentVerified ? (
            <>
              <Icon
                name="payment-verified"
                className="employer-info-icon cmr-3"
              />
              <span className="text-sm">Payment is verified</span>
            </>
          ) : (
            <>
              <Icon
                name="cancel-line"
                className="employer-info-icon text-red cmr-3"
              />
              <span className="text-sm text-red">Payment is not verified</span>
            </>
          )}
        </div>
      </div>

      <Divider className="cmy-10" />

      <div className="d-flex align-items-center flex-wrap">
        <p className="text-sm cmb-2 cmr-13">
          Total Projects:{' '}
          <span className="text-bold">{employer?.projects.length || 0}</span>
        </p>
        <p className="text-sm cmb-2 cmr-13">
          Freelancers Hired:{' '}
          <span className="text-bold">
            {employer?.engagedFreelancers?.length}
          </span>
        </p>
        <p className="text-sm cmb-2 cmr-13">
          Total Paid Hours:{' '}
          <span className="text-bold">
            {' '}
            {employer?.totalPaidHours?.toFixed(2) || 0} hrs
          </span>
        </p>
        <p className="text-sm cmb-2 cmr-13">
          Total Paid:{' '}
          <span className="text-bold">
            ${employer?.totalPaid?.toFixed(2) || 0}
          </span>
        </p>
        <p className="text-sm cmb-2 cmr-13">
          Member Since:{' '}
          <span className="text-bold">
            {DateHelper.formatDate(employer?.createdAt, 'MMM YYYY')}
          </span>
        </p>
      </div>

      {employer.bio && (
        <>
          <Divider className="cmy-10" />

          <div
            className="text-md mb-0 break-word"
            dangerouslySetInnerHTML={{
              __html: StringHelper.plain2Html(employer.bio)
            }}
          />
        </>
      )}
    </div>
  );
};

export default EmployerInfo;
