import { TAccount } from '@/resources/types';
import { AUTH_ACTION } from '@/redux/action-types';
import { ITokens } from '@/resources/interfaces';
import { Dispatch } from 'redux';
import { AuthApi } from '@/apis';
import { notification } from 'antd';

export const setAccount = (account: TAccount | null) => ({
  type: AUTH_ACTION.SET_ACCOUNT,
  payload: { account }
});

export const setTokens = (tokens: ITokens) => ({
  type: AUTH_ACTION.SET_TOKENS,
  payload: { tokens }
});

export const fetch2faCodeSuccess = (data: any) => ({
  type: AUTH_ACTION.TFA.FETCH_2FA,
  data
});

export const fetch2faCode = () => (dispatch: Dispatch) => {
  return AuthApi.get2FACode()
    .then((res) => {
      dispatch(fetch2faCodeSuccess(res));

      return Promise.resolve(true);
    })
    .catch((err) => {
      notification.error({ message: err.message });
      return Promise.reject(err);
    });
};

export const verify2FASuccess = (data: any) => ({
  type: AUTH_ACTION.TFA.VERIFY_2FA,
  data
});

export const verify2FA = (data: any) => (dispatch: Dispatch) => {
  return AuthApi.verify2FA(data)
    .then((res) => {
      dispatch(verify2FASuccess(res));

      return Promise.resolve(true);
    })
    .catch((err) => {
      notification.error({ message: err.message });
      return Promise.reject(err);
    });
};

export const enable2FASuccess = (data: any) => ({
  type: AUTH_ACTION.TFA.ENABLE_2FA,
  data
});

export const enable2FA = (data: any) => (dispatch: Dispatch) => {
  return AuthApi.enable2FA(data)
    .then((res) => {
      dispatch(enable2FASuccess(res));

      return Promise.resolve(true);
    })
    .catch((err) => {
      notification.error({ message: err.message });
      return Promise.reject(err);
    });
};

export const disable2FASuccess = (data: any) => ({
  type: AUTH_ACTION.TFA.DISABLE_2FA,
  data
});

export const disable2FA = (data: any) => (dispatch: Dispatch) => {
  return AuthApi.disable2FA(data)
    .then((res) => {
      dispatch(disable2FASuccess(res));

      return Promise.resolve(true);
    })
    .catch((err) => {
      notification.error({ message: err.message });
      return Promise.reject(err);
    });
};
