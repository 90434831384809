import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { FreelancerModel } from '@/resources/models';
import { FreelancerApi } from '@/apis';
import { notification, Skeleton } from 'antd';
import { ROLE } from '@/resources/enums';
import BackButton from '@/components/common/BackButton';
import AccountForm from '@/components/pages/AccountForm';
import NoData from '@/components/common/NoData';
import { ROUTES } from '@/constants';
import PageHeader from '@/components/common/PageHeader';

const EditFreelancer = () => {
  const history = useHistory();
  const params: { id: string } = useParams();
  const { id } = params;
  const [loading, setLoading] = useState(false);
  const [freelancer, setFreelancer] = useState<FreelancerModel | undefined>();

  const onSubmit = async (values: any) => {
    if (freelancer) {
      try {
        await FreelancerApi.update(id, { user: values });
        notification.success({
          message: 'Freelancer is updated successfully.'
        });
        history.push(ROUTES.ADMIN.FREELANCERS.INDEX);
      } catch (err) {
        notification.error({ message: err.message });
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    FreelancerApi.find(id)
      .then((data) => {
        setLoading(false);
        setFreelancer(data);
      })
      .catch((err) => {
        setLoading(false);
        notification.error({ message: err.message });
      });
  }, [id]);

  return (
    <div>
      <div className="cmb-17">
        <BackButton />
      </div>

      <PageHeader title="Edit Freelancer" />

      <Skeleton loading={loading} paragraph={{ rows: 10 }}>
        {freelancer?.user ? (
          <AccountForm
            user={freelancer?.user}
            onSubmit={onSubmit}
            type={ROLE.FREELANCER}
          />
        ) : (
          <NoData title="Not found freelancer" />
        )}
      </Skeleton>
    </div>
  );
};

export default EditFreelancer;
