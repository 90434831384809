import React, { FC, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { notification, Table } from 'antd';
import { EMPLOYER_DETAILS_ROUTES, ROUTES } from '@/constants';
import { EmployerApi } from '@/apis';
import { EmployerModel } from '@/resources/models';
import './styles.scss';

const TopEmployers: FC = () => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (title: string, record: any) => (
        <Link
          className="link-tag"
          to={`${ROUTES.ADMIN.EMPLOYERS.PREFIX}/${record.id}/${EMPLOYER_DETAILS_ROUTES.DETAILS}`}
        >
          {title}
        </Link>
      )
    },
    { title: 'Email', dataIndex: 'email' },
    { title: 'Awarded Freelancers', dataIndex: 'awardedFreelancers' },
    { title: 'Completed Projects', dataIndex: 'completedProjects' },
    {
      title: 'Total Paid',
      dataIndex: 'totalPaid',
      render: (value: string) => <div>{`$${value}`}</div>
    }
  ];

  const [employers, setEmployers] = useState<EmployerModel[]>([]);
  const [loading, setLoading] = useState(false);

  const dataSource = useMemo(() => {
    return employers.map((employer) => ({
      id: employer.id,
      name: employer.user?.fullName,
      email: employer.user?.email,
      awardedFreelancers: employer.engagedFreelancers.length,
      completedProjects: employer.completedProjects.length,
      totalPaid: employer.totalPaid?.toFixed(2)
    }));
  }, [employers]);

  useEffect(() => {
    setLoading(true);
    EmployerApi.getTopEmployers()
      .then((res) => {
        setLoading(false);
        setEmployers(res);
      })
      .catch((err) => {
        setLoading(false);
        notification.error({ message: err.message });
      });
  }, []);

  return (
    <div className="top-employers-card">
      <h3 className="text-lg mb-3">Top 10 Employers</h3>
      <div className="table-wrapper">
        <Table
          rowKey="id"
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default TopEmployers;
