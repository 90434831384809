import { IChannel, IChannelSetting } from '@/resources/interfaces';
import { EmployerModel } from '@/resources/models/employer.model';
import { FreelancerModel } from '@/resources/models/freelancer.model';
import { ProjectModel } from '@/resources/models/project.model';
import { UserModel } from '@/resources/models/user.model';

export class ChannelModel {
  id: string;
  employer: EmployerModel;
  freelancer: FreelancerModel;
  project: ProjectModel;
  lastMessage?: {
    sender: UserModel;
    content: string;
    timestamp: string;
  };
  createdAt: string;
  updatedAt: string;
  hasUnreadMessage: boolean;
  hasFavouriteMessage: boolean;
  hasSpamMessage: boolean;
  freelancerSettings: IChannelSetting;
  employerSettings: IChannelSetting;
  disabled: boolean;

  constructor(data: IChannel) {
    this.id = data.id;
    this.employer = new EmployerModel(data.employer);
    this.freelancer = new FreelancerModel(data.freelancer);
    this.project = new ProjectModel(data.project);
    if (data.lastMessage) {
      this.lastMessage = {
        ...data.lastMessage,
        sender: new UserModel(data.lastMessage?.sender)
      };
    }
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.hasUnreadMessage = data.hasUnreadMessage;
    this.hasFavouriteMessage = data.hasFavouriteMessage;
    this.hasSpamMessage = data.hasSpamMessage;
    this.disabled = Boolean(data.disabled);

    if (data.freelancerSettings) {
      this.freelancerSettings = data.freelancerSettings;
    } else {
      this.freelancerSettings = {
        archive: false,
        mute: false,
        block: false
      };
    }

    if (data.employerSettings) {
      this.employerSettings = data.employerSettings;
    } else {
      this.employerSettings = {
        archive: false,
        mute: false,
        block: false
      };
    }
  }

  getTimeStamp() {
    return this.lastMessage ? this.lastMessage.timestamp : this.createdAt;
  }
}
