import React, { FC, useMemo } from 'react';
import { Divider, Empty } from 'antd';
import { ProjectModel } from '@/resources/models';
import { IAttachment, ISkill } from '@/resources/interfaces';
import { PAYMENT_TYPE, PROJECT_STATUS } from '@/resources/enums';
import Chip from '@/components/common/Chip';
import Ribbon from '@/components/common/Ribbon';
import { DateHelper, StringHelper } from '@/helpers';
import './styles.scss';

export interface IProjectInfoProps {
  project: ProjectModel;
}

const ProjectInfo: FC<IProjectInfoProps> = ({ project }) => {
  const ribbonType = useMemo(() => {
    if (
      project.status === PROJECT_STATUS.PENDING ||
      project.status === PROJECT_STATUS.DRAFT
    )
      return 'warning';

    if (
      project.status === PROJECT_STATUS.CLOSED ||
      project.status === PROJECT_STATUS.STOPPED ||
      project.status === PROJECT_STATUS.DISABLED
    )
      return 'danger';

    if (
      project.status === PROJECT_STATUS.AWARDED ||
      project.status === PROJECT_STATUS.COMPLETED
    )
      return 'success';

    return 'normal';
  }, [project.status]);

  return (
    <div className="project-info-container">
      <Ribbon type={ribbonType} content={project.status} />

      <h2 className="text-blue text-ellipsis cmb-9">{project.title}</h2>

      <div className="d-flex flex-wrap cmb-9">
        <p className="project-detail-info-item">
          {project.paymentType === PAYMENT_TYPE.HOURLY
            ? 'Hourly Price'
            : 'Project Budget'}
          :&nbsp;
          <span className="text-bold text-blue">${project.price}</span>
        </p>
        <p className="project-detail-info-item">
          Type:&nbsp;
          <span className="text-bold">
            {project.paymentType === PAYMENT_TYPE.HOURLY ? 'Hourly' : 'Fixed'}
          </span>
        </p>
        <p className="project-detail-info-item">
          Total Proposals:&nbsp;
          <span className="text-bold">{project.pitches}</span>
        </p>
        <p className="project-detail-info-item">
          Posted on:&nbsp;
          <span className="text-bold">
            {DateHelper.formatDate(project.createdAt, 'DD MMM, YYYY')}
          </span>
        </p>
      </div>

      <div className="d-flex flex-wrap">
        {project.category && <Chip title={project.category.name} />}
        <Chip className="text-capitalize" title={project.paymentType} />
        <Chip className="text-capitalize" title={`${project.level} Level`} />
      </div>

      <Divider className="cmy-12" />

      {project.description && (
        <>
          <div
            className="text-md mb-0 break-word"
            dangerouslySetInnerHTML={{
              __html: StringHelper.plain2Html(project.description || '')
            }}
          />

          <Divider className="cmy-12" />
        </>
      )}

      <div className="skill-section">
        <p className="text-heading3 cmb-9">Skills</p>
        <div className="d-flex flex-wrap">
          {(project.skills as ISkill[]).map((item, index) => (
            <Chip title={item.name} key={index} />
          ))}
        </div>
      </div>

      <Divider className="cmy-12" />

      <div className="attachment-section">
        <p className="text-heading3 cmb-9">
          Attachments&nbsp; ({project?.attachments?.length || 0})
        </p>

        {project?.attachments && project?.attachments.length ? (
          <div className="d-flex align-items-start flex-column">
            {(project?.attachments as IAttachment[])?.map(
              (attachment, index) => (
                <a
                  className="text-md attachment-link"
                  href={attachment.url}
                  target="_blank"
                  download={true}
                  key={index}
                >
                  {attachment.filename}
                </a>
              )
            )}
          </div>
        ) : (
          <Empty />
        )}
      </div>
    </div>
  );
};

export default ProjectInfo;
