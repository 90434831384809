import React, { FC, useMemo } from 'react';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';

interface LineChartProps {
  title: string;
  data: any[];
  categories: string[];
  tooltipPrefix?: string;
}

const BarChart: FC<LineChartProps> = ({
  title,
  data,
  categories,
  tooltipPrefix = ''
}) => {
  const chartOptions: any = useMemo(() => {
    const softMax = Math.round(
      data.reduce((max: number, el: number) => Math.max(max, el), 0)
    );

    return {
      chart: {
        type: 'column',
        zoomType: 'x',
        height: 300
      },
      colors: ['#108af9'],
      title: undefined,
      xAxis: {
        categories,
        labels: {
          style: {
            fontFamily: 'Helvetica',
            color: '#BFC5D5',
            fontSize: '14px'
          }
        }
      },
      yAxis: {
        title: undefined,
        gridLineColor: '#DBE2EA',
        gridLineDashStyle: 'Dash',
        labels: {
          style: {
            fontFamily: 'Helvetica',
            color: '#BFC5D5',
            fontSize: '14px'
          }
        },
        softMax: softMax < 5 ? 5 : softMax
      },
      tooltip: {
        headerFormat: '<b>{point.x:%b %d, %Y}</b><br>',
        pointFormatter(): any {
          const data = this as any;
          return `${data?.series?.name}: ${tooltipPrefix}${data.y}`;
        }
      },
      plotOptions: {
        column: {
          borderColor: '#108af9',
          borderWidth: 2
        }
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      series: [
        {
          name: title,
          data: data.map((item: number) => Math.round(item * 100) / 100)
        }
      ]
    };
  }, [data, title, categories]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
      allowChartUpdate={true}
      immutable={true}
    />
  );
};

export default BarChart;
