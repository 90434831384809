import { AUTH_ACTION } from '@/redux/action-types';
import { IAuthState } from '@/resources/interfaces';
import { EmployerModel, FreelancerModel } from '@/resources/models';
import { ObjectHelper } from '@/helpers';

const initialState: IAuthState = {
  account: null,
  tokens: undefined,
  qr: null,
  tfaEnabled: false
};

const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case AUTH_ACTION.SET_ACCOUNT:
      return {
        ...state,
        account: action.payload.account,
        tfaEnabled: action.payload.account?.tfaEnabled
      };

    case AUTH_ACTION.PATCH_ACCOUNT: {
      ObjectHelper.deepAssign(state.account, action.payload.updates);
      let newAccount;
      if (state.account instanceof FreelancerModel)
        newAccount = new FreelancerModel(state.account as any);
      else newAccount = new EmployerModel(state.account as any);

      return {
        ...state,
        account: newAccount
      };
    }

    case AUTH_ACTION.SET_TOKENS:
      if (action.payload.tokens?.accessToken === state.tokens?.accessToken)
        return state;

      return {
        ...state,
        tokens: {
          ...state.tokens,
          ...action.payload.tokens
        }
      };

    case AUTH_ACTION.TFA.FETCH_2FA:
      return {
        ...state,
        qr: action.data.qr
      };
    case AUTH_ACTION.TFA.VERIFY_2FA:
      return {
        ...state,
        tfaEnabled: true,
        qr: null
      };
    case AUTH_ACTION.TFA.ENABLE_2FA:
      return {
        ...state,
        tfaEnabled: true,
        qr: null
      };
    case AUTH_ACTION.TFA.DISABLE_2FA:
      return {
        ...state,
        tfaEnabled: false,
        qr: null
      };
    default:
      return state;
  }
};

export default authReducer;
