import { ISearchApiResult, ITransaction } from '@/resources/interfaces';
import { TransactionModel } from '@/resources/models';
import Http from '@/apis/http';

export class TransactionApi {
  static search(query: any = {}): Promise<ISearchApiResult<TransactionModel>> {
    return Http.get('/admin/transactions', query);
  }

  static find(transactionId: string): Promise<TransactionModel> {
    return Http.get(`/admin/transactions/${transactionId}`).then(
      (data: ITransaction) => new TransactionModel(data)
    );
  }

  static accept(id: string): Promise<TransactionModel> {
    return Http.post(`/admin/transactions/withdraws/${id}/accept`).then(
      (res: ITransaction) => new TransactionModel(res)
    );
  }

  static reject(id: string) {
    return Http.post(`/admin/transactions/withdraws/${id}/reject`);
  }

  static getRecentPayments() {
    return Http.get('/admin/transactions/statistics/recent-payments');
  }

  static exportTransactions(filters: any): Promise<any> {
    return Http.post(`/admin/transactions/export`, filters);
  }
}
