import React, { FC } from 'react';
import classnames from 'classnames';
import './styles.scss';

type RibbonType = 'normal' | 'success' | 'danger' | 'warning';

interface IRibbonProps {
  type?: RibbonType;
  content: string;
}

const Ribbon: FC<IRibbonProps> = ({ type = 'normal', content }) => {
  return (
    <div
      className={classnames('ribbon', {
        [type]: true
      })}
    >
      <span className="ribbon-content">{content}</span>
    </div>
  );
};

export default Ribbon;
