import { FreelancerModel } from '@/resources/models/freelancer.model';
import { EmployerModel } from '@/resources/models/employer.model';
import { InvoiceModel } from '@/resources/models/invoice.model';
import {
  CURRENCY,
  TRANSACTION_METHOD,
  TRANSACTION_STATUS,
  TRANSACTION_TYPE
} from '@/resources/enums';
import { IBalance, ITransaction } from '@/resources/interfaces';
import { BankModel } from '@/resources/models/bank.model';
import { OrderModel } from '@/resources/models/order.model';

export class TransactionModel {
  id: string;
  amount: number;
  freelancer: string | FreelancerModel;
  employer: string | EmployerModel;
  invoice?: string | InvoiceModel;
  order?: string | OrderModel;
  type: TRANSACTION_TYPE;
  status: TRANSACTION_STATUS;
  method: TRANSACTION_METHOD;
  note: string;
  accountInfo?: any;
  createdAt: string;
  bank?: string | BankModel;
  internalAmount: number;
  externalAmount: number;
  exAmount: number;
  exInternalAmount: number;
  exExternalAmount: number;
  exRate: number;
  currency: CURRENCY;
  invoiceTotalPrice?: number;
  sender?: string;
  receiver?: string;
  logs?: string[];
  balance?: IBalance | null;
  internal?: boolean;
  meta: any;
  completedAt?: any;

  constructor(data: ITransaction) {
    this.id = data.id;
    this.amount = data.amount;
    this.type = data.type;
    this.status = data.status;
    this.method = data.method;
    this.note = data.note;
    this.accountInfo = data.accountInfo;
    this.createdAt = data.createdAt;
    this.internalAmount = data.internalAmount;
    this.externalAmount = data.externalAmount;
    this.exAmount = data.exAmount;
    this.exInternalAmount = data.exInternalAmount;
    this.exExternalAmount = data.exExternalAmount;
    this.exRate = data.exRate;
    this.currency = data.currency;
    this.completedAt = data.completedAt;

    if (typeof data.freelancer === 'string') {
      this.freelancer = data.freelancer;
    } else {
      this.freelancer = new FreelancerModel(data.freelancer);
    }

    if (typeof data.employer === 'string') {
      this.employer = data.employer;
    } else {
      this.employer = new EmployerModel(data.employer);
    }

    if (data.bank) {
      if (typeof data.bank === 'string') {
        this.bank = data.bank;
      } else {
        this.bank = new BankModel(data.bank);
      }
    }

    if (data.invoice) {
      if (typeof data.invoice === 'string') {
        this.invoice = data.invoice;
      } else {
        this.invoice = new InvoiceModel(data.invoice);
      }
    }

    if (data.order) {
      if (typeof data.order === 'string') {
        this.order = data.order;
      } else {
        this.order = new OrderModel(data.order);
      }
    }
  }
}
