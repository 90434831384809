import React, { FC } from 'react';
import classnames from 'classnames';
import './styles.scss';

export enum BADGE_STATUS {
  SUCCESS = 'success',
  NORMAL = 'normal',
  WARNING = 'warning',
  DANGER = 'danger'
}

export interface IBadgeProps {
  title: string;
  className?: string;
  status?: BADGE_STATUS;
}

const Badge: FC<IBadgeProps> = ({
  title,
  className = '',
  status = BADGE_STATUS.NORMAL
}) => {
  return (
    <div className={`custom-badge-container ${className}`}>
      <div
        className={classnames(`custom-badge`, {
          normal: status === BADGE_STATUS.NORMAL,
          success: status === BADGE_STATUS.SUCCESS,
          warning: status === BADGE_STATUS.WARNING,
          danger: status === BADGE_STATUS.DANGER
        })}
      >
        <div className="dot" />
        <span className="badge-title">{title}</span>
      </div>
    </div>
  );
};

export default Badge;
