import React, { ChangeEvent, FC, useState } from 'react';
import { Eye as EyeIcon, EyeOff as EyeOffIcon } from 'react-feather';
import { WarningFilled as WaringIcon } from '@ant-design/icons/lib/icons';
import classnames from 'classnames';
import './styles.scss';

type TInputDirection = 'horizontal' | 'vertical';

export interface InputProps {
  direction?: TInputDirection;
  label?: string;
  type?: string;
  placeholder?: string;
  error?: string;
  required?: boolean;
  value?: string | number | readonly string[];
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  className?: string;
  maxLength?: number;
  disabled?: boolean;
}

const Input: FC<InputProps> = ({
  type = 'text',
  label,
  value,
  onChange,
  placeholder,
  error,
  required,
  name,
  className = '',
  maxLength = 60,
  disabled = false
}) => {
  const [defaultType, setDefaultType] = useState(true);

  const onClickVisible = (e: MouseEvent) => {
    e.stopPropagation();
    setDefaultType(!defaultType);
  };

  return (
    <div
      className={`custom-input-container d-flex flex-column w-100 ${className}`}
    >
      <span className="custom-input-label text-sm">
        {label} {required ? '*' : ''}
      </span>
      <div className="input-wrapper d-flex align-items-center w-100">
        <input
          name={name}
          className={'custom-input w-100 ' + (error ? 'error' : '')}
          type={defaultType ? type : 'text'}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          maxLength={maxLength}
          disabled={disabled}
        />
        {type === 'password' && (
          <div
            className={classnames('input-action', {
              error: !!error
            })}
            onClick={(e: any) => onClickVisible(e)}
          >
            {defaultType ? (
              <EyeIcon className="visible-icon" />
            ) : (
              <EyeOffIcon className="visible-icon" />
            )}
          </div>
        )}

        {error && (
          <div
            className={classnames('error-icon-wrapper', {
              'password-error': type === 'password'
            })}
          >
            <WaringIcon className="error-icon" />
          </div>
        )}
      </div>
      {!!error && <p className="text-red text-sm mb-0">{error}</p>}
    </div>
  );
};

export default Input;
