import React, { FC, useState } from 'react';
import { useHistory } from 'react-router';
import { Modal, notification } from 'antd';
import { ROUTES } from '@/constants';
import IconButton from '@/components/common/IconButton';
import { EmployerApi } from '@/apis';

interface EmployerActionButtonProps {
  employerId: string;
  refresh?: () => void;
}

const EmployerActions: FC<EmployerActionButtonProps> = ({
  employerId,
  refresh
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const deleteEmployer = () => {
    setLoading(true);
    EmployerApi.delete(employerId)
      .then(() => {
        setLoading(false);
        notification.success({ message: 'Employer is deleted successfully.' });
        if (refresh) refresh();
      })
      .catch((err) => {
        setLoading(false);
        notification.error({ message: err.message });
      });
  };

  const showDeleteEmployerModal = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this employer?',
      centered: true,
      onOk: deleteEmployer
    });
  };

  return (
    <div className="d-flex align-items-center">
      <IconButton
        icon="edit"
        onClick={() =>
          history.push(`${ROUTES.ADMIN.EMPLOYERS.PREFIX}/${employerId}/edit`)
        }
      />
      <IconButton
        icon="delete"
        onClick={showDeleteEmployerModal}
        loading={loading}
      />
    </div>
  );
};

export default EmployerActions;
