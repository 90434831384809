import React, { FC } from 'react';
import { useHistory, useParams } from 'react-router';
import { Tabs } from 'antd';
import { PROJECT_DETAILS_ROUTES, ROUTES } from '@/constants';
import BackButton from '@/components/common/BackButton';
import DetailSection from '@/pages/Admin/ProjectDetails/DetailSection';
import ProjectMessages from '@/pages/Admin/ProjectDetails/ProjectMessages';
import './styles.scss';
import PageHeader from '@/components/common/PageHeader';
import ChangeLogSection from '@/components/pages/ChangeLogSection';
import { useCheckRole } from 'src/hooks';

const ProjectDetails: FC = () => {
  const history = useHistory();
  const { tab: activeTab, id } = useParams<{ tab: string; id: string }>();
  const { isAboveAdmin } = useCheckRole();

  return (
    <div className="admin-project-details-page">
      <div className="cmb-17">
        <BackButton />
      </div>

      <PageHeader title="Project Details" />

      <Tabs
        activeKey={activeTab}
        onChange={(tab) =>
          history.replace(`${ROUTES.ADMIN.PROJECTS.PREFIX}/${id}/${tab}`)
        }
      >
        <Tabs.TabPane
          key={PROJECT_DETAILS_ROUTES.DETAILS}
          tabKey={PROJECT_DETAILS_ROUTES.DETAILS}
          tab="Details"
        />

        <Tabs.TabPane
          key={PROJECT_DETAILS_ROUTES.MESSAGES}
          tabKey={PROJECT_DETAILS_ROUTES.MESSAGES}
          tab="Messages"
        />
        {isAboveAdmin && (
          <Tabs.TabPane
            key={PROJECT_DETAILS_ROUTES.EVENTS}
            tabKey={PROJECT_DETAILS_ROUTES.EVENTS}
            tab="Change Logs"
          />
        )}
      </Tabs>

      {activeTab === PROJECT_DETAILS_ROUTES.DETAILS && <DetailSection />}

      {activeTab === PROJECT_DETAILS_ROUTES.MESSAGES && <ProjectMessages />}
      {isAboveAdmin && activeTab === PROJECT_DETAILS_ROUTES.EVENTS && (
        <ChangeLogSection objectType="project" objectId={id} />
      )}
    </div>
  );
};

export default ProjectDetails;
