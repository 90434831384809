import React from 'react';
import { useHistory } from 'react-router';
import { notification } from 'antd';
import BackButton from '@/components/common/BackButton';
import AccountForm from '@/components/pages/AccountForm';
import { ROLE } from '@/resources/enums';
import { FreelancerApi } from '@/apis';
import { ROUTES } from '@/constants';
import PageHeader from '@/components/common/PageHeader';

const CreateFreelancer = () => {
  const history = useHistory();

  const onSubmit = async (data: any) => {
    try {
      await FreelancerApi.create({ user: data });
      notification.success({ message: 'Freelancer is created successfully.' });
      history.push(ROUTES.ADMIN.FREELANCERS.INDEX);
    } catch (err) {
      notification.error({ message: err.message });
    }
  };

  return (
    <div>
      <div className="cmb-17">
        <BackButton />
      </div>

      <PageHeader title="Create a new freelancer" />

      <AccountForm onSubmit={onSubmit} type={ROLE.FREELANCER} />
    </div>
  );
};

export default CreateFreelancer;
