import React, { FC, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import {
  DashboardOutlined,
  FileDoneOutlined,
  PoweroffOutlined,
  ProjectOutlined,
  ScheduleOutlined,
  SecurityScanOutlined,
  SettingOutlined,
  TeamOutlined,
  TransactionOutlined,
  UserOutlined,
  UserSwitchOutlined,
  HistoryOutlined,
  TagOutlined,
  BankOutlined,
  DollarOutlined,
  SendOutlined
} from '@ant-design/icons';
import { ROUTES } from '@/constants';
import { AuthApi } from '@/apis';
import { useCheckRole } from '@/hooks';
import './styles.scss';
import Header from '@/components/common/Header';

const { Content, Sider } = Layout;
const { SubMenu } = Menu;

enum SUB_MENU_KEY {
  SETTINGS = 'SETTINGS',
  DASHBOARD = 'DASHBOARD'
}

const rootSubmenuKeys = [SUB_MENU_KEY.SETTINGS, SUB_MENU_KEY.DASHBOARD];

enum MENU_LIST {
  DASHBOARD = 'dashboard',
  PROJECT = 'projects',
  FREELANCER = 'freelancers',
  EMPLOYER = 'employers',
  CONTRACTS = 'contracts',
  INVOICE = 'invoices',
  ORDERS = 'orders',
  TRANSACTION = 'transactions',
  SECURITY = 'security',
  SUB_ACCOUNTS = 'sub-accounts',
  PLATFORM_STATS = 'platform',
  FINANCIAL_STATS = 'financial',
  EVENTS = 'events',
  INVITATIONS = 'invitations',
  DAEMON_LOGS = 'daemon-logs',
  WEBHOOK_LOGS = 'webhook-logs',
  TAGS = 'tags',
  BANK_ACCOUNTS = 'bank-accounts',
  BANK_PAYMENT_ORDERS = 'bank-payment-orders',
  APP_REPORTS = 'app-reports',
  TRACKER = 'tracker',
  RECEIVING_BANKS = 'receiving-banks',
  FREELANCER_BANKS = 'freelancer-banks'
}

const AdminLayout: FC = ({ children }) => {
  const { isSuperAdmin, isAboveAdmin } = useCheckRole();
  const location = useLocation();
  const [openSubMenu, setOpenedSubMenu] = useState<string[]>([]);

  const onOpenChange = (keys: string[]) => {
    const latestOpenKey = keys.find((key) => openSubMenu.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey as SUB_MENU_KEY) === -1) {
      setOpenedSubMenu(keys);
    } else {
      setOpenedSubMenu(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const activeMenu = useMemo(() => {
    if (location.pathname.includes(ROUTES.ADMIN.SETTINGS.SECURITY.PREFIX))
      return MENU_LIST.SECURITY;

    if (location.pathname.includes(ROUTES.ADMIN.SETTINGS.ACCOUNTS.PREFIX))
      return MENU_LIST.SUB_ACCOUNTS;

    if (location.pathname.includes(ROUTES.ADMIN.DASHBOARD.FINANCIAL_STATS))
      return MENU_LIST.FINANCIAL_STATS;

    if (location.pathname.includes(ROUTES.ADMIN.DASHBOARD.PLATFORM_STATS))
      return MENU_LIST.PLATFORM_STATS;

    if (location.pathname.includes(ROUTES.ADMIN.BANK_ACCOUNTS.RECEIVING_BANKS))
      return MENU_LIST.RECEIVING_BANKS;

    if (location.pathname.includes(ROUTES.ADMIN.BANK_ACCOUNTS.FREELANCER_BANKS))
      return MENU_LIST.FREELANCER_BANKS;

    return location.pathname.split('/')[1];
  }, [location]);

  const onLogout = () => {
    AuthApi.logout();
  };

  useEffect(() => {
    if (location.pathname.includes(ROUTES.ADMIN.SETTINGS.PREFIX)) {
      setOpenedSubMenu([SUB_MENU_KEY.SETTINGS]);
    }
  }, [location.pathname]);

  return (
    <Layout className="admin-layout">
      <Sider className="sidebar" width={256} breakpoint="lg" collapsedWidth="0">
        <div>
          <Link
            className="logo-wrapper"
            to={ROUTES.ADMIN.DASHBOARD.PLATFORM_STATS}
          >
            <img
              className="logo"
              src="/images/diligentspace_white_logo_v2.svg"
              alt=""
            />
          </Link>

          <Menu
            className="admin-menus"
            theme="dark"
            mode="inline"
            selectedKeys={[activeMenu]}
            openKeys={openSubMenu}
            onOpenChange={(value) => onOpenChange(value as string[])}
          >
            {isAboveAdmin && (
              <SubMenu
                key={SUB_MENU_KEY.DASHBOARD}
                icon={<DashboardOutlined />}
                title="Dashboard"
              >
                <Menu.Item key={MENU_LIST.PLATFORM_STATS}>
                  <Link to={ROUTES.ADMIN.DASHBOARD.PLATFORM_STATS}>
                    Platform Stats
                  </Link>
                </Menu.Item>

                <Menu.Item key={MENU_LIST.FINANCIAL_STATS}>
                  <Link to={ROUTES.ADMIN.DASHBOARD.FINANCIAL_STATS}>
                    Financial Stats
                  </Link>
                </Menu.Item>
              </SubMenu>
            )}
            <Menu.Item key={MENU_LIST.PROJECT} icon={<ProjectOutlined />}>
              <Link to={ROUTES.ADMIN.PROJECTS.INDEX}>Projects</Link>
            </Menu.Item>
            <Menu.Item key={MENU_LIST.EMPLOYER} icon={<UserOutlined />}>
              <Link to={ROUTES.ADMIN.EMPLOYERS.INDEX}>Employers</Link>
            </Menu.Item>
            <Menu.Item key={MENU_LIST.FREELANCER} icon={<TeamOutlined />}>
              <Link to={ROUTES.ADMIN.FREELANCERS.INDEX}>Freelancers</Link>
            </Menu.Item>
            <Menu.Item key={MENU_LIST.CONTRACTS} icon={<ScheduleOutlined />}>
              <Link to={ROUTES.ADMIN.CONTRACTS.INDEX}>Contracts</Link>
            </Menu.Item>
            {isAboveAdmin && (
              <SubMenu
                key={MENU_LIST.TRANSACTION}
                icon={<TransactionOutlined />}
                title="Transactions"
              >
                <Menu.Item
                  key={MENU_LIST.TRANSACTION}
                  icon={<TransactionOutlined />}
                >
                  <Link to={ROUTES.ADMIN.TRANSACTIONS.INDEX}>Transactions</Link>
                </Menu.Item>
                <Menu.Item
                  key={MENU_LIST.ORDERS}
                  icon={<TransactionOutlined />}
                >
                  <Link to={ROUTES.ADMIN.ORDERS.INDEX}>Orders</Link>
                </Menu.Item>
              </SubMenu>
            )}
            {isAboveAdmin && (
              <>
                <Menu.Item key={MENU_LIST.INVOICE} icon={<FileDoneOutlined />}>
                  <Link to={ROUTES.ADMIN.INVOICES.INDEX}>Invoices</Link>
                </Menu.Item>
              </>
            )}

            {isSuperAdmin && (
              <Menu.Item key={MENU_LIST.EVENTS} icon={<HistoryOutlined />}>
                <Link to={ROUTES.ADMIN.EVENTS.INDEX}>Events</Link>
              </Menu.Item>
            )}

            {isSuperAdmin && (
              <Menu.Item key={MENU_LIST.DAEMON_LOGS} icon={<HistoryOutlined />}>
                <Link to={ROUTES.ADMIN.DAEMON_LOGS.INDEX}>Daemon Logs</Link>
              </Menu.Item>
            )}

            {isSuperAdmin && (
              <Menu.Item
                key={MENU_LIST.WEBHOOK_LOGS}
                icon={<HistoryOutlined />}
              >
                <Link to={ROUTES.ADMIN.WEBHOOK_LOGS.INDEX}>Webhook Logs</Link>
              </Menu.Item>
            )}

            {isSuperAdmin && (
              <Menu.Item key={MENU_LIST.INVITATIONS} icon={<SendOutlined />}>
                <Link to={ROUTES.ADMIN.INVITATIONS.INDEX}>Invitations</Link>
              </Menu.Item>
            )}

            {isSuperAdmin && (
              <Menu.Item key={MENU_LIST.TAGS} icon={<TagOutlined />}>
                <Link to={ROUTES.ADMIN.TAGS.INDEX}>Tags</Link>
              </Menu.Item>
            )}

            {isAboveAdmin && (
              <SubMenu
                key={MENU_LIST.BANK_ACCOUNTS}
                icon={<BankOutlined />}
                title="Bank Accounts"
              >
                <Menu.Item key={MENU_LIST.RECEIVING_BANKS}>
                  <Link to={ROUTES.ADMIN.BANK_ACCOUNTS.RECEIVING_BANKS}>
                    Receiving Banks
                  </Link>
                </Menu.Item>

                <Menu.Item key={MENU_LIST.FREELANCER_BANKS}>
                  <Link to={ROUTES.ADMIN.BANK_ACCOUNTS.FREELANCER_BANKS}>
                    Freelancers Banks
                  </Link>
                </Menu.Item>
              </SubMenu>
            )}

            {isSuperAdmin && (
              <Menu.Item
                key={MENU_LIST.BANK_PAYMENT_ORDERS}
                icon={<DollarOutlined />}
              >
                <Link to={ROUTES.ADMIN.BANK_PAYMENT_ORDERS.INDEX}>
                  Bank Payments
                </Link>
              </Menu.Item>
            )}

            {isSuperAdmin && (
              <Menu.Item key={MENU_LIST.APP_REPORTS} icon={<DollarOutlined />}>
                <Link to={ROUTES.ADMIN.APP_REPORTS.INDEX}>App Reports</Link>
              </Menu.Item>
            )}

            <SubMenu
              key={SUB_MENU_KEY.SETTINGS}
              icon={<SettingOutlined />}
              title="Settings"
            >
              {isAboveAdmin && (
                <Menu.Item
                  key={MENU_LIST.SUB_ACCOUNTS}
                  icon={<UserSwitchOutlined />}
                >
                  <Link to={ROUTES.ADMIN.SETTINGS.ACCOUNTS.INDEX}>
                    Sub-Accounts
                  </Link>
                </Menu.Item>
              )}

              <Menu.Item
                key={MENU_LIST.SECURITY}
                icon={<SecurityScanOutlined />}
              >
                <Link to={ROUTES.ADMIN.SETTINGS.SECURITY.INDEX}>Security</Link>
              </Menu.Item>
              {isSuperAdmin && (
                <Menu.Item key={MENU_LIST.TRACKER} icon={<DollarOutlined />}>
                  <Link to={ROUTES.ADMIN.SETTINGS.TRACKER.INDEX}>Tracker</Link>
                </Menu.Item>
              )}
            </SubMenu>
          </Menu>
        </div>

        <div className="logout-wrapper pb-3">
          <div className="logout-btn" onClick={onLogout}>
            <PoweroffOutlined />
            Logout
          </div>
        </div>
      </Sider>
      <Layout>
        <Header />
        <Content className="layout-content">
          <div
            className="site-layout-background"
            style={{ padding: 0, minHeight: 360 }}
          >
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
