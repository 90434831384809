import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { notification } from 'antd';
import * as Yup from 'yup';
import queryString from 'query-string';
import Input from '@/components/forms/Input';
import Button from '@/components/common/Button';
import { AuthApi } from '@/apis';
import { ROUTES } from '@/constants';
import '../Login/styles.scss';

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required.')
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      'A minimum 8 characters password contains a combination of uppercase and lowercase letter, special letter and number are required.'
    ),
  passwordConfirm: Yup.string()
    .required('Password Confirmation is required.')
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      'A minimum 8 characters password contains a combination of uppercase and lowercase letter, special letter and number are required.'
    )
    .oneOf([Yup.ref('password')], 'Password is not matched.')
});

const SetPassword = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const queryParams = queryString.parse(location.search);
  const { token = '' } = queryParams;

  const handleSubmit = (values: any) => {
    const { password } = values;

    if (token) {
      setLoading(true);
      AuthApi.setPassword({ password, token })
        .then((res) => {
          notification.success({
            message:
              'Password is set successfully. Please sign in with your account.'
          });
          setLoading(false);
          history.push(ROUTES.AUTH.LOGIN);
        })
        .catch((err) => {
          setLoading(false);
          notification.error({ message: err.message });
        });
    }
  };

  const form = useFormik({
    initialValues: {
      password: '',
      passwordConfirm: ''
    },
    validationSchema,
    onSubmit: handleSubmit
  });

  return (
    <div className="login-page py-5">
      <div className="logo-wrapper">
        <img src="/images/diligentspace_logo.svg" alt="logo" />
      </div>

      <div className="login-form-wrapper">
        <h1 className="page-title text-center text-4xl font-weight-normal mb-5">
          Set Password
        </h1>

        <form className="login-form" onSubmit={form.handleSubmit}>
          <Input
            type="password"
            label="Password"
            {...form.getFieldProps('password')}
            error={
              form.errors.password && form.touched.password
                ? form.errors.password
                : ''
            }
            placeholder={'**********'}
            required={true}
          />
          <Input
            type="password"
            label="Password Confirm"
            {...form.getFieldProps('passwordConfirm')}
            error={
              form.errors.passwordConfirm && form.touched.passwordConfirm
                ? form.errors.passwordConfirm
                : ''
            }
            placeholder={'**********'}
            required={true}
          />

          <Button className="w-100 cmt-12" type="submit" loading={loading}>
            Submit
          </Button>

          <p className="mb-0 cmt-12 text-center">
            Already have an account? <Link to={ROUTES.AUTH.LOGIN}>Sign In</Link>
          </p>
        </form>
      </div>
    </div>
  );
};

export default SetPassword;
