import React, { useEffect, useState } from 'react';
import { Row, Col, Skeleton } from 'antd';
import StatusCard from './StatusCard';
import InvitedUsersTable from './InvitedUsersTable';
import { UserApi } from '@/apis';

const cards = [
  {
    label: 'By invitations',
    dataIndex: 'invitationsCount',
    icon: 'send',
    tooltip:
      'Count of invited users by invitations you sent through the platform.'
  },
  {
    label: 'By magic link',
    dataIndex: 'magicLinkCount',
    icon: 'link',
    tooltip: 'Count of invited users by magic links you shared on internet.'
  },
  {
    label: 'Total points',
    dataIndex: 'totalPoint',
    icon: 'award',
    tooltip:
      'Total points you earned by referral sharing and inviting your colleagues.'
  }
];

interface IInvitationsProps {
  userId?: string;
}

const Invitations: React.FC<IInvitationsProps> = ({ userId }) => {
  const [referral, setReferral] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const getReferral = () => {
    if (userId) {
      setLoading(true);
      UserApi.getUserReferral(userId)
        .then((res) => {
          setReferral(res);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (userId) {
      getReferral();
    }
  }, [userId]);

  return (
    <div className="freelancer-payments-page">
      <div className="page-body">
        <div className="cmb-4">
          <Row gutter={24}>
            {cards.map((item, index) => (
              <Col span={24} md={12} lg={8} key={index}>
                <Skeleton
                  loading={loading}
                  active={true}
                  paragraph={{ rows: 2 }}
                >
                  <StatusCard
                    value={referral ? referral[item.dataIndex] : 0}
                    label={item.label}
                    icon={item.icon}
                    tooltip={item.tooltip}
                  />
                </Skeleton>
              </Col>
            ))}
          </Row>
        </div>
        <div className="cmb-20">
          <InvitedUsersTable referral={referral} loading={loading} />
        </div>
      </div>
    </div>
  );
};

export default Invitations;
