import React, { FC } from 'react';
import { Divider } from 'antd';
import { InvoiceModel } from '@/resources/models';
import { DateHelper } from '@/helpers';
import './styles.scss';

export interface IInvoiceFooterProps {
  invoice?: InvoiceModel;
  total: number;
}

const InvoiceFooter: FC<IInvoiceFooterProps> = ({ invoice, total }) => {
  return (
    <div className="invoice-footer">
      <div className="additional-info-title text-heading3">
        {invoice ? 'Additional Information' : ''}
      </div>
      <div className="total-title text-heading3">Totals</div>

      <Divider className="footer-divider cmt-8 cmb-10" />

      <div className="additional-info-section">
        {invoice ? (
          <>
            <p className="text-sm cmb-2">
              Billing date:&nbsp;
              <span className="text-bold">
                {DateHelper.formatDate(
                  invoice.billingPeriod.startDate,
                  'MMM DD, YYYY'
                )}{' '}
                ~{' '}
                {DateHelper.formatDate(
                  invoice.billingPeriod.endDate,
                  'MMM DD, YYYY'
                )}
              </span>
            </p>
            <p className="text-sm cmb-2">
              Due date:&nbsp;
              <span className="text-bold">
                {DateHelper.formatDate(invoice.dueDate, 'MMM DD, YYYY')}
              </span>
            </p>
            <p className="text-sm word-break mb-0">
              Invoice description:&nbsp;
              <span className="text-bold">{invoice.notes}</span>
            </p>
          </>
        ) : (
          <></>
        )}
      </div>

      <div className="price-section">
        <div className="price-labels">
          <p className="text-sm cmb-2">Sub Total:</p>
          <p className="text-sm cmb-2">Service Fee:</p>
          <p className="text-sm mb-0">Net Amount:</p>
        </div>
        <div className="text-right">
          <p className="text-sm text-bold cmb-2">${total.toFixed(2)}</p>
          <p className="text-sm text-bold cmb-2">
            -${(total * 0.1).toFixed(2)}
          </p>
          <p className="text-sm text-bold mb-0">${(total * 0.9).toFixed(2)}</p>
        </div>
      </div>
    </div>
  );
};

export default InvoiceFooter;
