import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { notification, Skeleton } from 'antd';
import { EmployerModel } from '@/resources/models';
import { EmployerApi } from '@/apis';
import { ROLE } from '@/resources/enums';
import BackButton from '@/components/common/BackButton';
import AccountForm from '@/components/pages/AccountForm';
import NoData from '@/components/common/NoData';
import { ROUTES } from '@/constants';
import PageHeader from '@/components/common/PageHeader';

const EditEmployer = () => {
  const history = useHistory();
  const params: { id: string } = useParams();
  const { id } = params;
  const [loading, setLoading] = useState(false);
  const [employer, setEmployer] = useState<EmployerModel | undefined>();

  const updateEmployer = async (data: any) => {
    if (employer) {
      try {
        await EmployerApi.update(id, { user: data });
        notification.success({ message: 'Employer is updated successfully.' });
        history.push(ROUTES.ADMIN.EMPLOYERS.INDEX);
      } catch (err) {
        notification.error({ message: err.message });
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    EmployerApi.find(id)
      .then((data) => {
        setLoading(false);
        setEmployer(data);
      })
      .catch((err) => {
        setLoading(false);
        notification.error({ message: err.message });
      });
  }, [id]);

  return (
    <div>
      <div className="cmb-17">
        <BackButton />
      </div>

      <PageHeader title="Edit Employer" />

      <Skeleton loading={loading} paragraph={{ rows: 10 }}>
        {employer?.user ? (
          <AccountForm
            user={employer?.user}
            onSubmit={updateEmployer}
            type={ROLE.EMPLOYER}
          />
        ) : (
          <NoData title="Not found employer" />
        )}
      </Skeleton>
    </div>
  );
};

export default EditEmployer;
