import React, { FC } from 'react';
import { ProjectModel, ProposalModel } from '@/resources/models';
import { PAYMENT_TYPE, PROPOSAL_STATUS } from '@/resources/enums';
import { DateHelper } from '@/helpers';
import './styles.scss';

export interface IWorkStatusProps {
  project: ProjectModel;
  proposal?: ProposalModel;
}

const WorkStatus: FC<IWorkStatusProps> = ({ project, proposal }) => {
  return (
    <div className="work-status-section">
      <h3 className="cmb-8">Work Status</h3>

      {project.paymentType === PAYMENT_TYPE.HOURLY && (
        <p className="text-xs cmb-4">
          Total paid hours:&nbsp;
          <span className="text-bold">
            {Number(project?.totalPaidHours || 0).toFixed(2)} hrs
          </span>
        </p>
      )}

      <p className="text-xs mb-0">
        Total paid:&nbsp;
        <span className="text-bold">
          ${Number(project.totalPaid).toFixed(2)}
        </span>
      </p>

      {proposal?.status === PROPOSAL_STATUS.AWARDED && (
        <>
          <p className="text-xs cmt-4 mb-0">
            Started on:&nbsp;
            <span className="text-bold">
              {DateHelper.formatDate(proposal?.contractAt, 'MMM DD, YYYY')}
            </span>
          </p>

          <p className="text-xs cmt-4 mb-0">
            Status:&nbsp;
            <span className="text-bold">Ongoing</span>
          </p>
        </>
      )}

      {proposal?.status === PROPOSAL_STATUS.CLOSED && (
        <>
          <p className="text-xs cmt-4 mb-0">
            Started on:&nbsp;
            <span className="text-bold">
              {DateHelper.formatDate(proposal?.contractAt, 'MMM DD, YYYY')}
            </span>
          </p>

          <p className="text-xs cmt-4 mb-0">
            Ended on:&nbsp;
            <span className="text-bold">
              {DateHelper.formatDate(proposal?.endAt, 'MMM DD, YYYY')}
            </span>
          </p>

          <p className="text-xs cmt-4 mb-0">
            Status:&nbsp;
            <span className="text-bold">Ended</span>
          </p>
        </>
      )}
    </div>
  );
};

export default WorkStatus;
