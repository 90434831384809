import { ROLE } from '@/resources/enums';

export const getDetailLink = (row: any) => {
  switch (row.objectType) {
    case 'project':
      return `/projects/${row.objectId}/details`;
    case 'contract':
      return `/contracts/${row.objectId}/details`;
    case 'transaction':
      return `/transactions/${row.objectId}/details`;
    case 'invoice':
      return `/invoices/${row.objectId}/details`;
    case 'user':
      if (row.data.object.role === ROLE.EMPLOYER) {
        return `/employers/${row.data.object.accountId}/details`;
      } else {
        return `/freelancers/${row.data.object.accountId}/details`;
      }
    case 'employer':
      return `/employers/${row.objectId}/details`;
    case 'freelancer':
      return `/freelancers/${row.objectId}/details`;
    case 'admin_user':
      return `settings/accounts/${row.objectId}`;
    default:
      return '/';
  }
};
