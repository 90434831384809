import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Skeleton, Table, Row, Col } from 'antd';
import Pagination from '@/components/common/Pagination';
import { WebhookLogApi } from '@/apis';
import { DateHelper, IQueryField, NameHelper, QueryHelper } from '@/helpers';
import PageHeader from '@/components/common/PageHeader';
import Select from '@/components/forms/Select';
import FilterChipList from '@/components/common/filters/FilterChipList';
import DateRangePicker from '@/components/forms/DateRangePicker';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router';
import {
  TRANSACTION_METHOD_VALUES,
  WEBHOOK_LOG_STATUS,
  WEBHOOK_LOG_STATUS_VALUES
} from 'src/resources/enums';
import Badge, { BADGE_STATUS } from 'src/components/common/Badge';
import './styles.scss';
import IconButton from '@/components/common/IconButton';
import WebhookLogDetailModal from '@/components/pages/WebhookLogs/WebhookLogDetailModal';

const filterFields: IQueryField[] = [
  { name: 'page', type: 'number', default: 1 },
  { name: 'perPage', type: 'number', default: 10 },
  { name: 'type', type: 'string', default: undefined },
  { name: 'status', type: 'string', default: undefined },
  {
    name: 'startDate',
    type: 'dateRange',
    default: undefined
  },
  {
    name: 'endDate',
    type: 'dateRange',
    default: undefined
  }
];

const WebhookLogsPage = () => {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [webhookLogs, setWebhookLogs] = useState<any>([]);
  const location = useLocation();
  const history = useHistory();
  const [selectedRecord, setSelectedRecord] = useState<any>();
  const [showDetailModal, setShowDetailModal] = useState(false);

  const onClickRow = (e: any, record: any) => {
    e.stopPropagation();
    setShowDetailModal(true);
    setSelectedRecord(record);
  };

  const columns = useMemo(
    () => [
      {
        title: 'Type',
        dataIndex: 'type',
        render: (type: string) => {
          return <Badge title={NameHelper.getTransactionMethodLabel(type)} />;
        }
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (status: string) => {
          return (
            <Badge
              status={
                status === WEBHOOK_LOG_STATUS.SUCCESS
                  ? BADGE_STATUS.SUCCESS
                  : BADGE_STATUS.DANGER
              }
              title={NameHelper.getWebhookStatusLabel(status)}
            />
          );
        }
      },
      {
        title: 'Data',
        dataIndex: 'data',
        render: (data: any) => {
          return (
            <div className="data-column">
              {data ? JSON.stringify(data) : ''}
            </div>
          );
        }
      },
      {
        title: 'Date',
        dataIndex: 'createdAt',
        render: (date: string) => (
          <div>{DateHelper.formatDate(date, 'DD MMM YYYY, hh:mm:ss A')}</div>
        )
      },
      {
        title: 'Action',
        dataIndex: 'action',
        render: (_: string, record: any) => (
          <div>
            <IconButton
              icon="file-minus"
              onClick={(e) => onClickRow(e, record)}
            />
          </div>
        )
      }
    ],
    []
  );

  const filter = useMemo(() => {
    return QueryHelper.parseQuery(location.search, filterFields, true);
  }, [location.search]);

  const typeOptions = useMemo(() => {
    return TRANSACTION_METHOD_VALUES.map((item) => ({
      value: item,
      title: NameHelper.getTransactionMethodLabel(item)
    }));
  }, []);

  const statusOptions = useMemo(() => {
    return WEBHOOK_LOG_STATUS_VALUES.map((item) => ({
      value: item,
      title: NameHelper.getWebhookStatusLabel(item)
    }));
  }, []);

  useEffect(() => {
    setLoading(true);
    WebhookLogApi.search({
      ...filter,
      'createdAt>': filter.dateRange.startDate
        ? moment(filter.dateRange.startDate).startOf('day').toISOString()
        : undefined,
      'createdAt<': filter.dateRange.endDate
        ? moment(filter.dateRange.endDate).endOf('day').toISOString()
        : undefined
    })
      .then((res) => {
        const { totalCount, data } = res;
        setTotal(totalCount);
        setWebhookLogs(data);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [filter]);

  const dataSource = useMemo(() => {
    return webhookLogs.map((item: any) => ({
      key: item.id,
      ...item
    }));
  }, [webhookLogs]);

  const onChangeFilter = useCallback(
    (field: string, value: any, isPageUpdate = true) => {
      const newFilter = QueryHelper.getFilterToQuery(
        filter,
        field,
        value,
        isPageUpdate
      );

      const query = QueryHelper.stringifyQuery(newFilter);
      history.replace(`${location.pathname}?${query}`);
    },
    [filter, location.pathname]
  );

  const clearFilter = () => {
    history.replace(location.pathname);
  };

  return (
    <div className="webhook-logs-page">
      <PageHeader title="Webhook Logs" />

      <div className="log-filters">
        <div className="cmb-10">
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Select
                label="Type"
                showChipList={false}
                value={filter.type}
                onChange={(value) => onChangeFilter('type', value)}
                options={typeOptions}
              />
            </Col>
            <Col span={12}>
              <Select
                label="Status"
                showChipList={false}
                value={filter.status}
                onChange={(value) => onChangeFilter('status', value)}
                options={statusOptions}
              />
            </Col>
            <Col span={12}>
              <DateRangePicker
                label="Dates"
                dateRange={filter.dateRange}
                setDateRange={(value) => onChangeFilter('dateRange', value)}
              />
            </Col>
          </Row>
        </div>
      </div>

      <div>
        <FilterChipList
          filter={filter}
          onFilterChange={onChangeFilter}
          clearFilter={clearFilter}
          filterType="webhookLog"
        />
      </div>

      <Skeleton loading={loading} paragraph={{ rows: 0 }} active={true}>
        <p className="mt-3 mb-2 text-md">{total} logs found</p>
      </Skeleton>

      <Table
        className="custom-ant-table"
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />

      <div className="d-flex-center cmt-10">
        <Pagination
          total={total}
          current={filter.page}
          pageSize={filter.perPage}
          onChange={(page) => onChangeFilter('page', page)}
        />
      </div>

      <WebhookLogDetailModal
        onClose={() => setShowDetailModal(false)}
        open={showDetailModal}
        webhookLog={selectedRecord}
      />
    </div>
  );
};

export default WebhookLogsPage;
