export enum SPINNER_ACTION {
  START_LOADING = 'START_LOADING',
  FINISH_LOADING = 'FINISH_LOADING'
}

export const AUTH_ACTION = {
  SET_ACCOUNT: 'SET_ACCOUNT',
  PATCH_ACCOUNT: 'PATCH_ACCOUNT',
  LOGOUT: 'LOGOUT',
  SET_TOKENS: 'SET_TOKENS',

  TFA: {
    FETCH_2FA: 'FETCH_2FA',
    VERIFY_2FA: 'VERIFY_2FA',
    ENABLE_2FA: 'ENABLE_2FA',
    DISABLE_2FA: 'DISABLE_2FA'
  }
};

export enum SKILLS_ACTION {
  GET_SKILLS = 'GET_SKILLS'
}

export enum COMMON_ACTION {
  SET_COMMON_DATA = 'COMMON_ACTION/SET_COMMON_DATA',
  SET_LANGUAGE = 'COMMON_ACTION/SET_LANGUAGE'
}

export enum NOTIFICATION_ACTION {
  SET_NOTIFICATIONS = 'SET_NOTIFICATIONS',
  READ_ALL_NOTIFICATIONS = 'READ_ALL_NOTIFICATIONS',
  REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION',
  REMOVE_ALL_NOTIFICATIONS = 'REMOVE_ALL_NOTIFICATIONS',
  READ_NOTIFICATION = 'READ_NOTIFICATION',
  ADD_NOTIFICATION = 'ADD_NOTIFICATION'
}

export enum MESSAGE_ACTION {
  SET_MESSAGES = 'MESSAGE_ACTION/SET_MESSAGES',
  ADD_MESSAGE = 'MESSAGE_ACTION/ADD_MESSAGE',
  REMOVE_MESSAGE = 'MESSAGE_ACTION/REMOVE_MESSAGE',
  TOGGLE_BOOKMARK = 'MESSAGE_ACTION/TOGGLE_BOOKMARK',
  TOGGLE_SPAM = 'MESSAGE_ACTION/TOGGLE_SPAM',
  SET_ACTIVE_CHANNEL = 'MESSAGE_ACTION/SET_ACTIVE_CHANNEL',
  SET_CHANNELS = 'MESSAGE_ACTION/SET_CHANNELS',
  SET_UNREAD_MESSAGE_COUNT = 'MESSAGE_ACTION/SET_UNREAD_MESSAGE_COUNT',
  UPDATE_FREELANCER_SETTINGS = 'MESSAGE_ACTION/UPDATE_FREELANCER_SETTINGS',
  UPDATE_EMPLOYER_SETTINGS = 'MESSAGE_ACTION/UPDATE_EMPLOYER_SETTINGS',
  READ_ALL_CHANNEL_MESSAGES = 'MESSAGE_ACTION/READ_ALL_CHANNEL_MESSAGES'
}

export enum TIME_ACTION {
  SET_TIME_LOGS = 'TIME_ACTION/SET_TIME_LOGS',
  ADD_TIME_LOG = 'TIME_ACTION/ADD_TIME_LOG',
  PATCH_TIME_LOG = 'TIME_ACTION/PATCH_TIME_LOG',
  REMOVE_TIME_LOG = 'TIME_ACTION/REMOVE_TIME_LOG',
  STOP_TIME_TRACKING = 'TIME_ACTION/STOP_TIME_TRACKING',
  SET_TRACKABLE_PROJECTS = 'TIME_ACTION/SET_TRACKABLE_PROJECTS',
  SET_DESCRIPTION = 'TIME_ACTION/SET_DESCRIPTION',
  SET_TRACKING_TIME_LOG = 'TIME_ACTION/SET_TRACKING_TIME_LOG',
  SET_TRACKING_PROJECT = 'TIME_ACTION/SET_TRACKING_PROJECT',
  SET_TRACKING_START_TIME = 'TIME_ACTION/SET_TRACKING_START_TIME'
}

export const EMPLOYER_ACTIONS = {
  DASHBOARD: {
    ADD_PROJECT: 'EMPLOYER_ACTIONS/DASHBOARD/ADD_PROJECT',
    SET_RECENT_PROJECTS: 'EMPLOYER_ACTIONS/DASHBOARD/SET_RECENT_PROJECTS'
  },
  PROJECTS: {
    ADD_PROJECT: 'EMPLOYER_ACTIONS/PROJECTS/ADD_PROJECT',
    UPDATE_PROJECT: 'EMPLOYER_ACTIONS/PROJECTS/UPDATE_PROJECT'
  }
};
