import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { notification, Tabs } from 'antd';
import { EMPLOYER_DETAILS_ROUTES, ROUTES } from '@/constants';
import BackButton from '@/components/common/BackButton';
import UserDetails from '@/pages/Admin/EmployerDetails/UserDetails';
import EmployerMessages from './EmployerMessages';
import PageHeader from '@/components/common/PageHeader';
import ChangeLogSection from '@/components/pages/ChangeLogSection';
import ActionHistories from '@/components/pages/Settings/ActionHisotries';
import EmployerSettings from './EmployerSettings';
import { useCheckRole } from 'src/hooks';
import FeeExemptions from 'src/components/pages/FeeExemptions';
import { EmployerApi } from 'src/apis';
import { USER_STATUS } from 'src/resources/enums';
import { EmployerModel } from 'src/resources/models';
import Invitations from '@/components/pages/Invitations';
import Badge, { BADGE_STATUS } from '@/components/common/Badge';
import IconButton from '@/components/common/IconButton';
import SelectTagModal from '@/pages/Admin/EmployerDetails/SelectTagModal';

const EmployerDetails: FC = () => {
  const history = useHistory();
  const { tab: activeTab, id } = useParams<{ tab: string; id: string }>();
  const { isAboveAdmin } = useCheckRole();
  const [loading, setLoading] = useState(false);
  const [employer, setEmployer] = useState<EmployerModel | undefined>();
  const [userStatus, setUserStatus] = useState<USER_STATUS | undefined>(
    USER_STATUS.OPENED
  );
  const [isInternalUser, setIsInternalUser] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const getEmployer = async () => {
    try {
      setLoading(true);
      const data = await EmployerApi.find(id);

      setEmployer(data);
      setUserStatus(data.status);
      setIsInternalUser(data.isInternal);
    } catch (err) {
      notification.error({ message: err.message });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getEmployer().then();
  }, [id]);

  return (
    <div className="admin-employer-details-page">
      <div className="cmb-17">
        <BackButton />
      </div>

      <PageHeader title="Employer Details">
        {employer?.isInternal && (
          <div className="d-flex align-items-center">
            <Badge
              className="ml-3"
              title={employer?.tag?.name || ''}
              status={BADGE_STATUS.SUCCESS}
            />
            <IconButton icon="edit" onClick={() => setIsOpen(true)} />
          </div>
        )}
      </PageHeader>

      <Tabs
        activeKey={activeTab}
        onChange={(tab) =>
          history.replace(`${ROUTES.ADMIN.EMPLOYERS.PREFIX}/${id}/${tab}`)
        }
      >
        <Tabs.TabPane
          key={EMPLOYER_DETAILS_ROUTES.DETAILS}
          tabKey={EMPLOYER_DETAILS_ROUTES.DETAILS}
          tab="Details"
        />

        {isAboveAdmin && (
          <>
            <Tabs.TabPane
              key={EMPLOYER_DETAILS_ROUTES.MESSAGES}
              tabKey={EMPLOYER_DETAILS_ROUTES.MESSAGES}
              tab="Messages"
            />
            <Tabs.TabPane
              key={EMPLOYER_DETAILS_ROUTES.EVENTS}
              tabKey={EMPLOYER_DETAILS_ROUTES.EVENTS}
              tab="Change Logs"
            />
            <Tabs.TabPane
              key={EMPLOYER_DETAILS_ROUTES.ACTION_HISTORIES}
              tabKey={EMPLOYER_DETAILS_ROUTES.ACTION_HISTORIES}
              tab="Action Histories"
            />
            <Tabs.TabPane
              key={EMPLOYER_DETAILS_ROUTES.FEE_EXEMPTIONS}
              tabKey={EMPLOYER_DETAILS_ROUTES.FEE_EXEMPTIONS}
              tab="Fee Exemptions"
            />
            <Tabs.TabPane
              key={EMPLOYER_DETAILS_ROUTES.REFERRALS}
              tabKey={EMPLOYER_DETAILS_ROUTES.REFERRALS}
              tab="Referrals"
            />
            <Tabs.TabPane
              key={EMPLOYER_DETAILS_ROUTES.SETTINGS}
              tabKey={EMPLOYER_DETAILS_ROUTES.SETTINGS}
              tab="Settings"
            />
          </>
        )}
      </Tabs>

      {activeTab === EMPLOYER_DETAILS_ROUTES.DETAILS && (
        <UserDetails
          loading={loading}
          employer={employer}
          userStatus={userStatus}
          isInternalUser={isInternalUser}
          setUserStatus={setUserStatus}
          setIsInternalUser={setIsInternalUser}
          onRefresh={getEmployer}
        />
      )}

      {isAboveAdmin && activeTab === EMPLOYER_DETAILS_ROUTES.MESSAGES && (
        <EmployerMessages />
      )}
      {isAboveAdmin && activeTab === EMPLOYER_DETAILS_ROUTES.EVENTS && (
        <ChangeLogSection objectType="employer" objectId={id} />
      )}
      {isAboveAdmin &&
        activeTab === EMPLOYER_DETAILS_ROUTES.ACTION_HISTORIES && (
          <ActionHistories type="employeer" />
        )}
      {isAboveAdmin && activeTab === EMPLOYER_DETAILS_ROUTES.FEE_EXEMPTIONS && (
        <FeeExemptions userId={employer?.user?.id} />
      )}
      {isAboveAdmin && activeTab === EMPLOYER_DETAILS_ROUTES.REFERRALS && (
        <Invitations userId={employer?.user?.id} />
      )}
      {isAboveAdmin && activeTab === EMPLOYER_DETAILS_ROUTES.SETTINGS && (
        <EmployerSettings />
      )}
      <SelectTagModal
        employer={employer}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onConfirm={getEmployer}
      />
    </div>
  );
};

export default EmployerDetails;
