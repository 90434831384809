import { IAttachment, IMilestone, IProposal } from '@/resources/interfaces';
import { ProjectModel } from '@/resources/models/project.model';
import { FreelancerModel } from '@/resources/models/freelancer.model';
import {
  DELIVERY_TYPE,
  PROPOSAL_CLOSED_REASON,
  PROPOSAL_STATUS
} from '@/resources/enums';
import { EmployerModel } from '@/resources/models/employer.model';

export class ProposalModel {
  id: string;
  project: string | ProjectModel;
  freelancer: string | FreelancerModel;
  employer: string | EmployerModel;
  coverLetter: string;
  description: string;
  price: number;
  deliveryTime: string;
  deliveryType: DELIVERY_TYPE;
  milestones?: IMilestone[];
  status: PROPOSAL_STATUS;
  originalStatus: PROPOSAL_STATUS;
  attachment?: IAttachment;
  isShortlisted: boolean;
  isMessaged: boolean;
  closedReason?: PROPOSAL_CLOSED_REASON;
  createdAt: string;
  updatedAt: string;
  contractAt?: string;
  endAt?: string;
  billing?: number;
  totalPaid: number;
  totalPaidHours: number;

  constructor(init?: IProposal) {
    const data = {
      id: '',
      coverLetter: '',
      description: '',
      price: 0,
      deliveryTime: '',
      deliveryType: DELIVERY_TYPE.HOURLY,
      status: PROPOSAL_STATUS.ACTIVE,
      originalStatus: PROPOSAL_STATUS.ACTIVE,
      isShortlisted: false,
      paid: 0,
      createdAt: '',
      updatedAt: '',
      isMessaged: false,
      ...init
    };

    this.id = data.id;
    this.project =
      typeof data.project === 'string'
        ? data.project
        : new ProjectModel(data.project);
    this.freelancer =
      typeof data.freelancer === 'string'
        ? data.freelancer
        : new FreelancerModel(data.freelancer);
    this.employer =
      typeof data.employer === 'string'
        ? data.employer
        : new EmployerModel(data.employer);
    this.coverLetter = data.coverLetter;
    this.description = data.description;
    this.price = data.price;
    this.deliveryTime = data.deliveryTime;
    this.deliveryType = data.deliveryType;
    this.milestones = data.milestones;
    this.status = data.status;
    this.originalStatus = data.originalStatus;
    this.attachment = data.attachment;
    this.isShortlisted = data.isShortlisted;
    this.isMessaged = data.isMessaged;
    this.closedReason = data.closedReason;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.contractAt = data.contractAt;
    this.endAt = data.endAt;
    this.billing = data.billing;
    this.totalPaid = data.totalPaid || 0;
    this.totalPaidHours = data.totalPaidHours || 0;
  }
}
