import { ITag } from '@/resources/interfaces';

export class TagModel {
  id: string;
  name: string;

  constructor(data: ITag) {
    this.id = data.id;
    this.name = data.name;
  }
}
