import React from 'react';
import Messages from '@/components/pages/Messages';
import './styles.scss';

const ProjectMessages = () => {
  return (
    <div>
      <Messages type="project" />
    </div>
  );
};

export default ProjectMessages;
