import React, { FC, useState } from 'react';
import { Download as DownloadIcon } from 'react-feather';
import { Dropdown, Menu, notification, Spin, Tooltip } from 'antd';
import {
  CheckOutlined,
  FileOutlined as AttachmentIcon,
  FrownOutlined,
  MoreOutlined as MoreVertIcon
} from '@ant-design/icons';
import { ChannelModel, MessageModel } from '@/resources/models';
import { DateHelper, FileHelper } from '@/helpers';
import { MessageApi } from '@/apis';
import Avatar from '@/components/common/Avatar';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog';
import './styles.scss';

export interface IMessageCardProps {
  message: MessageModel;
  channel: ChannelModel;
  onRemove: () => void;
}

const MessageCard: FC<IMessageCardProps> = ({ message, channel, onRemove }) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [removing, setRemoving] = useState(false);

  const remove = () => {
    setRemoving(true);
    MessageApi.delete(message.id)
      .then(() => {
        setRemoving(false);
        onRemove();
      })
      .catch((err) => {
        setRemoving(false);
        notification.error({ message: err.message });
      });
  };

  return (
    <Spin spinning={removing}>
      {message.receiver === channel.employer.user?.id ? (
        <div className="message-item-received mb-2">
          <Avatar
            src={channel.employer.user?.avatar}
            name={channel.employer.user?.fullName}
            size="2.5rem"
            className="cmr-8"
            round={true}
          />
          <div className="message-info">
            <p className="text-xs font-weight-bold mb-1">
              {channel.employer.user?.fullName}
            </p>
            {!!message.content && (
              <div
                className="message-content"
                dangerouslySetInnerHTML={{ __html: message.content }}
              />
            )}
            <div className="d-flex align-items-center mt-1">
              <p className="text-xs text-gray mb-0">
                {DateHelper.formatDate(message.createdAt, 'HH:mm')}
              </p>
              {message.spam && <FrownOutlined className="cml-4" />}
              {message.read && <CheckOutlined className="status-icon cml-4" />}
            </div>

            {message.attachment && (
              <div className="message-attachment">
                <AttachmentIcon className="attachment-icon" />
                <Tooltip
                  overlayClassName="custom-tooltip"
                  getPopupContainer={(triggerNode) =>
                    triggerNode.parentNode as HTMLElement
                  }
                  title={message.attachment.filename}
                >
                  <span className="attachment-name">
                    {message.attachment.filename}
                  </span>
                </Tooltip>
                <DownloadIcon
                  className="download-icon"
                  onClick={() =>
                    FileHelper.download(
                      message.attachment?.url as string,
                      message.attachment?.filename as string
                    )
                  }
                />
              </div>
            )}
          </div>
          <div className="message-actions ml-2">
            <div className="d-inline-flex-flex position-relative">
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item onClick={() => setShowConfirmDialog(true)}>
                      Delete Message
                    </Menu.Item>
                  </Menu>
                }
                getPopupContainer={(triggerNode) =>
                  triggerNode.parentNode as HTMLElement
                }
              >
                <MoreVertIcon className="more-icon" />
              </Dropdown>
            </div>
          </div>
        </div>
      ) : (
        <div className="message-item-sent-wrapper mb-2">
          <div className="message-item-sent">
            <div className="d-flex align-items-center mb-1">
              <div className="message-actions mr-2">
                <div className="d-inline-flex position-relative">
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item onClick={() => setShowConfirmDialog(true)}>
                          Delete Message
                        </Menu.Item>
                      </Menu>
                    }
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentNode as HTMLElement
                    }
                  >
                    <MoreVertIcon className="more-icon" />
                  </Dropdown>
                </div>
              </div>
              <p className="text-xs font-weight-bold mb-0">
                {channel.freelancer.user?.fullName}
              </p>
            </div>
            {!!message.content && (
              <div
                className="message-content"
                dangerouslySetInnerHTML={{ __html: message.content }}
              />
            )}
            <div className="d-flex align-items-center mt-1">
              <span className="text-xs text-gray cmr-4">
                {DateHelper.formatDate(message.createdAt, 'HH:mm')}
              </span>
              {message.read && <CheckOutlined className="status-icon" />}
            </div>
            {message.attachment && (
              <div className="message-attachment">
                <AttachmentIcon className="attachment-icon" />
                <Tooltip
                  overlayClassName="custom-tooltip"
                  getPopupContainer={(triggerNode) =>
                    triggerNode.parentNode as HTMLElement
                  }
                  title={message.attachment.filename}
                >
                  <span className="attachment-name">
                    {message.attachment.filename}
                  </span>
                </Tooltip>
                <DownloadIcon
                  className="download-icon"
                  onClick={() =>
                    FileHelper.download(
                      message.attachment?.url as string,
                      message.attachment?.filename as string
                    )
                  }
                />
              </div>
            )}
          </div>

          <Avatar
            src={channel.freelancer.user?.avatar}
            name={channel.freelancer.user?.fullName}
            size="2.5rem"
            className="cml-8"
            round={true}
          />
        </div>
      )}
      <ConfirmDialog
        visible={showConfirmDialog}
        handleClose={() => setShowConfirmDialog(false)}
        onConfirm={remove}
        text="Are you sure you want to remove this message?"
        okText="Confirm"
        cancelText="Cancel"
      />
    </Spin>
  );
};

export default MessageCard;
