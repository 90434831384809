import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { notification, Skeleton, Tabs } from 'antd';
import { LeftOutlined as BackIcon } from '@ant-design/icons';
import { TransactionApi } from '@/apis';
import { TransactionModel } from '@/resources/models';
import TransactionDetailInfo from './TransactionDetailInfo';
import './styles.scss';
import { ROUTES } from '@/constants';
import ChangeLogSection from '@/components/pages/ChangeLogSection';

const TransactionDetails: FC = () => {
  const history = useHistory();

  const params: { id: string; tab: string } = useParams();
  const { id, tab: activeTab } = params;

  const [transaction, setTransaction] = useState<TransactionModel>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    TransactionApi.find(id)
      .then((res) => {
        setLoading(false);
        setTransaction(res);
      })
      .catch((err) => {
        setLoading(false);
        notification.error({ message: err.message });
      });
  }, [id]);

  return (
    <div className="admin-transaction-details-page">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="back-btn" onClick={() => history.go(-1)}>
          <BackIcon />
          <span className="ml-1 text-sm">Back</span>
        </div>
      </div>

      <Tabs
        activeKey={activeTab}
        onChange={(tab) =>
          history.replace(`${ROUTES.ADMIN.TRANSACTIONS.PREFIX}/${id}/${tab}`)
        }
      >
        <Tabs.TabPane key="details" tabKey="details" tab="Details" />

        <Tabs.TabPane key="events" tabKey="events" tab="Change Logs" />
      </Tabs>

      {activeTab === 'details' ? (
        <Skeleton loading={loading} paragraph={{ rows: 10 }} active={true}>
          {transaction && <TransactionDetailInfo transaction={transaction} />}
        </Skeleton>
      ) : (
        <ChangeLogSection objectType="invoice" objectId={id} />
      )}
    </div>
  );
};

export default TransactionDetails;
