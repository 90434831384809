import React, { FC, useMemo } from 'react';
import Ribbon from '@/components/common/Ribbon';
import { USER_STATUS } from '@/resources/enums';

export interface UserStatusProps {
  status: string;
}

const UserStatusRibbon: FC<UserStatusProps> = ({ status }) => {
  const ribbonType = useMemo(() => {
    if (status === USER_STATUS.OPENED) return 'normal';

    return 'danger';
  }, [status]);

  return <Ribbon type={ribbonType} content={status} />;
};

export default UserStatusRibbon;
