import qs from 'query-string';
import moment from 'moment';
import { ObjectHelper } from '@/helpers/object.helper';
import { NameHelper } from '@/helpers/name.helper';

type QueryType =
  | 'string'
  | 'number'
  | 'dateRange'
  | 'array'
  | 'boolean'
  | 'country-array'
  | 'other';

export interface IQueryField {
  name: string;
  type: QueryType;
  default: any;
}

export class QueryHelper {
  static parseValue(value: any, type: QueryType, defaultValue: any) {
    if (value === undefined) return defaultValue;

    switch (type) {
      case 'number':
        return Number(value);
      case 'boolean':
        return JSON.parse(value);
      case 'array':
        return Array.isArray(value) ? value : [value];
      case 'country-array': {
        const countryCodes = Array.isArray(value) ? value : [value];
        return countryCodes.map((code) =>
          NameHelper.getCountryIdFromCode(code)
        );
      }
      case 'dateRange':
        return value ? moment(value).toDate() : '';
      default:
        return value;
    }
  }

  static stringifyQuery(data: any) {
    return qs.stringify(ObjectHelper.trimQuery(data), { arrayFormat: 'comma' });
  }

  static parseQuery(
    queryString: string,
    fields: IQueryField[],
    hasDateRange = false
  ) {
    const query = qs.parse(queryString, { arrayFormat: 'comma' });

    const result: any = {};
    fields.forEach((field) => {
      result[field.name] = this.parseValue(
        query[field.name],
        field.type,
        field.default
      );
    });

    if (hasDateRange) {
      const { startDate, endDate, ...rest } = result;

      return {
        ...rest,
        dateRange: {
          startDate: startDate || undefined,
          endDate: endDate || undefined
        }
      };
    }

    return result;
  }

  static getFilterToQuery(
    filter: any,
    name: string,
    value: any,
    isPageUpdate = true
  ) {
    const { dateRange = {}, ...rest } = filter;

    if (name === 'dateRange') {
      const { startDate, endDate } = value;
      return {
        ...rest,
        startDate: startDate ? moment(startDate).toISOString() : undefined,
        endDate: endDate ? moment(endDate).toISOString() : undefined,
        page: 1
      };
    }

    const { startDate, endDate } = dateRange;

    return {
      ...rest,
      startDate: startDate ? moment(startDate).toISOString() : undefined,
      endDate: endDate ? moment(endDate).toISOString() : undefined,
      ...(name !== 'country'
        ? {
            [name]: value,
            country: filter.country
              ? filter.country.map((id: string) =>
                  NameHelper.getCountryCodeFromId(id)
                )
              : undefined
          }
        : {
            country: value.map((id: string) =>
              NameHelper.getCountryCodeFromId(id)
            )
          }),
      ...(name !== 'page' && isPageUpdate ? { page: 1 } : {})
    };
  }

  static convertFilterToQuery(filter: any) {
    const { dateRange, country, hourlyPrice, ...query } = filter;

    if (dateRange) {
      const { startDate, endDate } = dateRange;
      if (startDate) {
        query.startDate = moment(startDate).toISOString();
      }
      if (endDate) {
        query.endDate = moment(endDate).toISOString();
      }
    }

    if (country?.length) {
      query.country = country.map((id: string) =>
        NameHelper.getCountryCodeFromId(id)
      );
    }

    if (hourlyPrice) {
      query.hourlyPrice = hourlyPrice.replace('~', ',');
    }

    return query;
  }
}
