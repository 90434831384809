import React, { FC } from 'react';
import LongText from '@/components/common/LongText';
import { IExperience } from '@/resources/interfaces';
import { DateHelper } from '@/helpers';
import Icon from '@/components/common/Icon';
import './styles.scss';

export interface IExperienceCardProps {
  experience: IExperience;
}

const ExperienceCard: FC<IExperienceCardProps> = ({ experience }) => {
  return (
    <div className="experience-card">
      <div className="experience-card__header">
        {experience.position && experience.companyName && (
          <p className="card-title text-ellipsis">
            {experience.position} | {experience.companyName}
          </p>
        )}
      </div>

      <div className="d-flex flex-wrap cmb-7">
        {!!experience.employmentType && (
          <div className="d-flex align-items-center cmr-10">
            <Icon name="briefcase" className="label-icon" />
            <span className="text-sm">{experience.employmentType}</span>
          </div>
        )}

        {experience.startDate && experience.endDate && (
          <div className="d-flex align-items-center cmr-10">
            <Icon name="clock" className="label-icon" />
            <span className="text-sm">
              {DateHelper.getDateRangeLabel(
                experience.startDate,
                experience.endDate
              )}
            </span>
          </div>
        )}

        {experience.location && (
          <div className="d-flex align-items-center cmr-10">
            <Icon name="map-marker" className="label-icon" />
            <span className="text-sm">{experience.location}</span>
          </div>
        )}
      </div>

      <LongText text={experience.description || ''} />
    </div>
  );
};

export default ExperienceCard;
