import React, { FC, useMemo } from 'react';
import Badge, { BADGE_STATUS } from '@/components/common/Badge';
import { PAYMENT_STATUS } from '@/resources/enums';
import { NameHelper } from '@/helpers';

export interface PaymentStatusProps {
  status: string;
  className?: string;
}

const PaymentStatus: FC<PaymentStatusProps> = ({ status, className = '' }) => {
  const badgeStatus = useMemo(() => {
    if (status === PAYMENT_STATUS.PAID) return BADGE_STATUS.SUCCESS;

    if (status === PAYMENT_STATUS.PENDING) return BADGE_STATUS.WARNING;

    if (
      status === PAYMENT_STATUS.DECLINED ||
      status === PAYMENT_STATUS.DISABLED
    )
      return BADGE_STATUS.DANGER;

    return BADGE_STATUS.NORMAL;
  }, [status]);

  const badgeText = useMemo(() => {
    return NameHelper.getPaymentStatusLabel(status);
  }, [status]);

  return <Badge className={className} title={badgeText} status={badgeStatus} />;
};

export default PaymentStatus;
