import moment, { Moment } from 'moment';

export class DateHelper {
  static getUtcDateFromLocal(timestamp?: Date | string | number | Moment) {
    if (!timestamp) {
      return new Date();
    }
    return new Date(moment.utc(timestamp).format());
  }

  static formatDate(date?: string | Date | Moment | number, format?: string) {
    return moment(date).local().format(format);
  }

  static formatTime(seconds: number, format = 'hh:mm:ss') {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor(seconds / 60) % 60;
    const s = Math.floor(seconds) % 60;

    const hh = `${h < 10 ? '0' : ''}${h}`;
    const mm = `${m < 10 ? '0' : ''}${m}`;
    const ss = `${s < 10 ? '0' : ''}${s}`;

    return format.replace('hh', hh).replace('mm', mm).replace('ss', ss);
  }

  static getDateRangeLabel(from: string | Moment, to?: string | Moment) {
    if (!to) {
      return `${DateHelper.formatDate(moment(from), 'DD/MM/YYYY')} ~ `;
    }
    return `${DateHelper.formatDate(
      moment(from),
      'DD/MM/YYYY'
    )} ~ ${DateHelper.formatDate(moment(to), 'DD/MM/YYYY')}`;
  }
}
