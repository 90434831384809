import { GENDER, NOTIFICATION_TYPE, ROLE } from '@/resources/enums';
import {
  ICountry,
  ITimezone,
  IAddress,
  ISecurityQuestionOption,
  IPinOption
} from '@/resources/interfaces';

export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  gender: GENDER;
  country?: ICountry;
  phone: string;
  role: ROLE;
  avatar: string;
  legalNameOfBusiness: string;
  notificationSettings: NOTIFICATION_TYPE[];
  timezone?: ITimezone;
  address?: IAddress;
  securityQuestion?: ISecurityQuestionOption;
  pin?: IPinOption;
  isActive?: boolean;
  kyc?: IKYC;
  lastLogin: Date;
  publicProfile?: boolean;
}

export interface IApplicantData {
  id: string;
  createdAt: string;
  clientId: string;
  inspectionId: string;
  externalUserId: string;
  fixedInfo: {
    firstName: string;
    lastName: string;
  };
  info: {
    firstName: string;
    firstNameEn: string;
    middleName: string;
    middleNameEn: string;
    lastName: string;
    lastNameEn: string;
    dob: string;
    country: string;
    idDocs: any[];
  };
  agreement: {
    createdAt: string;
    source: string;
    targets: string[];
  };
  email: string;
  phone: string;
  applicantPlatform: string;
  requiredIdDocs: {
    docSets: any[];
  };
  review: {
    elapsedSincePendingMs: number;
    elapsedSinceQueuedMs: number;
    reprocessing: boolean;
    levelName: string;
    createDate: string;
    reviewDate: string;
    reviewResult: {
      reviewAnswer: string;
    };
    reviewStatus: string;
  };
  lang: string;
  type: string;
}

export enum KYC_REVIEW_STATUS {
  INIT = 'init',
  PENDING = 'pending',
  PRECHECKED = 'prechecked',
  QUEUED = 'queued',
  COMPLETED = 'completed',
  ONHOLD = 'onhold'
}

export enum KYC_REVIEW_ANSWER {
  RED = 'RED',
  GREEN = 'GREEN'
}

export enum KYC_ADMIN_REVIEW {
  APPROVED = 'approved',
  DECLINED = 'declined',
  UNAPPLIED = 'unapplied'
}

export enum KYC_ADMIN_REJECT_REASON {
  NAME = 'Name mismatched',
  GENDER = 'Gender mismatched',
  EMAIL_PHONE = 'Email/Phone number mismatched',
  FACE = 'Face mismatched',
  OTHER = 'Other reason'
}

export const KYC_ADMIN_REJECT_REASON_VALUES = Object.values(
  KYC_ADMIN_REJECT_REASON
);

export interface IKYC {
  reviewStatus?: KYC_REVIEW_STATUS;
  reviewAnswer?: KYC_REVIEW_ANSWER;
  applicantId: string;
  adminReviewStatus?: string;
  adminReviewNote?: string;
  applicantData?: IApplicantData;
  sumsubResponse?: any;
  adminReview: {
    reviewResult: KYC_ADMIN_REVIEW;
    rejectReasons: KYC_ADMIN_REJECT_REASON[];
  };
}
