import React, { FC, useState } from 'react';
import { Space, Steps, Button, Modal } from 'antd';
import Icon from '@/components/common/Icon';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Input from '@/components/forms/Input';
import { useDispatch } from 'react-redux';
import { enable2FA } from '@/redux/actions';
import './styles.scss';

const { Step } = Steps;

interface TfaModalProps {
  open: boolean;
  onClose: () => void;
  qr: string | null;
}

const TfaModal: FC<TfaModalProps> = ({ open, onClose, qr }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const steps = [
    {
      title: 'Enable 2FA for your protection'
    },
    {
      title: 'Scan QR Code with Authy App'
    },
    {
      title: 'Backup your secret key and put the code here'
    }
  ];

  const validationSchema = Yup.object().shape({
    code: Yup.string().matches(/^[0-9]{6}$/)
  });

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      await dispatch(enable2FA(values));
      setLoading(false);
      onClose();
    } catch (err) {
      setLoading(false);
    }
  };

  const form = useFormik({
    initialValues: {
      code: ''
    },
    validationSchema,
    onSubmit
  });

  const onNext = () => {
    if (currentStep < 2) {
      setCurrentStep(currentStep + 1);
    }
  };
  const onBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const getStepContent = (stepIndex: number) => {
    if (stepIndex !== currentStep) return <div />;

    let content;

    if (stepIndex === 0) {
      content = (
        <div>
          <p>
            Protect your account from hackers with Authy app. If you haven’t
            already, please download it.
          </p>
          <div className="my-3">
            <Button
              type="primary"
              href="https://authy.com/download/"
              target="_blank"
            >
              Download App
            </Button>
          </div>
        </div>
      );
    } else if (stepIndex === 1) {
      content = (
        <div>
          <p className="mb-3">Please scan the QR code below with Authy app</p>
          <div className="mb-3">
            {qr && <img src={qr} className="tz-modal__auth__qr" alt="" />}
          </div>
        </div>
      );
    } else {
      content = (
        <div>
          <p>
            Please write down or print a copy of the secret key and put the code
            from Authy app in the below input box
          </p>
          <div className="mt-3">
            <form onSubmit={form.handleSubmit}>
              <div className="mb-3">
                <Input
                  className="border-input"
                  label="Code"
                  placeholder="Code*"
                  {...form.getFieldProps('code')}
                  error={
                    form.errors.code && form.touched.code
                      ? form.errors.code
                      : ''
                  }
                />
              </div>
              <Space size={16}>
                <Button type="ghost" onClick={onBack}>
                  Back
                </Button>
                <Button
                  htmlType="submit"
                  className="w-100"
                  type="primary"
                  loading={loading}
                >
                  Verify
                </Button>
              </Space>
            </form>
          </div>
        </div>
      );
    }
    return (
      <div>
        {content}
        {stepIndex < 2 && (
          <Space size={16}>
            <Button type="ghost" onClick={onBack}>
              Back
            </Button>
            <Button type="primary" onClick={onNext}>
              Next
            </Button>
          </Space>
        )}
      </div>
    );
  };

  return (
    <div>
      <Modal
        className="tfa-modal"
        title={<h4 className="mb-0">Setup Authy App</h4>}
        visible={open}
        onOk={() => {}}
        onCancel={onClose}
        closeIcon={<Icon name="close-big" />}
        footer={false}
      >
        <Steps direction="vertical" current={currentStep}>
          {steps.map((step, index) => (
            <Step
              key={index}
              title={step.title}
              description={getStepContent(index)}
            />
          ))}
        </Steps>

        <Button type="primary" className="my-3" onClick={onClose}>
          Do it later
        </Button>
      </Modal>
    </div>
  );
};

export default TfaModal;
