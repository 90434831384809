import React from 'react';
import { NameHelper } from '@/helpers';
import moment from 'moment';
import './styles.scss';
import Alert from '@/components/common/Alert';
import { TRANSACTION_TYPE } from '@/resources/enums';

interface ITransactionDetailSectionProps {
  transaction: any;
}

const TransactionDetailSection: React.FC<ITransactionDetailSectionProps> = ({
  transaction
}) => {
  return (
    <div className="transaction-detail-section">
      {transaction.type !== TRANSACTION_TYPE.WITHDRAW ? (
        <table>
          <tbody>
            {transaction.subTransactions.map((item: any, index: number) => (
              <tr key={index}>
                <td className="cpl-20 type-column">
                  {NameHelper.getTransactionTypeLabel(item.type)}
                </td>
                <td className="data-column" />
                <td className="invoice-column" />
                <td className="text-right cpx-20">${item.amount.toFixed(2)}</td>
                <td className="">
                  {moment(item.createdAt).format('DD MMM, YYYY')}
                </td>
                <td className="" />
                <td className="" />
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <Alert type="danger">{transaction?.meta?.data?.message}</Alert>
      )}
    </div>
  );
};

export default TransactionDetailSection;
