import React from 'react';
import { Drawer } from 'antd';
import Icon from '@/components/common/Icon';
import { DateHelper, NameHelper } from '@/helpers';
import './styles.scss';
import { AppReportModel, UserModel } from '@/resources/models';
import moment from 'moment';

interface IAppReportDetailModalProps {
  onClose: () => void;
  open: boolean;
  appReport: AppReportModel;
}

const AppReportDetailModal: React.FC<IAppReportDetailModalProps> = ({
  onClose,
  open,
  appReport
}) => {
  return (
    <Drawer
      className="bank-payment-detail-drawer"
      title={() => <div />}
      closeIcon={<Icon name="close" />}
      placement="right"
      onClose={onClose}
      visible={open}
    >
      <table className="mb-3">
        <tbody>
          <tr>
            <td>User:</td>
            <td>
              <div className="text-blue">
                {NameHelper.getFullName(appReport?.user as UserModel)}
              </div>
            </td>
          </tr>
          <tr>
            <td>Timestamps:</td>
            <td>
              <div>
                {DateHelper.formatDate(
                  moment(appReport?.createdAt),
                  'DD MMM YYYY, hh:mm:ss A'
                )}
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <h3 className="font-weight-bold">Error Track</h3>
      <div>{appReport?.error}</div>
    </Drawer>
  );
};

export default AppReportDetailModal;
