import React, { ComponentType, FC } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import AuthLayout from '@/layouts/AuthLayout';

const AuthRoute: FC<RouteProps> = ({ component, ...rest }) => {
  const C = component as ComponentType;
  return (
    <Route
      {...rest}
      render={() => (
        <AuthLayout>
          <C />
        </AuthLayout>
      )}
    />
  );
};

AuthRoute.displayName = 'AuthRoute';

export default AuthRoute;
