import React, { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Radio } from 'antd';
import * as Yup from 'yup';
import { GENDER, ROLE } from '@/resources/enums';
import { getCountries } from '@/selectors';
import Input from '@/components/forms/Input';
import Button from '@/components/common/Button';
import Select from '@/components/forms/Select';
import './styles.scss';
import { IAdminUser } from '@/resources/interfaces';
import { UserModel } from '@/resources/models';

const validationSchema = Yup.object().shape({
  firstName: Yup.string().trim().required('Required Field'),
  lastName: Yup.string().trim().required('Required Field'),
  gender: Yup.string().trim().required('Required Field'),
  country: Yup.string().trim().required('Required Field'),
  email: Yup.string().email('Invalid Email').trim().required('Required Field'),
  role: Yup.string()
});

interface AccountFormProps {
  user?: IAdminUser | UserModel;
  onSubmit: (value: any) => void | Promise<void>;
  type?: ROLE.EMPLOYER | ROLE.FREELANCER | 'sub-account';
}

const AccountForm: FC<AccountFormProps> = ({
  user,
  onSubmit,
  type = 'sub-account'
}) => {
  const [loading, setLoading] = useState(false);
  const countries = useSelector(getCountries);

  const countryOptions = useMemo(() => {
    return countries.map((item) => ({
      title: item.name,
      value: item.id
    }));
  }, [countries]);

  const handleSubmit = async (values: any) => {
    const data: any = {
      ...values,
      role: type === 'sub-account' ? values.role : type
    };

    if (user) data.userId = user.id;

    setLoading(true);
    await onSubmit(data);
    setLoading(false);
  };

  const form = useFormik({
    validationSchema,
    onSubmit: handleSubmit,
    initialValues: {
      firstName: user ? user.firstName : '',
      lastName: user ? user.lastName : '',
      email: user ? user.email : '',
      gender: user ? user.gender : GENDER.MALE,
      role: user ? user.role : ROLE.ADMIN,
      country: user ? user.country?.id : ''
    }
  });

  return (
    <div className="account-form-container custom-card">
      <form onSubmit={form.handleSubmit}>
        <Input
          className="form-input"
          label="First Name"
          required={true}
          {...form.getFieldProps('firstName')}
          error={
            form.errors.firstName && form.touched.firstName
              ? form.errors.firstName
              : ''
          }
        />

        <Input
          className="form-input"
          label="Last Name"
          required={true}
          {...form.getFieldProps('lastName')}
          error={
            form.errors.lastName && form.touched.lastName
              ? form.errors.lastName
              : ''
          }
        />

        <div className="cmb-12">
          <p className="text-md mb-0">Gender *</p>
          <Radio.Group
            className="d-flex align-items-start"
            {...form.getFieldProps('gender')}
          >
            <Radio value={GENDER.MALE}>Male</Radio>
            <Radio value={GENDER.FEMALE}>Female</Radio>
            <Radio value={GENDER.UNKNOWN}>Unknown</Radio>
          </Radio.Group>
        </div>

        <Input
          className="form-input"
          label="Email"
          required={true}
          {...form.getFieldProps('email')}
          error={
            form.errors.email && form.touched.email ? form.errors.email : ''
          }
        />

        <div className="form-input">
          <Select
            label="Country"
            searchable={true}
            required={true}
            {...form.getFieldProps('country')}
            onChange={(value) => form.setFieldValue('country', value)}
            options={countryOptions}
            error={
              form.errors.country && form.touched.country
                ? form.errors.country
                : ''
            }
          />
        </div>

        {type === 'sub-account' && (
          <div className="cmb-12">
            <p className="text-md mb-0">Role *</p>
            <Radio.Group
              className="d-flex align-items-start"
              {...form.getFieldProps('role')}
            >
              <Radio value={ROLE.ADMIN}>Admin</Radio>
              <Radio value={ROLE.QA_TESTER}>QA Tester</Radio>
              <Radio value={ROLE.MARKETING_MANAGER}>Marketing Manager</Radio>
            </Radio.Group>
          </div>
        )}

        <div className="d-flex align-items-center justify-content-end">
          <Button type="submit" loading={loading}>
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AccountForm;
