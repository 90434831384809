import Http from '@/apis/http';
import { IDaemonLog, ISearchApiResult } from '@/resources/interfaces';
import { DaemonLogModel } from '@/resources/models';

export class DaemonLogApi {
  static search(query: any = {}): Promise<ISearchApiResult<DaemonLogModel>> {
    return Http.get('/admin/daemon-logs', query).then(
      (res: ISearchApiResult<IDaemonLog>) => {
        return {
          totalCount: res.totalCount,
          data: res.data.map((item) => new DaemonLogModel(item))
        };
      }
    );
  }
}
