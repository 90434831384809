import React, { FC } from 'react';
import { StringHelper } from '@/helpers';
import { IAttachment } from '@/resources/interfaces';
import './styles.scss';

export interface IPatchDetailsViewProps {
  coverLetter: string;
  attachments?: IAttachment[];
}

const ProposalDetailsView: FC<IPatchDetailsViewProps> = ({
  coverLetter,
  attachments = []
}) => {
  return (
    <div className="proposal-details-container">
      <p className="text-heading3 cmb-9">Proposal Details</p>

      <p className="text-md text-black cmb-5">Cover Letter</p>
      <div
        dangerouslySetInnerHTML={{
          __html: StringHelper.plain2Html(coverLetter)
        }}
      />

      {attachments?.length > 0 && (
        <div className="attachment-section cmt-12">
          <p className="text-md text-black cmb-5">Attachments</p>
          <div className="d-flex flex-wrap align-items-center">
            {attachments.map((item) => (
              <a
                key={item.url}
                className="text-md cmr-6 cmb-6"
                href={item.url}
                target="_blank"
                download={true}
              >
                {item.filename}
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProposalDetailsView;
