import {
  BANK_RECEIVER_TYPE,
  BANK_RECEIVING_ACCOUNT_TYPE,
  BANK_STATUS
} from '@/resources/enums';
import { IBank } from '@/resources/interfaces';
import { FreelancerModel } from '@/resources/models/freelancer.model';

export class BankModel {
  id: string;
  bankName: string;
  bankBic: string;
  receivingAccount: string;
  receivingAccountType: BANK_RECEIVING_ACCOUNT_TYPE;
  receiverName: string;
  routingNumber?: string;
  receiverType: BANK_RECEIVER_TYPE;
  country: string;
  city: string;
  street1: string;
  street2: string;
  bankBranchId: string;
  bankBranchName: string;
  bankClearingMemberId: string;
  taxId: string;
  purposeCode: string;
  purposeCode2: string;
  freelancer?: string | FreelancerModel;
  currency?: string;
  createdAt?: string;
  updatedAt?: string;
  postalCode: string;
  status: BANK_STATUS;
  declineReasons?: string[];
  declineNote?: string;
  bankCountry?: string;
  validation: {
    swift: boolean;
    accountNumber: boolean;
  };

  constructor(data: IBank) {
    this.id = data.id;
    this.bankName = data.bankName;
    this.bankBic = data.bankBic;
    this.receivingAccount = data.receivingAccount;
    this.receivingAccountType = data.receivingAccountType;
    this.receiverName = data.receiverName;
    this.routingNumber = data.routingNumber;

    this.receiverType = data.receiverType;
    this.country = data.country;
    this.city = data.city;
    this.street1 = data.street1;
    this.street2 = data.street2;
    this.bankBranchId = data.bankBranchId;
    this.bankBranchName = data.bankBranchName;
    this.bankClearingMemberId = data.bankClearingMemberId;
    this.taxId = data.taxId;
    this.purposeCode = data.purposeCode;
    this.purposeCode2 = data.purposeCode2;
    this.currency = data.currency;
    this.createdAt = data.createdAt;
    this.postalCode = data.postalCode;
    this.status = data.status;
    this.declineReasons = data.declineReasons;
    this.declineNote = data.declineNote;
    this.updatedAt = data.updatedAt;
    this.bankCountry = data.bankCountry;
    this.validation = data.validation;

    if (data.freelancer) {
      if (typeof data.freelancer === 'string') {
        this.freelancer = data.freelancer;
      } else {
        this.freelancer = new FreelancerModel(data.freelancer);
      }
    }
  }
}
