import React from 'react';

import { useSelector } from 'react-redux';
import { getAccount } from '@/selectors';
import Avatar from '@/components/common/Avatar';
import { DateHelper, NameHelper } from '@/helpers';
import { Dropdown, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { ROUTES } from '@/constants';
import { AuthApi } from '@/apis';
import './styles.scss';

const Header = () => {
  const user = useSelector(getAccount);

  const onLogout = () => {
    AuthApi.logout();
  };

  const ProfileMenuItems = (
    <Menu className="profile-menu">
      <Menu.Item>
        <Link to="#" className="profile-menu-item">
          <Avatar
            className="cmr-4"
            size="2.5rem"
            name={NameHelper.getFullName(user)}
            round={true}
          />
          <div>
            <p className="text-sm mb-0">{NameHelper.getFullName(user)}</p>
            <p className="user-role text-xs mb-0">
              {NameHelper.getRoleLabel(user?.role)}
            </p>
          </div>
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          to={`${ROUTES.ADMIN.SETTINGS.ACCOUNTS.INDEX}`}
          className="menu-link"
        >
          Settings
        </Link>
      </Menu.Item>
      <Menu.Item onClick={onLogout}>
        <Link className="menu-link" to={'#'}>
          Log out
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="app-header">
      <div className="d-flex align-items-center">
        <div className="d-flex cmr-10">
          <div className="cmr-4">Last Login:</div>
          <div>
            {DateHelper.formatDate(user?.lastLogin, 'MMMM Do, yyyy hh:mm A')}
          </div>
        </div>

        <Dropdown overlay={ProfileMenuItems} trigger={['click']}>
          <Avatar
            className="cursor-pointer"
            src={user?.avatar}
            name={NameHelper.getFullName(user)}
            size="2.5rem"
            round={true}
          />
        </Dropdown>
      </div>
    </div>
  );
};

export default Header;
