import React, { useState } from 'react';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog';
import Button from '@/components/common/Button';
import IconButton from '@/components/common/IconButton';

interface IConfirmDialogButtonProps {
  onConfirm: any;
  message: string;
  buttonProps?: any;
  buttonLabel?: string;
  isIconButton?: boolean;
  type?: string;
  okText?: string;
  cancelText?: string;
}

const ConfirmDialogButton: React.FC<IConfirmDialogButtonProps> = ({
  onConfirm,
  message,
  buttonProps,
  buttonLabel,
  isIconButton,
  type = 'warning',
  okText,
  cancelText
}) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const handleConfirm = () => {
    onConfirm();
    setShowConfirmDialog(false);
  };

  return (
    <>
      {isIconButton ? (
        <IconButton
          onClick={() => setShowConfirmDialog(true)}
          {...(buttonProps || {})}
        />
      ) : (
        <Button
          onClick={() => setShowConfirmDialog(true)}
          {...(buttonProps || {})}
        >
          {buttonLabel}
        </Button>
      )}
      <ConfirmDialog
        visible={showConfirmDialog}
        handleClose={() => setShowConfirmDialog(false)}
        onConfirm={handleConfirm}
        text={message}
        okText={okText || 'Confirm'}
        cancelText={cancelText || 'Cancel'}
        type={type}
      />
    </>
  );
};

export default ConfirmDialogButton;
