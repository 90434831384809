import React, { ChangeEvent, FC } from 'react';
import {
  ORDER_STATUS,
  ORDER_STATUS_VALUES,
  ORDER_TYPE,
  ORDER_TYPE_VALUES,
  TRANSACTION_METHOD
} from '@/resources/enums';
import Search from '@/components/forms/Search';
import Select from '@/components/forms/Select';
import DateRangePicker from '@/components/forms/DateRangePicker';
import './styles.scss';
import Switch from 'src/components/common/Switch';
import TagSelect from '@/components/pages/TagSelect';
import { NameHelper } from '@/helpers';

export interface IOrderSearchFilter {
  employer: string;
  freelancer: string;
  status?: ORDER_STATUS;
  method?: TRANSACTION_METHOD;
  page?: number;
  perPage?: number;
  sort?: string;
  type: ORDER_TYPE;
  isInternal?: boolean;
  tag: string;
  dateRange: any;
  search: string;
}

export interface IOrderSearchFilterProps {
  filter: IOrderSearchFilter;
  onChangeFilter: (field: string, value: any) => void;
}

const typeOptions = [
  { title: 'All', value: undefined },
  ...ORDER_TYPE_VALUES.map((item: string) => ({
    title: NameHelper.getOrderTypeLabel(item),
    value: item
  }))
];

const statusOptions = [
  { title: 'All', value: undefined },
  ...ORDER_STATUS_VALUES.map((item: string) => ({
    title: NameHelper.getOrderStatusLabel(item),
    value: item
  }))
];

const methodOptions = [
  { title: 'All', value: undefined },
  { title: 'Paypal', value: TRANSACTION_METHOD.PAYPAL },
  { title: 'Payoneer', value: TRANSACTION_METHOD.PAYONEER },
  { title: 'Bank', value: TRANSACTION_METHOD.KLARPAY }
];

const OrderSearchFilter: FC<IOrderSearchFilterProps> = ({
  filter,
  onChangeFilter
}) => {
  const { dateRange, search, status, type, tag, method } = filter;

  return (
    <div className="transactions-filter-container">
      <div className="cmb-10">
        <Search
          className="page-search-box"
          value={search}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChangeFilter('search', e.target.value)
          }
          placeholder="Search for orders here"
        />
      </div>

      <div className="filter-select-list">
        <div className="cmr-12 cmb-10">
          <DateRangePicker
            label="Dates"
            dateRange={dateRange}
            setDateRange={(value) => onChangeFilter('dateRange', value)}
          />
        </div>

        <div className="cmr-12 cmb-10">
          <Select
            label="Type"
            value={type}
            onChange={(value) => onChangeFilter('type', value)}
            options={typeOptions}
            className="filter-type"
          />
        </div>

        <div className="cmr-12 cmb-10">
          <Select
            label="Status"
            value={status}
            onChange={(value) => onChangeFilter('status', value)}
            options={statusOptions}
          />
        </div>

        <div className="cmr-12 cmb-10">
          <Select
            label="Method"
            value={method}
            onChange={(value) => onChangeFilter('method', value)}
            options={methodOptions}
          />
        </div>

        <div className="cmr-12 cmb-10">
          <TagSelect
            label="Tag"
            value={tag}
            onChange={(value) => onChangeFilter('tag', value)}
          />
        </div>

        <div className="cmb-15">
          <Switch
            enabled={filter.isInternal}
            onToggle={() => onChangeFilter('isInternal', !filter.isInternal)}
            label="Show Internal Data"
          />
        </div>
      </div>
    </div>
  );
};

export default OrderSearchFilter;
