import Http from '@/apis/http';
import { IWebhookLog, ISearchApiResult } from '@/resources/interfaces';
import { WebhookLogModel } from '@/resources/models';

export class WebhookLogApi {
  static search(query: any = {}): Promise<ISearchApiResult<WebhookLogModel>> {
    return Http.get('/admin/webhook-logs', query).then(
      (res: ISearchApiResult<IWebhookLog>) => {
        return {
          totalCount: res.totalCount,
          data: res.data.map((item) => new WebhookLogModel(item))
        };
      }
    );
  }
}
