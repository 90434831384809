import { UserModel } from '@/resources/models/user.model';
import {
  IAvailability,
  ICertificate,
  IEducation,
  IExperience,
  IFreelancer,
  IFreelancerTax,
  ILanguageInfo,
  IPaymentMethod,
  IProposal,
  ISkill,
  ISubCategory
} from '@/resources/interfaces';
import { IVerification } from '@/resources/interfaces/verification.interface';
import {
  FREELANCER_EDUCATION_LEVEL,
  FREELANCER_EXPERIENCE_LEVEL,
  USER_STATUS,
  VERIFICATION_STATUS
} from '@/resources/enums';
import store from '@/redux/store';
import { getCountries } from '@/selectors';

export class FreelancerModel {
  id: string;
  user?: UserModel;
  title: string;
  bio: string;
  tags: string[];
  linkedin?: string;
  website?: string;
  coverPhoto?: string;
  availability: IAvailability;
  subCategories: string[] | ISubCategory[];
  experienceLevel: FREELANCER_EXPERIENCE_LEVEL;
  experiences: IExperience[];
  educationLevel: FREELANCER_EDUCATION_LEVEL;
  education: IEducation[];
  certification: ICertificate[];
  languages: ILanguageInfo[];
  portfolio: any[];
  skills: string[] | ISkill[];
  hourlyPrice: number;
  rating: number;
  percent: string;
  favouriteProjects: string[];
  hiddenProjects: string[];
  completedProjects: string[];
  ranking: number;
  isSignupCompleted: boolean;
  totalEarned: number;
  createdAt: string;
  paymentMethods: IPaymentMethod[];
  verification: IVerification;
  tax?: IFreelancerTax;
  status: USER_STATUS;
  balance?: number;

  // additional fields
  matchSkills?: number;
  proposal?: IProposal;

  get coverPhotoUrl(): string {
    return this.coverPhoto
      ? this.coverPhoto
      : '/images/freelancer/cover-picture.png';
  }

  constructor(init?: IFreelancer) {
    const data = {
      id: '',
      title: '',
      bio: '',
      tags: [],
      subCategories: [],
      experienceLevel: FREELANCER_EXPERIENCE_LEVEL.BEGINNER,
      educationLevel: FREELANCER_EDUCATION_LEVEL.NEVER,
      experiences: [],
      education: [],
      certification: [],
      languages: [],
      portfolio: [],
      skills: [],
      hourlyPrice: 0,
      rating: 0,
      percent: '',
      favouriteProjects: [],
      appliedProjects: [],
      hiddenProjects: [],
      completedProjects: [],
      verification: {
        status: VERIFICATION_STATUS.PENDING
      },
      ranking: 0,
      isSignupCompleted: false,
      paymentMethods: [],
      totalEarned: 0,
      createdAt: '',
      status: USER_STATUS.OPENED,
      balance: 0,
      ...init
    };

    if (data.user) this.user = new UserModel(data.user);

    this.id = data.id;
    this.title = data.title;
    this.bio = data.bio;
    this.tags = data.tags;
    this.linkedin = data.linkedin;
    this.website = data.website;
    this.coverPhoto = data.coverPhoto;
    this.availability = data.availability as IAvailability;
    this.subCategories = data.subCategories;
    this.experienceLevel = data.experienceLevel;
    this.educationLevel = data.educationLevel;
    this.experiences = data.experiences;
    this.education = data.education;
    this.certification = data.certification;
    this.languages = data.languages;
    this.portfolio = data.portfolio;
    this.skills = data.skills;
    this.hourlyPrice = data.hourlyPrice;
    this.rating = data.rating ? data.rating : 0;
    this.percent = data.percent;
    this.favouriteProjects = data.favouriteProjects;
    this.hiddenProjects = data.hiddenProjects;
    this.completedProjects = data.completedProjects;
    this.verification = data.verification as IVerification;
    this.ranking = data.ranking;
    this.isSignupCompleted = data.isSignupCompleted;
    this.paymentMethods = data.paymentMethods;
    this.totalEarned = data.totalEarned;
    this.createdAt = data.createdAt;
    this.matchSkills = data.matchSkills;
    this.proposal = data.proposal;
    this.tax = data.tax;
    this.status = data.status;
    this.balance = data.balance;
  }

  getVerificationCountryName() {
    const countries = getCountries(store.getState());
    const country = countries.find(
      (item) => item.id === (this.verification.country as string)
    );

    return country?.name || '';
  }
}
