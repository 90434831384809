import { Skeleton } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { AccountApi } from 'src/apis';
import BarChart from 'src/components/charts/BarChart';
import Select from 'src/components/forms/Select';
import { STATIC_PERIOD, TRANSACTION_METHOD } from 'src/resources/enums';
import { getDateFormat, getInitialData } from 'src/utils';

const WithdrawStatistics = () => {
  const [loading, setLoading] = useState(false);
  const [statistics, setStatistics] = useState<any[]>([]);
  const [method, setMethod] = useState();
  const [period, setPeriod] = useState('daily');

  const methodOptions = [
    { title: 'All', value: undefined },
    { title: 'Paypal', value: TRANSACTION_METHOD.PAYPAL },
    { title: 'Payoneer', value: TRANSACTION_METHOD.PAYONEER }
  ];

  const periodOptions = [
    { title: 'Daily', value: STATIC_PERIOD.DAILY },
    { title: 'Weekly', value: STATIC_PERIOD.WEEKLY },
    { title: 'Monthly', value: STATIC_PERIOD.MONTHLY }
  ];

  useEffect(() => {
    setLoading(true);
    AccountApi.getDashboardWithdrawStatistics({
      method,
      period
    })
      .then((res) => {
        setStatistics(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [method, period]);

  const chartData = useMemo(() => {
    const data = getInitialData(period);
    statistics.forEach((item) => {
      data[getDateFormat(item._id, period)] = item.sum;
    });

    return data;
  }, [statistics]);

  return (
    <div className="custom-card">
      <h3 className="cmb-9">Withdraw Statistics</h3>

      <div className="d-flex justify-content-between cmb-12">
        <Select value={method} onChange={setMethod} options={methodOptions} />

        <Select value={period} onChange={setPeriod} options={periodOptions} />
      </div>
      <Skeleton active={true} loading={loading} paragraph={{ rows: 5 }}>
        <BarChart
          title="Withdraw Statistics"
          categories={Object.keys(chartData)}
          data={Object.values(chartData)}
          tooltipPrefix="$"
        />
      </Skeleton>
    </div>
  );
};

export default WithdrawStatistics;
