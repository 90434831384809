import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Divider, Rate } from 'antd';
import { FreelancerModel } from '@/resources/models';
import { FREELANCER_AVAILABILITY } from '@/resources/enums';
import { NameHelper } from '@/helpers';
import Avatar from '@/components/common/Avatar';
import { FREELANCER_DETAILS_ROUTES, ROUTES } from '@/constants';
import './styles.scss';

export interface IFreelancerInfoProps {
  freelancer: FreelancerModel;
}

const FreelancerInfoCard: FC<IFreelancerInfoProps> = ({ freelancer }) => {
  return (
    <div className="freelancer-detail-info">
      <h3 className="cmb-7">About the Freelancer</h3>

      <div className="d-flex cmb-10">
        <Avatar
          src={freelancer?.user?.avatarUrl}
          name={freelancer?.user?.fullName}
          size="3rem"
          className="cmr-6"
          round={true}
        />

        <div>
          <Link
            className="text-md mb-0"
            to={`${ROUTES.ADMIN.FREELANCERS.PREFIX}/${freelancer.id}/${FREELANCER_DETAILS_ROUTES.DETAILS}`}
          >
            {freelancer?.user?.fullName}
          </Link>
          <p className="text-xs mb-0 text-ellipsis">{freelancer.title}</p>
          <Rate value={freelancer?.rating} allowHalf={true} disabled={true} />
        </div>
      </div>

      <Divider className="cmy-12" />

      <p className="text-lg cmb-4">Skills</p>

      <div className="skill-chip-container">
        {freelancer.skills.map((item: any) => (
          <span className="skill-chip" key={item}>
            {NameHelper.getSkillName(item)}
          </span>
        ))}
      </div>

      <Divider className="cmy-12" />

      <p className="text-lg cmb-4">preferences</p>

      <div className="d-flex align-items-center justify-content-between cmb-2">
        <span className="text-xs">Availability to work</span>
        <span className="text-xs text-bold">
          {freelancer.availability.type === FREELANCER_AVAILABILITY.FULL_TIME
            ? 'Full Time'
            : 'Part Time'}
        </span>
      </div>

      <div className="d-flex align-items-center justify-content-between cmb-2">
        <span className="text-xs">Availability during the week</span>
        <span className="text-xs text-bold">
          {freelancer.availability.daysOfWork?.length} Day
        </span>
      </div>

      <div className="d-flex align-items-center justify-content-between cmb-2">
        <span className="text-xs">Hours per week</span>
        <span className="text-xs text-bold">
          {freelancer.availability?.hoursPerWeek
            ? freelancer.availability.hoursPerWeek
            : 0}{' '}
          hrs
        </span>
      </div>
    </div>
  );
};

export default FreelancerInfoCard;
