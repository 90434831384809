import React, { FC, useEffect, useState } from 'react';
import { Modal, notification } from 'antd';
import Button from '@/components/common/Button';
import { InvoiceModel } from '@/resources/models';
import './styles.scss';
import InvoiceStatusSelect from '@/components/pages/InvoiceStatusSelect';
import { PAYMENT_STATUS } from '@/resources/enums';
import { InvoiceApi } from '@/apis';

export interface ISelectInvoiceStatusModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  invoice?: InvoiceModel;
}

const SelectInvoiceStatusModal: FC<ISelectInvoiceStatusModalProps> = ({
  open,
  onClose,
  onConfirm,
  invoice
}) => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<PAYMENT_STATUS>();

  useEffect(() => {
    setStatus(invoice?.status);
  }, [invoice]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (invoice?.id) {
        await InvoiceApi.update(invoice.id, { status });
      }
      if (onConfirm) {
        onConfirm();
      }
      onClose();
    } catch (err) {
      notification.error({ message: err.message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={open}
      onCancel={onClose}
      className="confirm-dialog dialog"
      footer={null}
      width={600}
    >
      <div className="dialog-body cpt-15">
        <h1 className="text-heading2 mb-4">Select Status</h1>

        <div className="">
          <InvoiceStatusSelect
            className="mb-4"
            value={status}
            onChange={(e) => setStatus(e as PAYMENT_STATUS)}
          />
          <div className="d-flex align-items-center justify-content-end">
            <Button type="submit" loading={loading} onClick={handleSubmit}>
              Save
            </Button>

            <Button
              type="button"
              className="ml-3"
              variant="outline"
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SelectInvoiceStatusModal;
