import React, { FC } from 'react';
import { Empty } from 'antd';
import { ICertificate } from '@/resources/interfaces';
import { DateHelper } from '@/helpers';
import Icon from '@/components/common/Icon';

export interface ICertificatesProps {
  certification: ICertificate[];
}

const Certificates: FC<ICertificatesProps> = ({ certification }) => {
  return (
    <div className="certificates">
      <div className="d-flex justify-content-between align-items-center cmb-7">
        <p className="text-heading3 m-0">Certificates</p>
      </div>

      <div className="certificates-content">
        {certification && certification.length ? (
          certification.map((certificate, index) => (
            <div className="certificate-item" key={index}>
              <a
                className="certificate-title cmb-5"
                href={certificate.url}
                target="_blank"
              >
                {certificate.title}
              </a>
              {certificate.date && (
                <div className="d-flex align-items-center">
                  <Icon className="label-icon" name="clock" />
                  <span className="text-sm">
                    {DateHelper.formatDate(certificate.date, 'DD/MM/YYYY')}
                  </span>
                </div>
              )}
            </div>
          ))
        ) : (
          <Empty className="certificates-empty" />
        )}
      </div>
    </div>
  );
};

export default Certificates;
