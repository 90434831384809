import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Skeleton, Table, Row, Col, Modal } from 'antd';
import Pagination from '@/components/common/Pagination';
import { EventApi, UserApi } from '@/apis';
import {
  DateHelper,
  FileHelper,
  getDetailLink,
  IQueryField,
  NameHelper,
  ObjectHelper,
  QueryHelper
} from '@/helpers';
import PageHeader from '@/components/common/PageHeader';
import { Link } from 'react-router-dom';
import Select, { ISelectOption } from '@/components/forms/Select';
import FilterChipList from '@/components/common/filters/FilterChipList';
import { API_SERVER, EVENT_TYPES, OBJECT_TYPES } from '@/constants';
import DateRangePicker from '@/components/forms/DateRangePicker';
import moment from 'moment';
import Switch from '@/components/common/Switch';
import EventDetailSection from '@/components/pages/EventDetail';
import { UserModel } from '@/resources/models';
import { IAdminUser } from '@/resources/interfaces';
import { AdminUserApi } from '@/apis/adminUser.api';
import { useHistory, useLocation } from 'react-router';
import AsyncSelect from 'src/components/forms/AsyncSelect';
import './styles.scss';
import Button from '@/components/common/Button';
import Icon from 'src/components/common/Icon';

const eventTypeOptions = Object.entries(EVENT_TYPES).map(([key, value]) => ({
  title: value,
  value: key
}));

const objectOptions = Object.entries(OBJECT_TYPES).map(([key, value]) => ({
  title: value,
  value: key
}));

const getTitle = (event: any) => {
  switch (event.objectType) {
    case 'project':
    case 'contract':
      return event.data?.object?.title;
    case 'proposal':
      return event.data?.object?.project?.title;
    case 'user':
    case 'admin_user':
      return NameHelper.getFullName(event.data?.object);
    case 'employer':
    case 'freelancer':
      return NameHelper.getFullName(event.data?.object?.user);
    default:
      return event.object?._id;
  }
};

const columns = [
  {
    title: 'User',
    dataIndex: 'user'
  },
  {
    title: 'Type',
    dataIndex: 'type'
  },
  {
    title: 'Object',
    dataIndex: 'objectId',
    render: (objectId: string, row: any) => {
      return <Link to={getDetailLink(row)}>{getTitle(row)}</Link>;
    }
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
    render: (date: string) => (
      <div>{DateHelper.formatDate(date, 'DD MMM YYYY, hh:mm:ss A')}</div>
    )
  }
];

const filterFields: IQueryField[] = [
  { name: 'page', type: 'number', default: 1 },
  { name: 'perPage', type: 'number', default: 10 },
  { name: 'type', type: 'array', default: [] },
  { name: 'objectType', type: 'array', default: [] },
  {
    name: 'startDate',
    type: 'dateRange',
    default: moment().startOf('week').startOf('day').toDate()
  },
  {
    name: 'endDate',
    type: 'dateRange',
    default: moment().endOf('week').endOf('day').toDate()
  },
  { name: 'user', type: 'array', default: [] },
  { name: 'adminUser', type: 'array', default: [] },
  { name: 'isAdmin', type: 'boolean', default: false }
];

const EventsPage = () => {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [events, setEvents] = useState<any>([]);
  const [userOptions, setUserOptions] = useState<ISelectOption[]>([]);
  const [adminUserOptions, setAdminUserOptions] = useState<ISelectOption[]>([]);
  const location = useLocation();
  const history = useHistory();
  const [selectedUsers, setSelectedUsers] = useState<UserModel[]>([]);
  const [selectedAdminUsers, setSelectedAminUsers] = useState<IAdminUser[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<any[]>([]);
  const [isExporting, setIsExporting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const filter = useMemo(() => {
    return QueryHelper.parseQuery(location.search, filterFields, true);
  }, [location.search]);

  useEffect(() => {
    getEvents();
  }, [filter]);

  const getEvents = () => {
    setLoading(true);
    EventApi.search({
      ...filter,
      'createdAt>': filter.dateRange.startDate
        ? moment(filter.dateRange.startDate).startOf('day').toISOString()
        : undefined,
      'createdAt<': filter.dateRange.endDate
        ? moment(filter.dateRange.endDate).endOf('day').toISOString()
        : undefined
    })
      .then((res) => {
        const { totalCount, data } = res;
        setTotal(totalCount);
        setEvents(data);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (filter.user.length) {
      UserApi.search({ _id: filter.user }).then((res) => {
        setSelectedUsers(res.data);
      });
    }
  }, [filter.user]);

  useEffect(() => {
    if (filter.adminUser.length) {
      AdminUserApi.search({ _id: filter.adminUser }).then((res) => {
        setSelectedAminUsers(res.data);
      });
    }
  }, [filter.adminUser]);

  const dataSource = useMemo(() => {
    return events.map((item: any) => ({
      key: item.id,
      ...item,
      user: NameHelper.getFullName(item?.user || item?.adminUser),
      objectId: item?.data?.object?._id
    }));
  }, [events]);

  useEffect(() => {
    if (filter.isAdmin) {
      onChangeFilter('user', [], false);
    } else {
      onChangeFilter('adminUser', [], false);
    }
  }, [filter.isAdmin]);

  const onChangeFilter = useCallback(
    (field: string, value: any, isPageUpdate = true) => {
      const newFilter = QueryHelper.getFilterToQuery(
        filter,
        field,
        value,
        isPageUpdate
      );

      const query = QueryHelper.stringifyQuery(newFilter);
      history.replace(`${location.pathname}?${query}`);
    },
    [filter, location.pathname]
  );

  const clearFilter = () => {
    history.replace(location.pathname);
  };

  const getUserName = (userId: string) =>
    NameHelper.getFullName(selectedUsers.find((item) => item.id === userId));
  const getAdminUserName = (userId: string) =>
    NameHelper.getFullName(
      selectedAdminUsers.find((item) => item.id === userId)
    );

  const searchUser = (search: string) => {
    UserApi.search({ search, perPage: 20 }).then((res) => {
      if (res.data) {
        setUserOptions(
          res.data.map((user) => ({
            title: NameHelper.getFullName(user),
            value: user.id
          }))
        );
      }
    });
  };

  const searchAdminUser = (search: string) => {
    AdminUserApi.search({ search, perPage: 20 }).then((res) => {
      if (res.data) {
        setAdminUserOptions(
          res.data.map((user) => ({
            title: NameHelper.getFullName(user),
            value: user.id
          }))
        );
      }
    });
  };

  const onExport = async () => {
    try {
      setIsExporting(true);
      const res = await EventApi.exportEvents(
        ObjectHelper.trimQuery({
          ...filter,
          'createdAt>': filter.dateRange.startDate
            ? moment(filter.dateRange.startDate).startOf('day').toISOString()
            : undefined,
          'createdAt<': filter.dateRange.endDate
            ? moment(filter.dateRange.endDate).endOf('day').toISOString()
            : undefined
        })
      );

      FileHelper.download(
        `${API_SERVER}/public/download?token=${res}`,
        `events_${moment(filter.dateRange.startDate).format(
          'YYYY-MM-DD'
        )}-${moment(filter.dateRange.endDate).format('YYYY-MM-DD')}.xlsx`
      );
      setIsExporting(false);
    } catch (err) {
      console.error(err);
      setIsExporting(false);
    }
  };

  const confirmDeleteEvents = () => {
    Modal.confirm({
      title: 'Are you sure you want to clear these events?',
      centered: true,
      onOk: onDelete
    });
  };

  const onDelete = async () => {
    try {
      setIsDeleting(true);
      const res = await EventApi.deleteEvents(
        ObjectHelper.trimQuery({
          ...filter,
          'createdAt>': filter.dateRange.startDate
            ? moment(filter.dateRange.startDate).startOf('day').toISOString()
            : undefined,
          'createdAt<': filter.dateRange.endDate
            ? moment(filter.dateRange.endDate).endOf('day').toISOString()
            : undefined
        })
      );
      setIsDeleting(false);
      getEvents();
    } catch (err) {
      console.error(err);
      setIsDeleting(false);
    }
  };

  return (
    <div className="events-page">
      <PageHeader title="Events">
        <div className="flex-1 flex-grow-1" />
        <div className="d-flex align-items-center">
          <Button
            variant="outline"
            className="mr-2"
            icon={<Icon name="delete" className="mr-2" />}
            onClick={confirmDeleteEvents}
            loading={isDeleting}
            disabled={!events?.length}
          >
            Clear Logs
          </Button>
          <Button
            icon={<Icon name="download" />}
            onClick={onExport}
            loading={isExporting}
          >
            Export
          </Button>
        </div>
      </PageHeader>

      <div className="log-filters">
        <div className="cmb-10">
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Select
                label="Type"
                searchable={true}
                multiSelect={true}
                showChipList={false}
                value={filter.type}
                onChange={(value) => onChangeFilter('type', value)}
                options={eventTypeOptions}
              />
            </Col>
            <Col span={12}>
              <Select
                label="Object"
                searchable={true}
                multiSelect={true}
                showChipList={false}
                value={filter.objectType}
                onChange={(value) => onChangeFilter('objectType', value)}
                options={objectOptions}
              />
            </Col>
            <Col span={24}>
              <DateRangePicker
                label="Dates"
                dateRange={filter.dateRange}
                setDateRange={(value) => onChangeFilter('dateRange', value)}
              />
            </Col>
            <Col span={12}>
              <Switch
                enabled={filter.isAdmin}
                onToggle={() => onChangeFilter('isAdmin', !filter.isAdmin)}
                label="Admin Events?"
                className="cmt-14"
              />
            </Col>
            <Col span={12}>
              {filter.isAdmin ? (
                <AsyncSelect
                  label="Admin User"
                  searchable={true}
                  multiSelect={true}
                  showChipList={false}
                  value={filter.adminUser}
                  onChange={(value) => onChangeFilter('adminUser', value)}
                  options={adminUserOptions}
                  onFetch={searchAdminUser}
                />
              ) : (
                <AsyncSelect
                  label="User"
                  searchable={true}
                  multiSelect={true}
                  showChipList={false}
                  value={filter.user}
                  onChange={(value) => onChangeFilter('user', value)}
                  options={userOptions}
                  onFetch={searchUser}
                />
              )}
            </Col>
          </Row>
        </div>
      </div>

      <div>
        <FilterChipList
          filter={filter}
          onFilterChange={onChangeFilter}
          clearFilter={clearFilter}
          filterType="event"
          getUserName={getUserName}
          getAdminUserName={getAdminUserName}
        />
      </div>

      <Skeleton loading={loading} paragraph={{ rows: 0 }} active={true}>
        <p className="mt-3 mb-2 text-md">{total} events found</p>
      </Skeleton>

      <Table
        className="custom-ant-table"
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        expandable={{
          expandedRowKeys: expandedKeys,
          expandedRowRender: (record) => (
            <div key={`expand_${record.id}`}>
              <EventDetailSection event={record} />
            </div>
          ),
          expandRowByClick: true,
          onExpand: (expanded, record) => {
            if (expanded) {
              setExpandedKeys([record.key]);
            } else {
              setExpandedKeys([]);
            }
          },
          expandIconColumnIndex: 4,
          expandedRowClassName: (record) =>
            record.key === expandedKeys[0] ? 'expanded-row' : '',
          expandIcon: () => <div />
        }}
      />

      <div className="d-flex-center cmt-10">
        <Pagination
          total={total}
          current={filter.page}
          pageSize={filter.perPage}
          onChange={(page) => onChangeFilter('page', page)}
        />
      </div>
    </div>
  );
};

export default EventsPage;
