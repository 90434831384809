import {
  CURRENCY,
  ORDER_STATUS,
  ORDER_TYPE,
  TRANSACTION_METHOD
} from '@/resources/enums';
import { IOrder } from '@/resources/interfaces';
import { BankModel } from '@/resources/models/bank.model';
import { UserModel } from '@/resources/models/user.model';
import { TransactionModel } from '@/resources/models/transaction.model';
import { FreelancerModel } from '@/resources/models/freelancer.model';

export class OrderModel {
  id: string;
  user?: string | UserModel;
  freelancer?: string | FreelancerModel;
  transaction?: string | TransactionModel;
  bank?: string | BankModel;
  type: ORDER_TYPE;
  sender: string;
  receiver: string;
  exAmount: number;
  currency: CURRENCY;
  method: TRANSACTION_METHOD;
  status: ORDER_STATUS;
  amount: number;
  meta: any;
  createdAt: string;
  updatedAt: string;

  constructor(data: IOrder) {
    this.id = data.id;
    this.type = data.type;
    this.sender = data.sender;
    this.receiver = data.receiver;
    this.exAmount = data.exAmount;
    this.currency = data.currency;
    this.method = data.method;
    this.status = data.status;
    this.amount = data.amount;
    this.meta = data.meta;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;

    if (data.bank) {
      if (typeof data.bank === 'string') {
        this.bank = data.bank;
      } else {
        this.bank = new BankModel(data.bank);
      }
    }

    if (data.user) {
      if (typeof data.user === 'string') {
        this.user = data.user;
      } else {
        this.user = new UserModel(data.user);
      }
    }

    if (data.transaction) {
      if (typeof data.transaction === 'string') {
        this.transaction = data.transaction;
      } else {
        this.transaction = new TransactionModel(data.transaction);
      }
    }

    if (data.freelancer) {
      if (typeof data.freelancer === 'string') {
        this.freelancer = data.freelancer;
      } else {
        this.freelancer = new FreelancerModel(data.freelancer);
      }
    }
  }
}
