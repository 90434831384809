import { combineReducers } from 'redux';
import authReducer from './auth.reducer';
import commonReducer from './common.reducer';

const rootReducer = combineReducers({
  authReducer,
  commonReducer
});

export default rootReducer;
