import Http from '@/apis/http';

export class SettingsApi {
  static getSettings(): Promise<any> {
    return Http.get('/admin/settings');
  }

  static update(body: any) {
    return Http.post(`/admin/settings`, body);
  }
}
