import Http from '@/apis/http';
import store from '@/redux/store';
import { setTokens } from '@/redux/actions';

export class AuthApi {
  static login(email: string, password: string) {
    return Http.post('/admin/auth/login', { email, password });
  }

  static loginTFA(code: string, token: string) {
    return Http.post(
      '/admin/auth/login/tfa',
      { code },
      { Authorization: `Bearer ${token}` }
    );
  }

  static get2FACode() {
    return Http.get('/admin/account/2fa');
  }

  static verify2FA(data: any) {
    return Http.post('/admin/account/2fa/verify', data);
  }

  static enable2FA(data: any) {
    return Http.post('/admin/account/2fa/enable', data);
  }

  static disable2FA(data: any) {
    return Http.post('/admin/account/2fa/disable', data);
  }

  static me() {
    return Http.get('/admin/auth/me');
  }

  static verifyPassword(password: string) {
    return Http.post('/admin/auth/verify-password', { password });
  }

  static setPassword(data: any) {
    return Http.post(`/admin/auth/set-password`, data);
  }

  static forgotPassword(email: string) {
    return Http.post('/admin/auth/forgot-password', { email });
  }

  static resetPassword(password: string, token: string) {
    return Http.post('/admin/auth/reset-password', { password, token });
  }

  static logout() {
    store.dispatch(
      setTokens({
        accessToken: null,
        refreshToken: null
      })
    );
  }
}
