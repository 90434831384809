// @ts-ignore
import html2pdf from 'html2pdf.js'; // TODO -> need to install higher version
import { saveAs } from 'file-saver';

export class FileHelper {
  static exportPDF(selector: string, fileName: string) {
    const element = document.querySelector(selector);
    const options = {
      margin: [0.5, 0.5, 0.5, 0.5],
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 3, letterRendering: true },
      jsPDF: {
        unit: 'in',
        format: 'b4',
        orientation: 'portrait',
        compress: true
      }
    };
    html2pdf().set(options).from(element).save(fileName);
  }

  static download(url: string, filename: string) {
    saveAs(url, filename);
  }
}
