import { notification } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { EmployerApi } from 'src/apis';
import ResetPasswordForm from 'src/components/pages/ResetPasswordForm';

const EmployerSettings = () => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams() as any;

  const onResetPassword = (values: any) => {
    setLoading(true);
    EmployerApi.update(id, { user: { password: values.password } })
      .then(() => {
        notification.success({ message: 'Password is reset successfully.' });
      })
      .catch((error) => {
        notification.error({ message: error.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="custom-card">
      <ResetPasswordForm loading={loading} onSubmit={onResetPassword} />
    </div>
  );
};

export default EmployerSettings;
