import React, { FC, useCallback } from 'react';
import { Empty } from 'antd';
import { ILanguageInfo } from '@/resources/interfaces';
import languageData from '@/assets/languages.json';

export interface ILanguagesProps {
  languages: ILanguageInfo[];
}

const Languages: FC<ILanguagesProps> = ({ languages }) => {
  const getLanguageNameFromCode = useCallback(
    (code: string) => {
      const lang = languageData.find((item) => item.code === code);
      return lang?.nativeName;
    },
    [languageData]
  );

  return (
    <div className="certificates cmb-12">
      <div className="d-flex justify-content-between align-items-center cmb-7">
        <p className="text-heading3 mb-0">Language(s)</p>
      </div>

      <div className="certificates-content">
        {languages && languages.length ? (
          <div className="language-list">
            {languages.map((language, index) => (
              <div className="certificate-item language-item" key={index}>
                {language.language && language.level && (
                  <p className="certificate-title text-sm mb-0">
                    {getLanguageNameFromCode(language.language)}:{' '}
                    <span className="text-capitalize">{language.level}</span>
                  </p>
                )}
              </div>
            ))}
          </div>
        ) : (
          <Empty className="certificates-empty" />
        )}
      </div>
    </div>
  );
};

export default Languages;
