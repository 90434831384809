import React, { useEffect, useState } from 'react';
import BalanceStatistics from '@/pages/Admin/Dashboard/BalanceStatistics';
import { Col, notification, Row } from 'antd';
import PayStatistics from '@/pages/Admin/Dashboard/PaymentStatistics';
import WithdrawStatistics from '@/pages/Admin/Dashboard/WithdrawStatistics';
import RevenueStatistics from '@/pages/Admin/Dashboard/RevenueStatistics';
import { AccountApi } from '@/apis';
import PageHeader from '@/components/common/PageHeader';

const FinancialStats = () => {
  const [balanceStatisticsLoading, setBalanceStatisticsLoading] = useState(
    false
  );
  const [balanceStatisticsData, setBalanceStatisticsData] = useState({});

  useEffect(() => {
    setBalanceStatisticsLoading(true);
    AccountApi.getDashboardStatistics()
      .then((res) => {
        setBalanceStatisticsLoading(false);
        if (res) {
          setBalanceStatisticsData(res);
        }
      })
      .catch((err) => {
        setBalanceStatisticsLoading(false);
        notification.error({ message: err.message });
      });
  }, []);

  return (
    <div>
      <PageHeader title="Financial Statistics" />
      <div className="cmb-12">
        <BalanceStatistics
          statistics={balanceStatisticsData}
          loading={balanceStatisticsLoading}
        />
      </div>

      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} xl={24} className="cmb-12">
          <PayStatistics />
        </Col>
        <Col xs={24} sm={24} md={24} xl={24} className="cmb-12">
          <WithdrawStatistics />
        </Col>
        <Col xs={24} sm={24} md={24} xl={24} className="cmb-12">
          <RevenueStatistics />
        </Col>
      </Row>
    </div>
  );
};

export default FinancialStats;
