import React, { useEffect, useState } from 'react';
import FreelancerStatistics from '@/pages/Admin/Dashboard/FreelancerStatistics';
import EmployerStatistics from '@/pages/Admin/Dashboard/EmployerStatistics';
import { Col, notification, Row, Skeleton } from 'antd';
import ProjectStatistics from '@/pages/Admin/Dashboard/ProjectStatistics';
import TopEmployers from '@/pages/Admin/Dashboard/TopEmployers';
import TopFreelancers from '@/pages/Admin/Dashboard/TopFreelancers';
import { AccountApi } from '@/apis';
import PageHeader from '@/components/common/PageHeader';
import Icon from '@/components/common/Icon';

const cards = [
  {
    title: 'Total Employers',
    dataIndex: 'totalEmployers',
    icon: 'user'
  },
  {
    title: 'Total Freelancers',
    dataIndex: 'totalFreelancers',
    icon: 'user'
  },
  {
    title: 'Job Posts',
    dataIndex: 'totalJobPosts',
    icon: 'briefcase'
  },
  {
    title: 'Active Contracts',
    dataIndex: 'activeContracts',
    icon: 'bookmark'
  }
];

const PlatformStats = () => {
  const [loadingStatistics, setLoadingStatistics] = useState(false);
  const [statisticsData, setStatisticsData] = useState<any>({ cards: {} });

  useEffect(() => {
    setLoadingStatistics(true);
    AccountApi.getStatistics()
      .then((res) => {
        setLoadingStatistics(false);
        if (res) {
          setStatisticsData(res);
        }
      })
      .catch((err) => {
        setLoadingStatistics(false);
        notification.error({ message: err.message });
      });
  }, []);

  return (
    <div>
      <PageHeader title="Platform Statistics" />

      <Skeleton
        active={true}
        loading={loadingStatistics}
        paragraph={{ rows: 5 }}
      >
        <Row gutter={[24, 24]}>
          {cards.map((item, index) => (
            <Col span={24} xs={24} sm={12} md={8} lg={6} key={index}>
              <div className="custom-card cmb-12">
                <div className="text-lg font-weight-bold mb-2">
                  {item.title}
                </div>
                <div className="d-flex align-items-center">
                  <Icon
                    size="large"
                    className="text-primary cmr-6"
                    name={item.icon}
                  />
                  <div className="text-xl">
                    {statisticsData.cards[item.dataIndex]}
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Skeleton>

      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} xl={24} className="cmb-12">
          <FreelancerStatistics />
        </Col>
        <Col xs={24} sm={24} md={24} xl={24} className="cmb-12">
          <EmployerStatistics />
        </Col>
      </Row>

      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} xl={24} className="cmb-12">
          <ProjectStatistics />
        </Col>
      </Row>

      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} xl={12} className="cmb-12">
          <TopEmployers />
        </Col>

        <Col xs={24} sm={24} md={24} xl={12} className="cmb-12">
          <TopFreelancers />
        </Col>
      </Row>
    </div>
  );
};

export default PlatformStats;
