import React, { useMemo, useState } from 'react';
import { Switch, notification } from 'antd';
import { FreelancerModel } from '@/resources/models';
import { SITE_URL } from '@/constants';
import { NameHelper } from 'src/helpers';
import { FreelancerApi } from 'src/apis';
import ConfirmDialog from 'src/components/dialogs/ConfirmDialog';

interface IShareProfileProps {
  freelancer?: FreelancerModel;
  onRefresh: () => void;
}

const ShareProfile: React.FC<IShareProfileProps> = ({
  freelancer,
  onRefresh
}) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const link = useMemo(() => {
    return `${SITE_URL}/profiles/freelancers/${freelancer?.id}`;
  }, [freelancer?.id]);

  const togglePublicProfile = () => {
    if (!freelancer?.id) {
      return;
    }

    const data = { user: { publicProfile: !freelancer?.user?.publicProfile } };

    FreelancerApi.update(freelancer.id, data)
      .then(async () => {
        await onRefresh();
        notification.success({
          message: !freelancer?.user?.publicProfile
            ? 'Your profile visibility has been become public successfully.'
            : 'Your profile visibility has been become private successfully.'
        });
      })
      .catch((err) => {
        notification.error({ message: err.message });
      });
  };

  return (
    <div className="custom-card cmb-12">
      <h2 className="cmb-12">Profile Visibility</h2>

      <div className="content">
        <div className="cmb-12">
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center cmr-6">
              <Switch
                checked={freelancer?.user?.publicProfile}
                onChange={() => setShowConfirmDialog(true)}
              />
            </div>

            <p className="mb-0">Profile Visibility</p>
          </div>
        </div>

        <p className="text-sm mb-2">{`${NameHelper.getFullName(
          freelancer?.user
        )} profile link`}</p>

        <div className="d-flex align-items-center">
          <a href={link} target="_blank" className="mb-0 cmr-12 word-break">
            {link}
          </a>
        </div>
      </div>

      <ConfirmDialog
        visible={showConfirmDialog}
        handleClose={() => setShowConfirmDialog(false)}
        onConfirm={togglePublicProfile}
        text="Are you sure you want to make this changes?"
        okText="Ok"
        cancelText="Cancel"
        type="info"
      />
    </div>
  );
};

export default ShareProfile;
