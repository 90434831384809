import React, { FC, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { AuthApi } from '@/apis';
import Input from '@/components/forms/Input';
import Button from '@/components/common/Button';
import './styles.scss';
import { ROUTES } from 'src/constants';
import { Link } from 'react-router-dom';
import { notification } from 'antd';

const ForgotPassword: FC = () => {
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email is not valid.')
      .required('Email is required.')
  });

  const handleSubmit = (values: { email: string }) => {
    setLoading(true);
    AuthApi.forgotPassword(values.email)
      .then(() => {
        setLoading(false);
        notification.success({
          message: 'We have sent an email to you. Please check your Inbox.'
        });
      })
      .catch((err) => {
        setLoading(false);
        notification.error({ message: err.message });
      });
  };
  return (
    <div className="login-page py-5">
      <div className="logo-wrapper">
        <img src="/images/diligentspace_logo.svg" alt="logo" />
      </div>

      <div className="login-form-wrapper">
        <h1 className="page-title text-center text-4xl font-weight-normal cmb-16">
          Forgot Password?
        </h1>

        <p className="text-md cmb-16">
          Please enter your email address to reset your password.
        </p>

        <Formik
          initialValues={{ email: '' }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => (
            <Form className="login-form" onSubmit={handleSubmit}>
              <Input
                name="email"
                type="email"
                label="Email Address"
                value={values.email}
                onChange={handleChange}
                error={errors.email && touched.email ? errors.email : ''}
                placeholder="Work email address"
                required={true}
              />
              <Button
                className="w-100 cmt-16 cmb-12"
                type="submit"
                loading={loading}
              >
                Send
              </Button>
            </Form>
          )}
        </Formik>
        <p className="text-center">
          Already have an account?&nbsp;
          <Link className="sign-in-link" to={ROUTES.AUTH.LOGIN}>
            Sign in
          </Link>
        </p>
      </div>
    </div>
  );
};

export default ForgotPassword;
