import React, { FC } from 'react';
import { DELIVERY_TYPE, PAYMENT_TYPE } from '@/resources/enums';
import { DateHelper } from '@/helpers';
import { ContractModel } from '@/resources/models';
import { ICategory } from '@/resources/interfaces';
import Chip from '@/components/common/Chip';

interface IContractInfoValuesProps {
  contract: ContractModel;
}

const ContractInfoValues: FC<IContractInfoValuesProps> = ({ contract }) => {
  return (
    <>
      <div className="d-flex w-100 flex-wrap align-items-center cmb-9">
        <p className="text-sm cmb-2 cmr-12">
          Rate:&nbsp;
          <span className="text-bold text-blue">
            {contract.deliveryType === DELIVERY_TYPE.HOURLY
              ? `$${Number(contract.price).toFixed(2)}/hr`
              : `$${Number(contract.price).toFixed(2)}`}
          </span>
        </p>

        {contract.createdAt && (
          <div className="text-sm cmb-2 cmr-12">
            <span>Started on:&nbsp;</span>
            <span className="text-bold">
              {DateHelper.formatDate(contract.createdAt, 'MMM DD, YYYY')}
            </span>
          </div>
        )}

        {contract.endAt && (
          <div className="text-sm cmb-2 cmr-12">
            <span>Ended on:&nbsp;</span>
            <span className="text-bold">
              {DateHelper.formatDate(contract.endAt, 'MMM DD, YYYY')}
            </span>
          </div>
        )}

        {contract.paymentType === PAYMENT_TYPE.HOURLY && (
          <div className="text-sm cmb-2 cmr-12">
            <span>Total Paid Hours:&nbsp;</span>
            <span className="text-bold">
              {Number(contract.totalPaidHours || 0).toFixed(2)} hrs
            </span>
          </div>
        )}

        <div className="text-sm cmb-2 cmr-12">
          <span>Total Paid:&nbsp;</span>
          <span className="text-bold">
            ${Number(contract.totalPaid).toFixed(2)}
          </span>
        </div>
      </div>

      <div className="d-flex flex-wrap cmb-3">
        <Chip title={(contract.category as ICategory)?.name} />
        <Chip className="text-capitalize" title={contract.paymentType} />
        <Chip className="text-capitalize" title={`${contract.level} Level`} />
      </div>
    </>
  );
};

export default ContractInfoValues;
