import React, { useEffect, useState } from 'react';
import PageHeader from '@/components/common/PageHeader';
import Input from '@/components/forms/Input';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '@/components/common/Button';
import { SettingsApi } from '@/apis';
import { notification } from 'antd';

const validationSchema = Yup.object().shape({
  version: Yup.string().trim().required('Required Field')
});

const TrackerSettings = () => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: any) => {
    setLoading(true);
    await SettingsApi.update({
      version: values.version
    });
    notification.success({
      message: 'Tracker Version is updated successfully.'
    });
    setLoading(false);
  };

  const form = useFormik({
    validationSchema,
    onSubmit: handleSubmit,
    initialValues: {
      version: ''
    }
  });

  useEffect(() => {
    SettingsApi.getSettings().then((res) => {
      form.setFieldValue('version', res.trackerVersion);
    });
  }, []);

  return (
    <div>
      <PageHeader title="Tracker Settings" />

      <form onSubmit={form.handleSubmit}>
        <Input
          className="form-input"
          label="Tracker Version"
          required={true}
          {...form.getFieldProps('version')}
          error={
            form.errors.version && form.touched.version
              ? form.errors.version
              : ''
          }
        />

        <div className="d-flex align-items-center justify-content-end mt-3">
          <Button type="submit" loading={loading}>
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

export default TrackerSettings;
