import React, { FC, useEffect, useState } from 'react';
import { Empty, notification, Skeleton } from 'antd';
import { FreelancerModel, ReviewModel } from '@/resources/models';
import Pagination from '@/components/common/Pagination';
import ReviewItem from '@/components/pages/ReviewItem';
import { ReviewApi } from '@/apis';
import { REVIEW_WRITER } from '@/resources/enums';
import './styles.scss';

export interface IReviewsProps {
  freelancer?: FreelancerModel;
}

const Reviews: FC<IReviewsProps> = ({ freelancer }) => {
  const [filter, setFilter] = useState({
    page: 1,
    perPage: 3
  });

  const [reviews, setReviews] = useState<ReviewModel[]>([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  //
  useEffect(() => {
    if (freelancer) {
      const query = {
        freelancer: freelancer.id,
        writer: REVIEW_WRITER.EMPLOYER,
        page: filter.page,
        perPage: filter.perPage,
        expands: ['contract'],
        sort: '-createdAt'
      };

      setLoading(true);
      ReviewApi.search(query)
        .then((res) => {
          setTotal(res.totalCount);
          setReviews(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          notification.error({ message: err.message });
        });
    }
  }, [filter, freelancer]);

  return (
    <div className="work-history">
      <div className="work-history__header">
        <p className="text-heading3 mb-0">Work History ({total})</p>
      </div>

      {reviews.length > 0 ? (
        <div className="review-content">
          <Skeleton active={true} loading={loading} paragraph={{ rows: 6 }}>
            <div className="review-list">
              {reviews.map((item) => (
                <ReviewItem review={item} key={item.id} />
              ))}
            </div>
          </Skeleton>

          <div className="pagination-container">
            <Pagination
              total={total}
              current={filter.page}
              pageSize={filter.perPage}
              onChange={(page, perPage) => setFilter({ page, perPage })}
            />
          </div>
        </div>
      ) : (
        <Skeleton active={true} loading={loading} paragraph={{ rows: 6 }}>
          <div className="cpy-10">
            <Empty />
          </div>
        </Skeleton>
      )}
    </div>
  );
};

export default Reviews;
