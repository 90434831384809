import React, { FC, useState } from 'react';
import { Modal, Spin } from 'antd';
import Button from '@/components/common/Button';
import './styles.scss';
import Select from '@/components/forms/Select';
import { KYC_ADMIN_REJECT_REASON_VALUES } from '@/resources/interfaces';
import { KycApi } from '@/apis';

export interface IKycDeclineDialogProps {
  visible: boolean;
  handleClose: () => void;
  onConfirm: () => void;
  title?: string;
  text: string;
  type?: string;
  userId?: string;
}

const KycDeclineDialog: FC<IKycDeclineDialogProps> = ({
  visible,
  handleClose,
  onConfirm,
  text,
  userId
}) => {
  const [loading, setLoading] = useState(false);
  const [rejectReasons, setRejectReasons] = useState('');

  const onConfirmClick = async () => {
    try {
      setLoading(true);
      if (userId) {
        await KycApi.deny(userId, { rejectReasons });
      }
      onConfirm();
    } catch (e) {
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  const options = KYC_ADMIN_REJECT_REASON_VALUES.map((item) => ({
    title: item,
    value: item
  }));

  return (
    <Modal
      visible={visible}
      onCancel={handleClose}
      className="confirm-dialog dialog"
      footer={null}
      width={600}
    >
      <div className="dialog-body cpt-15 cpb-20">
        <p className="text-3xl cpt-8 cmb-12 text-center">{text}</p>
        <div className="cmb-20">
          <Select
            label="Reject Reason"
            value={rejectReasons}
            onChange={setRejectReasons}
            options={options}
            multiSelect={true}
          />
        </div>

        <div className="d-flex justify-content-center">
          <Spin spinning={loading} size="small">
            <Button
              className="cmr-12"
              onClick={onConfirmClick}
              disabled={!rejectReasons.length}
            >
              Confirm Decline
            </Button>
          </Spin>
          <Button variant="outline" onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default KycDeclineDialog;
