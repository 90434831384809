import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { notification, Skeleton, Tabs } from 'antd';
import { InvoiceApi } from '@/apis';
import { InvoiceModel } from '@/resources/models';
import InvoiceDetail from '@/components/pages/InvoiceDetail';
import BackButton from '@/components/common/BackButton';
import IconButton from '@/components/common/IconButton';
import { FileHelper } from '@/helpers';
import { API_SERVER, ROUTES } from '@/constants';
import NoData from '@/components/common/NoData';
import './styles.scss';
import ChangeLogSection from '@/components/pages/ChangeLogSection';

const InvoiceDetails: FC = () => {
  const history = useHistory();
  const params: { id: string; tab: string } = useParams();
  const { id, tab: activeTab } = params;

  const [loading, setLoading] = useState(false);
  const [invoice, setInvoice] = useState<InvoiceModel | undefined>();
  const [exportingPDF, setExportingPDF] = useState(false);

  const downloadInvoice = async () => {
    if (invoice) {
      setExportingPDF(true);

      try {
        const res = await InvoiceApi.exportAsPDF(invoice.id);
        FileHelper.download(
          `${API_SERVER}/public/download?token=${res}`,
          `${invoice.invoiceId}.pdf`
        );
        setExportingPDF(false);
      } catch (err) {
        setExportingPDF(false);
      }
    }
  };

  useEffect(() => {
    getInvoice();
  }, [id]);

  const getInvoice = () => {
    setLoading(true);
    InvoiceApi.find(id)
      .then((res) => {
        setLoading(false);
        setInvoice(res);
      })
      .catch((err) => {
        setLoading(false);
        notification.error({ message: err.message });
      });
  };

  return (
    <div className="admin-invoice-details-page">
      <div className="d-flex align-items-center justify-content-between cmb-17 none-printable">
        <BackButton />

        <div className="d-flex align-items-center">
          <IconButton
            className="cmr-10"
            icon="download"
            onClick={downloadInvoice}
            tooltip="Download"
            loading={exportingPDF}
          />

          <IconButton
            icon="print"
            onClick={() => window.print()}
            tooltip="Print"
          />
        </div>
      </div>

      <Tabs
        activeKey={activeTab}
        onChange={(tab) =>
          history.replace(`${ROUTES.ADMIN.INVOICES.PREFIX}/${id}/${tab}`)
        }
      >
        <Tabs.TabPane key="details" tabKey="details" tab="Details" />

        <Tabs.TabPane key="events" tabKey="events" tab="Change Logs" />
      </Tabs>

      {activeTab === 'details' ? (
        <Skeleton loading={loading} active={true} paragraph={{ rows: 10 }}>
          {invoice ? (
            <InvoiceDetail invoice={invoice} getInvoice={getInvoice} />
          ) : (
            <NoData title="Not found invoice" />
          )}
        </Skeleton>
      ) : (
        <ChangeLogSection objectType="invoice" objectId={id} />
      )}
    </div>
  );
};

export default InvoiceDetails;
