import React, { FC, memo } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { AUTH_ROUTES, ROUTES } from '@/constants';
import { AuthRoute } from '@/routes';
import { ServerError } from '@/pages/Public';

const UnauthenticatedRoutes: FC = memo(() => (
  <Switch>
    <Redirect exact={true} path={ROUTES.HOME} to={ROUTES.AUTH.LOGIN} />
    {AUTH_ROUTES.map((route) => (
      <AuthRoute key={route.path as string} {...route} />
    ))}
    <AuthRoute
      path={ROUTES.SERVER_ERROR}
      exact={true}
      component={ServerError}
    />
    <Redirect path="*" to={ROUTES.AUTH.LOGIN} />
  </Switch>
));

UnauthenticatedRoutes.displayName = 'UnauthenticatedRoutes';

export default UnauthenticatedRoutes;
