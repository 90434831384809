import { config } from 'dotenv';

config();

export const ACCESS_TOKEN_KEY =
  process.env.REACT_APP_ACCESS_TOKEN || 'access-token';
export const REFRESH_TOKEN_KEY =
  process.env.REACT_APP_REFRESH_TOKEN || 'refresh-token';
export const REMEMBER_ME_KEY = 'remember-me';

export const API_SERVER = process.env.REACT_APP_API_SERVER || 'api-server';

export const DEFAULT_LANGUAGE = process.env.REACT_APP_DEFAULT_LANGUAGE || 'en';

export const SITE_URL =
  process.env.REACT_APP_SITE_URL || 'https://staging-app.diligentspace.com';

export const usdWithdrawFee = process.env.REACT_APP_USD_WITHDRAW_FEE
  ? Number(process.env.REACT_APP_USD_WITHDRAW_FEE)
  : 30;
export const sepaEURWithdrawFee = process.env.REACT_APP_SEPA_EUR_WITHDRAW_FEE
  ? Number(process.env.REACT_APP_SEPA_EUR_WITHDRAW_FEE)
  : 5;
export const nonSepaEURWithdrawFee = process.env
  .REACT_APP_NONE_SEPA_EUR_WITHDRAW_FEE
  ? Number(process.env.REACT_APP_NONE_SEPA_EUR_WITHDRAW_FEE)
  : 25;
export const minWithdrawAmount = process.env.REACT_APP_MIN_WITHDRAW_AMOUNT
  ? Number(process.env.REACT_APP_MIN_WITHDRAW_AMOUNT)
  : 50;
