import { ISearchApiResult, IOrder } from '@/resources/interfaces';
import { OrderModel } from '@/resources/models';
import Http from '@/apis/http';

export class OrderApi {
  static search(query: any = {}): Promise<ISearchApiResult<OrderModel>> {
    return Http.get('/admin/orders', query);
  }

  static find(orderId: string): Promise<OrderModel> {
    return Http.get(`/admin/orders/${orderId}`).then(
      (data: IOrder) => new OrderModel(data)
    );
  }

  static retry(id: string): Promise<OrderModel> {
    return Http.post(`/admin/orders/${id}/retry`).then(
      (res: IOrder) => new OrderModel(res)
    );
  }
}
