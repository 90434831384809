import React, { FC, useState } from 'react';
import { Button, Modal } from 'antd';
import Icon from '@/components/common/Icon';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Input from '@/components/forms/Input';
import { disable2FA } from '@/redux/actions';
import { useActionDispatch } from '@/hooks';
import './styles.scss';

interface TfaModalProps {
  open: boolean;
  onClose: () => void;
  qr: string | null;
}

const TfaVerifyModal: FC<TfaModalProps> = ({ open, onClose, qr }) => {
  const dispatch = useActionDispatch();
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    code: Yup.string().matches(/^[0-9]{6}$/)
  });

  const onSubmit = async (values: any) => {
    setLoading(true);
    await dispatch(disable2FA(values));
    setLoading(false);
    onClose();
  };

  const form = useFormik({
    initialValues: {
      code: ''
    },
    validationSchema,
    onSubmit
  });

  return (
    <div>
      <Modal
        className="tfa-modal"
        title={<h4 className="mb-0">Two Factor Authentication</h4>}
        visible={open}
        onOk={() => {}}
        onCancel={onClose}
        closeIcon={<Icon name="close-big" />}
        footer={false}
      >
        <div className="d-flex justify-content-center">
          {qr ? (
            <img src={qr} className="tz-modal__auth__qr" alt="" />
          ) : (
            <p className="mt-3">Please enter the code from your Authy app</p>
          )}
        </div>

        <form onSubmit={form.handleSubmit}>
          <div className="mb-3">
            <Input
              className="border-input"
              label="Code"
              placeholder="Code*"
              {...form.getFieldProps('code')}
              error={
                form.errors.code && form.touched.code ? form.errors.code : ''
              }
            />
          </div>
          <Button
            htmlType="submit"
            className="w-100 btn-submit"
            type="primary"
            loading={loading}
          >
            Verify
          </Button>
        </form>
      </Modal>
    </div>
  );
};

export default TfaVerifyModal;
