import React, { FC, useState } from 'react';
import { PAYMENT_STATUS } from '@/resources/enums';
import Select, { ISelectOption } from '@/components/forms/Select';
import Search from '@/components/forms/Search';
import DateRangePicker from '@/components/forms/DateRangePicker';
import AsyncSelect from '@/components/forms/AsyncSelect';
import { EmployerApi } from '@/apis';
import { NameHelper } from '@/helpers';
import './styles.scss';

const statusOptions = [
  { title: 'All', value: undefined },
  { title: 'Paid', value: PAYMENT_STATUS.PAID },
  { title: 'Pending', value: PAYMENT_STATUS.PENDING },
  { title: 'Processed', value: PAYMENT_STATUS.PROCESSED },
  {
    title: 'Waiting for payment',
    value: PAYMENT_STATUS.WAITING_FOR_PAYMENT
  },
  { title: 'Disabled', value: PAYMENT_STATUS.DISABLED },
  { title: 'Declined', value: PAYMENT_STATUS.DECLINED },
  { title: 'New', value: PAYMENT_STATUS.NONE }
];

export interface IInvoiceSearchFilterProps {
  filter: any;
  onChangeFilter: (field: string, value: any) => void;
  user?: string;
}

const InvoiceSearchFilter: FC<IInvoiceSearchFilterProps> = ({
  filter,
  onChangeFilter
}) => {
  const [employerOptions, setEmployerOptions] = useState<ISelectOption[]>([]);

  const searchEmployers = (search: string) => {
    EmployerApi.search({ search, perPage: 20 }).then((res) => {
      if (res.data) {
        setEmployerOptions(
          res.data.map((employer) => ({
            title: NameHelper.getFullName(employer.user),
            value: employer.id
          }))
        );
      }
    });
  };

  return (
    <div className="invoice-filter-container">
      <div className="cmb-10">
        <Search
          className="page-search-box"
          placeholder="Search for invoices by invoice ID, project title, employer name or freelancer name"
          value={filter.search}
          onChange={(e) => onChangeFilter('search', e.target.value)}
        />
      </div>

      <div className="filter-select-list">
        <div className="cmr-12 cmb-10">
          <DateRangePicker
            label="Dates"
            dateRange={filter.dateRange}
            setDateRange={(value) => onChangeFilter('dateRange', value)}
          />
        </div>

        <div className="cmr-12 cmb-10">
          <Select
            label="Status"
            value={filter.status}
            onChange={(value) => onChangeFilter('status', value)}
            options={statusOptions}
          />
        </div>
        <div className="cmb-10">
          <AsyncSelect
            label="Employer"
            searchable={true}
            multiSelect={true}
            showChipList={false}
            value={filter.employer}
            onChange={(value) => onChangeFilter('employer', value)}
            options={employerOptions}
            onFetch={searchEmployers}
          />
        </div>
      </div>
    </div>
  );
};

export default InvoiceSearchFilter;
