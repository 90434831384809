import React, { ChangeEvent, FC, useMemo } from 'react';
import { PAYMENT_TYPE, PROJECT_STATUS } from '@/resources/enums';
import DateRangePicker from '@/components/forms/DateRangePicker';
import Select from '@/components/forms/Select';
import Search from '@/components/forms/Search';
import Button from '@/components/common/Button';
import './styles.scss';

export interface IDateRange {
  startDate?: Date;
  endDate?: Date;
}

export interface IProjectSearchFilter {
  title: string;
  dateRange: IDateRange;
  paymentType?: PAYMENT_TYPE;
  status?: PROJECT_STATUS;
  page?: number;
  perPage?: number;
}

export interface IProjectSearchFilterProps {
  filter: IProjectSearchFilter;
  onChangeFilter: (field: string, value: any) => void;
}

const ProjectSearchFilter: FC<IProjectSearchFilterProps> = ({
  filter,
  onChangeFilter
}) => {
  const { title, paymentType, dateRange, status } = filter;

  const paymentTypeOptions = useMemo(
    () => [
      { title: 'All', value: undefined },
      { title: 'Hourly', value: PAYMENT_TYPE.HOURLY },
      { title: 'Fixed', value: PAYMENT_TYPE.FIXED }
    ],
    []
  );

  const statusOptions = useMemo(
    () => [
      { title: 'All', value: undefined },
      { title: 'Opened', value: PROJECT_STATUS.OPEN },
      { title: 'Awarded', value: PROJECT_STATUS.AWARDED },
      { title: 'Pending', value: PROJECT_STATUS.PENDING },
      { title: 'Draft', value: PROJECT_STATUS.DRAFT },
      { title: 'Closed', value: PROJECT_STATUS.CLOSED },
      { title: 'Disabled', value: PROJECT_STATUS.DISABLED }
    ],
    []
  );

  return (
    <div className="project-filter-container">
      <div className="cmb-10">
        <Search
          className="page-search-box"
          value={title}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChangeFilter('title', e.target.value)
          }
          placeholder="Search for projects by title"
        />
      </div>

      <div className="filter-select-list">
        <div className="cmr-12 cmb-10">
          <DateRangePicker
            label="Dates"
            dateRange={dateRange}
            setDateRange={(value) => onChangeFilter('dateRange', value)}
          />
        </div>

        <div className="cmr-12 cmb-10">
          <Select
            label="Payment Type"
            value={paymentType}
            onChange={(value) => onChangeFilter('paymentType', value)}
            options={paymentTypeOptions}
          />
        </div>

        <div className="cmr-12 cmb-10">
          <Select
            label="Status"
            value={status}
            onChange={(value) => onChangeFilter('status', value)}
            options={statusOptions}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectSearchFilter;
