import React, { FC, useState } from 'react';
import { notification, Switch } from 'antd';
import { FreelancerApi } from '@/apis';
import QuestionSecurityDialog from './QuestionSecurityDialog';
import { FreelancerModel } from 'src/resources/models';
import ConfirmDialog from 'src/components/dialogs/ConfirmDialog';

interface ISecuritySettingsProps {
  freelancer?: FreelancerModel;
  onRefresh: () => void;
}

const SecuritySettings: FC<ISecuritySettingsProps> = ({
  freelancer,
  onRefresh
}) => {
  const [showQSSDialog, setShowQSSDialog] = useState(false);
  const [showQuestionConfirmDialog, setShowQuestionConfirmDialog] = useState(
    false
  );
  const [showPinConfirmDialog, setShowPinConfirmDialog] = useState(false);
  const [loadingPin, setLoadingPin] = useState(false);
  const [loadingQS, setLoadingQS] = useState(false);

  const togglePinSecurity = () => {
    if (!freelancer?.id) {
      return;
    }

    const pinEnabled = freelancer?.user?.pin?.enabled;

    setLoadingPin(true);

    FreelancerApi.update(freelancer?.id, {
      user: { pin: { enabled: !pinEnabled } }
    })
      .then(() => {
        onRefresh();

        notification.success({
          message: pinEnabled
            ? 'Text message verification is disabled successfully.'
            : 'Text message verification is enabled successfully.'
        });
      })
      .catch((err) => {
        notification.error({ message: err.message });
      })
      .finally(() => {
        setLoadingPin(false);
      });
  };

  const handleChangeQS = () => {
    if (!freelancer?.user?.securityQuestion?.enabled) {
      setShowQSSDialog(true);
    } else {
      setShowQuestionConfirmDialog(true);
    }
  };

  const onUpdateSecurityQuestion = (securityQuestion: any) => {
    if (!freelancer?.id) {
      return;
    }

    setLoadingQS(true);
    FreelancerApi.update(freelancer.id, { user: { securityQuestion } })
      .then(async () => {
        await onRefresh();

        securityQuestion.enabled
          ? setShowQSSDialog(false)
          : setShowQuestionConfirmDialog(false);

        notification.success({
          message: securityQuestion.enabled
            ? 'Security question is saved successfully.'
            : 'Security question is disabled successfully.'
        });
      })
      .catch((err) => {
        notification.error({ message: err.message });
      })
      .finally(() => {
        setLoadingQS(false);
      });
  };

  return (
    <div className="custom-card h-100">
      <h2 className="cmb-12">Security Setting</h2>

      <div className="content">
        <div className="d-flex cmb-12">
          <div className="cmr-12">
            <Switch
              checked={freelancer?.user?.pin?.enabled}
              onChange={() => setShowPinConfirmDialog(true)}
              loading={loadingPin}
            />
          </div>

          <p>Text message verification</p>
        </div>

        <div className="d-flex">
          <div className="cmr-12">
            <Switch
              checked={freelancer?.user?.securityQuestion?.enabled}
              onClick={handleChangeQS}
              loading={loadingQS}
            />
          </div>

          <p>Security question</p>
        </div>
      </div>

      <QuestionSecurityDialog
        visible={showQSSDialog}
        handleClose={() => setShowQSSDialog(false)}
        onSubmit={onUpdateSecurityQuestion}
      />

      <ConfirmDialog
        visible={showQuestionConfirmDialog}
        handleClose={() => setShowQuestionConfirmDialog(false)}
        onConfirm={() =>
          onUpdateSecurityQuestion({ enabled: false, question: '', answer: '' })
        }
        text="Are you sure you want to make this changes?"
        okText="Ok"
        cancelText="Cancel"
        type="info"
      />
      <ConfirmDialog
        visible={showPinConfirmDialog}
        handleClose={() => setShowPinConfirmDialog(false)}
        onConfirm={togglePinSecurity}
        text="Are you sure you want to make this changes?"
        okText="Ok"
        cancelText="Cancel"
        type="info"
      />
    </div>
  );
};

export default SecuritySettings;
