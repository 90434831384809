import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Modal, notification, Skeleton } from 'antd';
import { PERMISSION, USER_STATUS } from '@/resources/enums';
import { FreelancerModel } from '@/resources/models';
import { FreelancerApi } from '@/apis';
import { ROUTES } from '@/constants';
import { useCheckPermission } from '@/hooks';
import Icon from '@/components/common/Icon';
import Button from '@/components/common/Button';
import Switch from '@/components/common/Switch';
import FreelancerProfile from '@/components/pages/FreelancerProfile';
import KycVerificationCard from '@/pages/Admin/FreelancerDetails/UserDetails/KycVerificationCard';
import NoData from '@/components/common/NoData';

const UserDetails = () => {
  const history = useHistory();
  const params: { id: string } = useParams();
  const { id } = params;

  const checkPermission = useCheckPermission();

  const [loading, setLoading] = useState(false);
  const [freelancer, setFreelancer] = useState<FreelancerModel>();
  const [userStatus, setUserStatus] = useState<USER_STATUS | undefined>(
    USER_STATUS.OPENED
  );
  const [deleting, setDeleting] = useState(false);
  const [blocking, setBlocking] = useState(false);

  const blockFreelancer = () => {
    setBlocking(true);
    FreelancerApi.block(id)
      .then(() => {
        setBlocking(false);
        setUserStatus(USER_STATUS.BLOCKED);
        notification.success({
          message: 'Freelancer is blocked successfully.'
        });
      })
      .catch((err) => {
        setBlocking(false);
        notification.error({ message: err.message });
      });
  };

  const unblockFreelancer = () => {
    setBlocking(true);
    FreelancerApi.unblock(id)
      .then(() => {
        setBlocking(false);
        setUserStatus(USER_STATUS.OPENED);
        notification.success({
          message: 'Freelancer is unblocked successfully.'
        });
      })
      .catch((err) => {
        setBlocking(false);
        notification.error({ message: err.message });
      });
  };

  const deleteFreelancer = () => {
    setDeleting(true);
    FreelancerApi.delete(id)
      .then(() => {
        setDeleting(false);
        notification.success({
          message: 'Freelancer is deleted successfully.'
        });
        history.push(ROUTES.ADMIN.FREELANCERS.INDEX);
      })
      .catch((err) => {
        setDeleting(false);
        notification.error({ message: err.message });
      });
  };

  const showDeleteFreelancerModal = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this freelancer?',
      centered: true,
      onOk: deleteFreelancer
    });
  };

  const confirmBlockFreelancer = () => {
    Modal.confirm({
      title: 'Are you sure you want to block this user?',
      centered: true,
      onOk: blockFreelancer
    });
  };

  const confirmUnblockFreelancer = () => {
    Modal.confirm({
      title: 'Are you sure you want to unblock this user?',
      centered: true,
      onOk: unblockFreelancer
    });
  };

  const onStatusToggle = async () => {
    if (userStatus === USER_STATUS.BLOCKED) {
      confirmUnblockFreelancer();
    } else {
      confirmBlockFreelancer();
    }
  };

  const getFreelancer = async () => {
    setLoading(true);
    FreelancerApi.find(id)
      .then((data) => {
        setLoading(false);
        setFreelancer(data);
        setUserStatus(data.status);
      })
      .catch((err) => {
        setLoading(false);
        notification.error({ message: err.message });
      });
  };
  useEffect(() => {
    getFreelancer().then();
  }, [id]);

  return (
    <div className="freelancer-user-detail">
      {checkPermission(PERMISSION.DELETE_USER) && (
        <div className="d-flex justify-content-end align-items-center cmb-17">
          <Button
            variant="outline"
            disabled={userStatus === USER_STATUS.DELETED}
            onClick={showDeleteFreelancerModal}
            icon={<Icon name="delete" className="cmr-6" />}
            loading={deleting}
          >
            Delete
          </Button>

          <Switch
            className="cml-12"
            enabled={userStatus !== USER_STATUS.BLOCKED}
            onToggle={onStatusToggle}
            label="Enabled"
            loading={blocking}
          />
        </div>
      )}

      <Skeleton loading={loading} active={true} paragraph={{ rows: 10 }}>
        {freelancer ? (
          <>
            <div className="cmb-12">
              <FreelancerProfile freelancer={freelancer} status={userStatus} />
            </div>

            <div className="cmb-12">
              <KycVerificationCard
                freelancer={freelancer}
                getFreelancer={getFreelancer}
              />
            </div>
          </>
        ) : (
          <NoData title="Not found freelancer" />
        )}
      </Skeleton>
    </div>
  );
};

export default UserDetails;
