import React, { FC, useEffect, useMemo, useState } from 'react';
import { Table } from 'antd';
import {
  EmployerModel,
  FreelancerModel,
  InvoiceModel,
  ContractModel
} from '@/resources/models';
import { DateHelper } from '@/helpers';
import { DELIVERY_TYPE, PAYMENT_TYPE } from '@/resources/enums';
import './styles.scss';

export interface IInvoiceDetailProps {
  invoice: InvoiceModel;
  showLogo?: boolean;
}

const InvoiceDetailInfo: FC<IInvoiceDetailProps> = ({
  invoice,
  showLogo = false
}) => {
  const [imageUrl, setImageUrl] = useState('');
  const { billingPeriod } = invoice;
  const contract = invoice.contract as ContractModel;
  const freelancer = invoice.freelancer as FreelancerModel;
  const employer = invoice.employer as EmployerModel;

  const columns = useMemo(() => {
    if (invoice.deliveryType === DELIVERY_TYPE.HOURLY) {
      return [
        {
          title: 'Description',
          dataIndex: 'description',
          ellipsis: true,
          render: (description: string) => (
            <div className="break-word">{description}</div>
          )
        },
        {
          title: `Quantity (hr)`,
          dataIndex: 'quantity'
        },
        {
          title: 'Rate',
          dataIndex: 'price'
        },
        {
          title: 'Price',
          dataIndex: 'total'
        }
      ];
    }

    return [
      {
        title: 'Description',
        dataIndex: 'description',
        ellipsis: true,
        render: (description: string) => (
          <div className="break-word">{description}</div>
        )
      },
      {
        title: 'Price',
        dataIndex: 'price'
      }
    ];
  }, [invoice]);

  const dataSource = useMemo(() => {
    return invoice.details.map((item) => {
      if (invoice.deliveryType === DELIVERY_TYPE.HOURLY) {
        return {
          ...item,
          price: `$${item.price.toFixed(2)}/hr`,
          total: `$${(Number(item.price) * Number(item.quantity)).toFixed(2)}`
        };
      }

      return {
        ...item,
        price: `$${item.price.toFixed(2)}`
      };
    });
  }, [invoice]);

  useEffect(() => {
    const previewUrl = freelancer.user?.avatarUrl;
    if (previewUrl) {
      fetch(previewUrl)
        .then((res) => res.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.addEventListener(
            'load',
            function () {
              setImageUrl(reader.result as string);
            },
            false
          );
          reader.readAsDataURL(blob);
        });
    }
  }, [invoice]);

  return (
    <div className="invoice-detail-container">
      <div className="invoice-detail-header">
        <div>
          <p className="text-md mb-0">Invoice ID: {invoice.invoiceId}</p>
          <p className="text-md mb-0">
            Date: {DateHelper.formatDate(invoice.createdAt, 'YYYY-MM-DD')}
          </p>
        </div>
        {showLogo && (
          <div className="logo-wrapper">
            <img src="/images/blue-logo.png" alt="avatarImage" />
          </div>
        )}
      </div>

      <div className="client-info">
        <div className="row">
          <div className="col-lg-9 col-md-6 col-sm-12 left-side">
            <div className="d-flex mb-2">
              <div className="avatar-wrapper">
                <img src={imageUrl} alt="avatarImage" />
              </div>
              <div className="d-flex flex-grow-1 flex-column project-info">
                <p className="text-primary text-md mt-1 mb-0">
                  {freelancer.user?.fullName}
                </p>
                <p className="text-md text-ellipsis mt-1">{freelancer.title}</p>
              </div>
            </div>

            <div>
              <div className="mb-1">
                <span className="text-sm mr-1">Project: </span>
                <span className="text-md font-weight-bold">
                  {contract.title}
                </span>
              </div>
              <div>
                <span className="text-sm mr-1">
                  {contract.paymentType === PAYMENT_TYPE.FIXED
                    ? 'Project Budget: '
                    : 'Hourly Price: '}
                </span>
                <span className="text-md font-weight-bold">
                  ${Number(contract.price).toFixed(2)}
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 right-side">
            <p className="font-weight-bold m-0">To:</p>
            <p className="text-sm mb-0">{employer.user?.fullName}</p>
            <p className="text-sm text-blue mb-2">{employer.user?.email}</p>
            <p className="font-weight-bold m-0">From:</p>
            <p className="text-sm mb-0">{freelancer.user?.fullName}</p>
            <p className="text-sm text-blue">{freelancer.user?.email}</p>
          </div>
        </div>
      </div>

      <div className="billing-info">
        <p className="text-lg font-weight-bold">
          Billing Date:{' '}
          {DateHelper.formatDate(billingPeriod.startDate, 'YYYY-MM-DD')} to{' '}
          {DateHelper.formatDate(billingPeriod.endDate, 'YYYY-MM-DD')}
        </p>
      </div>

      {(invoice.deliveryType === DELIVERY_TYPE.HOURLY ||
        invoice.deliveryType === DELIVERY_TYPE.MILESTONE) && (
        <div className="invoice-details my-3">
          <Table
            rowKey={'_id'}
            dataSource={dataSource}
            columns={columns}
            pagination={false}
          />
        </div>
      )}

      <div className="invoice-total my-4 mx-auto">
        <span className="text-md font-weight-bold">Invoice Total</span>
        <span className="text-md font-weight-bold">
          ${Number(invoice.total).toFixed(2)}
        </span>
      </div>

      <div className="note-container">
        <p className="text-md font-weight-bold mb-2 break-word text-center">
          Note : {invoice.notes}
        </p>
      </div>
    </div>
  );
};

export default InvoiceDetailInfo;
