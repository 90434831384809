import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { notification, Tabs } from 'antd';
import { FREELANCER_DETAILS_ROUTES, ROUTES } from '@/constants';
import BackButton from '@/components/common/BackButton';
import UserDetails from '@/pages/Admin/FreelancerDetails/UserDetails';
import PageHeader from '@/components/common/PageHeader';
import ChangeLogSection from '@/components/pages/ChangeLogSection';
import ActionHistories from '@/components/pages/Settings/ActionHisotries';
import FreelancerSettings from './FreelancerSettings';
import FreelancerMessages from './FreelancerMessages';
import { useCheckRole } from 'src/hooks';
import { FreelancerApi } from 'src/apis';
import { FreelancerModel } from 'src/resources/models';
import WithdrawHistories from './WithdrawHistories';
import FeeExemptions from 'src/components/pages/FeeExemptions';
import Invitations from '@/components/pages/Invitations';
import Badge, { BADGE_STATUS } from '@/components/common/Badge';
import { FreelancerBankAccounts } from '@/pages/Admin/FreelancerDetails/FreelancerBankAccounts';

const FreelancerDetails: FC = () => {
  const history = useHistory();
  const { tab: activeTab, id } = useParams<{ tab: string; id: string }>();
  const { isAboveAdmin } = useCheckRole();
  const [loading, setLoading] = useState(false);
  const [freelancer, setFreelancer] = useState<FreelancerModel>();

  const getFreelancer = useCallback(() => {
    setLoading(true);
    FreelancerApi.find(id)
      .then((data) => {
        setLoading(false);
        setFreelancer(data);
      })
      .catch((err) => {
        setLoading(false);
        notification.error({ message: err.message });
      });
  }, [id]);

  useEffect(() => {
    getFreelancer();
  }, [getFreelancer]);

  return (
    <div className="admin-freelancer-details-page">
      <div className="cmb-17">
        <BackButton />
      </div>

      <PageHeader title="Freelancer Details">
        {freelancer?.tags &&
          freelancer.tags.map((tag, index) => (
            <Badge
              key={index}
              className="ml-3"
              title={tag}
              status={BADGE_STATUS.SUCCESS}
            />
          ))}
      </PageHeader>

      <Tabs
        activeKey={activeTab}
        onChange={(tab) =>
          history.replace(`${ROUTES.ADMIN.FREELANCERS.PREFIX}/${id}/${tab}`)
        }
      >
        <Tabs.TabPane
          key={FREELANCER_DETAILS_ROUTES.DETAILS}
          tabKey={FREELANCER_DETAILS_ROUTES.DETAILS}
          tab="Details"
        />

        {isAboveAdmin && (
          <>
            <Tabs.TabPane
              key={FREELANCER_DETAILS_ROUTES.MESSAGES}
              tabKey={FREELANCER_DETAILS_ROUTES.MESSAGES}
              tab="Messages"
            />
            <Tabs.TabPane
              key={FREELANCER_DETAILS_ROUTES.EVENTS}
              tabKey={FREELANCER_DETAILS_ROUTES.EVENTS}
              tab="Change Logs"
            />
            <Tabs.TabPane
              key={FREELANCER_DETAILS_ROUTES.ACTION_HISTORIES}
              tabKey={FREELANCER_DETAILS_ROUTES.ACTION_HISTORIES}
              tab="Action Histories"
            />
            <Tabs.TabPane
              key={FREELANCER_DETAILS_ROUTES.WITHDRAW_HISTORIES}
              tabKey={FREELANCER_DETAILS_ROUTES.WITHDRAW_HISTORIES}
              tab="Withdraw Histories"
            />
            <Tabs.TabPane
              key={FREELANCER_DETAILS_ROUTES.FEE_EXEMPTIONS}
              tabKey={FREELANCER_DETAILS_ROUTES.FEE_EXEMPTIONS}
              tab="Fee Exemptions"
            />
            <Tabs.TabPane
              key={FREELANCER_DETAILS_ROUTES.REFERRALS}
              tabKey={FREELANCER_DETAILS_ROUTES.REFERRALS}
              tab="Referrals"
            />
            <Tabs.TabPane
              key={FREELANCER_DETAILS_ROUTES.SETTINGS}
              tabKey={FREELANCER_DETAILS_ROUTES.SETTINGS}
              tab="Settings"
            />
            <Tabs.TabPane
              key={FREELANCER_DETAILS_ROUTES.BANK_ACCOUNTS}
              tabKey={FREELANCER_DETAILS_ROUTES.BANK_ACCOUNTS}
              tab="Bank Accounts"
            />
          </>
        )}
      </Tabs>

      {activeTab === FREELANCER_DETAILS_ROUTES.DETAILS && <UserDetails />}

      {isAboveAdmin && activeTab === FREELANCER_DETAILS_ROUTES.MESSAGES && (
        <FreelancerMessages />
      )}
      {isAboveAdmin && activeTab === FREELANCER_DETAILS_ROUTES.EVENTS && (
        <ChangeLogSection objectType="freelancer" objectId={id} />
      )}
      {isAboveAdmin &&
        activeTab === FREELANCER_DETAILS_ROUTES.ACTION_HISTORIES && (
          <ActionHistories type="freelancer" />
        )}
      {isAboveAdmin &&
        activeTab === FREELANCER_DETAILS_ROUTES.WITHDRAW_HISTORIES && (
          <WithdrawHistories />
        )}
      {isAboveAdmin &&
        activeTab === FREELANCER_DETAILS_ROUTES.FEE_EXEMPTIONS && (
          <FeeExemptions userId={freelancer?.user?.id} />
        )}
      {isAboveAdmin && activeTab === FREELANCER_DETAILS_ROUTES.REFERRALS && (
        <Invitations userId={freelancer?.user?.id} />
      )}
      {isAboveAdmin && activeTab === FREELANCER_DETAILS_ROUTES.SETTINGS && (
        <FreelancerSettings freelancer={freelancer} onRefresh={getFreelancer} />
      )}
      {isAboveAdmin &&
        activeTab === FREELANCER_DETAILS_ROUTES.BANK_ACCOUNTS && (
          <FreelancerBankAccounts
            freelancer={freelancer}
            onRefresh={getFreelancer}
            loading={loading}
          />
        )}
    </div>
  );
};

export default FreelancerDetails;
