import axios, { AxiosRequestConfig, Method } from 'axios';
import { API_SERVER, ROUTES } from '@/constants';
import { IHttpError } from '@/resources/interfaces';
import store, { history } from '@/redux/store';
import { setTokens } from '@/redux/actions';
import { getTokens } from '@/selectors';
import * as _ from 'lodash';

const http = axios.create({ baseURL: `${API_SERVER}/` });
let reqQueue: {
  resolve: (data?: any) => void;
  reject: (data?: any) => void;
}[] = [];

const request = (method: Method, url: string, options: AxiosRequestConfig) => {
  const tokens = getTokens(store.getState());
  if (tokens?.accessToken) {
    options.headers.Authorization = `Bearer ${tokens.accessToken}`;
  }

  return http
    .request({
      ...options,
      method,
      url
    })
    .then(httpResponseHandler)
    .catch(httpErrorHandler);
};

const checkTokens = (response: any): void => {
  if (response?.headers['access-token']) {
    store.dispatch(
      setTokens({
        accessToken: response.headers['access-token'],
        refreshToken: response.headers['refresh-token']
      })
    );
  }
};

// tslint:disable-next-line: ban-types
const _refreshTokens: Function = _.debounce((resolve, reject): Promise<any> => {
  const tokens = getTokens(store.getState());
  if (!tokens?.refreshToken) {
    return reject();
  }

  return http
    .post('/admin/auth/refresh-token', { refreshToken: tokens.refreshToken })
    .then((response) => {
      store.dispatch(
        setTokens({
          accessToken: response.data.accessToken,
          refreshToken: response.data.refreshToken,
          remember: tokens.remember
        })
      );
      resolve(response.data);
    })
    .catch(() => reject());
}, 100);

// tslint:disable-next-line: ban-types
const refreshTokens: Function = (
  resolve: () => void,
  reject: () => void
): void => {
  reqQueue.push({ resolve, reject });
  _refreshTokens(
    (data: any) => {
      reqQueue.forEach((req) => {
        req.resolve(data);
      });
      reqQueue = [];
    },
    () => {
      reqQueue.forEach((req) => {
        req.reject();
      });
      reqQueue = [];
    }
  );
};

const httpResponseHandler = (response: any): any => {
  checkTokens(response);
  return response.data;
};

const httpErrorHandler = async (err: any, refresh = true): Promise<any> => {
  const response = err?.response;
  checkTokens(response);
  if (response?.status === 401) {
    if (refresh) {
      return new Promise((resolve, reject) => {
        return refreshTokens(resolve, reject);
      })
        .then((tokens: any) => {
          err.config.headers.Authorization = `Bearer ${tokens.accessToken}`;
          return http
            .request(err.config)
            .then(httpResponseHandler)
            .catch((error) => httpErrorHandler(error, false));
        })
        .catch((error) => {
          if (error) throw error;

          store.dispatch(
            setTokens({
              accessToken: null,
              refreshToken: null
            })
          );
          history.push(ROUTES.AUTH.LOGIN);
        });
    }

    store.dispatch(
      setTokens({
        accessToken: null,
        refreshToken: null
      })
    );
    history.push(ROUTES.AUTH.LOGIN);
  }

  const data = response?.data;
  if (!data) {
    history.push(ROUTES.SERVER_ERROR);
  }

  throw {
    ...data,
    message: data?.message || 'Network Error!'
  } as IHttpError;
};

const Http = {
  get(url: string, params: any = {}, headers: any = {}) {
    return request('GET', url, { params, headers });
  },
  post(url: string, body: any = {}, headers: any = {}) {
    return request('POST', url, { data: body, headers });
  },
  put(url: string, body: any = {}, headers: any = {}) {
    return request('PUT', url, { data: body, headers });
  },
  patch(url: string, body: any = {}, headers: any = {}) {
    return request('PATCH', url, { data: body, headers });
  },
  delete(url: string, body: any = {}, headers: any = {}) {
    return request('DELETE', url, { data: body, headers });
  }
};

export default Http;
