import React, { FC } from 'react';
import { ProjectModel } from '@/resources/models';
import './styles.scss';

export interface IProjectStatusProps {
  project: ProjectModel;
}

const ProjectStatus: FC<IProjectStatusProps> = ({ project }) => {
  return (
    <div className="project-status-section">
      <h3 className="cmb-8">Project Status</h3>

      <p className="text-xs cmb-4">
        Proposals:&nbsp;
        <span className="text-bold">{project.pitches}</span>
      </p>

      <p className="text-xs cmb-4">
        Shortlisted Candidate:&nbsp;
        <span className="text-bold">{project.shortlisted || 0}</span>
      </p>

      <p className="text-xs cmb-4">
        Hired Freelancers:&nbsp;
        <span className="text-bold">
          {project.awardedFreelancers?.length || 0}
        </span>
      </p>

      <p className="text-xs mb-0">
        Open Positions:&nbsp;
        <span className="text-bold">
          {project.countOfMember - (project.awardedFreelancers.length || 0)}
        </span>
      </p>
    </div>
  );
};

export default ProjectStatus;
