import React, { FC, useMemo } from 'react';
import Badge from '@/components/common/Badge';
import { NameHelper } from '@/helpers';

export interface IProjectStatusProps {
  status: string;
  className?: string;
}

const ProjectStatus: FC<IProjectStatusProps> = ({ status, className = '' }) => {
  const badgeStatus = useMemo(() => {
    return NameHelper.getProjectBadgeStatus(status);
  }, [status]);

  return (
    <Badge
      className={`text-capitalize ${className}`}
      title={NameHelper.getProjectBadgeText(status)}
      status={badgeStatus}
    />
  );
};

export default ProjectStatus;
