import Http from '@/apis/http';
import { ISearchApiResult, IBank } from '@/resources/interfaces';
import { BankModel } from '@/resources/models';

export class BankApi {
  static search(query: any = {}): Promise<ISearchApiResult<BankModel>> {
    return Http.get('/admin/banks', query).then(
      (res: ISearchApiResult<IBank>) => {
        return {
          totalCount: res.totalCount,
          data: res.data.map((item) => new BankModel(item))
        };
      }
    );
  }

  static find(id: string): Promise<BankModel> {
    return Http.get(`/admin/banks/${id}`).then((res: IBank) => {
      return new BankModel(res);
    });
  }

  static create(data: any): Promise<BankModel> {
    return Http.post(`/admin/banks`, data).then((res: IBank) => {
      return new BankModel(res);
    });
  }

  static update(id: string, body: any) {
    return Http.put(`/admin/banks/${id}`, body);
  }

  static delete(id: string) {
    return Http.delete(`/admin/banks/${id}`);
  }

  static approve(id: string) {
    return Http.post(`/admin/banks/${id}/approve`);
  }

  static decline(id: string, data: any) {
    return Http.post(`/admin/banks/${id}/decline`, data);
  }
}
