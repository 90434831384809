import moment from 'moment';

export const getInitialData = (period: string) => {
  const data: any = {};
  if (period === 'daily') {
    const startDate = moment().subtract(31, 'days').startOf('day').toDate();
    for (let i = 0; i < 31; i++) {
      data[moment(startDate).add(i, 'day').format('M/DD')] = 0;
    }
  } else if (period === 'weekly') {
    const startDate = moment().subtract(10, 'week').startOf('week').toDate();
    for (let i = 0; i < 10; i++) {
      data[moment(startDate).add(i, 'week').format('YYYY-ww')] = 0;
    }
  } else if (period === 'monthly') {
    const startDate = moment().subtract(12, 'month').startOf('month').toDate();
    for (let i = 0; i < 12; i++) {
      data[moment(startDate).add(i, 'month').format('MMM')] = 0;
    }
  }
  return data;
};

export const getDateFormat = (date: string, period: string) => {
  if (period === 'daily') {
    return moment(date, 'YY-MM-DD').format('M/DD');
  }
  if (period === 'monthly') {
    return moment(date, 'YYYY-MM').format('MMM');
  }
  return date;
};
