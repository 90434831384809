import React from 'react';
import Icon from 'src/components/common/Icon';
import './styles.scss';
import { Tooltip } from 'antd';

interface IStatusCardProps {
  value: number | string;
  label: string;
  icon: string;
  tooltip: string;
}

const StatusCard: React.FC<IStatusCardProps> = ({
  value,
  label,
  icon,
  tooltip
}) => {
  return (
    <div className="d-flex custom-card referral-status-card cmb-12">
      <div className="help-icon">
        <Tooltip overlayClassName="custom-tooltip" title={tooltip}>
          <Icon name="question-circle" />
        </Tooltip>
      </div>
      <div className="cmr-12">
        <Icon name={icon} size="xLarge" />
      </div>
      <div>
        <h2 className="font-semibold">{value}</h2>
        <div>{label}</div>
      </div>
    </div>
  );
};

export default StatusCard;
