import React from 'react';
import Icon from '@/components/common/Icon';
import './styles.scss';

interface IFilterChipProps {
  label?: string;
  onClose?: () => void;
}

const FilterChip: React.FC<IFilterChipProps> = ({ label, onClose }) => {
  return (
    <div className="filter-chip">
      <span className="filter-chip__title">{label}</span>
      <Icon
        name="close-small"
        size="small"
        className="cursor-pointer"
        onClick={onClose}
      />
    </div>
  );
};

export default FilterChip;
