import React, { FC, memo } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { AUTH_ROUTES, PUBLIC_ROUTES, ROUTES } from '@/constants';
import { PrivateRoute } from '@/routes';
import Admin from '@/pages/Admin';

const AdminRoutes: FC = memo(() => (
  <Switch>
    {AUTH_ROUTES.map((route) => (
      <Redirect
        key={route.path as string}
        path={route.path as string}
        to={ROUTES.ADMIN.PREFIX}
      />
    ))}
    <PrivateRoute path={ROUTES.ADMIN.PREFIX} component={Admin} />
    {PUBLIC_ROUTES.map((route) => (
      <PrivateRoute key={route.path as string} {...route} />
    ))}
  </Switch>
));

export default AdminRoutes;
