import React, { FC } from 'react';
import { Divider, Rate, Skeleton } from 'antd';
import { EmployerModel } from '@/resources/models';
import { DateHelper } from '@/helpers';
import Icon from '@/components/common/Icon';
import Avatar from '@/components/common/Avatar';
import './styles.scss';

export interface IEmployerCardProps {
  employer?: EmployerModel;
}

const EmployerCard: FC<IEmployerCardProps> = ({ employer }) => {
  return (
    <div className="employer-card">
      <Skeleton active={true} loading={!employer}>
        <h3 className="cmb-7">About the Employer</h3>

        <div className="d-flex align-items-center cmb-7">
          <Avatar
            src={employer?.user?.avatar}
            name={employer?.user?.fullName}
            size="3rem"
            className="cmr-7"
            round={true}
          />

          <p className="text-lg mb-0">{employer?.user?.fullName}</p>
        </div>

        <Rate
          className="cmb-7"
          disabled={true}
          allowHalf={true}
          value={employer?.rating}
        />

        <div className="d-flex align-items-center cmb-5">
          {employer?.paymentVerified ? (
            <>
              <Icon
                name="payment-verified"
                className="employer-info-icon cmr-3"
              />
              <span className="text-xs">Payment is verified</span>
            </>
          ) : (
            <>
              <Icon
                name="cancel-line"
                className="employer-info-icon text-red cmr-3"
              />
              <span className="text-xs text-red">Payment is not verified</span>
            </>
          )}
        </div>

        <div className="d-flex align-items-center cmb-5">
          <Icon name="email" className="employer-info-icon cmr-3" />
          <span className="text-xs">{employer?.user?.email}</span>
        </div>

        <div className="d-flex align-items-center">
          <Icon name="map-marker" className="employer-info-icon cmr-3" />
          <span className="text-xs">{employer?.user?.country?.name}</span>
        </div>

        <Divider className="cmy-10" />

        <p className="text-xs cmb-4">
          Total Projects:&nbsp;
          <span className="text-bold">{employer?.projects.length || 0}</span>
        </p>

        <p className="text-xs cmb-4">
          Freelancers Hired:&nbsp;
          <span className="text-bold">
            {employer?.engagedFreelancers?.length}
          </span>
        </p>

        <p className="text-xs cmb-4">
          Total Paid Hours:&nbsp;
          <span className="text-bold">{employer?.totalPaidHours?.toFixed(2) || 0} hrs</span>
        </p>

        <p className="text-xs cmb-4">
          Total Paid:&nbsp;
          <span className="text-bold">${employer?.totalPaid?.toFixed(2) || 0}</span>
        </p>

        <p className="text-xs">
          Member Since:&nbsp;
          <span className="text-bold">
            {DateHelper.formatDate(employer?.createdAt as string, 'MMM YYYY')}
          </span>
        </p>
      </Skeleton>
    </div>
  );
};

export default EmployerCard;
