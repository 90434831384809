import React from 'react';
import { Drawer } from 'antd';
import Icon from '@/components/common/Icon';
import Badge, { BADGE_STATUS } from '@/components/common/Badge';
import { DAEMON_STATUS } from '@/resources/enums';
import { DateHelper, NameHelper } from '@/helpers';
import ReactJson from 'react-json-view';
import './styles.scss';

interface IWebhookLogDetailModalProps {
  onClose: () => void;
  open: boolean;
  webhookLog: any;
}

const WebhookLogDetailModal: React.FC<IWebhookLogDetailModalProps> = ({
  onClose,
  open,
  webhookLog
}) => {
  return (
    <Drawer
      className="webhook-log-detail-drawer"
      title={() => <div />}
      closeIcon={<Icon name="close" />}
      placement="right"
      onClose={onClose}
      visible={open}
    >
      <table>
        <tbody>
          <tr>
            <td>WebhookLog ID:</td>
            <td>{webhookLog?.id}</td>
          </tr>
          <tr>
            <td>Type:</td>
            <td>
              {webhookLog?.type && (
                <Badge
                  title={NameHelper.getTransactionMethodLabel(webhookLog.type)}
                />
              )}
            </td>
          </tr>
          <tr>
            <td>Status:</td>
            <td>
              <Badge
                status={
                  webhookLog?.status === DAEMON_STATUS.SUCCESS
                    ? BADGE_STATUS.SUCCESS
                    : BADGE_STATUS.DANGER
                }
                title={NameHelper.getDaemonStatusLabel(webhookLog?.status)}
              />
            </td>
          </tr>
          <tr>
            <td>Message:</td>
            <td>
              <div>{webhookLog?.message}</div>
            </td>
          </tr>
          <tr>
            <td>Date:</td>
            <td>
              <div>
                {DateHelper.formatDate(
                  webhookLog?.createdAt,
                  'DD MMM YYYY, hh:mm:ss A'
                )}
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div className="cmt-12">
        <ReactJson name="data" src={webhookLog?.data} />
      </div>
    </Drawer>
  );
};

export default WebhookLogDetailModal;
