import React, { useState } from 'react';
import { Drawer, notification } from 'antd';
import Icon from '@/components/common/Icon';
import { DateHelper, NameHelper } from '@/helpers';
import { BankModel, FreelancerModel } from '@/resources/models';
import { Link } from 'react-router-dom';
import { FREELANCER_DETAILS_ROUTES, ROUTES } from '@/constants';
import Button from '@/components/common/Button';
import BankStatus from '@/components/pages/BankStatus';
import { BankApi } from '@/apis';
import './styles.scss';
import { BANK_STATUS } from '@/resources/enums';
import BankDeclineModal from '@/pages/Admin/FreelancerDetails/FreelancerBankAccounts/BankDeclineModal';

interface IFreelancerBankDetailModalProps {
  onClose: () => void;
  open: boolean;
  bank: BankModel;
  fetchBanks: () => void;
}

const FreelancerBankDetailModal: React.FC<IFreelancerBankDetailModalProps> = ({
  onClose,
  open,
  bank,
  fetchBanks
}) => {
  const [isApproving, setIsApproving] = useState(false);
  const [isDeclining, setIsDeclining] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);

  const onApprove = async () => {
    try {
      setIsApproving(true);
      await BankApi.approve(bank.id);

      notification.success({
        message: 'Bank account is approved successfully'
      });
      fetchBanks();
      onClose();
    } catch (err) {
      notification.error({ message: err.message });
    } finally {
      setIsApproving(false);
    }
  };

  const onReject = async (declineReasons: string[], declineNote: string) => {
    try {
      setIsDeclining(true);
      await BankApi.decline(bank.id, {
        declineReasons,
        declineNote
      });

      fetchBanks();
      notification.success({
        message: 'Bank account is rejected successfully'
      });
      onClose();
    } catch (err) {
      notification.error({ message: err.message });
    } finally {
      setIsDeclining(false);
    }
  };

  return (
    <Drawer
      className="bank-payment-detail-drawer"
      title={() => <div />}
      closeIcon={<Icon name="close" />}
      placement="right"
      onClose={onClose}
      visible={open}
    >
      <table className="mb-3">
        <tbody>
          <tr>
            <td>Bank Status:</td>
            <td>
              <BankStatus status={bank?.status} />
            </td>
          </tr>
        </tbody>
      </table>
      <h3 className="font-weight-bold">Bank Details</h3>
      <table className="mb-3">
        <tbody>
          <tr>
            <td>Freelancer:</td>
            <td>
              <Link
                className="d-flex align-items-center text-blue"
                to={`${ROUTES.ADMIN.FREELANCERS.PREFIX}/${
                  (bank?.freelancer as FreelancerModel)?.id
                }/${FREELANCER_DETAILS_ROUTES.DETAILS}`}
              >
                {NameHelper.getFullName(
                  (bank?.freelancer as FreelancerModel)?.user
                )}
              </Link>
            </td>
          </tr>
          <tr>
            <td>Currency:</td>
            <td>{NameHelper.getCurrencyLabel(bank?.currency)}</td>
          </tr>
          <tr>
            <td>Account Number/IBAN:</td>
            <td>
              {bank?.receivingAccount}
              {bank?.validation?.accountNumber ? (
                <Icon name="check-circle" className="text-success" />
              ) : (
                ''
              )}
            </td>
          </tr>
          <tr>
            <td>Bank Name:</td>
            <td>{bank?.bankName}</td>
          </tr>
          <tr>
            <td>Swift Code:</td>
            <td>
              {bank?.bankBic}
              {bank?.validation?.swift ? (
                <Icon name="check-circle" className="text-success" />
              ) : (
                ''
              )}
            </td>
          </tr>
          <tr>
            <td>Beneficiary Name:</td>
            <td>{bank?.receiverName}</td>
          </tr>
          <tr>
            <td>Created Date:</td>
            <td>
              <div>
                {DateHelper.formatDate(
                  bank?.createdAt,
                  'DD MMM YYYY, hh:mm:ss A'
                )}
              </div>
            </td>
          </tr>

          {bank?.status === BANK_STATUS.DECLINED && (
            <>
              <tr>
                <td>Declined Reasons:</td>
                <td>{bank?.declineReasons?.join(', ')}</td>
              </tr>
              <tr>
                <td>Decline Note:</td>
                <td>{bank?.declineNote}</td>
              </tr>
            </>
          )}
        </tbody>
      </table>

      <h3 className="font-weight-bold">Bank Address</h3>
      <table className="mb-3">
        <tbody>
          <tr>
            <td>Country:</td>
            <td>{NameHelper.getCountryCode(bank?.country)}</td>
          </tr>
          <tr>
            <td>Street1:</td>
            <td>{bank?.street1}</td>
          </tr>
          <tr>
            <td>Street2:</td>
            <td>{bank?.street2}</td>
          </tr>
          <tr>
            <td>City:</td>
            <td>{bank?.city}</td>
          </tr>
          <tr>
            <td>Zip Code:</td>
            <td>{bank?.postalCode}</td>
          </tr>
        </tbody>
      </table>
      {bank?.status !== BANK_STATUS.DEACTIVATED && (
        <div className="flex-1 d-flex flex-column justify-content-end">
          <div className="d-flex justify-content-end">
            <Button
              className="cmr-12"
              onClick={onApprove}
              loading={isApproving}
            >
              Approve
            </Button>
            <Button variant="outline" onClick={() => setShowDeclineModal(true)}>
              Decline
            </Button>
          </div>
        </div>
      )}
      <BankDeclineModal
        open={showDeclineModal}
        onClose={() => setShowDeclineModal(false)}
        onConfirm={onReject}
        loading={isDeclining}
      />
    </Drawer>
  );
};

export default FreelancerBankDetailModal;
