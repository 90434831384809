import React, { FC, ReactNode, useMemo } from 'react';
import classnames from 'classnames';
import { Spin, Tooltip } from 'antd';
import Icon from '../Icon';

import './styles.scss';

interface IconButtonProps {
  icon?: ReactNode | string;
  onClick?: (e: any) => void;
  disabled?: boolean;
  className?: string;
  loading?: boolean;
  tooltip?: string;
}

const IconButton: FC<IconButtonProps> = ({
  icon,
  onClick,
  className = '',
  disabled,
  loading = false,
  tooltip
}) => {
  const disabledButton = useMemo(() => {
    return disabled || loading;
  }, [disabled, loading]);

  const content = (
    <span
      className={classnames(`icon-special-btn ${className}`, {
        disabled: disabledButton
      })}
      onClick={disabledButton ? () => {} : onClick}
    >
      <Spin spinning={loading} size="small">
        {typeof icon === 'string' ? <Icon name={icon} /> : icon}
      </Spin>
    </span>
  );

  return (
    <>
      {!disabledButton && tooltip ? (
        <Tooltip overlayClassName="custom-tooltip" title={tooltip}>
          {content}
        </Tooltip>
      ) : (
        content
      )}
    </>
  );
};

export default IconButton;
