import React, { ComponentType, FC } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import AdminLayout from '@/layouts/AdminLayout';

interface IRouteProps extends RouteProps {
  hasLayout?: boolean;
}

const PrivateRoute: FC<IRouteProps> = ({
  component,
  hasLayout = true,
  ...rest
}) => {
  const C = component as ComponentType;
  return (
    <Route
      {...rest}
      render={() =>
        hasLayout ? (
          <AdminLayout>
            <C />
          </AdminLayout>
        ) : (
          <C />
        )
      }
    />
  );
};

PrivateRoute.displayName = 'PrivateRoute';

export default PrivateRoute;
