import React from 'react';
import { Route, Switch } from 'react-router';
import { Redirect } from 'react-router-dom';
import { ROUTES } from '@/constants';
import { useCheckRole } from '@/hooks';
import Projects from '@/pages/Admin/Projects';
import Employers from '@/pages/Admin/Employers';
import Freelancers from '@/pages/Admin/Freelancers';
import Invoices from '@/pages/Admin/Invoices';
import Transactions from '@/pages/Admin/Transactions';
import ProjectDetails from '@/pages/Admin/ProjectDetails';
import EmployerDetails from '@/pages/Admin/EmployerDetails';
import FreelancerDetails from '@/pages/Admin/FreelancerDetails';
import InvoiceDetails from '@/pages/Admin/InvoiceDetails';
import TransactionDetails from '@/pages/Admin/TransactionDetails';
import SubAccounts from 'src/pages/Admin/Settings/SubAccounts';
import Contracts from '@/pages/Admin/Contracts';
import ContractDetails from '@/pages/Admin/ContractDetails';
import CreateAccount from '@/pages/Admin/Settings/CreateAccount';
import EditAccount from '@/pages/Admin/Settings/EditAccount';
import TFA from '@/pages/Admin/Settings/TFA';
import CreateEmployer from '@/pages/Admin/Employers/CreateEmployer';
import EditEmployer from '@/pages/Admin/Employers/EditEmployer';
import CreateFreelancer from '@/pages/Admin/Freelancers/CreateFreelancer';
import EditFreelancer from '@/pages/Admin/Freelancers/EditFreelancer';
import EventsPage from '@/pages/Admin/Events';
import SubAccountDetail from '@/pages/Admin/Settings/SubAccountDetail';
import PlatformStats from '@/pages/Admin/Dashboard/PlatformStats';
import FinancialStats from '@/pages/Admin/Dashboard/FinancialStats';
import Invitations from './Invitations';
import DaemonLogsPage from './DaemonLogs';
import WebhookLogsPage from '@/pages/Admin/WebhookLogs';
import TagsPage from '@/pages/Admin/Tags';
import ReceivingBankAccountsPage from '@/pages/Admin/ReceivingBankAccounts';
import BankPaymentOrdersPage from '@/pages/Admin/BankPaymentOrders';
import AppReportsPage from '@/pages/Admin/AppReports';
import TrackerSettings from '@/pages/Admin/Settings/TrackerSettings';
import FreelancerBankAccounts from '@/pages/Admin/FreelancerBankAccounts';
import Orders from '@/pages/Admin/Orders';

const commonRoutes = [
  { path: ROUTES.ADMIN.PROJECTS.INDEX, component: Projects },
  { path: ROUTES.ADMIN.EMPLOYERS.INDEX, component: Employers },
  { path: ROUTES.ADMIN.FREELANCERS.INDEX, component: Freelancers },
  { path: ROUTES.ADMIN.PROJECTS.DETAIL, component: ProjectDetails },
  { path: ROUTES.ADMIN.CONTRACTS.INDEX, component: Contracts },
  { path: ROUTES.ADMIN.CONTRACTS.DETAIL, component: ContractDetails },
  { path: ROUTES.ADMIN.SETTINGS.SECURITY.INDEX, component: TFA }
];

const qaTesterRoutes = [
  ...commonRoutes,
  { path: ROUTES.ADMIN.FREELANCERS.DETAIL, component: FreelancerDetails },
  { path: ROUTES.ADMIN.EMPLOYERS.DETAIL, component: EmployerDetails }
];

const marketingManagerRoutes = qaTesterRoutes;

const adminRoutes = [
  ...commonRoutes,
  { path: ROUTES.ADMIN.DASHBOARD.PLATFORM_STATS, component: PlatformStats },
  { path: ROUTES.ADMIN.DASHBOARD.FINANCIAL_STATS, component: FinancialStats },
  { path: ROUTES.ADMIN.ORDERS.INDEX, component: Orders },
  { path: ROUTES.ADMIN.INVOICES.INDEX, component: Invoices },
  { path: ROUTES.ADMIN.INVOICES.DETAIL, component: InvoiceDetails },
  { path: ROUTES.ADMIN.TRANSACTIONS.INDEX, component: Transactions },
  { path: ROUTES.ADMIN.TRANSACTIONS.DETAIL, component: TransactionDetails },
  { path: ROUTES.ADMIN.FREELANCERS.CREATE, component: CreateFreelancer },
  { path: ROUTES.ADMIN.FREELANCERS.EDIT, component: EditFreelancer },
  { path: ROUTES.ADMIN.FREELANCERS.DETAIL, component: FreelancerDetails },
  { path: ROUTES.ADMIN.EMPLOYERS.CREATE, component: CreateEmployer },
  { path: ROUTES.ADMIN.EMPLOYERS.EDIT, component: EditEmployer },
  { path: ROUTES.ADMIN.EMPLOYERS.DETAIL, component: EmployerDetails },
  { path: ROUTES.ADMIN.SETTINGS.ACCOUNTS.INDEX, component: SubAccounts },
  { path: ROUTES.ADMIN.INVITATIONS.INDEX, component: Invitations }
];

const superAdminRoutes = [
  ...adminRoutes,
  { path: ROUTES.ADMIN.EVENTS.INDEX, component: EventsPage },
  { path: ROUTES.ADMIN.SETTINGS.ACCOUNTS.CREATE, component: CreateAccount },
  { path: ROUTES.ADMIN.SETTINGS.ACCOUNTS.EDIT, component: EditAccount },
  { path: ROUTES.ADMIN.SETTINGS.ACCOUNTS.DETAIL, component: SubAccountDetail },
  { path: ROUTES.ADMIN.DAEMON_LOGS.INDEX, component: DaemonLogsPage },
  { path: ROUTES.ADMIN.WEBHOOK_LOGS.INDEX, component: WebhookLogsPage },
  { path: ROUTES.ADMIN.TAGS.INDEX, component: TagsPage },
  {
    path: ROUTES.ADMIN.BANK_ACCOUNTS.RECEIVING_BANKS,
    component: ReceivingBankAccountsPage
  },
  {
    path: ROUTES.ADMIN.BANK_ACCOUNTS.FREELANCER_BANKS,
    component: FreelancerBankAccounts
  },
  {
    path: ROUTES.ADMIN.BANK_PAYMENT_ORDERS.INDEX,
    component: BankPaymentOrdersPage
  },
  { path: ROUTES.ADMIN.APP_REPORTS.INDEX, component: AppReportsPage },
  {
    path: ROUTES.ADMIN.SETTINGS.TRACKER.INDEX,
    component: TrackerSettings
  }
];

const AdminRouting = () => {
  const {
    isSuperAdmin,
    isAdmin,
    isQATester,
    isMarketingManager
  } = useCheckRole();

  return (
    <Switch>
      <Redirect
        exact={true}
        path={ROUTES.ADMIN.PREFIX}
        to={ROUTES.ADMIN.DASHBOARD.PLATFORM_STATS}
      />

      {isMarketingManager &&
        marketingManagerRoutes.map((item) => (
          <Route
            exact={true}
            key={item.path}
            path={item.path}
            component={item.component}
          />
        ))}

      {isQATester &&
        qaTesterRoutes.map((item) => (
          <Route
            exact={true}
            key={item.path}
            path={item.path}
            component={item.component}
          />
        ))}

      {isAdmin &&
        adminRoutes.map((item) => (
          <Route
            exact={true}
            key={item.path}
            path={item.path}
            component={item.component}
          />
        ))}

      {isSuperAdmin &&
        superAdminRoutes.map((item) => (
          <Route
            exact={true}
            key={item.path}
            path={item.path}
            component={item.component}
          />
        ))}
    </Switch>
  );
};

export default AdminRouting;
