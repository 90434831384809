import React, { FC, useCallback, useMemo } from 'react';
import { ChevronLeft, ChevronRight, MoreHorizontal } from 'react-feather';
import classnames from 'classnames';
import './styles.scss';

export interface IPaginationProps {
  className?: string;
  total: number;
  current: number;
  pageSize?: number;
  onChange: (page: number, pageSize: number) => void;
}

const Pagination: FC<IPaginationProps> = ({
  className = '',
  total,
  current,
  pageSize = 10,
  onChange
}) => {
  const totalPageNumbers = useMemo(() => {
    return Math.ceil(total / pageSize) || 1;
  }, [total, pageSize]);

  const prevPage = useCallback(() => {
    if (current === 1) return;
    onChange(current - 1, pageSize);
  }, [onChange, current, pageSize]);

  const nextPage = useCallback(() => {
    if (current === totalPageNumbers) return;
    onChange(current + 1, pageSize);
  }, [current, totalPageNumbers, pageSize, current]);

  const onChangePageNumber = useCallback(
    (pageNumber: number) => {
      if (pageNumber === current) return;
      if (pageNumber > totalPageNumbers) {
        onChange(totalPageNumbers, pageSize);
        return;
      }
      if (pageNumber < 1) {
        onChange(1, pageSize);
        return;
      }
      onChange(pageNumber, pageSize);
    },
    [onChange, current, pageSize, totalPageNumbers]
  );

  return (
    <div className={`custom-pagination-container ${className}`}>
      <div className="custom-pagination">
        <div
          className={classnames('custom-pagination__btn', {
            disabled: current === 1
          })}
          onClick={prevPage}
        >
          <ChevronLeft className="action-icon previous" />
          <span className="cml-8">Previous</span>
        </div>

        <div className="page-numbers">
          {totalPageNumbers <= 7 ? (
            Array(totalPageNumbers)
              .fill(0)
              .map((item, index) => (
                <span
                  key={index}
                  className={classnames('page-numbers__item', {
                    active: index + 1 === current
                  })}
                  onClick={() => onChangePageNumber(index + 1)}
                >
                  {index + 1}
                </span>
              ))
          ) : current <= 4 ? (
            <>
              {Array(5)
                .fill(0)
                .map((item, index) => (
                  <span
                    key={index}
                    className={classnames('page-numbers__item', {
                      active: index + 1 === current
                    })}
                    onClick={() => onChangePageNumber(index + 1)}
                  >
                    {index + 1}
                  </span>
                ))}
              <span
                className="page-numbers__item"
                onClick={() => onChangePageNumber(current + 5)}
              >
                <MoreHorizontal className="more-icon" />
              </span>
              <span
                className={classnames('page-numbers__item', {
                  active: totalPageNumbers === current
                })}
                onClick={() => onChangePageNumber(totalPageNumbers)}
              >
                {totalPageNumbers}
              </span>
            </>
          ) : current >= totalPageNumbers - 3 ? (
            <>
              <span
                className={classnames('page-numbers__item', {
                  active: 1 === current
                })}
                onClick={() => onChangePageNumber(1)}
              >
                1
              </span>
              <span
                className="page-numbers__item"
                onClick={() => onChangePageNumber(current - 5)}
              >
                <MoreHorizontal className="more-icon" />
              </span>
              {Array(5)
                .fill(0)
                .map((item, index) => (
                  <span
                    key={totalPageNumbers - 4 + index}
                    className={classnames('page-numbers__item', {
                      active: totalPageNumbers - 4 + index === current
                    })}
                    onClick={() =>
                      onChangePageNumber(totalPageNumbers - 4 + index)
                    }
                  >
                    {totalPageNumbers - 4 + index}
                  </span>
                ))}
            </>
          ) : (
            <>
              <span
                className={classnames('page-numbers__item', {
                  active: 1 === current
                })}
                onClick={() => onChangePageNumber(1)}
              >
                1
              </span>
              <span
                className="page-numbers__item"
                onClick={() => onChangePageNumber(current - 5)}
              >
                <MoreHorizontal className="more-icon" />
              </span>
              {Array(5)
                .fill(0)
                .map((item, index) => (
                  <span
                    key={current - 2 + index}
                    className={classnames('page-numbers__item', {
                      active: index === 2
                    })}
                    onClick={() => onChangePageNumber(current - 2 + index)}
                  >
                    {current - 2 + index}
                  </span>
                ))}
              <span
                className="page-numbers__item"
                onClick={() => onChangePageNumber(current + 5)}
              >
                <MoreHorizontal className="more-icon" />
              </span>
              <span
                className={classnames('page-numbers__item', {
                  active: totalPageNumbers === current
                })}
                onClick={() => onChangePageNumber(totalPageNumbers)}
              >
                {totalPageNumbers}
              </span>
            </>
          )}
        </div>

        <div
          className={classnames('custom-pagination__btn', {
            disabled: current === totalPageNumbers
          })}
          onClick={nextPage}
        >
          <span className="cmr-8">Next</span>
          <ChevronRight className="action-icon next" />
        </div>
      </div>
    </div>
  );
};

export default Pagination;
