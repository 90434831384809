import Http from '@/apis/http';
import { IProject, ISearchApiResult } from '@/resources/interfaces';
import { ProjectModel } from '@/resources/models';

export class ProjectApi {
  static search(query: any): Promise<ISearchApiResult<ProjectModel>> {
    return Http.get('/admin/projects', query).then(
      (res: ISearchApiResult<any>) => {
        return {
          totalCount: res.totalCount,
          data: res.data.map((item) => new ProjectModel(item))
        };
      }
    );
  }

  static find(id: string, query: any = {}): Promise<ProjectModel> {
    return Http.get(`/admin/projects/${id}`, query).then(
      (project: IProject) => {
        return new ProjectModel(project);
      }
    );
  }

  static toggleDisableProject(projectId: string) {
    return Http.post(`/admin/projects/${projectId}/disable`);
  }

  static approveProject(projectId: string) {
    return Http.post(`/admin/projects/${projectId}/approve`);
  }
}
