import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { notification, Skeleton, Table } from 'antd';
import { debounce } from 'lodash';
import moment from 'moment';
import {
  EMPLOYER_DETAILS_ROUTES,
  PROJECT_DETAILS_ROUTES,
  ROUTES
} from '@/constants';
import { ProjectApi } from '@/apis';
import { IQueryField, ObjectHelper, QueryHelper } from '@/helpers';
import { EmployerModel, ProjectModel } from '@/resources/models';
import { PAYMENT_TYPE, PROJECT_STATUS } from '@/resources/enums';
import ProjectSearchFilter, {
  IDateRange
} from '@/components/filters/ProjectSearchFilter';
import Pagination from '@/components/common/Pagination';
import Avatar from '@/components/common/Avatar';
import ProjectStatus from '@/components/pages/Project/ProjectStatus';
import FilterChipList from '@/components/common/filters/FilterChipList';
import Tooltip from '@/components/common/Tooltip';
import './styles.scss';
import PageHeader from '@/components/common/PageHeader';
import { useHistory, useLocation } from 'react-router';

const tableColumns = [
  {
    title: 'Title',
    dataIndex: 'title',
    sorter: true,
    showSorterTooltip: false,
    render: (title: string, record: any) => (
      <Link
        className="link-tag"
        to={`${ROUTES.ADMIN.PROJECTS.PREFIX}/${record.id}/${PROJECT_DETAILS_ROUTES.DETAILS}`}
      >
        {title}
      </Link>
    )
  },
  {
    title: 'Employer',
    dataIndex: 'fullName',
    sorter: true,
    showSorterTooltip: false,
    render: (title: string, record: any) => (
      <Link
        className="d-flex align-items-center"
        to={`${ROUTES.ADMIN.EMPLOYERS.PREFIX}/${record.employer.id}/${EMPLOYER_DETAILS_ROUTES.DETAILS}`}
      >
        <Avatar
          src={record?.avatar}
          name={title}
          size="2.5rem"
          className="cmr-6"
          round={true}
        />

        <Tooltip title={title}>
          <div>
            <p className="mb-0 text-dark">{title}</p>
            <p className="text-xs mb-0 text-blue">{record.email}</p>
          </div>
        </Tooltip>
      </Link>
    )
  },
  {
    title: 'Status',
    dataIndex: 'status',
    sorter: true,
    showSorterTooltip: false,
    render: (status: string) => <ProjectStatus status={status} />
  },
  {
    title: 'Payment Type',
    dataIndex: 'paymentType',
    sorter: true,
    showSorterTooltip: false
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
    sorter: true,
    showSorterTooltip: false
  }
];

export interface IProjectFilter {
  title: string;
  dateRange: IDateRange;
  paymentType?: PAYMENT_TYPE;
  status?: PROJECT_STATUS;
  page?: number;
  perPage?: number;
  sort: string;
}

const filterFields: IQueryField[] = [
  { name: 'title', type: 'string', default: '' },
  { name: 'status', type: 'string', default: undefined },
  { name: 'paymentType', type: 'string', default: undefined },
  { name: 'page', type: 'number', default: 1 },
  { name: 'perPage', type: 'number', default: 10 },
  { name: 'sort', type: 'string', default: '-createdAt' },
  { name: 'startDate', type: 'dateRange', default: undefined },
  { name: 'endDate', type: 'dateRange', default: undefined }
];

const Projects: FC = () => {
  const [totalProjectCount, setTotalProjectCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState<ProjectModel[]>([]);
  const location = useLocation();
  const history = useHistory();

  const filter = useMemo(() => {
    return QueryHelper.parseQuery(location.search, filterFields, true);
  }, [location.search]);

  const searchProjects = useCallback(
    (filter: IProjectFilter) => {
      const query: any = {
        search: filter.title,
        page: filter.page,
        perPage: filter.perPage,
        'createdAt>': filter.dateRange.startDate
          ? moment(filter.dateRange.startDate).startOf('day').toISOString()
          : undefined,
        'createdAt<': filter.dateRange.endDate
          ? moment(filter.dateRange.endDate).endOf('day').toISOString()
          : undefined,
        paymentType: filter.paymentType,
        status: filter.status,
        expands: ['employer.user.country'],
        sort: filter.sort
      };

      setLoading(true);
      ProjectApi.search(ObjectHelper.trimQuery(query))
        .then((res) => {
          setProjects(res.data);
          setTotalProjectCount(res.totalCount);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          notification.error({ message: err.message });
        });
    },
    [setProjects, setTotalProjectCount]
  );

  const debounceFilter = useMemo(() => {
    return debounce(searchProjects, 500);
  }, [searchProjects]);

  useEffect(() => {
    debounceFilter(filter);
  }, [filter]);

  const onChangeFilter = (field: string, value: any) => {
    const newFilter = QueryHelper.getFilterToQuery(filter, field, value);

    const query = QueryHelper.stringifyQuery(newFilter);
    history.replace(`${location.pathname}?${query}`);
  };

  const clearFilter = () => {
    history.replace(location.pathname);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let sort = '';
    if (sorter.order) {
      const field =
        sorter.field === 'fullName' ? 'user.fullName' : sorter.field;
      sort = sorter.order === 'ascend' ? field : `-${field}`;
    }

    onChangeFilter('sort', sort);
  };

  const dataSource = useMemo(() => {
    return projects.map((item) => {
      return {
        ...item,
        key: item.id,
        createdAt: moment(item.createdAt).format('DD MMM, YYYY'),
        fullName: (item.employer as EmployerModel)?.user?.fullName,
        avatar: (item.employer as EmployerModel)?.user?.avatar,
        email: (item.employer as EmployerModel)?.user?.email
      };
    });
  }, [projects]);

  return (
    <div className="admin-projects-page">
      <PageHeader title="Projects" />

      <ProjectSearchFilter filter={filter} onChangeFilter={onChangeFilter} />

      <FilterChipList
        filter={filter}
        onFilterChange={onChangeFilter}
        clearFilter={clearFilter}
        filterType="project"
      />

      <Skeleton loading={loading} paragraph={{ rows: 0 }} active={true}>
        <p className="cmb-10 text-md">{totalProjectCount} projects found</p>
      </Skeleton>

      <Table
        className="custom-ant-table"
        loading={loading}
        columns={tableColumns}
        dataSource={dataSource}
        pagination={false}
        onChange={handleTableChange}
      />

      <div className="d-flex-center cmt-10">
        <Pagination
          total={totalProjectCount}
          current={filter.page}
          pageSize={filter.perPage}
          onChange={(page) => onChangeFilter('page', page)}
        />
      </div>
    </div>
  );
};

export default Projects;
