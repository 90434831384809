import React, { ChangeEvent, FC } from 'react';
import {
  TRANSACTION_METHOD,
  TRANSACTION_STATUS,
  TRANSACTION_TYPE
} from '@/resources/enums';
import Search from '@/components/forms/Search';
import Select from '@/components/forms/Select';
import DateRangePicker from '@/components/forms/DateRangePicker';
import './styles.scss';
import Switch from 'src/components/common/Switch';
import TagSelect from '@/components/pages/TagSelect';

export interface ITransactionSearchFilter {
  employer: string;
  freelancer: string;
  status?: TRANSACTION_STATUS;
  method?: TRANSACTION_METHOD;
  page?: number;
  perPage?: number;
  sort?: string;
  type: TRANSACTION_TYPE;
  isInternal?: boolean;
  tag?: string;
}

export interface ITransactionSearchFilterProps {
  filter: any;
  onChangeFilter: (field: string, value: any) => void;
}

const typeOptions = [
  { title: 'All', value: undefined },
  { title: 'Invoice Payment', value: TRANSACTION_TYPE.PAID },
  { title: 'Employer Fee', value: TRANSACTION_TYPE.PAID_EMPLOYER_FEE },
  {
    title: 'Freelancer Fee',
    value: TRANSACTION_TYPE.PAID_FREELANCER_FEE
  },
  {
    title: 'Paypal Fee',
    value: TRANSACTION_TYPE.PAYPAL_CHECKOUT_FEE
  },
  { title: 'Withdraw', value: TRANSACTION_TYPE.WITHDRAW },
  { title: 'Withdraw Fee', value: TRANSACTION_TYPE.WITHDRAW_FEE }
];

const statusOptions = [
  { title: 'All', value: undefined },
  { title: 'Success', value: TRANSACTION_STATUS.SUCCESS },
  { title: 'Completed', value: TRANSACTION_STATUS.COMPLETED },
  { title: 'Pending', value: TRANSACTION_STATUS.PENDING },
  { title: 'Created', value: TRANSACTION_STATUS.CREATED },
  { title: 'Unclaimed', value: TRANSACTION_STATUS.UNCLAIMED },
  { title: 'Returned', value: TRANSACTION_STATUS.RETURNED },
  { title: 'Refunded', value: TRANSACTION_STATUS.REFUNDED },
  { title: 'On Hold', value: TRANSACTION_STATUS.ONHOLD },
  { title: 'Failed', value: TRANSACTION_STATUS.FAILED },
  { title: 'Denied', value: TRANSACTION_STATUS.DENIED },
  { title: 'Canceled', value: TRANSACTION_STATUS.CANCELED },
  { title: 'Blocked', value: TRANSACTION_STATUS.BLOCKED },
  { title: 'Declined', value: TRANSACTION_STATUS.DECLINED }
];

const methodOptions = [
  { title: 'All', value: undefined },
  { title: 'Paypal', value: TRANSACTION_METHOD.PAYPAL },
  { title: 'Payoneer', value: TRANSACTION_METHOD.PAYONEER },
  { title: 'Bank', value: TRANSACTION_METHOD.KLARPAY }
];

const TransactionSearchFilter: FC<ITransactionSearchFilterProps> = ({
  filter,
  onChangeFilter
}) => {
  const { dateRange, search, status, type, tag, method } = filter;

  return (
    <div className="transactions-filter-container">
      <div className="cmb-10">
        <Search
          className="page-search-box"
          value={search}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChangeFilter('search', e.target.value)
          }
          placeholder="Search for transactions here"
        />
      </div>

      <div className="filter-select-list">
        <div className="cmr-12 cmb-10">
          <DateRangePicker
            label="Dates"
            dateRange={dateRange}
            setDateRange={(value) => onChangeFilter('dateRange', value)}
          />
        </div>

        <div className="cmr-12 cmb-10">
          <Select
            label="Type"
            value={type}
            onChange={(value) => onChangeFilter('type', value)}
            options={typeOptions}
            className="filter-type"
          />
        </div>

        <div className="cmr-12 cmb-10">
          <Select
            label="Status"
            value={status}
            onChange={(value) => onChangeFilter('status', value)}
            options={statusOptions}
          />
        </div>

        <div className="cmr-12 cmb-10">
          <Select
            label="Method"
            value={method}
            onChange={(value) => onChangeFilter('method', value)}
            options={methodOptions}
          />
        </div>

        <div className="cmr-12 cmb-10">
          <TagSelect
            label="Tag"
            value={tag}
            onChange={(value) => onChangeFilter('tag', value)}
          />
        </div>

        <div className="cmb-15">
          <Switch
            enabled={filter.isInternal}
            onToggle={() => onChangeFilter('isInternal', !filter.isInternal)}
            label="Show Internal Data"
          />
        </div>
      </div>
    </div>
  );
};

export default TransactionSearchFilter;
