import Http from '@/apis/http';
import { ISearchApiResult, IAppReport } from '@/resources/interfaces';
import { AppReportModel } from '@/resources/models';

export class AppReportApi {
  static search(query: any = {}): Promise<ISearchApiResult<AppReportModel>> {
    return Http.get('/admin/app-reports', query).then(
      (res: ISearchApiResult<IAppReport>) => {
        return {
          totalCount: res.totalCount,
          data: res.data.map((item) => new AppReportModel(item))
        };
      }
    );
  }

  static find(id: string): Promise<AppReportModel> {
    return Http.get(`/admin/app-reports/${id}`).then((res: IAppReport) => {
      return new AppReportModel(res);
    });
  }

  static create(data: any): Promise<AppReportModel> {
    return Http.post(`/admin/app-reports`, data).then((res: IAppReport) => {
      return new AppReportModel(res);
    });
  }

  static update(id: string, body: any) {
    return Http.put(`/admin/app-reports/${id}`, body);
  }

  static delete(id: string) {
    return Http.delete(`/admin/app-reports/${id}`);
  }
}
