import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import TfaModal from '@/components/pages/Settings/TfaModal';
import TfaVerifyModal from '@/components/pages/Settings/TfaVerifyModal';
import { getQr, getTFAEnabled } from '@/selectors';
import { fetch2faCode } from '@/redux/actions';
import Button from '@/components/common/Button';

import './styles.scss';

const TfaCard = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const qr = useSelector(getQr);
  const tfaEnabled = useSelector(getTFAEnabled);
  const [loading, setLoading] = useState(false);

  const openModal = async () => {
    setLoading(true);

    try {
      await dispatch(fetch2faCode());
      setOpen(true);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  return (
    <div className="tfa-card-container">
      <h5>Two-Factor Authentication</h5>

      <p className="tfa-description mb-4">
        Two-factor authentication is a method for protection of your account.
        When it is activated you need to enter a special code to access to your
        account. You can receive this code by your mobile app or by text
        message. Even if third person found your password, then can't access
        without this special code. New code is generated every time you want to
        access to your account.
      </p>

      <div className="d-flex justify-content-between align-items-center">
        <Button onClick={openModal} loading={loading}>
          {!tfaEnabled ? 'Enable 2FA' : 'Disable 2FA'}
        </Button>

        <div className={classnames('tfa-status', tfaEnabled ? 'active' : '')}>
          {tfaEnabled ? '2FA Enabled' : '2FA Disabled'}
        </div>
      </div>

      {tfaEnabled ? (
        <TfaVerifyModal open={open} onClose={() => setOpen(false)} qr={qr} />
      ) : (
        <TfaModal open={open} onClose={() => setOpen(false)} qr={qr} />
      )}
    </div>
  );
};

export default TfaCard;
