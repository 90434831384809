import React, { FC, useState } from 'react';
import { Modal, Spin } from 'antd';
import Button from '@/components/common/Button';
import Icon from '@/components/common/Icon';
import classnames from 'classnames';
import './styles.scss';

export interface IPasswordConfirmDialogProps {
  visible: boolean;
  handleClose: () => void;
  onConfirm: () => void;
  title?: string;
  text: string;
  okText?: string;
  cancelText?: string;
  type?: string;
}

const ConfirmDialog: FC<IPasswordConfirmDialogProps> = ({
  visible,
  handleClose,
  onConfirm,
  text,
  okText,
  cancelText,
  type = 'warning'
}) => {
  const [loading, setLoading] = useState(false);

  const onConfirmClick = async () => {
    try {
      setLoading(true);
      await onConfirm();
    } catch (e) {
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={handleClose}
      className="confirm-dialog dialog"
      footer={null}
      width={600}
    >
      <div className="dialog-body cpt-15 cpb-20">
        <div className="d-flex justify-content-center">
          <Icon
            name={type === 'success' ? 'check-circle' : 'info-outline'}
            size="huge"
            className={classnames('cmy-8', type)}
          />
        </div>
        <p className="text-3xl cmb-20 text-center">{text}</p>

        <div className="d-flex justify-content-center">
          <Spin spinning={loading} size="small">
            <Button className="cmr-12" onClick={onConfirmClick}>
              {okText || 'Confirm'}
            </Button>
          </Spin>
          <Button variant="outline" onClick={handleClose}>
            {cancelText || 'Cancel'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmDialog;
