import React, { FC, ReactNode, useMemo } from 'react';
import classnames from 'classnames';
import { WarningFilled } from '@ant-design/icons';
import './styles.scss';

type AlertType = 'info' | 'success' | 'danger' | 'warning';

interface IAlertProps {
  type?: AlertType;
  children: ReactNode | string;
  className?: string;
  showIcon?: boolean;
}

const Alert: FC<IAlertProps> = ({
  type = 'info',
  className = '',
  children,
  showIcon = false
}) => {
  const icon = useMemo(() => {
    return <WarningFilled className="alert-icon warning-icon" />;
  }, [type]);

  return (
    <div
      className={classnames(`custom-alert ${className}`, {
        info: type === 'info',
        success: type === 'success',
        danger: type === 'danger',
        warning: type === 'warning'
      })}
    >
      {showIcon && <div className="icon-container">{icon}</div>}
      <div className="alert-content">{children}</div>
    </div>
  );
};

export default Alert;
