import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { notification, Skeleton, Table } from 'antd';
import Pagination from '@/components/common/Pagination';
import { BankApi, FreelancerApi } from '@/apis';
import { DateHelper, IQueryField, NameHelper, QueryHelper } from '@/helpers';
import PageHeader from '@/components/common/PageHeader';
import { useHistory, useLocation } from 'react-router';
import { BankModel, FreelancerModel } from '@/resources/models';
import { useDebounce } from '@/hooks';
import { Link } from 'react-router-dom';
import { FREELANCER_DETAILS_ROUTES, ROUTES } from '@/constants';
import Avatar from '@/components/common/Avatar';
import Tooltip from '@/components/common/Tooltip';
import FreelancerBankDetailModal from '@/components/pages/FreelancerBanks/FreelancerBankDetailModal';
import IconButton from '@/components/common/IconButton';
import BankStatus from '@/components/pages/BankStatus';
import FreelancerBankSearchFilter from '@/components/filters/FreelancerBankSearchFilter';
import FilterChipList from '@/components/common/filters/FilterChipList';
import './styles.scss';

const filterFields: IQueryField[] = [
  { name: 'status', type: 'string', default: undefined },
  { name: 'freelancer', type: 'array', default: [] },
  { name: 'page', type: 'number', default: 1 },
  { name: 'perPage', type: 'number', default: 10 },
  { name: 'search', type: 'string', default: undefined }
];

const FreelancerBankAccountsPage = () => {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [bankAccounts, setBankAccounts] = useState<BankModel[]>([]);
  const location = useLocation();
  const history = useHistory();
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<any>();
  const [selectedFreelancers, setSelectedFreelancers] = useState<
    FreelancerModel[]
  >([]);

  const filter = useMemo(() => {
    return QueryHelper.parseQuery(location.search, filterFields, true);
  }, [location.search]);

  const debounceFilter = useDebounce(filter, 500);

  const onClickRow = (e: any, record: any) => {
    e.stopPropagation();
    setShowDetailModal(true);
    setSelectedRecord(record);
  };

  const fetchBankAccounts = useCallback(async () => {
    try {
      setLoading(true);
      const res = await BankApi.search(filter);

      const { totalCount, data } = res;
      setTotal(totalCount);
      setBankAccounts(data);
    } catch (err) {
      notification.error({ message: err.message });
    } finally {
      setLoading(false);
    }
  }, [filter]);

  const dataSource = useMemo(() => {
    return bankAccounts.map((item: any) => ({
      key: item.id,
      ...item
    }));
  }, [bankAccounts]);

  const onChangeFilter = useCallback(
    (field: string, value: any, isPageUpdate = true) => {
      const newFilter = QueryHelper.getFilterToQuery(
        filter,
        field,
        value,
        isPageUpdate
      );

      const query = QueryHelper.stringifyQuery(newFilter);
      history.replace(`${location.pathname}?${query}`);
    },
    [filter, location.pathname]
  );

  useEffect(() => {
    fetchBankAccounts().then();
  }, [debounceFilter]);

  const columns = useMemo(
    () => [
      {
        title: 'Freelancer',
        dataIndex: 'freelancer',
        render: (freelancer: FreelancerModel) =>
          freelancer ? (
            <Link
              className="d-flex align-items-center"
              to={`${ROUTES.ADMIN.FREELANCERS.PREFIX}/${freelancer.id}/${FREELANCER_DETAILS_ROUTES.DETAILS}`}
            >
              <Avatar
                src={freelancer?.user?.avatar}
                name={freelancer?.user?.fullName}
                size="2.5rem"
                className="cmr-6"
                round={true}
              />

              <Tooltip title={freelancer?.user?.fullName || ''}>
                <div>
                  <p className="mb-0 text-dark">{freelancer?.user?.fullName}</p>
                  <p className="text-xs mb-0 text-blue">
                    {freelancer?.user?.email}
                  </p>
                </div>
              </Tooltip>
            </Link>
          ) : (
            <div />
          )
      },
      {
        title: 'Bank Name',
        dataIndex: 'bankName',
        render: (bankName: string) => {
          return <div>{bankName}</div>;
        }
      },
      {
        title: 'Country',
        dataIndex: 'country',
        render: (country: string) => {
          return <div>{NameHelper.getCountryCode(country)}</div>;
        }
      },
      {
        title: 'Account Number/IBAN',
        dataIndex: 'receivingAccount',
        render: (iban: string) => {
          return <div>{iban}</div>;
        }
      },
      {
        title: 'Swift Code',
        dataIndex: 'bankBic',
        render: (bankBic: string) => {
          return <div>{bankBic}</div>;
        }
      },
      {
        title: 'Beneficiary Name',
        dataIndex: 'receiverName',
        render: (receiverName: string) => {
          return <div>{receiverName}</div>;
        }
      },
      {
        title: 'Created Date',
        dataIndex: 'createdAt',
        render: (date: string) => (
          <div>{DateHelper.formatDate(date, 'DD MMM YYYY, hh:mm:ss A')}</div>
        )
      },
      {
        title: 'Updated Date',
        dataIndex: 'updatedAt',
        render: (date: string) => (
          <div>{DateHelper.formatDate(date, 'DD MMM YYYY, hh:mm:ss A')}</div>
        )
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (status: string) => <BankStatus status={status} />
      },
      {
        title: 'Action',
        dataIndex: 'action',
        render: (_: string, record: any) => (
          <div className="d-flex align-items-center">
            <IconButton
              icon="file-minus"
              onClick={(e) => onClickRow(e, record)}
            />
          </div>
        )
      }
    ],
    []
  );

  const clearFilter = () => {
    history.replace(location.pathname);
  };

  const getFreelancerName = (freelancerId: string) =>
    NameHelper.getFullName(
      selectedFreelancers.find((item) => item.id === freelancerId)?.user
    );

  useEffect(() => {
    if (filter.freelancer.length) {
      FreelancerApi.search({ _id: filter.freelancer }).then((res) => {
        setSelectedFreelancers(res.data);
      });
    }
  }, [filter.freelancer]);

  return (
    <div className="webhook-logs-page">
      <PageHeader title="Freelancer Bank Accounts" />

      <div className="log-filters">
        <FreelancerBankSearchFilter
          filter={filter}
          onChangeFilter={onChangeFilter}
        />
      </div>

      <div>
        <FilterChipList
          filter={filter}
          onFilterChange={onChangeFilter}
          clearFilter={clearFilter}
          filterType="bank"
          getFreelancerName={getFreelancerName}
        />
      </div>

      <div className="d-flex justify-content-between mt-4 mb-3 align-items-center">
        <Skeleton loading={loading} paragraph={{ rows: 0 }} active={true}>
          <p className="text-md mb-0">{total} bank accounts found</p>
        </Skeleton>
      </div>
      <Table
        className="custom-ant-table"
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
      <div className="d-flex-center cmt-10">
        <Pagination
          total={total}
          current={filter.page}
          pageSize={filter.perPage}
          onChange={(page) => onChangeFilter('page', page)}
        />
      </div>
      <FreelancerBankDetailModal
        onClose={() => setShowDetailModal(false)}
        open={showDetailModal}
        bank={selectedRecord}
        fetchBanks={fetchBankAccounts}
      />
    </div>
  );
};

export default FreelancerBankAccountsPage;
