import Http from '@/apis/http';
import { IChannel, ISearchApiResult } from '@/resources/interfaces';
import { ChannelModel } from '@/resources/models';

export class ChannelApi {
  static search(query: any = {}): Promise<ISearchApiResult<ChannelModel>> {
    return Http.get('/admin/channels', query).then(
      (res: ISearchApiResult<IChannel>) => {
        return {
          totalCount: res.totalCount,
          data: res.data.map((item) => new ChannelModel(item))
        };
      }
    );
  }

  static toggleDisable(channelId: string) {
    return Http.post(`/admin/channels/${channelId}/disable`);
  }
}
