import React, { FC } from 'react';
import { Rate } from 'antd';
import { FreelancerModel } from '@/resources/models';
import CoverPhoto from './CoverPhoto';
import AccountAvatar from './AccountAvatar';
import { DateHelper } from '@/helpers';
import { ISkill, KYC_ADMIN_REVIEW } from '@/resources/interfaces';
import KycVerifiedIcon from '@/components/common/KycVerifiedIcon';
import Skills from '../Skills';
import './styles.scss';

export interface IDetailsProps {
  freelancer?: FreelancerModel;
}

const Details: FC<IDetailsProps> = ({ freelancer }) => {
  const { user } = freelancer || {};

  return (
    <div className="freelancer-info-details">
      <CoverPhoto freelancer={freelancer} />

      <div className="profile-wrapper d-flex cmb-12">
        <div className="d-flex align-items-center">
          <AccountAvatar user={user} />

          <div className="profile-details cml-12">
            <div className="d-flex align-items-center cmt-12 cmb-3">
              <h2 className="mb-0">{user?.fullName}</h2>
              {user?.kyc?.adminReview?.reviewResult ===
                KYC_ADMIN_REVIEW.APPROVED && (
                <KycVerifiedIcon className="cml-8" />
              )}
            </div>

            <p className="text-lg text-bold text-blue text-ellipsis cmb-1">
              {freelancer?.user?.email}
            </p>
            <p className="text-lg text-bold text-ellipsis cmb-1">
              {freelancer?.title}
            </p>
            <p className="text-lg cmb-1">
              Hourly Rate:{' '}
              <span className="text-bold">${freelancer?.hourlyPrice}/hr</span>
            </p>
            <Rate value={freelancer?.rating} allowHalf={true} disabled={true} />
          </div>
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-9">
        <p className="text-sm cmb-2 cmr-13">
          Projects Completed:{' '}
          <span className="text-bold">
            {freelancer?.completedProjects?.length || 0}
          </span>
        </p>
        <p className="text-sm cmb-2 cmr-13">
          Location: <span className="text-bold">{user?.country?.name}</span>
        </p>
        <p className="text-sm cmb-2 cmr-13">
          Member Since:{' '}
          <span className="text-bold">
            {DateHelper.formatDate(freelancer?.createdAt, 'MMM YYYY')}
          </span>
        </p>
      </div>

      <div className="cmb-4">
        <Skills skills={(freelancer?.skills || []) as ISkill[]} />
      </div>

      <p className="text-sm break-word mb-0">{freelancer?.bio}</p>
    </div>
  );
};

export default Details;
