import React from 'react';
import Icon from '@/components/common/Icon';
import './styles.scss';
interface IEventDetailSectionProps {
  event: any;
}

const EventDetailSection: React.FC<IEventDetailSectionProps> = ({ event }) => {
  const renderProperty = (property: any) => {
    return typeof property === 'object'
      ? JSON.stringify(property)
      : property || 'N/A';
  };

  return (
    <div className="event-detail-section">
      <table>
        <tbody>
          {event.data.previousAttributes.map((item: any, index: number) => (
            <tr key={index}>
              <td className="cpx-6 cpy-4">{renderProperty(item[0])}</td>
              <td className="cpx-6 cpy-4">{renderProperty(item[1])}</td>
              <td className="cpx-6 cpy-4">
                <Icon name="arrow-long-right" />
              </td>
              <td className="cpx-6 cpy-4">
                {renderProperty(event.data.object[item[0]])}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EventDetailSection;
