import Http from '@/apis/http';
import { ISearchApiResult, ITag } from '@/resources/interfaces';
import { TagModel } from '@/resources/models';

export class TagApi {
  static search(query: any = {}): Promise<ISearchApiResult<TagModel>> {
    return Http.get('/admin/tags', query).then(
      (res: ISearchApiResult<ITag>) => {
        return {
          totalCount: res.totalCount,
          data: res.data.map((item) => new TagModel(item))
        };
      }
    );
  }

  static find(id: string): Promise<TagModel> {
    return Http.get(`/admin/tags/${id}`).then((res: ITag) => {
      return new TagModel(res);
    });
  }

  static create(data: any): Promise<TagModel> {
    return Http.post(`/admin/tags`, data).then((res: ITag) => {
      return new TagModel(res);
    });
  }

  static update(id: string, body: any) {
    return Http.put(`/admin/tags/${id}`, body);
  }

  static delete(id: string) {
    return Http.delete(`/admin/tags/${id}`);
  }
}
