import Http from '@/apis/http';

export class EventApi {
  static search(query: any): Promise<any> {
    return Http.get('/admin/events', query);
  }

  static exportEvents(filters: any): Promise<any> {
    return Http.post(`/admin/events/export`, filters);
  }

  static deleteEvents(filters: any): Promise<any> {
    return Http.post(`/admin/events/clear`, filters);
  }
}
