import React, { FC, useState } from 'react';
import { Switch as AntSwitch } from 'antd';

interface ISwitchProps {
  enabled?: boolean;
  onToggle: (checked: boolean) => void | Promise<void>;
  label?: string;
  className?: string;
  loading?: boolean;
}

const Switch: FC<ISwitchProps> = ({
  className = '',
  enabled = false,
  onToggle,
  label,
  loading
}) => {
  const [apiLoading, setApiLoading] = useState(false);

  const onChange = async (checked: boolean) => {
    setApiLoading(true);
    await onToggle(checked);
    setApiLoading(false);
  };

  return (
    <div className={`d-flex align-items-center ${className}`}>
      <AntSwitch
        checked={enabled}
        onClick={onChange}
        loading={loading || apiLoading}
      />

      {label && <span className="cml-4">{label}</span>}
    </div>
  );
};

export default Switch;
