import React, { useEffect, useState } from 'react';
import { EventApi } from '@/apis';
import { Skeleton, Timeline } from 'antd';
import { getDetailLink, NameHelper } from '@/helpers';
import moment from 'moment';
import { Link } from 'react-router-dom';
import './styles.scss';

interface IChangeLogSection {
  objectType: string;
  objectId: string;
}

const ChangeLogSection: React.FC<IChangeLogSection> = ({
  objectType,
  objectId
}) => {
  const [events, setEvents] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    EventApi.search({ objectType, objectId, isDetail: true })
      .then((res) => {
        if (res) {
          setEvents(res.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getSubject = (event: any) =>
    NameHelper.getFullName(event.user || event.adminUser);

  const getRole = (event: any) =>
    NameHelper.getRoleLabel(
      event?.user ? event.user.role : event.adminUser?.role
    );

  const getVerb = (event: any) => {
    const arrs = event.type.split('.');
    return arrs ? arrs[1] : '';
  };

  const getTitle = (event: any) => {
    switch (event.objectType) {
      case 'project':
      case 'contract':
        return event.data?.object?.title;
      case 'proposal':
        return event.data?.object?.project?.title;
      case 'user':
      case 'admin_user':
        return NameHelper.getFullName(event.data?.object);
      case 'employer':
      case 'freelancer':
        return NameHelper.getFullName(event.data?.object?.user);
      default:
        return event.object?._id;
    }
  };

  const getTime = (event: any) =>
    moment(event.createdAt).format('hh:mm A MM.DD.YYYY');

  return (
    <div className="change-logs">
      <Skeleton loading={loading} active={true} paragraph={{ rows: 10 }}>
        <Timeline>
          {events.map((event: any) => (
            <Timeline.Item key={event.id}>
              <div className="d-flex">
                <div className="cmr-3">
                  {`${getSubject(event)}(${getRole(event)}) ${getVerb(event)} ${
                    event.objectType
                  }`}
                </div>
                <Link to={getDetailLink(event)} className="cmr-3">
                  {getTitle(event)}
                </Link>
                <div className="cmr-3">at {getTime(event)}</div>
              </div>
            </Timeline.Item>
          ))}
        </Timeline>
      </Skeleton>
    </div>
  );
};

export default ChangeLogSection;
