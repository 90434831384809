import React, { FC, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import classnames from 'classnames';
import Avatar from '@/components/common/Avatar';
import { MessagePageType } from '@/components/pages/Messages/Messages';
import { ChannelModel } from '@/resources/models';
import './styles.scss';

interface ChannelCardProps {
  type: MessagePageType;
  channel: ChannelModel;
}

const ChannelCard: FC<ChannelCardProps> = ({ type, channel }) => {
  const { project } = channel;

  const location = useLocation();
  const history = useHistory();
  const queryParams = queryString.parse(location.search);
  const { channelId } = queryParams;

  const avatarInfo = useMemo(() => {
    if (type === 'employer' || type === 'project')
      return {
        url: channel.freelancer.user?.avatar,
        name: channel.freelancer.user?.fullName
      };

    return {
      url: channel.employer.user?.avatar,
      name: channel.employer.user?.fullName
    };
  }, [type, channel]);

  const selectChannel = () => {
    const query = queryString.stringify({ channelId: channel.id });
    history.replace(`${location.pathname}?${query}`);
  };

  return (
    <div
      className={classnames('message-channel-card', {
        active: channelId === channel.id,
        disabled: channel.disabled
      })}
      onClick={selectChannel}
    >
      <div className="d-flex align-items-center">
        <Avatar
          src={avatarInfo.url}
          name={avatarInfo.name}
          size="2.5rem"
          className="cmr-6"
          round={true}
        />

        <div className="channel-user-info">
          <p className="text-md mb-0">{avatarInfo.name}</p>
          <p
            className="text-sm text-bold text-blue mb-0 text-ellipsis"
            title={project.title}
          >
            {project.title}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ChannelCard;
