import React from 'react';

import { ReactComponent as AddImageSvg } from './svgs/add-image.svg';
import { ReactComponent as AlertSvg } from './svgs/alert.svg';
import { ReactComponent as ArrowDownSvg } from './svgs/arrow-down.svg';
import { ReactComponent as ArrowLeftSvg } from './svgs/arrow-left.svg';
import { ReactComponent as ArrowRightSvg } from './svgs/arrow-right.svg';
import { ReactComponent as ArrowsSvg } from './svgs/arrows.svg';
import { ReactComponent as BellSvg } from './svgs/bell.svg';
import { ReactComponent as BellWithSvg } from './svgs/bell-with.svg';
import { ReactComponent as BookmarkSvg } from './svgs/bookmark.svg';
import { ReactComponent as BriefcaseSvg } from './svgs/briefcase.svg';
import { ReactComponent as CalendarSvg } from './svgs/calendar.svg';
import { ReactComponent as CancelLineSvg } from './svgs/cancel-line.svg';
import { ReactComponent as CheckmarkSvg } from './svgs/checkmark.svg';
import { ReactComponent as CheckmarkCircleSvg } from './svgs/checkmark-circle.svg';
import { ReactComponent as ClockSvg } from './svgs/clock.svg';
import { ReactComponent as CloseSvg } from './svgs/close.svg';
import { ReactComponent as CloseBigSvg } from './svgs/close-big.svg';
import { ReactComponent as CloseSmallSvg } from './svgs/close-small.svg';
import { ReactComponent as CopySvg } from './svgs/copy.svg';
import { ReactComponent as DeleteSvg } from './svgs/delete.svg';
import { ReactComponent as DollarSvg } from './svgs/dollar.svg';
import { ReactComponent as DuplicateSvg } from './svgs/duplicate.svg';
import { ReactComponent as DownloadSvg } from './svgs/download.svg';
import { ReactComponent as EditSvg } from './svgs/edit.svg';
import { ReactComponent as EyeSvg } from './svgs/eye.svg';
import { ReactComponent as EmailSvg } from './svgs/email.svg';
import { ReactComponent as ExternalSvg } from './svgs/external.svg';
import { ReactComponent as FacebookSvg } from './svgs/facebook.svg';
import { ReactComponent as FacebookOutlineSvg } from './svgs/facebook-outline.svg';
import { ReactComponent as HeartSvg } from './svgs/heart.svg';
import { ReactComponent as HeartOutlineSvg } from './svgs/heart-outline.svg';
import { ReactComponent as InfoCircleSvg } from './svgs/info-circle.svg';
import { ReactComponent as InstagramSvg } from './svgs/instagram.svg';
import { ReactComponent as InstagramOutlineSvg } from './svgs/instagram-outline.svg';
import { ReactComponent as LinkSvg } from './svgs/link.svg';
import { ReactComponent as LinkedinSvg } from './svgs/linkedin.svg';
import { ReactComponent as LinkedinOutlineSvg } from './svgs/linkedin-outline.svg';
import { ReactComponent as MapMarkerSvg } from './svgs/map-marker.svg';
import { ReactComponent as MessageSvg } from './svgs/message.svg';
import { ReactComponent as MoreSvg } from './svgs/more.svg';
import { ReactComponent as OfficeSvg } from './svgs/office.svg';
import { ReactComponent as PaymentVerifiedSvg } from './svgs/payment-verified.svg';
import { ReactComponent as PlaySvg } from './svgs/play.svg';
import { ReactComponent as PlayOutlineSvg } from './svgs/play-outline.svg';
import { ReactComponent as PlusSvg } from './svgs/plus.svg';
import { ReactComponent as PrintSvg } from './svgs/print.svg';
import { ReactComponent as SaveSvg } from './svgs/save.svg';
import { ReactComponent as SearchSvg } from './svgs/search.svg';
import { ReactComponent as TextCheckSvg } from './svgs/text-check.svg';
import { ReactComponent as TwitterSvg } from './svgs/twitter.svg';
import { ReactComponent as TwitterOutlineSvg } from './svgs/twitter-outline.svg';
import { ReactComponent as UserSvg } from './svgs/user.svg';
import { ReactComponent as WebSvg } from './svgs/web.svg';
import { ReactComponent as InfoOutlineSvg } from './svgs/info-outline.svg';
import { ReactComponent as ArrowLongRightSvg } from './svgs/arrow-long-right.svg';
import { ReactComponent as FileMinusSvg } from './svgs/file-minus.svg';
import { ReactComponent as SendIconSvg } from './svgs/send.svg';
import { ReactComponent as AwardIconSvg } from './svgs/award.svg';

const iconsMap: any = {
  'add-image': AddImageSvg,
  alert: AlertSvg,
  'arrow-down': ArrowDownSvg,
  'arrow-left': ArrowLeftSvg,
  'arrow-right': ArrowRightSvg,
  'arrow-long-right': ArrowLongRightSvg,
  arrows: ArrowsSvg,
  bell: BellSvg,
  'bell-with': BellWithSvg,
  bookmark: BookmarkSvg,
  briefcase: BriefcaseSvg,
  calendar: CalendarSvg,
  'cancel-line': CancelLineSvg,
  check: CheckmarkSvg,
  'check-circle': CheckmarkCircleSvg,
  clock: ClockSvg,
  close: CloseSvg,
  'close-big': CloseBigSvg,
  'close-small': CloseSmallSvg,
  copy: CopySvg,
  delete: DeleteSvg,
  duplicate: DuplicateSvg,
  dollar: DollarSvg,
  download: DownloadSvg,
  edit: EditSvg,
  email: EmailSvg,
  external: ExternalSvg,
  eye: EyeSvg,
  facebook: FacebookSvg,
  'facebook-outline': FacebookOutlineSvg,
  heart: HeartSvg,
  'heart-outline': HeartOutlineSvg,
  'info-circle': InfoCircleSvg,
  'info-outline': InfoOutlineSvg,
  instagram: InstagramSvg,
  'instagram-outline': InstagramOutlineSvg,
  link: LinkSvg,
  linkedin: LinkedinSvg,
  'linkedin-outline': LinkedinOutlineSvg,
  'map-marker': MapMarkerSvg,
  message: MessageSvg,
  more: MoreSvg,
  office: OfficeSvg,
  'payment-verified': PaymentVerifiedSvg,
  play: PlaySvg,
  'play-outline': PlayOutlineSvg,
  plus: PlusSvg,
  print: PrintSvg,
  save: SaveSvg,
  search: SearchSvg,
  'text-check': TextCheckSvg,
  twitter: TwitterSvg,
  'twitter-outline': TwitterOutlineSvg,
  user: UserSvg,
  web: WebSvg,
  'file-minus': FileMinusSvg,
  send: SendIconSvg,
  award: AwardIconSvg
};

interface IconProps {
  className?: string;
  name: string;
  onClick?: (e: Event) => void;
  color?: string;
  size?: 'tiny' | 'small' | 'medium' | 'large' | 'xLarge' | 'huge';
}

const Icon: React.FC<IconProps> = ({
  name,
  className,
  color,
  size = 'medium',
  ...props
}) => {
  const sizes = {
    tiny: '0.5rem',
    small: '1rem',
    medium: '1.5rem',
    large: '2rem',
    xLarge: '3rem',
    huge: '5rem'
  };

  const Component = iconsMap[name];

  if (!Component) {
    return <div />;
  }

  return (
    <Component
      width={sizes[size]}
      height={sizes[size]}
      className={className}
      style={{ color }}
      {...props}
    />
  );
};

export default Icon;
