import React, { FC, useState } from 'react';
import { ChannelModel } from '@/resources/models';
import ChannelList from '@/components/pages/Messages/ChannelList';
import MessageHistory from '@/components/pages/Messages/MessageHistory';
import './styles.scss';
import { IChannel } from '@/resources/interfaces';

export type MessagePageType = 'employer' | 'freelancer' | 'project';

interface MessagesProps {
  type: MessagePageType;
}

const Messages: FC<MessagesProps> = ({ type }) => {
  const [channels, setChannels] = useState<ChannelModel[]>([]);

  const onChannelStatusChange = (channelId: string, disabled: boolean) => {
    const updatedChannels = channels.map((item) => {
      if (item.id === channelId) {
        return new ChannelModel({
          ...item,
          disabled
        } as IChannel);
      }

      return item;
    });

    setChannels(updatedChannels);
  };

  return (
    <div className="messages-container">
      <div className="channels-container">
        <ChannelList
          type={type}
          channels={channels}
          setChannels={setChannels}
        />
      </div>
      <div className="message-history-container">
        <MessageHistory
          channels={channels}
          onChannelStatusChange={onChannelStatusChange}
        />
      </div>
    </div>
  );
};

export default Messages;
