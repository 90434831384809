import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { debounce } from 'lodash';
import { notification, Skeleton, Table } from 'antd';
import {
  ContractModel,
  EmployerModel,
  FreelancerModel
} from '@/resources/models';
import { ContractApi } from '@/apis';
import { IQueryField, ObjectHelper, QueryHelper } from '@/helpers';
import {
  EMPLOYER_DETAILS_ROUTES,
  FREELANCER_DETAILS_ROUTES,
  ROUTES
} from '@/constants';
import { PAYMENT_TYPE } from '@/resources/enums';
import ContractSearchFilter from '@/components/filters/ContractSearchFilter';
import FilterChipList from '@/components/common/filters/FilterChipList';
import Pagination from '@/components/common/Pagination';
import Avatar from '@/components/common/Avatar';
import Tooltip from '@/components/common/Tooltip';
import ContractStatus from '@/components/pages/ContractStatus';
import './styles.scss';
import PageHeader from '@/components/common/PageHeader';
import { useHistory, useLocation } from 'react-router';

const columns = [
  {
    title: 'Title',
    dataIndex: 'title',
    render: (title: string, contract: ContractModel) => (
      <Link to={`${ROUTES.ADMIN.CONTRACTS.PREFIX}/${contract.id}/details`}>
        {title}
      </Link>
    )
  },
  {
    title: 'Employer',
    dataIndex: 'employer',
    render: (employer: EmployerModel) => (
      <Link
        className="d-flex align-items-center"
        to={`${ROUTES.ADMIN.EMPLOYERS.PREFIX}/${employer.id}/${EMPLOYER_DETAILS_ROUTES.DETAILS}`}
      >
        <Avatar
          src={employer?.user?.avatar}
          name={employer?.user?.fullName}
          size="2.5rem"
          className="cmr-6"
          round={true}
        />

        <Tooltip title={employer.user?.fullName || ''}>
          <div>
            <p className="mb-0 text-dark">{employer.user?.fullName}</p>
            <p className="text-xs mb-0 text-blue">{employer.user?.email}</p>
          </div>
        </Tooltip>
      </Link>
    )
  },
  {
    title: 'Freelancer',
    dataIndex: 'freelancer',
    render: (freelancer: FreelancerModel) => (
      <Link
        className="d-flex align-items-center"
        to={`${ROUTES.ADMIN.FREELANCERS.PREFIX}/${freelancer.id}/${FREELANCER_DETAILS_ROUTES.DETAILS}`}
      >
        <Avatar
          src={freelancer?.user?.avatar}
          name={freelancer?.user?.fullName}
          size="2.5rem"
          className="cmr-6"
          round={true}
        />

        <Tooltip title={freelancer.user?.fullName || ''}>
          <div>
            <p className="mb-0 text-dark">{freelancer.user?.fullName}</p>
            <p className="text-xs mb-0 text-blue">{freelancer.user?.email}</p>
          </div>
        </Tooltip>
      </Link>
    )
  },
  {
    title: 'Payment Type',
    dataIndex: 'paymentType',
    render: (paymentType: string) => (
      <span className="text-capitalize">{paymentType}</span>
    )
  },
  {
    title: 'Price',
    dataIndex: 'price',
    render: (price: string, contract: ContractModel) =>
      contract.paymentType === PAYMENT_TYPE.FIXED
        ? `$${Number(price).toFixed(2)}`
        : `$${Number(price).toFixed(2)}/hr`
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (status: string) => <ContractStatus status={status} />
  }
];

const filterFields: IQueryField[] = [
  { name: 'search', type: 'string', default: '' },
  { name: 'paymentType', type: 'string', default: undefined },
  { name: 'page', type: 'number', default: 1 },
  { name: 'perPage', type: 'number', default: 10 },
  { name: 'status', type: 'array', default: [] },
  { name: 'startDate', type: 'dateRange', default: undefined },
  { name: 'endDate', type: 'dateRange', default: undefined },
  { name: 'sort', type: 'string', default: '-createdAt' }
];

const Contracts = () => {
  const [contracts, setContracts] = useState<ContractModel[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const filter = useMemo(() => {
    return QueryHelper.parseQuery(location.search, filterFields, true);
  }, [location.search]);

  const searchContracts = useCallback((filter: any) => {
    const query: any = {
      search: filter.search,
      page: filter.page,
      perPage: filter.perPage,
      paymentType: filter.paymentType,
      status: filter.status,
      sort: filter.sort,
      'createdAt>': filter.dateRange.startDate
        ? moment(filter.dateRange.startDate).startOf('day').toISOString()
        : undefined,
      'createdAt<': filter.dateRange.endDate
        ? moment(filter.dateRange.endDate).endOf('day').toISOString()
        : undefined
    };

    setLoading(true);
    ContractApi.search(ObjectHelper.trimQuery(query))
      .then((res) => {
        setContracts(res.data);
        setTotal(res.totalCount);
      })
      .catch((err) => {
        notification.error({ message: err.message });
      })
      .finally(() => setLoading(false));
  }, []);

  const debounceFilter = useMemo(() => {
    return debounce(searchContracts, 500);
  }, [searchContracts]);

  useEffect(() => {
    debounceFilter(filter);
  }, [filter]);

  const onChangeFilter = (field: string, value: any) => {
    const newFilter = QueryHelper.getFilterToQuery(filter, field, value);

    const query = QueryHelper.stringifyQuery(newFilter);
    history.replace(`${location.pathname}?${query}`);
  };

  const clearFilter = () => {
    history.replace(location.pathname);
  };

  const dataSource = useMemo(() => {
    return contracts;
  }, [contracts]);

  return (
    <div className="contracts-page">
      <PageHeader title="Contracts" />

      <ContractSearchFilter filter={filter} onChangeFilter={onChangeFilter} />

      <FilterChipList
        filter={filter}
        onFilterChange={onChangeFilter}
        clearFilter={clearFilter}
        filterType="contract"
      />

      <Skeleton loading={loading} paragraph={{ rows: 0 }} active={true}>
        <p className="mt-3 mb-2 text-md">{total} contracts found</p>
      </Skeleton>

      <Table
        className="custom-ant-table"
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />

      <div className="d-flex-center cmt-10">
        <Pagination
          total={total}
          current={filter.page}
          pageSize={filter.perPage}
          onChange={(page) => onChangeFilter('page', page)}
        />
      </div>
    </div>
  );
};

export default Contracts;
