import React from 'react';
import Avatar from '@/components/common/Avatar';
import Badge, { BADGE_STATUS } from '@/components/common/Badge';
import { NameHelper } from '@/helpers';
import { ROLE } from '@/resources/enums';
import { IAdminUser } from '@/resources/interfaces';

interface IAccountInfoProps {
  user?: IAdminUser;
}

const AccountInfo: React.FC<IAccountInfoProps> = ({ user }) => {
  return (
    <div className="custom-card">
      <div className="d-flex align-items-center cmb-12">
        <Avatar
          src={user?.avatar}
          name={NameHelper.getFullName(user)}
          size="7rem"
          className="cmr-12"
          round={true}
        />

        <div>
          <p className="text-lg text-bold cmb-1">
            {NameHelper.getFullName(user)}
          </p>
          <p className="text-lg text-blue cmb-2">{user?.email}</p>

          <Badge
            className="text-capitalize"
            title={NameHelper.getRoleLabel(user?.role)}
            status={
              user?.role === ROLE.ADMIN || user?.role === ROLE.SUPER_ADMIN
                ? BADGE_STATUS.SUCCESS
                : BADGE_STATUS.NORMAL
            }
          />
        </div>
      </div>
    </div>
  );
};

export default AccountInfo;
