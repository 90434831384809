import React, { FC } from 'react';
import Badge, { BADGE_STATUS } from '@/components/common/Badge';
import { ORDER_STATUS } from '@/resources/enums';
import { NameHelper } from '@/helpers';

export interface IOrderStatusProps {
  status: string;
  className?: string;
}

const OrderStatus: FC<IOrderStatusProps> = ({ status, className = '' }) => {
  const getBadgeStatus = (status: string) => {
    if (status === ORDER_STATUS.CREATED || status === ORDER_STATUS.PENDING) {
      return BADGE_STATUS.NORMAL;
    }
    if (status === ORDER_STATUS.PROCESSED) {
      return BADGE_STATUS.SUCCESS;
    }
    if (status === ORDER_STATUS.CANCELLED || status === ORDER_STATUS.REJECTED) {
      return BADGE_STATUS.DANGER;
    }
  };

  return (
    <Badge
      className={className}
      title={NameHelper.getOrderStatusLabel(status)}
      status={getBadgeStatus(status)}
    />
  );
};

export default OrderStatus;
