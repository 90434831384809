import { IAdminUser, ICountry } from '@/resources/interfaces';
import { GENDER, ROLE } from '@/resources/enums';

export class AdminModel {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  gender: GENDER;
  country?: ICountry;
  phone: string;
  role: ROLE;
  avatar: string;
  isActive?: boolean;
  tfaEnabled: boolean;
  lastLogin: Date;

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  get avatarUrl(): string {
    return this.avatar ? this.avatar : '/images/user.svg';
  }

  constructor(data: IAdminUser) {
    this.id = data.id;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.gender = data.gender;
    this.country = data.country;
    this.phone = data.phone;
    this.role = data.role;
    this.avatar = data.avatar;
    this.isActive = data.isActive;
    this.lastLogin = data.lastLogin;
    this.tfaEnabled = data.tfaEnabled;
  }
}
