import { PAYMENT_STATUS, TIME_METHOD } from '@/resources/enums';
import { ITime } from '@/resources/interfaces';
import { ContractModel } from '@/resources/models/contract.model';

export class TimeModel {
  id: string;
  description: string;
  freelancer: string;
  contract: string | ContractModel;
  from: string;
  to?: string;
  paymentStatus: PAYMENT_STATUS;
  paidAmount: number;
  method: TIME_METHOD;
  invoice?: string;
  duration: number; // in seconds
  hourlyPrice: number;

  constructor(data: ITime) {
    this.id = data.id;
    this.description = data.description;
    this.freelancer = data.freelancer;
    this.from = data.from;
    this.to = data.to;
    this.paymentStatus = data.paymentStatus;
    this.paidAmount = data.paidAmount;
    this.method = data.method;
    this.duration = data.duration;
    this.hourlyPrice = data.hourlyPrice;

    if (typeof data.contract === 'string') {
      this.contract = data.contract;
    } else {
      this.contract = new ContractModel(data.contract);
    }
  }
}
