import React, { FC } from 'react';
import { FreelancerModel } from '@/resources/models';
import {
  ICertificate,
  IExperience,
  ILanguageInfo
} from '@/resources/interfaces';
import Details from './Details';
import Preferences from './Preferences';
import Certificates from './Certificates';
import Experience from './Experience';
import Languages from './Languages';
import Reviews from './Reviews';
import { USER_STATUS } from '@/resources/enums';
import UserStatusRibbon from '@/components/pages/UserStatus/UserStatusRibbon';
import PaymentMethods from './PaymentMethods';
import './styles.scss';

export interface IFreelancerProfileProps {
  freelancer: FreelancerModel;
  status?: USER_STATUS;
}

const FreelancerProfile: FC<IFreelancerProfileProps> = ({
  freelancer,
  status
}) => {
  return (
    <div className="freelancer-profile-container">
      <div className="row">
        <div className="col-xl-8 cmb-12">
          <div className="left-section-wrapper">
            <UserStatusRibbon status={status || freelancer.status} />

            <Details freelancer={freelancer} />

            <Experience
              experiences={freelancer?.experiences as IExperience[]}
            />

            <Reviews freelancer={freelancer} />
          </div>
        </div>
        <div className="col-xl-4">
          <Preferences freelancer={freelancer} />

          <PaymentMethods
            paymentMethods={freelancer.paymentMethods}
            balance={freelancer?.balance}
          />

          <Languages languages={freelancer?.languages as ILanguageInfo[]} />

          <Certificates
            certification={freelancer?.certification as ICertificate[]}
          />
        </div>
      </div>
    </div>
  );
};

export default FreelancerProfile;
