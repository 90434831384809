import React, { FC } from 'react';
import { ISkill } from '@/resources/interfaces';
import './styles.scss';

export interface ISkillsProps {
  skills: ISkill[];
}

const Skills: FC<ISkillsProps> = ({ skills }) => {
  return (
    <div className="freelancer-skill-list">
      <div className="skills">
        {skills.map((item) => (
          <span className="skill-chip" key={item.id}>
            {item.name}
          </span>
        ))}
      </div>
    </div>
  );
};

export default Skills;
