import React, { FC, useCallback, useMemo } from 'react';
import { DateHelper, NameHelper } from '@/helpers';
import FilterChip from '@/components/common/filters/FilterChip';
import Button from '@/components/common/Button';
import './styles.scss';

type FilterType =
  | 'project'
  | 'user'
  | 'transaction'
  | 'invoice'
  | 'contract'
  | 'event'
  | 'invitation'
  | 'daemonLog'
  | 'webhookLog'
  | 'bank'
  | 'order';

export interface IFilterChipListProps {
  filter: any;
  onFilterChange: (field: string, value: any) => void;
  clearFilter: () => void;
  filterType: FilterType;
  getUserName?: (id: string) => any;
  getAdminUserName?: (id: string) => any;
  getTabLabel?: (id: string) => any;
  getFreelancerName?: (id: string) => any;
}

const FilterChipList: FC<IFilterChipListProps> = ({
  filter,
  onFilterChange,
  clearFilter,
  filterType,
  getUserName,
  getAdminUserName,
  getTabLabel,
  getFreelancerName
}) => {
  const canClearFilter = useMemo(() => {
    if (filterType === 'project')
      return (
        filter.paymentType ||
        filter.status ||
        (filter.dateRange.startDate && filter.dateRange.endDate)
      );

    if (filterType === 'user')
      return filter.country.length > 0 || filter.status || filter.role;

    if (filterType === 'transaction')
      return (
        filter.status ||
        filter.method ||
        filter.type ||
        (filter.dateRange?.startDate && filter.dateRange?.endDate)
      );

    if (filterType === 'invoice')
      return (
        filter.status ||
        (filter.dateRange?.startDate && filter.dateRange?.endDate)
      );

    if (filterType === 'contract')
      return (
        filter.paymentType ||
        filter.status?.length > 0 ||
        (filter.dateRange?.startDate && filter.dateRange?.endDate)
      );

    if (filterType === 'event') {
      return (
        filter.type?.length ||
        filter.objectType?.length > 0 ||
        (filter.dateRange?.startDate && filter.dateRange?.endDate) ||
        filter.user?.length > 0 ||
        filter.adminUser?.length > 0 ||
        filter.isAdmin
      );
    }
    if (filterType === 'bank') {
      return (
        filter.status?.length ||
        filter.freelancer?.length > 0 ||
        filter.search?.length > 0
      );
    }
    if (filterType === 'daemonLog') {
      return (
        filter.type ||
        filter.status ||
        (filter.dateRange?.startDate && filter.dateRange?.endDate)
      );
    }
    if (filterType === 'webhookLog') {
      return (
        filter.type ||
        filter.status ||
        (filter.dateRange?.startDate && filter.dateRange?.endDate)
      );
    }
    if (filterType === 'order') {
      return (
        filter.status ||
        filter.method ||
        filter.type ||
        filter.tag ||
        (filter.dateRange?.startDate && filter.dateRange?.endDate)
      );
    }
    return false;
  }, [filter, filterType]);

  const getStatusLabel = useCallback(
    (value: string) => {
      if (filterType === 'project')
        return NameHelper.getProjectStatusLabel(value);

      if (filterType === 'user') return NameHelper.getUserStatusLabel(value);

      if (filterType === 'transaction')
        return NameHelper.getTransactionStatusLabel(value);

      if (filterType === 'invoice')
        return NameHelper.getPaymentStatusLabel(value);

      if (filterType === 'contract')
        return NameHelper.getContractStatusLabel(value);

      if (filterType === 'invitation')
        return NameHelper.getInvitationStatusLabel(value);

      if (filterType === 'daemonLog')
        return NameHelper.getDaemonStatusLabel(value);

      if (filterType === 'webhookLog')
        return NameHelper.getWebhookStatusLabel(value);

      if (filterType === 'bank') return NameHelper.getBankStatusLabel(value);

      if (filterType === 'order') return NameHelper.getOrderStatusLabel(value);

      return value;
    },
    [filterType]
  );

  const removeArrayFilter = (field: string, value: any) => {
    const filterValue = filter[field].filter((item: any) => item !== value);
    onFilterChange(field, filterValue);
  };

  return (
    <div className="filter-chip-list">
      {filter.dateRange &&
        filter.dateRange.startDate &&
        filter.dateRange.endDate && (
          <FilterChip
            label={DateHelper.getDateRangeLabel(
              filter.dateRange.startDate,
              filter.dateRange.endDate
            )}
            onClose={() =>
              onFilterChange('dateRange', {
                startDate: undefined,
                endDate: undefined
              })
            }
          />
        )}

      {filter.country && filter.country.length > 0 && (
        <>
          {filter.country.map((item: string) => (
            <FilterChip
              key={item}
              label={NameHelper.getCountryName(item)}
              onClose={() => removeArrayFilter('country', item)}
            />
          ))}
        </>
      )}

      {filter.type && (
        <>
          {filterType === 'daemonLog' && (
            <FilterChip
              label={NameHelper.getDaemonTypeLabel(filter.type)}
              onClose={() => onFilterChange('type', undefined)}
            />
          )}
          {filterType === 'webhookLog' && (
            <FilterChip
              label={NameHelper.getTransactionMethodLabel(filter.type)}
              onClose={() => onFilterChange('type', undefined)}
            />
          )}
          {filterType === 'event' && (
            <>
              {filter.type.length
                ? filter.type.map((item: string) => (
                    <FilterChip
                      key={item}
                      label={NameHelper.getEventTypeName(item)}
                      onClose={() => removeArrayFilter('type', item)}
                    />
                  ))
                : ''}
            </>
          )}
          {filterType === 'transaction' && (
            <FilterChip
              label={NameHelper.getTransactionTypeLabel(filter.type)}
              onClose={() => onFilterChange('type', undefined)}
            />
          )}
          {filterType === 'order' && (
            <FilterChip
              label={NameHelper.getOrderTypeLabel(filter.type)}
              onClose={() => onFilterChange('type', undefined)}
            />
          )}
        </>
      )}

      {filter.objectType && filter.objectType.length > 0 && (
        <>
          {filter.objectType.map((item: string) => (
            <FilterChip
              key={item}
              label={NameHelper.getObjectTypeName(item)}
              onClose={() => removeArrayFilter('objectType', item)}
            />
          ))}
        </>
      )}

      {filter.user && filter.user.length > 0 && (
        <>
          {filter.user.map((item: string) => (
            <FilterChip
              key={item}
              label={getUserName && getUserName(item)}
              onClose={() => removeArrayFilter('user', item)}
            />
          ))}
        </>
      )}

      {filter.adminUser && filter.adminUser.length > 0 && (
        <>
          {filter.adminUser.map((item: string) => (
            <FilterChip
              key={item}
              label={getAdminUserName && getAdminUserName(item)}
              onClose={() => removeArrayFilter('adminUser', item)}
            />
          ))}
        </>
      )}

      {filter.freelancer && filter.freelancer.length > 0 && (
        <>
          {filter.freelancer.map((item: string) => (
            <FilterChip
              key={item}
              label={getFreelancerName && getFreelancerName(item)}
              onClose={() => removeArrayFilter('freelancer', item)}
            />
          ))}
        </>
      )}

      {filter.role && (
        <FilterChip
          label={NameHelper.getRoleLabel(filter.role)}
          onClose={() => onFilterChange('role', undefined)}
        />
      )}

      {filter.status &&
        (filterType === 'contract' ? (
          filter.status.map((item: string) => (
            <FilterChip
              key={item}
              label={getStatusLabel(item)}
              onClose={() => removeArrayFilter('status', item)}
            />
          ))
        ) : (
          <FilterChip
            label={getStatusLabel(filter.status)}
            onClose={() => onFilterChange('status', undefined)}
          />
        ))}

      {filter.method && (
        <FilterChip
          label={NameHelper.getTransactionMethodLabel(filter.method)}
          onClose={() => onFilterChange('method', undefined)}
        />
      )}

      {filter.tag && (
        <FilterChip
          label={getTabLabel && getTabLabel(filter.tag)}
          onClose={() => onFilterChange('tag', undefined)}
        />
      )}

      {filter.paymentType && (
        <FilterChip
          label={NameHelper.getPaymentTypeLabel(filter.paymentType)}
          onClose={() => onFilterChange('paymentType', undefined)}
        />
      )}

      {canClearFilter && (
        <Button
          className="filter-clear-btn"
          variant="link"
          onClick={clearFilter}
        >
          Clear Filters
        </Button>
      )}
    </div>
  );
};

export default FilterChipList;
