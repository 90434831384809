import {
  ICategory,
  IProject,
  IAttachment,
  ISkill,
  ISubCategory,
  IFreelancer
} from '@/resources/interfaces';
import { PAYMENT_TYPE, PROJECT_LEVEL, PROJECT_STATUS } from '@/resources/enums';
import { EmployerModel } from '@/resources/models/employer.model';
import { ProposalModel } from '@/resources/models/proposal.model';
import { FreelancerModel } from '@/resources/models/freelancer.model';

export class ProjectModel {
  id: string;
  title: string;
  description: string;
  category: ICategory;
  status: PROJECT_STATUS;
  originalStatus: PROJECT_STATUS;
  subCategory: ISubCategory;
  level: PROJECT_LEVEL;
  paymentType: PAYMENT_TYPE;
  countOfMember: number;
  attachments: IAttachment[];
  skills: string[] | ISkill[];
  price: number;
  awarded: number;
  awardedFreelancers:
    | string[]
    | FreelancerModel[]
    | (string | FreelancerModel)[];
  pitches: number;
  invitedFreelancers:
    | string[]
    | FreelancerModel[]
    | (string | FreelancerModel)[];
  messaged: number;
  closedReason?: string;
  createdAt: string;
  updatedAt: string;
  employer?: EmployerModel;
  proposal?: ProposalModel;
  proposals?: ProposalModel[];
  billing?: {
    hours: number | null;
    thisWeek: number;
    total: number;
  };

  // additional fields
  shortlisted?: number;
  totalPaidHours?: number;
  totalPaid: number;

  get employerCountry(): string {
    return this.employer?.user?.country?.name || '---';
  }

  constructor(init?: IProject) {
    const data = {
      id: '',
      title: '',
      description: '',
      status: PROJECT_STATUS.DRAFT,
      originalStatus: PROJECT_STATUS.DRAFT,
      level: PROJECT_LEVEL.BEGINNER,
      paymentType: PAYMENT_TYPE.HOURLY,
      countOfMember: 0,
      attachments: [],
      skills: [],
      price: 0,
      awarded: 0,
      awardedFreelancers: [],
      pitches: 0,
      invitedFreelancers: [],
      messaged: 0,
      totalPaid: 0,
      createdAt: '',
      updatedAt: '',
      shortlisted: 0,
      ...init
    };

    if (data.employer) {
      this.employer = new EmployerModel(data.employer);
    }
    if (data.proposal) {
      this.proposal = new ProposalModel(data.proposal);
    }
    if (data.proposals) {
      this.proposals = data.proposals.map((item) => new ProposalModel(item));
    }

    this.id = data.id;
    this.title = data.title;
    this.description = data.description;
    this.category = data.category as ICategory;
    this.status = data.status;
    this.originalStatus = data.originalStatus;
    this.subCategory = data.subCategory as ISubCategory;
    this.level = data.level;
    this.paymentType = data.paymentType;
    this.countOfMember = data.countOfMember;
    this.attachments = data.attachments;
    this.skills = data.skills;
    this.price = data.price;
    this.awarded = data.awarded;
    this.pitches = data.pitches;
    this.shortlisted = data.shortlisted;

    this.invitedFreelancers = data.invitedFreelancers.map(
      (item: string | IFreelancer) => {
        if (typeof item === 'string') return item;
        return new FreelancerModel(item);
      }
    );

    this.awardedFreelancers = data.awardedFreelancers.map(
      (item: string | IFreelancer) => {
        if (typeof item === 'string') return item;
        return new FreelancerModel(item);
      }
    );

    this.messaged = data.messaged;
    this.totalPaid = data.totalPaid;
    this.closedReason = data.closedReason;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.closedReason = data.closedReason;
    this.totalPaidHours = data.totalPaidHours;

    if (data.billing) {
      this.billing = data.billing;
    }
  }
}
