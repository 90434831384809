import React from 'react';
import { useHistory } from 'react-router';
import Icon from '@/components/common/Icon';
import Button from '@/components/common/Button';

interface IBackButtonProps {
  className?: string;
}

const BackButton: React.FC<IBackButtonProps> = ({ className }) => {
  const history = useHistory();

  return (
    <Button
      className={className}
      icon={<Icon name="arrow-left" className="cmr-2" />}
      iconPosition="left"
      variant="link"
      type="button"
      onClick={() => history.goBack()}
      disabled={!window.history.state}
    >
      Back
    </Button>
  );
};

export default BackButton;
