import React from 'react';
import Select from '@/components/forms/Select';
import { PAYMENT_STATUS } from '@/resources/enums';

interface IInvoiceStatusSelectProps {
  value?: PAYMENT_STATUS;
  onChange: (value: string) => void;
  label?: string;
  className?: string;
}

const options = [
  {
    value: PAYMENT_STATUS.PAID,
    title: 'Paid'
  },
  {
    value: PAYMENT_STATUS.PENDING,
    title: 'Pending'
  },
  {
    value: PAYMENT_STATUS.DECLINED,
    title: 'Declined'
  },
  {
    value: PAYMENT_STATUS.DISABLED,
    title: 'Disabled'
  },
  {
    value: PAYMENT_STATUS.WAITING_FOR_PAYMENT,
    title: 'Waiting for payment'
  },
  {
    value: PAYMENT_STATUS.PROCESSED,
    title: 'Processed'
  },
  {
    value: PAYMENT_STATUS.NONE,
    title: 'None'
  }
];

const InvoiceStatusSelect = ({
  label,
  value,
  onChange,
  className
}: IInvoiceStatusSelectProps) => (
  <Select
    className={className}
    label={label}
    value={value}
    onChange={onChange}
    options={options}
  />
);

export default InvoiceStatusSelect;
