import React, { FC } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Input from '@/components/forms/Input';
import Button from '@/components/common/Button';
import './styles.scss';

interface ILoginForm {
  code: string;
}

interface Login2FAProps {
  onSubmit: ({ code }: ILoginForm) => void;
  loading?: boolean;
}

const Login2FA: FC<Login2FAProps> = ({ onSubmit, loading }) => {
  const loginValidationSchema = Yup.object().shape({
    code: Yup.string().matches(/^[0-9]{6}$/)
  });

  return (
    <div className="login-form-wrapper">
      <h1 className="page-title text-center text-4xl font-weight-normal mb-5">
        Two-Factor Authentication Code
      </h1>
      <Formik
        initialValues={{ code: '' }}
        validationSchema={loginValidationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => (
          <Form className="login-form" onSubmit={handleSubmit}>
            <Input
              name="code"
              type="number"
              label="Authenticator Code (6 Digits)*"
              value={values.code}
              onChange={handleChange}
              error={errors.code && touched.code ? errors.code : ''}
              placeholder="Work email address"
              required={true}
            />

            <Button
              className="submit-button w-100"
              type="submit"
              loading={loading}
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Login2FA;
