import React, { FC } from 'react';
import { Empty } from 'antd';
import { IExperience } from '@/resources/interfaces';
import ExperienceCard from './ExperienceCard';
import './styles.scss';

export interface IExperienceProps {
  experiences: IExperience[];
}

const Experience: FC<IExperienceProps> = ({ experiences }) => {
  return (
    <div className="experience">
      <div className="experience__header">
        <p className="text-heading3 mb-0">Professional Experience</p>
      </div>

      <div className="experience-content">
        {experiences?.length ? (
          experiences
            .sort((a, b) => b.startDate.localeCompare(a.startDate))
            .map((experience, index) => (
              <ExperienceCard key={index} experience={experience} />
            ))
        ) : (
          <div className="cpy-10">
            <Empty />
          </div>
        )}
      </div>
    </div>
  );
};

export default Experience;
