import React from 'react';
import Messages from '@/components/pages/Messages';

const EmployerMessages = () => {
  return (
    <div>
      <Messages type="employer" />
    </div>
  );
};

export default EmployerMessages;
