import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  DateRangePicker as ReactDateRangePicker,
  Range,
  DefinedRange
} from 'react-date-range';
import moment from 'moment';
import { Dropdown, Menu, Row, Col } from 'antd';
import classnames from 'classnames';
import Icon from '@/components/common/Icon';
import { DateHelper } from '@/helpers';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import './styles.scss';

const ranges = [
  {
    label: 'Today',
    startDate: moment().startOf('day').toDate(),
    endDate: moment().endOf('day').toDate(),
    hasCustomRendering: true
  },
  {
    label: 'Yesterday',
    startDate: moment().add(-1, 'day').startOf('day').toDate(),
    endDate: moment().add(-1, 'day').endOf('day').toDate(),
    hasCustomRendering: true
  },
  {
    label: 'This week',
    startDate: moment().startOf('week').startOf('day').toDate(),
    endDate: moment().endOf('week').endOf('day').toDate(),
    hasCustomRendering: true
  },
  {
    label: 'Last week',
    startDate: moment().add(-7, 'days').startOf('week').startOf('day').toDate(),
    endDate: moment().add(-7, 'days').endOf('week').endOf('day').toDate(),
    hasCustomRendering: true
  },
  {
    label: 'This month',
    startDate: moment().startOf('month').startOf('day').toDate(),
    endDate: moment().endOf('month').endOf('day').toDate(),
    hasCustomRendering: true
  },
  {
    label: 'Last month',
    startDate: moment()
      .add(-1, 'month')
      .startOf('month')
      .startOf('day')
      .toDate(),
    endDate: moment().add(-1, 'month').endOf('month').endOf('day').toDate(),
    hasCustomRendering: true
  },
  {
    label: 'This year',
    startDate: moment().startOf('year').startOf('day').toDate(),
    endDate: moment().endOf('year').endOf('day').toDate(),
    hasCustomRendering: true
  },
  {
    label: 'Last year',
    startDate: moment().add(-1, 'year').startOf('year').startOf('day').toDate(),
    endDate: moment().add(-1, 'year').endOf('year').endOf('day').toDate(),
    hasCustomRendering: true
  }
];

interface IDateRange {
  startDate?: Date;
  endDate?: Date;
}

export interface IDateRangePickerProps {
  dateRange: IDateRange;
  setDateRange: (value: any) => void;
  placeholder?: string;
  label?: string;
  error?: string;
  className?: string;
  size?: 'small' | 'medium';
}

const DateRangePicker: FC<IDateRangePickerProps> = ({
  setDateRange,
  dateRange,
  label,
  size = 'medium',
  error,
  className = '',
  placeholder = 'Choose date range'
}) => {
  const [visible, setVisible] = useState(false);
  const [selectedStaticRange, setSelectedStaticRange] = useState('');
  const [showRanges, setShowRanges] = useState(false);

  useEffect(() => {
    const range = ranges.find(
      (item) =>
        moment(dateRange.startDate).isSame(item.startDate, 'day') &&
        moment(dateRange.endDate).isSame(item.endDate, 'day')
    );

    if (range) {
      setSelectedStaticRange(range.label);
    } else {
      setSelectedStaticRange('');
    }
  }, [dateRange]);

  useEffect(() => {
    if (visible) {
      setSelectedStaticRange('');
    }
  }, [visible]);

  const handleDateRangeChange = (item: any) => {
    setDateRange({
      startDate: item.range1.startDate,
      endDate: item.range1.endDate
    });
  };

  const onClickPrev = () => {
    const startDate = moment(dateRange.startDate)
      .subtract(1, 'weeks')
      .startOf('week')
      .toDate();
    const endDate = moment(startDate).endOf('week').toDate();
    setDateRange({
      startDate,
      endDate
    });
  };

  const onClickNext = () => {
    const startDate = moment(dateRange.startDate)
      .add(1, 'weeks')
      .startOf('week')
      .toDate();
    const endDate = moment(startDate).endOf('week').toDate();
    setDateRange({
      startDate,
      endDate
    });
  };

  const staticRanges = useMemo(() => {
    return ranges.map((staticRange) => ({
      label: staticRange.label,
      isSelected: (range: Range) => {
        return (
          moment(range.startDate).isSame(staticRange.startDate, 'day') &&
          moment(range.endDate).isSame(staticRange.endDate, 'day')
        );
      },
      range: () => ({
        startDate: staticRange.startDate,
        endDate: staticRange.endDate
      }),
      hasCustomRendering: staticRange.hasCustomRendering
    }));
  }, []);

  const renderStaticRangeLabel = (range: DefinedRange) => {
    const handleClick = () => {
      setSelectedStaticRange(range.label);
      setVisible(false);
    };

    return (
      <div className="custom-static-range-label" onClick={handleClick}>
        {range.label}
      </div>
    );
  };

  const navigatorRenderer = (
    currentFocusedDate: Date,
    changeShownDate: any
  ) => {
    return (
      <div>
        <div className="menu-button" onClick={() => setShowRanges(!showRanges)}>
          {showRanges ? <DoubleLeftOutlined /> : <DoubleRightOutlined />}
        </div>
        <Row className="d-flex navigator-wrapper">
          <Col span={12}>
            <div className="d-flex align-items-center">
              <div
                className="navigator-button"
                onClick={() =>
                  changeShownDate(
                    moment(currentFocusedDate).add(-1, 'month').toDate()
                  )
                }
              >
                <Icon name="arrow-left" />
              </div>
              <div className="flex-grow-1 text-center">
                {moment(currentFocusedDate).format('MMMM YYYY')}
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 text-center">
                {moment(currentFocusedDate).add(1, 'month').format('MMMM YYYY')}
              </div>
              <div
                className="navigator-button"
                onClick={() =>
                  changeShownDate(
                    moment(currentFocusedDate).add(1, 'month').toDate()
                  )
                }
              >
                <Icon name="arrow-right" />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <div className="custom-date-range-picker-container">
      {!!label && <p className="text-sm mb-0">{label}</p>}
      <div
        className={classnames(`custom-date-range-picker ${className}`, {
          'small-picker': size === 'small',
          error: !!error,
          hidden: !showRanges
        })}
      >
        <div className="icon-wrapper prev-icon" onClick={onClickPrev}>
          <Icon name="arrow-left" />
        </div>

        <Dropdown
          arrow={true}
          visible={visible}
          onVisibleChange={(value) => setVisible(value)}
          overlay={
            <Menu>
              <div>
                <ReactDateRangePicker
                  months={2}
                  direction="horizontal"
                  onChange={handleDateRangeChange}
                  navigatorRenderer={navigatorRenderer}
                  monthDisplayFormat="MMMM yyyy"
                  weekdayDisplayFormat="EEE"
                  weekStartsOn={0}
                  rangeColors={['#90caf940']}
                  showDateDisplay={false}
                  minDate={new Date(1970, 1, 1)}
                  maxDate={moment().add(300, 'day').toDate()}
                  showMonthAndYearPickers={false}
                  ranges={
                    dateRange
                      ? [dateRange]
                      : [{ startDate: undefined, endDate: undefined }]
                  }
                  staticRanges={staticRanges}
                  inputRanges={[]}
                  renderStaticRangeLabel={renderStaticRangeLabel}
                />
              </div>
            </Menu>
          }
          getPopupContainer={(triggerNode) =>
            triggerNode.parentNode as HTMLElement
          }
          trigger={['click']}
        >
          <div className="selected-date-container">
            <div className="selected-date d-flex align-items-center">
              <span className="calendar-icon-wrapper d-flex-center">
                <Icon name="calendar" />
              </span>
              {dateRange && dateRange.startDate && dateRange.endDate ? (
                <p className="range-text text-ellipsis">
                  {selectedStaticRange
                    ? selectedStaticRange
                    : `${DateHelper.formatDate(
                        dateRange.startDate,
                        'YYYY-MM-DD'
                      )} ~ ${DateHelper.formatDate(
                        dateRange.endDate,
                        'YYYY-MM-DD'
                      )}`}
                </p>
              ) : (
                <p className="range-text text-grey text-ellipsis">
                  {placeholder}
                </p>
              )}
            </div>
          </div>
        </Dropdown>
        <div className="icon-wrapper next-icon" onClick={onClickNext}>
          <Icon name="arrow-right" />
        </div>
      </div>

      {!!error && <p className="text-red text-sm mb-0">{error}</p>}
    </div>
  );
};

export default DateRangePicker;
