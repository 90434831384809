import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { notification, Skeleton, Table } from 'antd';
import { debounce } from 'lodash';
import moment from 'moment';
import { InvitationApi } from '@/apis';
import { IQueryField, NameHelper, ObjectHelper, QueryHelper } from '@/helpers';
import { InvitationModel } from '@/resources/models';
import InvitationSearchFilter from '@/components/filters/InvitationSearchFilter';
import Pagination from '@/components/common/Pagination';
import FilterChipList from '@/components/common/filters/FilterChipList';
import PageHeader from '@/components/common/PageHeader';
import { useHistory, useLocation } from 'react-router';
import InvitationStatus from 'src/components/pages/InvitationStatus';
import './styles.scss';

const columns = [
  {
    title: 'Sender',
    dataIndex: 'sender',
    render: (sender: any) => <div>{NameHelper.getFullName(sender)}</div>
  },
  {
    title: 'Receiver',
    dataIndex: 'receiver',
    render: (title: string) => <div>{title}</div>
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (status: string) => <InvitationStatus status={status} />
  },
  {
    title: 'Date',
    dataIndex: 'createdAt'
  }
];

const filterFields: IQueryField[] = [
  { name: 'search', type: 'string', default: '' },
  { name: 'status', type: 'string', default: undefined },
  { name: 'page', type: 'number', default: 1 },
  { name: 'perPage', type: 'number', default: 10 },
  { name: 'sort', type: 'string', default: '-createdAt' },
  { name: 'startDate', type: 'dateRange', default: undefined },
  { name: 'endDate', type: 'dateRange', default: undefined }
];

const Invitations: FC = () => {
  const [loading, setLoading] = useState(false);
  const [totalInvitationCount, setTotalInvitationCount] = useState(0);
  const [invitations, setInvitations] = useState<InvitationModel[]>([]);
  const location = useLocation();
  const history = useHistory();

  const filter = useMemo(() => {
    return QueryHelper.parseQuery(location.search, filterFields, true);
  }, [location.search]);

  const searchInvitations = useCallback((filter: any) => {
    const query: any = {
      search: filter.search,
      status: filter.status,
      page: filter.page,
      perPage: filter.perPage,
      sort: filter.sort,
      'createdAt>': filter.dateRange.startDate
        ? moment(filter.dateRange.startDate).startOf('day').toISOString()
        : undefined,
      'createdAt<': filter.dateRange.endDate
        ? moment(filter.dateRange.endDate).endOf('day').toISOString()
        : undefined,
      expands: ['sender']
    };

    setLoading(true);
    InvitationApi.search(ObjectHelper.trimQuery(query))
      .then((res) => {
        setLoading(false);
        setInvitations(res.data);
        setTotalInvitationCount(res.totalCount);
      })
      .catch((err) => {
        setLoading(false);
        notification.error({ message: err.message });
      });
  }, []);

  const debounceFilter = useMemo(() => {
    return debounce(searchInvitations, 500);
  }, [searchInvitations]);

  useEffect(() => {
    debounceFilter(filter);
  }, [filter]);

  const onChangeFilter = (field: string, value: any) => {
    const newFilter = QueryHelper.getFilterToQuery(filter, field, value);

    const query = QueryHelper.stringifyQuery(newFilter);
    history.replace(`${location.pathname}?${query}`);
  };

  const clearFilter = () => {
    history.replace(location.pathname);
  };

  const dataSource = useMemo(() => {
    return invitations.map((item) => {
      return {
        ...item,
        key: item.id,
        createdAt: moment(item.createdAt).format('DD MMM, YYYY')
      };
    });
  }, [invitations]);

  return (
    <div className="admin-invitations-page">
      <PageHeader title="Invitations" />

      <InvitationSearchFilter filter={filter} onChangeFilter={onChangeFilter} />

      <FilterChipList
        filter={filter}
        onFilterChange={onChangeFilter}
        clearFilter={clearFilter}
        filterType="invitation"
      />

      <Skeleton loading={loading} active={true} paragraph={{ rows: 0 }}>
        <p className="mt-3 mb-2 text-md">
          {totalInvitationCount} invitations found
        </p>
      </Skeleton>

      <Table
        className="custom-ant-table"
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />

      <div className="d-flex-center cmt-10">
        <Pagination
          total={totalInvitationCount}
          current={filter.page}
          pageSize={filter.perPage}
          onChange={(page) => onChangeFilter('page', page)}
        />
      </div>
    </div>
  );
};

export default Invitations;
