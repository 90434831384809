import React, { useEffect, useState } from 'react';
import PageHeader from '@/components/common/PageHeader';
import BackButton from '@/components/common/BackButton';
import AccountInfo from '@/components/pages/Settings/AccountInfo';
import { useParams } from 'react-router';
import { AdminUserApi } from '@/apis';
import { IAdminUser } from '@/resources/interfaces';
import ActionHistories from 'src/components/pages/Settings/ActionHisotries';

const SubAccountDetail = () => {
  const { id }: { id: string } = useParams();
  const [user, setUser] = useState<IAdminUser>();

  useEffect(() => {
    if (id) {
      AdminUserApi.find(id).then((res) => {
        setUser(res);
      });
    }
  }, []);

  return (
    <div>
      <div className="cmb-17">
        <BackButton />
      </div>
      <PageHeader title="Sub Account Profile" />

      <div className="cmb-16">
        <AccountInfo user={user} />
      </div>

      <div>
        <ActionHistories />
      </div>
    </div>
  );
};

export default SubAccountDetail;
