import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { notification, Skeleton } from 'antd';
import BackButton from '@/components/common/BackButton';
import AccountForm from '@/components/pages/AccountForm';
import NoData from '@/components/common/NoData';
import { ObjectHelper } from '@/helpers';
import { ROUTES, SETTING_DETAILS_ROUTES } from '@/constants';
import PageHeader from '@/components/common/PageHeader';
import { AdminUserApi } from '@/apis/adminUser.api';
import { IAdminUser } from '@/resources/interfaces';

const EditAccount = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<IAdminUser | undefined>(undefined);

  const updateAccount = async (values: any) => {
    try {
      const { userId, ...data } = values;

      await AdminUserApi.update(userId, ObjectHelper.trimQuery(data));
      notification.success({ message: 'User is updated successfully.' });
      history.push(
        `${ROUTES.ADMIN.SETTINGS.PREFIX}/${SETTING_DETAILS_ROUTES.ACCOUNTS}`
      );
    } catch (err) {
      notification.error({ message: err.message });
    }
  };

  useEffect(() => {
    setLoading(true);
    AdminUserApi.find(id)
      .then((res) => {
        setUser(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        notification.error({ message: err.message });
      });
  }, [id]);

  return (
    <div>
      <div className="cmb-17">
        <BackButton />
      </div>

      <PageHeader title="Edit account" />

      <Skeleton loading={loading} paragraph={{ rows: 10 }}>
        {user ? (
          <AccountForm user={user} onSubmit={updateAccount} />
        ) : (
          <NoData title="Not found user" />
        )}
      </Skeleton>
    </div>
  );
};

export default EditAccount;
