import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { notification, Skeleton, Table } from 'antd';
import { debounce } from 'lodash';
import moment from 'moment';
import {
  EMPLOYER_DETAILS_ROUTES,
  FREELANCER_DETAILS_ROUTES,
  PROJECT_DETAILS_ROUTES,
  ROUTES
} from '@/constants';
import { InvoiceApi } from '@/apis';
import { IQueryField, ObjectHelper, QueryHelper } from '@/helpers';
import {
  ContractModel,
  EmployerModel,
  FreelancerModel,
  InvoiceModel
} from '@/resources/models';
import InvoiceSearchFilter from '@/components/filters/InvoiceSearchFilter';
import Pagination from '@/components/common/Pagination';
import PaymentStatus from '@/components/pages/PaymentStatus';
import FilterChipList from '@/components/common/filters/FilterChipList';
import './styles.scss';
import Switch from '@/components/common/Switch';
import PageHeader from '@/components/common/PageHeader';
import { useHistory, useLocation } from 'react-router';

const columns = [
  {
    title: 'Invoice ID',
    dataIndex: 'invoiceId',
    render: (title: string, record: any) => (
      <Link to={`${ROUTES.ADMIN.INVOICES.INDEX}/${record.id}/details`}>
        {title}
      </Link>
    )
  },
  {
    title: 'Project',
    dataIndex: 'projectTitle',
    sorter: true,
    showSorterTooltip: false,
    render: (title: string, record: any) => (
      <Link
        to={`${ROUTES.ADMIN.CONTRACTS.PREFIX}/${record.contract?.id}/${PROJECT_DETAILS_ROUTES.DETAILS}`}
      >
        {title}
      </Link>
    )
  },
  {
    title: 'Amount',
    dataIndex: 'total',
    sorter: true,
    showSorterTooltip: false
  },
  {
    title: 'Sender',
    dataIndex: 'freelancerFullName',
    sorter: true,
    showSorterTooltip: false,
    render: (title: string, record: any) => (
      <Link
        className="link-tag"
        to={`${ROUTES.ADMIN.FREELANCERS.PREFIX}/${record.freelancer?.id}/${FREELANCER_DETAILS_ROUTES.DETAILS}`}
      >
        {title}
      </Link>
    )
  },
  {
    title: 'Receiver',
    dataIndex: 'employerFullName',
    sorter: true,
    showSorterTooltip: false,
    render: (title: string, record: any) => (
      <Link
        className="link-tag"
        to={`${ROUTES.ADMIN.EMPLOYERS.PREFIX}/${record.employer?.id}/${EMPLOYER_DETAILS_ROUTES.DETAILS}`}
      >
        {title}
      </Link>
    )
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
    sorter: true,
    showSorterTooltip: false
  },
  {
    title: 'Status',
    dataIndex: 'status',
    sorter: true,
    showSorterTooltip: false,
    render: (status: string) => <PaymentStatus status={status} />
  }
];

const filterFields: IQueryField[] = [
  { name: 'search', type: 'string', default: '' },
  { name: 'status', type: 'string', default: undefined },
  { name: 'page', type: 'number', default: 1 },
  { name: 'perPage', type: 'number', default: 10 },
  { name: 'sort', type: 'string', default: '-createdAt' },
  { name: 'startDate', type: 'dateRange', default: undefined },
  { name: 'endDate', type: 'dateRange', default: undefined },
  { name: 'isInternal', type: 'boolean', default: false },
  { name: 'employer', type: 'array', default: [] }
];

const Invoices: FC = () => {
  const [loading, setLoading] = useState(false);
  const [totalInvoiceCount, setTotalInvoiceCount] = useState(0);
  const [invoices, setInvoices] = useState<InvoiceModel[]>([]);
  const location = useLocation();
  const history = useHistory();

  const filter = useMemo(() => {
    return QueryHelper.parseQuery(location.search, filterFields, true);
  }, [location.search]);

  const searchInvoices = useCallback((filter: any) => {
    const query: any = {
      search: filter.search,
      status: filter.status,
      page: filter.page,
      perPage: filter.perPage,
      sort: filter.sort,
      isInternal: filter.isInternal,
      employer: filter.employer,
      'createdAt>': filter.dateRange.startDate
        ? moment(filter.dateRange.startDate).startOf('day').toISOString()
        : undefined,
      'createdAt<': filter.dateRange.endDate
        ? moment(filter.dateRange.endDate).endOf('day').toISOString()
        : undefined,
      expands: ['freelancer.user', 'contract', 'employer.user']
    };

    setLoading(true);
    InvoiceApi.search(ObjectHelper.trimQuery(query))
      .then((res) => {
        setLoading(false);
        setInvoices(res.data);
        setTotalInvoiceCount(res.totalCount);
      })
      .catch((err) => {
        setLoading(false);
        notification.error({ message: err.message });
      });
  }, []);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const sortColumns: any = {
      projectTitle: 'contract.title',
      total: 'total',
      freelancerFullName: 'freelancer.user.fullName',
      employerFullName: 'employer.user.fullName',
      createdAt: 'createdAt',
      status: 'status'
    };
    let sort = '';
    if (sorter.order) {
      sort =
        sorter.order === 'ascend'
          ? sortColumns[sorter.field]
          : `-${sortColumns[sorter.field]}`;
    }

    onChangeFilter('sort', sort);
  };

  const debounceFilter = useMemo(() => {
    return debounce(searchInvoices, 500);
  }, [searchInvoices]);

  useEffect(() => {
    debounceFilter(filter);
  }, [filter]);

  const onChangeFilter = (field: string, value: any) => {
    const newFilter = QueryHelper.getFilterToQuery(filter, field, value);

    const query = QueryHelper.stringifyQuery(newFilter);
    history.replace(`${location.pathname}?${query}`);
  };

  const clearFilter = () => {
    history.replace(location.pathname);
  };

  const dataSource = useMemo(() => {
    return invoices.map((item) => {
      return {
        ...item,
        key: item.id,
        projectTitle: (item.contract as ContractModel)?.title,
        freelancerFullName: (item.freelancer as FreelancerModel)?.user
          ?.fullName,
        employerFullName: (item.employer as EmployerModel)?.user?.fullName,
        total: `$${Number(item.total).toFixed(2)}`,
        createdAt: moment(item.createdAt).format('DD MMM, YYYY')
      };
    });
  }, [invoices]);

  return (
    <div className="admin-invoices-page">
      <PageHeader title="Invoices" />

      <InvoiceSearchFilter filter={filter} onChangeFilter={onChangeFilter} />

      <FilterChipList
        filter={filter}
        onFilterChange={onChangeFilter}
        clearFilter={clearFilter}
        filterType="invoice"
      />

      <div className="d-flex justify-content-end cmb-10">
        <Switch
          enabled={filter.isInternal}
          onToggle={() => onChangeFilter('isInternal', !filter.isInternal)}
          label="Show Internal Data"
        />
      </div>

      <Skeleton loading={loading} active={true} paragraph={{ rows: 0 }}>
        <p className="mt-3 mb-2 text-md">{totalInvoiceCount} invoices found</p>
      </Skeleton>

      <Table
        className="custom-ant-table"
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        onChange={handleTableChange}
      />

      <div className="d-flex-center cmt-10">
        <Pagination
          total={totalInvoiceCount}
          current={filter.page}
          pageSize={filter.perPage}
          onChange={(page) => onChangeFilter('page', page)}
        />
      </div>
    </div>
  );
};

export default Invoices;
