import { useSelector } from 'react-redux';
import { getAccount } from '@/selectors';
import { PERMISSION, ROLE } from '@/resources/enums';

const superAdminPermissions = [
  PERMISSION.CREATE_ADMIN,
  PERMISSION.EDIT_ADMIN,
  PERMISSION.VIEW_ADMIN,
  PERMISSION.CREATE_USER,
  PERMISSION.EDIT_USER,
  PERMISSION.VIEW_USER,
  PERMISSION.DELETE_USER,
  PERMISSION.APPROVE_PROJECT,
  PERMISSION.VIEW_PROJECT,
  PERMISSION.DISABLE_PROJECT,
  PERMISSION.DISABLE_CONTRACT,
  PERMISSION.VIEW_CONTRACT,
  PERMISSION.VIEW_TRANSACTIONS,
  PERMISSION.VIEW_INVOICES,
  PERMISSION.VIEW_EVENTS
];

const adminPermissions = [
  PERMISSION.VIEW_ADMIN,
  PERMISSION.CREATE_USER,
  PERMISSION.EDIT_USER,
  PERMISSION.VIEW_USER,
  PERMISSION.DELETE_USER,
  PERMISSION.APPROVE_PROJECT,
  PERMISSION.VIEW_PROJECT,
  PERMISSION.DISABLE_PROJECT,
  PERMISSION.DISABLE_CONTRACT,
  PERMISSION.VIEW_CONTRACT,
  PERMISSION.VIEW_TRANSACTIONS,
  PERMISSION.VIEW_INVOICES
];

const qaTesterPermissions = [
  PERMISSION.VIEW_USER,
  PERMISSION.APPROVE_PROJECT,
  PERMISSION.VIEW_PROJECT,
  PERMISSION.VIEW_CONTRACT
];

const marketingManagerPermissions = [
  PERMISSION.VIEW_USER,
  PERMISSION.VIEW_PROJECT,
  PERMISSION.VIEW_CONTRACT
];

const checkPermission = (
  permissions: PERMISSION[],
  actions: PERMISSION[] | PERMISSION
) => {
  if (!Array.isArray(actions)) {
    return permissions.includes(actions);
  }

  return !actions.some((item) => !permissions.includes(item));
};

export const useCheckPermission = () => {
  const account = useSelector(getAccount);
  const role = account?.role;

  return (actions: PERMISSION | PERMISSION[]) => {
    if (role === ROLE.SUPER_ADMIN)
      return checkPermission(superAdminPermissions, actions);
    if (role === ROLE.ADMIN) return checkPermission(adminPermissions, actions);
    if (role === ROLE.QA_TESTER)
      return checkPermission(qaTesterPermissions, actions);
    if (role === ROLE.MARKETING_MANAGER)
      return checkPermission(marketingManagerPermissions, actions);

    return false;
  };
};
