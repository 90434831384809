import React, { FC, useCallback } from 'react';
import Badge, { BADGE_STATUS } from '@/components/common/Badge';
import { BANK_STATUS } from '@/resources/enums';
import { NameHelper } from 'src/helpers';

export interface BankStatusProps {
  status: string;
  className?: string;
}

const BankStatus: FC<BankStatusProps> = ({ status, className = '' }) => {
  const getBadgeStatus = useCallback((status: string) => {
    if (status === BANK_STATUS.PENDING) return BADGE_STATUS.NORMAL;
    if (status === BANK_STATUS.APPROVED) return BADGE_STATUS.SUCCESS;

    return BADGE_STATUS.DANGER;
  }, []);

  return (
    <Badge
      className={className}
      title={NameHelper.getBankStatusLabel(status)}
      status={getBadgeStatus(status)}
    />
  );
};

export default BankStatus;
