import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { notification, Skeleton, Table } from 'antd';
import { debounce } from 'lodash';
import { IQueryField, NameHelper, ObjectHelper, QueryHelper } from '@/helpers';
import { ROUTES } from '@/constants';
import { PERMISSION, ROLE } from '@/resources/enums';
import { IAdminUser, ICountry } from '@/resources/interfaces';
import Badge, { BADGE_STATUS } from '@/components/common/Badge';
import UserSearchFilter, {
  USER_FILTER_TYPE
} from '@/components/filters/UserSearchFilter';
import FilterChipList from '@/components/common/filters/FilterChipList';
import Button from '@/components/common/Button';
import Icon from '@/components/common/Icon';
import Pagination from '@/components/common/Pagination';
import Avatar from '@/components/common/Avatar';
import Tooltip from '@/components/common/Tooltip';
import IconButton from '@/components/common/IconButton';
import { useCheckPermission } from '@/hooks';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog';
import PageHeader from '@/components/common/PageHeader';
import { AdminUserApi } from '@/apis/adminUser.api';

const filterFields: IQueryField[] = [
  { name: 'title', type: 'string', default: '' },
  { name: 'country', type: 'country-array', default: [] },
  { name: 'page', type: 'number', default: 1 },
  { name: 'perPage', type: 'number', default: 10 },
  { name: 'role', type: 'string', default: undefined },
  { name: 'sort', type: 'string', default: '-createdAt' }
];

const SubAccounts = () => {
  const checkPermission = useCheckPermission();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<IAdminUser[]>([]);
  const [total, setTotal] = useState(0);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState('');

  const location = useLocation();

  const filter = useMemo(() => {
    return QueryHelper.parseQuery(location.search, filterFields, true);
  }, [location.search]);

  const goToEditPage = (e: any, id: string) => {
    history.push(`${ROUTES.ADMIN.SETTINGS.ACCOUNTS.PREFIX}/${id}/edit`);
    e.stopPropagation();
  };

  const showConfirmModal = (e: any, id: string) => {
    setShowConfirmDialog(true);
    setSelectedAccountId(id);
    e.stopPropagation();
  };

  const onRemoveAccount = async () => {
    try {
      await AdminUserApi.remove(selectedAccountId);
      await searchUsers(filter);
    } catch (err) {
      notification.error({
        message: err.message
      });
    }
  };

  const columns = useMemo(() => {
    return [
      {
        title: 'Name',
        dataIndex: 'fullName',
        render: (fullName: string, user: IAdminUser) => (
          <div className="d-flex align-items-center">
            <Avatar
              src={user?.avatar}
              name={NameHelper.getFullName(user)}
              size="2.5rem"
              className="cmr-6"
              round={true}
            />

            <Tooltip title={fullName}>
              <div>
                <p className="mb-0 text-dark">{fullName}</p>
                <p className="text-xs mb-0 text-blue">{user.email}</p>
              </div>
            </Tooltip>
          </div>
        )
      },
      {
        title: 'Role',
        dataIndex: 'role',
        render: (role: ROLE) => {
          return (
            <Badge
              className="text-capitalize"
              title={NameHelper.getRoleLabel(role)}
              status={
                role === ROLE.ADMIN || role === ROLE.SUPER_ADMIN
                  ? BADGE_STATUS.SUCCESS
                  : BADGE_STATUS.NORMAL
              }
            />
          );
        }
      },
      {
        title: 'Country',
        dataIndex: 'country',
        render: (country: ICountry) => country?.name
      },
      ...(checkPermission(PERMISSION.EDIT_ADMIN)
        ? [
            {
              title: 'Actions',
              dataIndex: 'id',
              render: (id: string, row: any) => {
                return (
                  <div className="d-flex align-items-center">
                    <IconButton
                      icon="edit"
                      onClick={(e) => goToEditPage(e, id)}
                    />
                    {row.role !== ROLE.SUPER_ADMIN && (
                      <IconButton
                        icon="delete"
                        onClick={(e) => showConfirmModal(e, id)}
                      />
                    )}
                  </div>
                );
              }
            }
          ]
        : [])
    ];
  }, []);

  const searchUsers = useCallback((filter: any) => {
    const query: any = {
      search: filter.title,
      country: filter.country,
      page: filter.page,
      perPage: filter.perPage,
      sort: filter.sort,
      role: filter.role
    };

    setLoading(true);
    AdminUserApi.search(ObjectHelper.trimQuery(query))
      .then((res) => {
        setUsers(res.data);
        setTotal(res.totalCount);
        setLoading(false);
      })
      .catch((err) => {
        notification.error({ message: err.message });
        setLoading(false);
      });
  }, []);

  const debounceFilter = useMemo(() => {
    return debounce(searchUsers, 500);
  }, [searchUsers]);

  useEffect(() => {
    debounceFilter(filter);
  }, [filter]);

  const onChangeFilter = (field: string, value: any) => {
    const newFilter = QueryHelper.getFilterToQuery(filter, field, value);

    const query = QueryHelper.stringifyQuery(newFilter);
    history.replace(`${location.pathname}?${query}`);
  };

  const clearFilter = () => {
    history.replace(location.pathname);
  };

  useEffect(() => {
    searchUsers(filter);
  }, []);

  return (
    <div className="admin-accounts-page">
      <PageHeader title="Sub Accounts" />

      <UserSearchFilter
        filter={filter}
        onChangeFilter={onChangeFilter}
        type={USER_FILTER_TYPE.USER}
      />

      <FilterChipList
        filter={filter}
        onFilterChange={onChangeFilter}
        clearFilter={clearFilter}
        filterType="user"
      />

      {checkPermission(PERMISSION.CREATE_ADMIN) && (
        <div className="d-flex align-items-end justify-content-end">
          <Button
            icon={<Icon name="plus" className="cmr-10" size="small" />}
            onClick={() => history.push(ROUTES.ADMIN.SETTINGS.ACCOUNTS.CREATE)}
          >
            Add New Account
          </Button>
        </div>
      )}

      <Skeleton loading={loading} paragraph={{ rows: 0 }} active={true}>
        <p className="mt-3 mb-2 text-md">{total} users found</p>
      </Skeleton>

      <Table
        className="custom-ant-table"
        loading={loading}
        columns={columns}
        dataSource={users}
        pagination={false}
        rowKey={'email'}
        onRow={(record) => {
          return {
            onClick: () => {
              history.push(
                `${ROUTES.ADMIN.SETTINGS.ACCOUNTS.PREFIX}/${record.id}`
              );
            }
          };
        }}
      />

      <div className="d-flex-center cmt-10">
        <Pagination
          total={total}
          current={filter.page}
          pageSize={filter.perPage}
          onChange={(page) => onChangeFilter('page', page)}
        />
      </div>

      <ConfirmDialog
        visible={showConfirmDialog}
        handleClose={() => setShowConfirmDialog(false)}
        onConfirm={onRemoveAccount}
        text="Are you sure you want to remove this account?"
        okText="Confirm"
        cancelText="Cancel"
        type="error"
      />
    </div>
  );
};

export default SubAccounts;
