import React, { FC, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { Modal, notification } from 'antd';
import { ROUTES } from '@/constants';
import {
  FreelancerModel,
  InvoiceModel,
  TransactionModel
} from '@/resources/models';
import {
  PERMISSION,
  TRANSACTION_STATUS,
  TRANSACTION_TYPE
} from '@/resources/enums';
import { TransactionApi } from '@/apis';
import { useCheckPermission } from '@/hooks';
import InvoiceDetailInfo from '@/components/pages/Invoice/InvoiceDetail/InvoiceDetailInfo';
import Button from '@/components/common/Button';
import '../styles.scss';

export interface ITransactionDetailInfoProps {
  transaction: TransactionModel;
}

const TransactionDetailInfo: FC<ITransactionDetailInfoProps> = ({
  transaction
}) => {
  const history = useHistory();
  const checkPermission = useCheckPermission();

  const { method } = transaction;
  const freelancer = transaction.freelancer as FreelancerModel;

  const [accepting, setAccepting] = useState(false);
  const [rejecting, setRejecting] = useState(false);

  const paymentEmail = useMemo(() => {
    const paymentMethod = (freelancer.paymentMethods || []).find(
      (item) => item.type === method
    );
    return paymentMethod?.email;
  }, [method, freelancer]);

  const acceptTransaction = () => {
    setAccepting(true);
    TransactionApi.accept(transaction.id)
      .then(() => {
        setAccepting(false);
        notification.success({
          message: 'Transaction is accepted successfully.'
        });
        history.push(ROUTES.ADMIN.TRANSACTIONS.INDEX);
      })
      .catch((err) => {
        setAccepting(false);
        notification.error({ message: err.message });
      });
  };

  const rejectTransaction = () => {
    setRejecting(true);
    TransactionApi.reject(transaction.id)
      .then(() => {
        setRejecting(false);
        notification.success({
          message: 'Transaction is rejected successfully.'
        });
        history.push(ROUTES.ADMIN.TRANSACTIONS.INDEX);
      })
      .catch((err) => {
        setRejecting(false);
        notification.error({ message: err.message });
      });
  };

  const confirmAccept = () => {
    Modal.confirm({
      title: 'Are you sure you want to accept this request?',
      centered: true,
      onOk: acceptTransaction
    });
  };

  const confirmReject = () => {
    Modal.confirm({
      title: 'Are you sure you want to reject this request?',
      centered: true,
      onOk: rejectTransaction
    });
  };

  return (
    <div className="transaction-detail-container">
      {transaction.type === TRANSACTION_TYPE.PAID ? (
        <InvoiceDetailInfo invoice={transaction?.invoice as InvoiceModel} />
      ) : (
        <>
          <div className="freelancer-info">
            <div className="row">
              <div className="col-lg-9 col-md-6 col-sm-12 left-side">
                <div className="d-flex">
                  <div className="avatar-wrapper">
                    <img src={freelancer.user?.avatarUrl} alt="avatarImage" />
                  </div>
                  <div className="d-flex flex-grow-1 flex-column">
                    <p className="text-primary text-md m-0">
                      {freelancer.user?.fullName}
                    </p>
                    <p className="text-md mt-2">{freelancer.user?.email}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center flex-column">
                <p className="font-weight-bold text-capitalize">
                  Payment Method: {method}
                </p>
                <p className="font-weight-bold">Email: {paymentEmail}</p>
              </div>
            </div>
          </div>

          <div className="transaction-amount my-4 mx-auto">
            <span className="text-md font-weight-bold">Amount</span>
            <span className="text-md font-weight-bold">
              ${(transaction.amount || 0).toFixed(2)}
            </span>
          </div>

          {checkPermission(PERMISSION.VIEW_TRANSACTIONS) &&
            transaction.status === TRANSACTION_STATUS.PENDING && (
              <div className="d-flex w-100 justify-content-center my-3">
                <Button loading={accepting} onClick={confirmAccept}>
                  Accept
                </Button>
                <Button
                  className="cml-9"
                  loading={rejecting}
                  onClick={confirmReject}
                >
                  Reject
                </Button>
              </div>
            )}
        </>
      )}
    </div>
  );
};

export default TransactionDetailInfo;
