import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import './styles.scss';

const ServerError: FC = () => {
  const history = useHistory();

  return (
    <div className="public-page server-error-page">
      <p className="page-title">Server Error</p>
      <img
        className="fail-img"
        src="/images/common/serer-error.png"
        alt={'server-error'}
      />
      <Button
        size="large"
        className="d-flex align-items-center"
        icon={<ArrowLeftOutlined />}
        onClick={() => history.goBack()}
      >
        Back
      </Button>
    </div>
  );
};

export default ServerError;
