import React from 'react';
import './styles.scss';

interface PageHeaderProps {
  title: string;
  children?: any;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, children }) => {
  return (
    <div className="page-header cmb-17">
      <div className="d-flex align-items-center w-full">
        <h1 className="cmb-0">{title}</h1>
        {children}
      </div>
    </div>
  );
};

export default PageHeader;
