import React, { useMemo, useState } from 'react';
import { Col, notification, Row, Typography } from 'antd';
import { FreelancerModel } from '@/resources/models';
import { KycApi } from '@/apis';
import { KYC_ADMIN_REVIEW, KYC_REVIEW_ANSWER } from '@/resources/interfaces';
import Badge, { BADGE_STATUS } from '@/components/common/Badge';
import ConfirmDialogButton from '@/components/pages/ConfirmDialogButton';
import KycDeclineButton from '@/components/pages/KycDeclineButton';

interface IKycVerificationCardProps {
  freelancer: FreelancerModel;
  getFreelancer: () => void;
}

const { Text } = Typography;

const KycVerificationCard: React.FC<IKycVerificationCardProps> = ({
  freelancer,
  getFreelancer
}) => {
  const { user } = freelancer;
  const { applicantData, applicantId } = user?.kyc || {};

  const [loading, setLoading] = useState(false);
  const fullAddress = useMemo(
    () =>
      `${user?.address?.zip || ''} ${user?.address?.street || ''} ${
        user?.address?.city || ''
      } ${user?.address?.province || ''}`,
    [user?.address]
  );

  const reCheckVerification = async () => {
    if (applicantData && user) {
      setLoading(true);
      KycApi.requestVerification(user.id, 'wlCheck')
        .then(() => {
          setLoading(false);
          notification.success({
            message: 'Identity is verified successfully.'
          });
        })
        .catch(() => {
          setLoading(false);
          notification.error({ message: 'Identity verification is failed.' });
        });
    }
  };

  const confirmApprove = async () => {
    if (user) {
      await KycApi.approve(user.id);
      await getFreelancer();
      notification.success({
        message: 'Freelancer is approved successfully'
      });
    }
  };

  const confirmDecline = async () => {
    await getFreelancer();
    notification.success({
      message: 'Freelancer is declined successfully'
    });
  };

  const getKycVerificationStatus = () => {
    if (user?.kyc?.adminReview?.reviewResult === KYC_ADMIN_REVIEW.APPROVED) {
      return (
        <Badge
          title="The KYC verification has been completed"
          status={BADGE_STATUS.SUCCESS}
        />
      );
    }
    if (user?.kyc?.adminReview?.reviewResult === KYC_ADMIN_REVIEW.DECLINED) {
      return (
        <Badge
          title="The KYC verification has been declined"
          status={BADGE_STATUS.DANGER}
        />
      );
    }
  };

  return (
    <div className="custom-card">
      <div className="freelancer-header mb-4">
        <p className="text-md text-bold text-gray-dark mb-1">
          Identity Verification
        </p>

        <p className="text-md text-gray-dark mb-2 d-flex align-items-center">
          <span className="mr-2">Admin Review Status: </span>
          {getKycVerificationStatus()}
        </p>
        <p className="text-md text-gray-dark mb-3 d-flex align-items-center">
          <span className="mr-2">SumSub Review Status: </span>
          <Badge
            title={user?.kyc?.sumsubResponse?.reviewResult?.reviewAnswer}
            status={
              user?.kyc?.sumsubResponse?.reviewResult?.reviewAnswer ===
              KYC_REVIEW_ANSWER.GREEN
                ? BADGE_STATUS.SUCCESS
                : BADGE_STATUS.DANGER
            }
          />
        </p>

        <p className="text-md text-bold text-gray-dark mb-1">Reject Reasons</p>
        <div className="d-flex">
          {[
            ...(user?.kyc?.sumsubResponse?.reviewResult?.rejectLabels || []),
            ...(user?.kyc?.adminReview?.rejectReasons || [])
          ].map((item: string, index: number) => (
            <Badge
              key={index}
              title={item}
              status={BADGE_STATUS.WARNING}
              className="mr-3"
            />
          ))}
        </div>
        {user?.kyc?.reviewAnswer === KYC_REVIEW_ANSWER.RED && (
          <p className="text-md text-gray-dark mb-1 d-flex align-items-center">
            <span className="mr-2">Reason: </span>
            {user?.kyc?.sumsubResponse?.reviewResult?.rejectLabels?.join(', ')}
          </p>
        )}
      </div>
      <Row gutter={48} className="mb-4">
        <Col span={24} lg={12}>
          <div className="d-flex justify-content-between mb-3">
            <Text strong={true} className="text-lg my-0">
              Sumbsub Applicant Id:
            </Text>
            <Text strong={true} className="text-lg my-0">
              {applicantData?.id}
            </Text>
          </div>

          <div className="d-flex justify-content-between mb-2">
            <Text className="text-base my-0">First Name:</Text>
            <Text className="text-base my-0">
              {applicantData?.info?.firstName}
            </Text>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <Text className="text-base my-0">First Name (EN):</Text>
            <Text className="text-base my-0">
              {applicantData?.info?.firstNameEn}
            </Text>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <Text className="text-base my-0">Middle Name:</Text>
            <Text className="text-base my-0">
              {applicantData?.info?.middleName}
            </Text>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <Text className="text-base my-0">Middle Name (EN):</Text>
            <Text className="text-base my-0">
              {applicantData?.info?.middleNameEn}
            </Text>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <Text className="text-base my-0">Last Name:</Text>
            <Text className="text-base my-0">
              {applicantData?.info?.lastName}
            </Text>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <Text className="text-base my-0">Last Name (EN):</Text>
            <Text className="text-base my-0">
              {applicantData?.info?.lastNameEn}
            </Text>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <Text className="text-base my-0">Email:</Text>
            <Text className="text-base my-0">{applicantData?.email}</Text>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <Text className="text-base my-0">Phone:</Text>
            <Text className="text-base my-0">{applicantData?.phone}</Text>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <Text className="text-base my-0">Gender:</Text>
            <Text className="text-base my-0 text-capitalize">
              {user?.gender}
            </Text>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <Text className="text-base my-0">ID docs:</Text>
            <Text className="text-base my-0">
              {applicantData?.info?.idDocs?.length} documents
            </Text>
          </div>
        </Col>
        <Col span={24} lg={12}>
          <div className="d-flex justify-content-between mb-3">
            <Text strong={true} className="text-lg my-0">
              Verification Date:
            </Text>
            <Text strong={true} className="text-lg my-0">
              {applicantData?.createdAt}
            </Text>
          </div>

          <div className="d-flex justify-content-between mb-2">
            <Text className="text-base my-0">Address:</Text>
            <Text className="text-base my-0">{fullAddress}</Text>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <Text className="text-base my-0">Country of Birth:</Text>
            <Text className="text-base my-0" />
          </div>
          <div className="d-flex justify-content-between mb-2">
            <Text className="text-base my-0">State of Birth:</Text>
            <Text className="text-base my-0" />
          </div>
          <div className="d-flex justify-content-between mb-2">
            <Text className="text-base my-0">Country:</Text>
            <Text className="text-base my-0">
              {applicantData?.info?.country}
            </Text>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <Text className="text-base my-0">Date of Birth:</Text>
            <Text className="text-base my-0 text-capitalize">
              {applicantData?.info?.dob}
            </Text>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <Text className="text-base my-0">Verification Type:</Text>
            <Text className="text-base my-0 text-capitalize">
              {applicantData?.type}
            </Text>
          </div>
        </Col>
      </Row>
      <div className="d-flex justify-content-end">
        <ConfirmDialogButton
          onConfirm={confirmApprove}
          message="Are you sure you approve Identity Verification result?"
          okText="Confirm"
          cancelText="Cancel"
          buttonLabel="Approve"
          buttonProps={{
            className: 'mr-3'
          }}
        />
        <KycDeclineButton
          onConfirm={confirmDecline}
          message="Are you sure you decline Identity Verification result?"
          buttonLabel="Decline"
          userId={user?.id}
          buttonProps={{
            className: 'mr-3 btn-danger'
          }}
        />
        {applicantId && (
          <ConfirmDialogButton
            onConfirm={reCheckVerification}
            message="Do you want to ask re-check verification of this user's identity?"
            okText="Confirm"
            cancelText="Cancel"
            buttonLabel="Re-request Verification"
            buttonProps={{
              variant: 'outline',
              loading
            }}
          />
        )}
      </div>
    </div>
  );
};

export default KycVerificationCard;
