import React, { FC } from 'react';
import './styles.scss';

export interface INoDataProps {
  title: string;
  description?: string;
  image?: string;
}

const NoData: FC<INoDataProps> = ({
  title,
  description = '',
  image = '/images/common/no-pitches.png'
}) => {
  return (
    <div className="no-data-container">
      <img src={image} alt="" />
      <p className="text-lg mt-5 mb-2 text-center">{title}</p>
      <p className="text-sm text-center text-gray">{description}</p>
    </div>
  );
};

export default NoData;
