import React, { useMemo, useState } from 'react';
import { Skeleton, Table } from 'antd';
import { DateHelper, NameHelper } from 'src/helpers';
import { REFEREE_ACTION, ROLE } from 'src/resources/enums';
import Pagination from 'src/components/common/Pagination';

interface IInvitedUsersTableProps {
  referral: any;
  loading: boolean;
}

const InvitedUsersTable: React.FC<IInvitedUsersTableProps> = ({
  referral,
  loading
}) => {
  const [page, setPage] = useState(1);

  const columns = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email'
      },
      {
        title: 'User Type',
        dataIndex: 'role',
        key: 'role'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status'
      },
      {
        title: 'Date for SignUp',
        dataIndex: 'createdAt',
        key: 'createdAt'
      },
      {
        title: 'Origin',
        dataIndex: 'source',
        key: 'source'
      },
      {
        title: 'Point',
        dataIndex: 'point',
        key: 'point'
      }
    ],
    []
  );

  const mappedData = useMemo(() => {
    if (!referral || !referral.referees) return [];

    return referral.referees.map((item: any) => ({
      name: NameHelper.getFullName(item.user),
      email: item.user?.email || item.invitation?.receiver,
      role: item.user
        ? item.user.role === ROLE.FREELANCER
          ? 'Freelancer'
          : 'Employer'
        : '',
      status: NameHelper.getRefereeStatus(item.lastAction),
      createdAt: DateHelper.formatDate(item.user?.createdAt, 'MMM DD, YYYY'),
      source: NameHelper.getRefereeSource(item.source),
      point:
        item.lastAction === REFEREE_ACTION.IDENTITY_VERIFIED ||
        item.lastAction === REFEREE_ACTION.HIRED_FREELANCER
          ? 1
          : undefined
    }));
  }, [referral]);

  const dataSource = useMemo(
    () => mappedData.slice((page - 1) * 10, page * 10),
    [mappedData, page]
  );

  return (
    <div>
      <h2 className="cmb-12">Invited Users</h2>

      <Skeleton loading={loading} active={true} paragraph={{ rows: 10 }}>
        <Table
          className="custom-ant-table"
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          rowKey="id"
        />
      </Skeleton>

      <div className="d-flex-center cmt-10">
        <Pagination
          total={mappedData.length}
          current={page}
          pageSize={10}
          onChange={setPage}
        />
      </div>
    </div>
  );
};

export default InvitedUsersTable;
