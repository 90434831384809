import Http from '@/apis/http';
import { IContract, ISearchApiResult } from '@/resources/interfaces';
import { ContractModel } from '@/resources/models';

export class ContractApi {
  static search(query: any): Promise<ISearchApiResult<ContractModel>> {
    return Http.get('/admin/contracts', query).then(
      (res: ISearchApiResult<IContract>) => {
        return {
          totalCount: res.totalCount,
          data: res.data.map((item) => new ContractModel(item))
        };
      }
    );
  }

  static find(id: string): Promise<ContractModel> {
    return Http.get(`/admin/contracts/${id}`).then((res: IContract) => {
      return new ContractModel(res);
    });
  }

  static disable(id: string) {
    return Http.post(`/admin/contracts/${id}/disable`);
  }
}
