import { COMMON_ACTION } from '@/redux/action-types';
import { ILanguage } from '@/resources/interfaces';

export const setCommonData = (data: any) => ({
  type: COMMON_ACTION.SET_COMMON_DATA,
  payload: { data }
});

export const setLanguage = (lang: ILanguage) => ({
  type: COMMON_ACTION.SET_LANGUAGE,
  payload: { lang }
});
