import React from 'react';
import { Formik, Form } from 'formik';
import Input from '@/components/forms/Input';
import Button from '@/components/common/Button';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required.')
    .matches(/[a-zA-Z]/, 'Password should contain at least one alphabet.')
    .matches(/\d/, 'Password should contain at least one number.')
    .min(8, 'Password should contain at least 8 characters.'),
  passwordConfirm: Yup.string()
    .required('Password confirmation is required.')
    .matches(
      /[a-zA-Z]/,
      'Password confirmation should contain at least one alphabet.'
    )
    .matches(/\d/, 'Password confirmation should contain at least one number.')
    .min(8, 'Password confirmation should contain at least 8 characters.')
    .oneOf([Yup.ref('password')], 'Password is not matched.')
});

interface IResetPasswordFormProps {
  loading: boolean;
  onSubmit: any;
}

const ResetPasswordForm: React.FC<IResetPasswordFormProps> = ({
  loading,
  onSubmit
}) => {
  return (
    <div>
      <h2 className="cmb-12">Reset Password</h2>
      <Formik
        initialValues={{ password: '', passwordConfirm: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Input
              name="password"
              type="password"
              label="Password"
              value={values.password}
              onChange={handleChange}
              error={errors.password && touched.password ? errors.password : ''}
              placeholder="Password"
              required={true}
              className="cmb-12"
            />
            <Input
              name="passwordConfirm"
              type="password"
              label="Confirm password"
              value={values.passwordConfirm}
              onChange={handleChange}
              error={
                errors.passwordConfirm && touched.passwordConfirm
                  ? errors.passwordConfirm
                  : ''
              }
              placeholder="Confirm password"
              required={true}
              className="cmb-12"
            />

            <div className="d-flex justify-content-end">
              <Button className="cmb-12" type="submit" loading={loading}>
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ResetPasswordForm;
