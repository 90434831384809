import store from '@/redux/store';
import {
  getCategories,
  getCountries,
  getSkills,
  getSubCategories
} from '@/selectors';
import {
  ACTION_HISTORY_TYPE,
  BANK_ACCOUNT_TYPES,
  BANK_PAYMENT_ORDER_STATUS,
  BANK_STATUS,
  CONTRACT_STATUS,
  CURRENCY,
  DAEMON_STATUS,
  DAEMON_TYPE,
  INVITATION_STATUS,
  PAYMENT_STATUS,
  PAYMENT_TYPE,
  PROJECT_STATUS,
  REFEREE_ACTION,
  REFEREE_SOURCE,
  ROLE,
  TRANSACTION_METHOD,
  TRANSACTION_STATUS,
  TRANSACTION_TYPE,
  USER_STATUS
} from '@/resources/enums';
import { BADGE_STATUS } from '@/components/common/Badge';
import { EVENT_TYPES, OBJECT_TYPES } from '@/constants';

export class NameHelper {
  static getSkillName(id: string) {
    const skills = getSkills(store.getState());
    const skill = skills.find((item) => item.id === id);
    return skill?.name || 'Unknown';
  }

  static getCategoryName(id: string) {
    const categories = getCategories(store.getState());
    const category = categories.find((item) => item.id === id);
    return category?.name;
  }

  static getSubCategoryName(id: string) {
    const subCategories = getSubCategories(store.getState());
    const subCategory = subCategories.find((item) => item.id === id);
    return subCategory?.name;
  }

  static getCountryName(id: string) {
    const countries = getCountries(store.getState());
    const country = countries.find((item) => item.id === id);
    return country?.name || 'Unknown';
  }

  static getCountryCode(code: string) {
    const countries = getCountries(store.getState());
    const country = countries.find((item) => item.code === code);
    return country?.name || 'Unknown';
  }

  static getProjectBadgeText(status: string) {
    if (status === PROJECT_STATUS.STOPPED) return 'Paused';

    return status;
  }

  static getProjectBadgeStatus(status: string) {
    if (status === PROJECT_STATUS.PENDING) return BADGE_STATUS.WARNING;
    if (status === PROJECT_STATUS.AWARDED) return BADGE_STATUS.SUCCESS;
    if (status === PROJECT_STATUS.DRAFT) return BADGE_STATUS.WARNING;
    if (status === PROJECT_STATUS.COMPLETED) return BADGE_STATUS.SUCCESS;
    if (status === PROJECT_STATUS.CLOSED) return BADGE_STATUS.DANGER;
    if (status === PROJECT_STATUS.DISABLED) return BADGE_STATUS.DANGER;
    if (status === PROJECT_STATUS.STOPPED) return BADGE_STATUS.DANGER;

    return BADGE_STATUS.NORMAL;
  }

  static getPaymentTypeLabel(value: string) {
    if (value === PAYMENT_TYPE.HOURLY) return 'Hourly';

    if (value === PAYMENT_TYPE.FIXED) return 'Fixed';

    return 'Unknown';
  }

  static getProjectStatusLabel(value: string) {
    if (value === PROJECT_STATUS.OPEN) return 'Opened';

    if (value === PROJECT_STATUS.AWARDED) return 'Awarded';

    if (value === PROJECT_STATUS.PENDING) return 'Pending';

    if (value === PROJECT_STATUS.DRAFT) return 'Draft';

    if (value === PROJECT_STATUS.CLOSED) return 'Closed';

    if (value === PROJECT_STATUS.DISABLED) return 'Disabled';

    return 'Unknown';
  }

  static getUserStatusLabel(value: string) {
    if (value === USER_STATUS.OPENED) return 'Opened';
    if (value === USER_STATUS.BLOCKED) return 'Blocked';
    if (value === USER_STATUS.CLOSED) return 'Closed';
    if (value === USER_STATUS.PENDING) return 'Pending';
    if (value === USER_STATUS.INACTIVE) return 'Inactive';
    return 'Unknown';
  }

  static getTransactionTypeLabel(value: string) {
    if (value === TRANSACTION_TYPE.PAID) return 'Invoice Payment';
    if (value === TRANSACTION_TYPE.PAID_EMPLOYER_FEE) return 'Employer Fee';
    if (value === TRANSACTION_TYPE.PAYPAL_CHECKOUT_FEE) return 'Paypal Fee';
    if (value === TRANSACTION_TYPE.PAID_FREELANCER_FEE) return 'Service Fee';
    if (value === TRANSACTION_TYPE.WITHDRAW) return 'Withdrawal';
    if (value === TRANSACTION_TYPE.WITHDRAW_FEE) return 'Withdrawal Fee';

    return 'Unknown';
  }

  static getRoleLabel(value?: string) {
    if (value === ROLE.SUPER_ADMIN) return 'Super Admin';
    if (value === ROLE.ADMIN) return 'Admin';
    if (value === ROLE.QA_TESTER) return 'QA Tester';
    if (value === ROLE.MARKETING_MANAGER) return 'Marketing Manager';
    if (value === ROLE.EMPLOYER) return 'Employer';
    if (value === ROLE.FREELANCER) return 'Freelancer';

    return 'Unknown';
  }

  static getTransactionMethodLabel(value: string) {
    if (value === TRANSACTION_METHOD.KLARPAY) return 'Bank Account';

    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  static getTransactionStatusLabel(value: string) {
    if (value === TRANSACTION_STATUS.SUCCESS) return 'Success';
    if (value === TRANSACTION_STATUS.PENDING) return 'Pending';
    if (value === TRANSACTION_STATUS.UNCLAIMED) return 'Unclaimed';
    if (value === TRANSACTION_STATUS.RETURNED) return 'Returned';
    if (value === TRANSACTION_STATUS.REFUNDED) return 'Refunded';
    if (value === TRANSACTION_STATUS.ONHOLD) return 'On Hold';
    if (value === TRANSACTION_STATUS.FAILED) return 'Failed';
    if (value === TRANSACTION_STATUS.DENIED) return 'Denied';
    if (value === TRANSACTION_STATUS.CANCELED) return 'Canceled';
    if (value === TRANSACTION_STATUS.BLOCKED) return 'Blocked';
    if (value === TRANSACTION_STATUS.CREATED) return 'Created';
    if (value === TRANSACTION_STATUS.COMPLETED) return 'Completed';
    if (value === TRANSACTION_STATUS.DECLINED) return 'Declined';
    if (value === TRANSACTION_STATUS.RETRYING) return 'Retrying';

    return 'Unknown';
  }

  static getPaymentStatusLabel(value: string) {
    if (value === PAYMENT_STATUS.PAID) return 'Paid';
    if (value === PAYMENT_STATUS.PENDING) return 'Pending';
    if (value === PAYMENT_STATUS.DECLINED) return 'Declined';
    if (value === PAYMENT_STATUS.NONE) return 'New';
    if (value === PAYMENT_STATUS.DISABLED) return 'Disabled';
    if (value === PAYMENT_STATUS.PROCESSED) return 'Processed';
    if (value === PAYMENT_STATUS.WAITING_FOR_PAYMENT) {
      return 'Waiting for payment';
    }

    return 'Unknown';
  }

  static getContractStatusLabel(value: string) {
    if (value === CONTRACT_STATUS.ACTIVE) return 'Active';
    if (value === CONTRACT_STATUS.PENDING) return 'Pending';
    if (value === CONTRACT_STATUS.PAUSED) return 'Paused';
    if (value === CONTRACT_STATUS.COMPLETED) return 'Completed';
    if (value === CONTRACT_STATUS.CANCELLED) return 'Cancelled';
    if (value === CONTRACT_STATUS.REJECTED) return 'Rejected';

    return 'Unknown';
  }

  static getFullName(user: any) {
    if (!user) return '';

    const { firstName, lastName } = user;

    return `${firstName} ${lastName}`;
  }

  static getEventTypeName(eventType: string) {
    return EVENT_TYPES[eventType];
  }

  static getObjectTypeName(objectType: string) {
    return OBJECT_TYPES[objectType];
  }

  static getActionHistoryTypeLabel(value: string) {
    if (value === ACTION_HISTORY_TYPE.LOGIN) return 'Login';
    if (value === ACTION_HISTORY_TYPE.REGISTER) return 'Register';
    if (value === ACTION_HISTORY_TYPE.RESET_PASSWORD) return 'Reset Password';
    if (value === ACTION_HISTORY_TYPE.WITHDRAW) return 'Withdraw';

    return 'Unknown';
  }

  static getCountryIdFromCode(code: string) {
    const countries = getCountries(store.getState());
    const country = countries.find((item) => item.code === code);
    return country?.id || '';
  }

  static getCountryCodeFromId(id: string) {
    const countries = getCountries(store.getState());
    const country = countries.find((item) => item.id === id);
    return country?.code || '';
  }

  static getInvitationStatusLabel(value: string) {
    if (value === INVITATION_STATUS.PENDING) return 'Pending';
    if (value === INVITATION_STATUS.ACCEPTED) return 'Accepted';

    return 'Unknown';
  }

  static getDaemonTypeLabel(value: string) {
    if (value === DAEMON_TYPE.WITHDRAW) return 'Withdraw';
    if (value === DAEMON_TYPE.PAYONEER) return 'Payoneer';
    if (value === DAEMON_TYPE.KLARPAY) return 'Klarpay';
    if (value === DAEMON_TYPE.TRANSACTION) return 'Transaction';

    return 'Unknown';
  }

  static getDaemonStatusLabel(value: string) {
    if (value === DAEMON_STATUS.SUCCESS) return 'Success';
    if (value === DAEMON_STATUS.FAILED) return 'Failed';
    if (value === DAEMON_STATUS.PARTIALLY_FAILED) return 'Partially failed';

    return 'Unknown';
  }

  static getWebhookStatusLabel(value: string) {
    if (value === DAEMON_STATUS.SUCCESS) return 'Success';
    if (value === DAEMON_STATUS.FAILED) return 'Failed';

    return 'Unknown';
  }

  static getRefereeStatus(status: REFEREE_ACTION) {
    if (status === REFEREE_ACTION.REGISTERED) return 'Registered';

    if (status === REFEREE_ACTION.INVITED) return 'Invited';

    if (status === REFEREE_ACTION.IDENTITY_VERIFIED) return 'Identify Verified';

    if (status === REFEREE_ACTION.HIRED_FREELANCER) return 'Hired Freelancer';

    return '';
  }

  static getRefereeSource(source: REFEREE_SOURCE) {
    if (source === REFEREE_SOURCE.INVITATION) return 'Invitation';

    if (source === REFEREE_SOURCE.REFERRAL_LINK) return 'Shared link';

    return '';
  }

  static getBankAccountTypeLabel(value: string) {
    if (value === BANK_ACCOUNT_TYPES.SAVINGS) return 'Savings';
    if (value === BANK_ACCOUNT_TYPES.CHECKING) return 'Checking';
  }

  static getCurrencyLabel(value?: string) {
    if (value?.toLowerCase() === CURRENCY.USD) return 'USD';
    return 'EUR';
  }

  static getBankPaymentOrderStatusLabel(
    value: string | BANK_PAYMENT_ORDER_STATUS
  ) {
    if (value === BANK_PAYMENT_ORDER_STATUS.COMPLETED) return 'Completed';
    if (value === BANK_PAYMENT_ORDER_STATUS.CANCELLED) return 'Cancelled';

    return 'Pending';
  }

  static getBankStatusLabel(value: string) {
    if (value === BANK_STATUS.APPROVED) return 'Approved';
    if (value === BANK_STATUS.DECLINED) return 'Declined';
    if (value === BANK_STATUS.PENDING) return 'Pending Approval';
    if (value === BANK_STATUS.DEACTIVATED) return 'Deactivated';
    return 'Unknown';
  }

  static getOrderStatusLabel(value: string) {
    if (!value) return '';

    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  static getOrderTypeLabel(value: string) {
    if (!value) return '';

    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}
