import React from 'react';
import { Drawer, Modal, notification } from 'antd';
import Icon from '@/components/common/Icon';
import { Link } from 'react-router-dom';
import { FREELANCER_DETAILS_ROUTES, ROUTES } from '@/constants';
import { NameHelper } from '@/helpers';
import './styles.scss';
import { ORDER_STATUS } from '@/resources/enums';
import Button from '@/components/common/Button';
import { OrderApi } from '@/apis';
import Alert from '@/components/common/Alert';
import OrderStatus from '@/components/pages/OrderStatus';

interface IOrderDetailModalProps {
  onClose: () => void;
  open: boolean;
  order: any;
  refetch: () => void;
}

const OrderDetailModal: React.FC<IOrderDetailModalProps> = ({
  onClose,
  open,
  order,
  refetch
}) => {
  const onRetry = async () => {
    try {
      await OrderApi.retry(order?._id);

      notification.success({
        message: 'Order is reset successfully'
      });
      onClose();
      refetch();
    } catch (err) {
      notification.error({
        message: err.message
      });
    }
  };

  const confirmRetryOrder = () => {
    Modal.confirm({
      title: 'Are you sure you want to retry this order manually?',
      centered: true,
      onOk: onRetry
    });
  };

  return (
    <Drawer
      className="transaction-detail-drawer"
      title={() => <div />}
      closeIcon={<Icon name="close" />}
      placement="right"
      onClose={onClose}
      visible={open}
    >
      <div>
        <table>
          <tbody>
            <tr>
              <td>Order ID:</td>
              <td>{order?._id}</td>
            </tr>
            <tr>
              <td>Type:</td>
              <td>
                <div className="d-flex align-items-center">
                  <div>{NameHelper.getOrderTypeLabel(order?.type)}</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>Status:</td>
              <td>
                <OrderStatus status={order?.status} />
              </td>
            </tr>
            {order?.status === ORDER_STATUS.REJECTED && (
              <tr>
                <td>Failed Reason:</td>
                <td>
                  <Alert type="danger">{order?.meta?.message}</Alert>
                </td>
              </tr>
            )}
            <tr>
              <td>Amount:</td>
              <td>{order?.exAmount}</td>
            </tr>
            <tr>
              <td>Currency:</td>
              <td>{NameHelper.getCurrencyLabel(order?.currency)}</td>
            </tr>
            <tr>
              <td>Date:</td>
              <td>{order?.createdAt}</td>
            </tr>
            <tr>
              <td>Freelancer:</td>
              <td>
                <Link
                  to={`${ROUTES.ADMIN.FREELANCERS.PREFIX}/${order?.freelancer?._id}/${FREELANCER_DETAILS_ROUTES.DETAILS}`}
                >
                  {NameHelper.getFullName(order?.freelancer?.user)}
                </Link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {order?.status === ORDER_STATUS.REJECTED && (
        <div className="flex-1 d-flex justify-content-end">
          <div className="d-flex mt-auto justify-content-end">
            <Button variant="outline" onClick={confirmRetryOrder}>
              Retry Order
            </Button>
          </div>
        </div>
      )}
    </Drawer>
  );
};

export default OrderDetailModal;
