import Http from '@/apis/http';
import { IMessage, ISearchApiResult } from '@/resources/interfaces';
import { MessageModel } from '@/resources/models';

export class MessageApi {
  static search(
    channelId: string,
    query: any = {}
  ): Promise<ISearchApiResult<MessageModel>> {
    return Http.get('/admin/messages', { channel: channelId, ...query }).then(
      (res: ISearchApiResult<IMessage>) => {
        return {
          totalCount: res.totalCount,
          data: res.data.map((item) => new MessageModel(item))
        };
      }
    );
  }

  static delete(messageId: string) {
    return Http.delete(`/admin/messages/${messageId}`);
  }
}
