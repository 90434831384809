import React, { FC, MouseEventHandler, ReactElement } from 'react';
import classnames from 'classnames';
import { LoadingOutlined } from '@ant-design/icons';
import './styles.scss';

type ButtonVariant = 'standard' | 'outline' | 'link';
type ButtonColor = 'primary' | 'danger';
type IconPosition = 'left' | 'right';

interface IButtonProps {
  variant?: ButtonVariant;
  color?: ButtonColor;
  className?: string;
  icon?: ReactElement;
  iconPosition?: IconPosition;
  type?: 'button' | 'submit' | 'reset';
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  loading?: boolean;
}

const Button: FC<IButtonProps> = ({
  children,
  className = '',
  variant = 'standard',
  color = 'primary',
  iconPosition = 'left',
  icon,
  loading = false,
  disabled,
  ...props
}) => {
  return (
    <button
      className={classnames(`custom-btn ${className}`, {
        'outline-btn': variant === 'outline',
        'link-btn': variant === 'link'
      })}
      disabled={disabled || loading}
      {...props}
    >
      {loading ? (
        <LoadingOutlined />
      ) : (
        <>
          {iconPosition === 'left' && !!icon && <>{icon}</>}
          {children}
          {iconPosition === 'right' && !!icon && <>{icon}</>}
        </>
      )}
    </button>
  );
};

export default Button;
