import { FreelancerModel } from '@/resources/models/freelancer.model';
import { IDetails, IInvoice } from '@/resources/interfaces/invoice.interface';
import { EmployerModel } from '@/resources/models/employer.model';
import { DELIVERY_TYPE, PAYMENT_STATUS } from '@/resources/enums';
import { TimeModel } from '@/resources/models/time.model';
import { ProposalModel } from '@/resources/models/proposal.model';
import { ContractModel } from '@/resources/models/contract.model';

export class InvoiceModel {
  id: string;
  invoiceId: string;
  title: string;
  contract: string | ContractModel;
  freelancer: string | FreelancerModel;
  employer: string | EmployerModel;
  deliveryType: DELIVERY_TYPE;
  total: number;
  notes: string;
  details: IDetails[];
  billingPeriod: {
    startDate: string;
    endDate: string;
  };
  timeLogs: string[] | TimeModel[] | (string | TimeModel)[];
  status: PAYMENT_STATUS;
  paidAt?: string;
  milestones: string[];
  clientSecret: any;
  createdAt: string;
  updatedAt: string;
  dueDate: string;
  receivedAmount: number;
  paymentInstantId: string;

  // extra field
  proposal?: ProposalModel;

  constructor(data: IInvoice) {
    this.id = data.id;
    this.invoiceId = data.invoiceId;
    this.title = data.title;

    if (typeof data.contract === 'string') {
      this.contract = data.contract;
    } else {
      this.contract = new ContractModel(data.contract);
    }
    if (typeof data.freelancer === 'string') {
      this.freelancer = data.freelancer;
    } else {
      this.freelancer = new FreelancerModel(data.freelancer);
    }
    if (typeof data.employer === 'string') {
      this.employer = data.employer;
    } else {
      this.employer = new EmployerModel(data.employer);
    }

    this.total = data.total;
    this.notes = data.notes;
    this.details = data.details;
    this.billingPeriod = data.billingPeriod;
    this.timeLogs = data.timeLogs.map((item: any) => {
      if (typeof item === 'string') return item;
      return new TimeModel(item);
    });
    this.paidAt = data.paidAt;
    this.milestones = data.milestones;
    this.deliveryType = data.deliveryType;
    this.status = data.status;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.dueDate = data.dueDate;
    this.receivedAmount = data.receivedAmount;
    this.paymentInstantId = data.paymentInstantId;

    if (data.proposal) {
      this.proposal = new ProposalModel(data.proposal);
    }
  }
}
