import React, { FC, useState } from 'react';
import { EmployerModel, FreelancerModel, InvoiceModel } from '@/resources/models';
import { DateHelper } from '@/helpers';
import './styles.scss';
import PaymentStatus from '@/components/pages/PaymentStatus';
import IconButton from '@/components/common/IconButton';
import SelectInvoiceStatusModal from '@/pages/Admin/InvoiceDetails/SelectInvoiceStatusModal';
import { PAYMENT_STATUS } from '@/resources/enums';

export interface IInvoiceHeaderProps {
  invoice?: InvoiceModel;
  freelancer: FreelancerModel;
  employer: EmployerModel;
  getInvoice?: () => void;
}

const InvoiceHeader: FC<IInvoiceHeaderProps> = ({
  invoice,
  freelancer,
  employer,
  getInvoice
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="invoice-header">
      <div className="title-container">
        <div>
          <p className="text-heading2 cmb-4">Invoice</p>
          <p className="text-sm mb-0">
            Date:&nbsp;
            <span className="text-bold">
              {DateHelper.formatDate(invoice?.createdAt, 'MMM DD, YYYY')}
            </span>
          </p>
          {!!invoice && (
            <p className="text-sm mb-0">
              Invoice ID: <span className="text-bold">{invoice.invoiceId}</span>
            </p>
          )}
        </div>

        <div className="log-wrapper flex-column">
          {invoice?.status && (
            <div className="d-flex justify-content-end mb-3 align-items-center">
              <PaymentStatus status={invoice?.status} />

              {invoice?.status !== PAYMENT_STATUS.PAID && (
                <IconButton icon="edit" onClick={() => setIsOpen(true)} />
              )}
            </div>
          )}
          <img
            className="logo-img"
            src="/images/diligentspace_logo.svg"
            alt=""
          />
        </div>
      </div>

      <div className="d-flex justify-content-between">
        <div>
          <p className="text-md text-bold mb-0">{freelancer.user?.fullName}</p>
          <p className="text-sm mb-0">{freelancer.user?.country?.name || ''}</p>
          <p className="text-sm mb-0">
            {freelancer.user?.address?.province || ''}
          </p>
          <p className="text-sm mb-0">{freelancer.user?.address?.city || ''}</p>
          <p className="text-sm mb-0">
            {freelancer.user?.address?.street || ''}
          </p>
          <p className="text-sm mb-0">{freelancer.user?.address?.zip || ''}</p>
        </div>

        <div className="client-info">
          <p className="text-md mb-0">Bill to</p>
          <p className="text-md text-bold mb-0">{employer.user?.fullName}</p>
          <p className="text-sm mb-0">{employer.user?.country?.name || ''}</p>
          <p className="text-sm mb-0">
            {employer.user?.address?.province || ''}
          </p>
          <p className="text-sm mb-0">{employer.user?.address?.city || ''}</p>
          <p className="text-sm mb-0">{employer.user?.address?.street || ''}</p>
          <p className="text-sm mb-0">{employer.user?.address?.zip || ''}</p>
        </div>
      </div>

      <SelectInvoiceStatusModal
        invoice={invoice}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onConfirm={getInvoice}
      />
    </div>
  );
};

export default InvoiceHeader;
