import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { notification, Skeleton, Table } from 'antd';
import Pagination from '@/components/common/Pagination';
import { BankPaymentOrderApi } from '@/apis';
import { DateHelper, IQueryField, NameHelper, QueryHelper } from '@/helpers';
import PageHeader from '@/components/common/PageHeader';
import { useHistory, useLocation } from 'react-router';
import IconButton from '@/components/common/IconButton';
import Search from '@/components/forms/Search';
import {
  BankAccountModel,
  EmployerModel,
  FreelancerModel,
  InvoiceModel
} from '@/resources/models';
import './styles.scss';
import { useDebounce } from '@/hooks';
import { Link } from 'react-router-dom';
import {
  EMPLOYER_DETAILS_ROUTES,
  FREELANCER_DETAILS_ROUTES,
  ROUTES
} from '@/constants';
import Avatar from '@/components/common/Avatar';
import Tooltip from '@/components/common/Tooltip';
import Badge, { BADGE_STATUS } from '@/components/common/Badge';
import { BANK_PAYMENT_ORDER_STATUS } from '@/resources/enums';
import BankPaymentDetailModal from '@/components/pages/BankPayments/BankPaymentDetailModal';

const filterFields: IQueryField[] = [
  { name: 'page', type: 'number', default: 1 },
  { name: 'perPage', type: 'number', default: 10 },
  { name: 'search', type: 'string', default: undefined }
];

const BankPaymentOrdersPage = () => {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [bankPaymentOrders, setBankPaymentOrders] = useState<any>([]);
  const location = useLocation();
  const history = useHistory();
  const filter = useMemo(() => {
    return QueryHelper.parseQuery(location.search, filterFields, true);
  }, [location.search]);
  const [selectedRecord, setSelectedRecord] = useState<any>();
  const [showDetailModal, setShowDetailModal] = useState(false);

  const debounceFilter = useDebounce(filter, 500);

  const onClickRow = (e: any, record: any) => {
    e.stopPropagation();
    setShowDetailModal(true);
    setSelectedRecord(record);
  };

  const fetchBankPaymentOrders = useCallback(async () => {
    try {
      setLoading(true);
      const res = await BankPaymentOrderApi.search(filter);

      const { totalCount, data } = res;
      setTotal(totalCount);
      setBankPaymentOrders(data);
    } catch (err) {
      notification.error({ message: err.message });
    } finally {
      setLoading(false);
    }
  }, [filter]);

  const dataSource = useMemo(() => {
    return bankPaymentOrders.map((item: any) => ({
      key: item.id,
      ...item
    }));
  }, [bankPaymentOrders]);

  const onChangeFilter = useCallback(
    (field: string, value: any, isPageUpdate = true) => {
      const newFilter = QueryHelper.getFilterToQuery(
        filter,
        field,
        value,
        isPageUpdate
      );

      const query = QueryHelper.stringifyQuery(newFilter);
      history.replace(`${location.pathname}?${query}`);
    },
    [filter, location.pathname]
  );

  useEffect(() => {
    fetchBankPaymentOrders();
  }, [debounceFilter]);

  const columns = useMemo(
    () => [
      {
        title: 'Employer',
        dataIndex: 'employer',
        render: (employer: EmployerModel) => (
          <Link
            className="d-flex align-items-center"
            to={`${ROUTES.ADMIN.EMPLOYERS.PREFIX}/${employer.id}/${EMPLOYER_DETAILS_ROUTES.DETAILS}`}
          >
            <Avatar
              src={employer?.user?.avatar}
              name={employer?.user?.fullName}
              size="2.5rem"
              className="cmr-6"
              round={true}
            />

            <Tooltip title={employer.user?.fullName || ''}>
              <div>
                <p className="mb-0 text-dark">{employer.user?.fullName}</p>
                <p className="text-xs mb-0 text-blue">{employer.user?.email}</p>
              </div>
            </Tooltip>
          </Link>
        )
      },
      {
        title: 'Freelancer',
        dataIndex: 'freelancer',
        render: (freelancer: FreelancerModel) => (
          <Link
            className="d-flex align-items-center"
            to={`${ROUTES.ADMIN.FREELANCERS.PREFIX}/${freelancer.id}/${FREELANCER_DETAILS_ROUTES.DETAILS}`}
          >
            <Avatar
              src={freelancer?.user?.avatar}
              name={freelancer?.user?.fullName}
              size="2.5rem"
              className="cmr-6"
              round={true}
            />

            <Tooltip title={freelancer.user?.fullName || ''}>
              <div>
                <p className="mb-0 text-dark">{freelancer.user?.fullName}</p>
                <p className="text-xs mb-0 text-blue">
                  {freelancer.user?.email}
                </p>
              </div>
            </Tooltip>
          </Link>
        )
      },
      {
        title: 'Invoice',
        dataIndex: 'invoice',
        render: (invoice: InvoiceModel) => (
          <Link to={`${ROUTES.ADMIN.INVOICES.INDEX}/${invoice?.id}/details`}>
            {invoice?.invoiceId}
          </Link>
        )
      },
      {
        title: 'Ref Code',
        dataIndex: 'refCode',
        render: (refCode: string) => {
          return <div>{refCode}</div>;
        }
      },
      {
        title: 'Timestamps',
        dataIndex: 'timestamps',
        render: (timestamps: string) => (
          <div>{DateHelper.formatDate(Number(timestamps), 'DD MMM YYYY')}</div>
        )
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (status: string) => (
          <Badge
            status={
              status === BANK_PAYMENT_ORDER_STATUS.COMPLETED
                ? BADGE_STATUS.SUCCESS
                : status === BANK_PAYMENT_ORDER_STATUS.CANCELLED
                ? BADGE_STATUS.DANGER
                : BADGE_STATUS.NORMAL
            }
            title={NameHelper.getBankPaymentOrderStatusLabel(status)}
          />
        )
      },
      {
        title: 'BankAccountId',
        dataIndex: 'bankAccountId',
        render: (bankAccountId: BankAccountModel) => (
          <div>{bankAccountId?.id}</div>
        )
      },
      {
        title: 'Action',
        dataIndex: 'action',
        render: (_: string, record: any) => (
          <div className="d-flex align-items-center">
            <IconButton
              icon="file-minus"
              onClick={(e) => onClickRow(e, record)}
            />
          </div>
        )
      }
    ],
    []
  );

  return (
    <div className="webhook-logs-page">
      <PageHeader title="Bank Payments" />

      <div className="log-filters">
        <div className="cmb-10">
          <Search
            className="page-search-box"
            placeholder="Search for bank payments by title"
            value={filter.search}
            onChange={(e) => onChangeFilter('search', e.target.value)}
          />
        </div>
      </div>

      <div className="d-flex justify-content-between mt-4 mb-3 align-items-center">
        <Skeleton loading={loading} paragraph={{ rows: 0 }} active={true}>
          <p className="text-md mb-0">{total} payment orders found</p>
        </Skeleton>
      </div>

      <Table
        className="custom-ant-table"
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />

      <div className="d-flex-center cmt-10">
        <Pagination
          total={total}
          current={filter.page}
          pageSize={filter.perPage}
          onChange={(page) => onChangeFilter('page', page)}
        />
      </div>
      <BankPaymentDetailModal
        onClose={() => setShowDetailModal(false)}
        open={showDetailModal}
        bankPayment={selectedRecord}
        fetchBankPaymentOrders={fetchBankPaymentOrders}
      />
    </div>
  );
};

export default BankPaymentOrdersPage;
