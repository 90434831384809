export class ObjectHelper {
  static trimQuery(query: any) {
    const result: any = {};
    Object.keys(query).forEach((key: string) => {
      const value: any = query[key];
      if (
        value !== undefined &&
        value !== '' &&
        (!Array.isArray(value) || value.length > 0)
      ) {
        result[key] = value;
      }
    });
    return result;
  }

  static deepAssign(src: any, copy: any) {
    Object.keys(copy).forEach((key) => {
      const value = copy[key];
      if (value === undefined) return;

      if (value && typeof value === 'object' && !Array.isArray(value)) {
        if (!src[key]) src[key] = {};
        this.deepAssign(src[key], value);
      } else src[key] = value;
    });
  }
}
