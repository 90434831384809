import React, { FC } from 'react';
import './styles.scss';

export interface IChipProps {
  className?: string;
  title: string;
}

const Chip: FC<IChipProps> = ({ title, className = '' }) => {
  return <div className={`custom-chip ${className}`}>{title}</div>;
};

export default Chip;
