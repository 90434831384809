import React from 'react';
import { Drawer } from 'antd';
import Icon from '@/components/common/Icon';
import { Link } from 'react-router-dom';
import {
  EMPLOYER_DETAILS_ROUTES,
  FREELANCER_DETAILS_ROUTES,
  INVOICE_DETAILS_ROUTES,
  ROUTES
} from '@/constants';
import { DateHelper, NameHelper } from '@/helpers';
import './styles.scss';
import TransactionStatus from '@/components/pages/Transaction/TransactionStatus';
import Badge, { BADGE_STATUS } from '@/components/common/Badge';
import { TRANSACTION_STATUS, TRANSACTION_TYPE } from '@/resources/enums';
import Button from '@/components/common/Button';
import { TransactionApi } from '@/apis';
import Alert from '@/components/common/Alert';

interface ITransactionDetailModalProps {
  onClose: () => void;
  open: boolean;
  transaction: any;
}

const TransactionDetailModal: React.FC<ITransactionDetailModalProps> = ({
  onClose,
  open,
  transaction
}) => {
  const getTransactionTypeLabel = (type: TRANSACTION_TYPE) => {
    switch (type) {
      case TRANSACTION_TYPE.PAID:
        return 'Invoiced Amount';
      case TRANSACTION_TYPE.PAID_EMPLOYER_FEE:
        return 'Employee Fee (Revenue for DiligentSpace)';
      case TRANSACTION_TYPE.PAYPAL_CHECKOUT_FEE:
        return 'PayPal Fee';
      default:
        return '';
    }
  };

  const onReject = async () => {
    await TransactionApi.reject(transaction._id);
  };

  return (
    <Drawer
      className="transaction-detail-drawer"
      title={() => <div />}
      closeIcon={<Icon name="close" />}
      placement="right"
      onClose={onClose}
      visible={open}
    >
      <div>
        <table>
          <tbody>
            <tr>
              <td>Transaction ID:</td>
              <td>{transaction?._id}</td>
            </tr>
            <tr>
              <td>Type:</td>
              <td>
                <div className="d-flex align-items-center">
                  <div>
                    {NameHelper.getTransactionTypeLabel(transaction?.type)}
                  </div>
                  {transaction?.internal && (
                    <Badge
                      className="cml-4"
                      title="Internal Transaction"
                      status={BADGE_STATUS.SUCCESS}
                    />
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td>Status:</td>
              <td>
                <TransactionStatus status={transaction?.status} />
              </td>
            </tr>
            {transaction?.status === TRANSACTION_STATUS.FAILED && (
              <tr>
                <td>Failed Reason:</td>
                <td>
                  <Alert type="danger">
                    {transaction?.meta?.data?.message}
                  </Alert>
                </td>
              </tr>
            )}
            <tr>
              <td>Amount:</td>
              <td>{transaction?.amount}</td>
            </tr>
            <tr>
              <td>Exchange rate:</td>
              <td>{transaction?.exRate || 1}</td>
            </tr>
            <tr>
              <td>Date:</td>
              <td>{transaction?.createdAt}</td>
            </tr>
            {transaction?.order && (
              <tr>
                <td>Order:</td>
                <td>
                  <Link
                    to={`${ROUTES.ADMIN.ORDERS.PREFIX}?order=${transaction?.order?._id}`}
                  >
                    {transaction.order._id}
                  </Link>
                </td>
              </tr>
            )}
            {(transaction?.type === TRANSACTION_TYPE.WITHDRAW ||
              transaction?.type === TRANSACTION_TYPE.WITHDRAW_FEE) && (
              <tr>
                <td>Completed At:</td>
                <td>
                  {transaction?.completedAt
                    ? DateHelper.formatDate(
                        transaction?.completedAt,
                        'DD MMM, YYYY'
                      )
                    : ''}
                </td>
              </tr>
            )}
            {transaction?.type !== TRANSACTION_TYPE.WITHDRAW &&
              transaction?.type !== TRANSACTION_TYPE.WITHDRAW_FEE && (
                <tr>
                  <td>Completed At:</td>
                  <td>
                    {transaction?.contract?.endAt
                      ? DateHelper.formatDate(
                          transaction?.contract?.endAt,
                          'DD MMM, YYYY'
                        )
                      : ''}
                  </td>
                </tr>
              )}
            {transaction?.type !== TRANSACTION_TYPE.WITHDRAW &&
              transaction?.type !== TRANSACTION_TYPE.WITHDRAW_FEE && (
                <tr>
                  <td>Employer:</td>
                  <td>
                    <Link
                      to={`${ROUTES.ADMIN.EMPLOYERS.PREFIX}/${transaction?.employer?._id}/${EMPLOYER_DETAILS_ROUTES.DETAILS}`}
                    >
                      {NameHelper.getFullName(transaction?.employer?.user)}
                    </Link>
                  </td>
                </tr>
              )}
            <tr>
              <td>Freelancer:</td>
              <td>
                <Link
                  to={`${ROUTES.ADMIN.FREELANCERS.PREFIX}/${transaction?.freelancer?._id}/${FREELANCER_DETAILS_ROUTES.DETAILS}`}
                >
                  {NameHelper.getFullName(transaction?.freelancer?.user)}
                </Link>
              </td>
            </tr>
            {transaction?.type === TRANSACTION_TYPE.WITHDRAW && (
              <tr>
                <td>Reference:</td>
                <td>{`Withdraw of balance by ${NameHelper.getFullName(
                  transaction?.freelancer?.user
                )} earning through DiligentSpace platform as ${
                  transaction?.freelancer?.title
                }`}</td>
              </tr>
            )}
            {transaction?.type !== TRANSACTION_TYPE.WITHDRAW &&
              transaction?.type !== TRANSACTION_TYPE.WITHDRAW_FEE && (
                <>
                  <tr>
                    <td>Contract:</td>
                    <td>
                      <Link
                        className="link-tag"
                        to={`${ROUTES.ADMIN.CONTRACTS.PREFIX}/${transaction?.contract?._id}/details`}
                      >
                        {transaction?.contract?.title}
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>Invoice:</td>
                    <td>
                      <Link
                        className="link-tag"
                        to={`${ROUTES.ADMIN.INVOICES.PREFIX}/${transaction?.invoice?._id}/${INVOICE_DETAILS_ROUTES.DETAILS}`}
                      >
                        {transaction?.invoice?.invoiceId}
                      </Link>
                    </td>
                  </tr>
                </>
              )}
          </tbody>
        </table>
      </div>

      <div>
        {transaction?.type === TRANSACTION_TYPE.PAID && (
          <table className="w-100 invoice-payment-table">
            <tbody>
              {transaction?.subTransactions
                ? transaction?.subTransactions.map(
                    (item: any, index: number) => (
                      <tr key={index}>
                        <td>{getTransactionTypeLabel(item.type)}</td>
                        <td className="text-right">{`$${Math.abs(
                          Number(item.amount)
                        ).toFixed(2)}`}</td>
                      </tr>
                    )
                  )
                : ''}
              <tr>
                <td>Total</td>
                <td className="text-right">{transaction?.amount}</td>
              </tr>
            </tbody>
          </table>
        )}
      </div>

      {transaction?.status === TRANSACTION_STATUS.PENDING && (
        <div className="flex-1 d-flex flex-column justify-content-end">
          <div className="d-flex mt-auto">
            <Button variant="outline" onClick={onReject}>
              Reject
            </Button>
          </div>
        </div>
      )}
    </Drawer>
  );
};

export default TransactionDetailModal;
