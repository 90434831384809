import React, { FC, useState } from 'react';
import { Formik, Form } from 'formik';
import { Modal } from 'antd';
import * as Yup from 'yup';
import { SECURITY_QUESTION_OPTIONS } from '@/constants';
import Button from '@/components/common/Button';
import Select from '@/components/forms/Select/Select';
import Input from '@/components/forms/Input';
import Icon from '@/components/common/Icon';
import ConfirmDialog from 'src/components/dialogs/ConfirmDialog';

const validationSchema = Yup.object().shape({
  question: Yup.string().required('common.required_field'),
  answer: Yup.string().required('common.required_field'),
  lockAccount: Yup.boolean()
});

export interface IQuestionSecurityDialogProps {
  visible: boolean;
  handleClose: () => void;
  onSubmit: (values: any) => void;
}

const QuestionSecurityDialog: FC<IQuestionSecurityDialogProps> = ({
  visible,
  handleClose,
  onSubmit
}) => {
  const [formValues, setFormValues] = useState<any>({});
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const onFinish = (values: any) => {
    setFormValues(values);
    setConfirmModalOpen(true);
  };

  const onConfirm = () => {
    onSubmit({ ...formValues, enabled: true });
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={handleClose}
        className="dialog question-secure-dialog"
        footer={null}
        width={800}
      >
        <div className="dialog-header">
          <h2 className="title text-capitalize">Security Question</h2>
          <span className="close-btn" onClick={handleClose}>
            <Icon name="close-big" />
          </span>
        </div>
        <div className="dialog-body">
          <Formik
            initialValues={{
              question: '',
              answer: '',
              lockAccount: false
            }}
            onSubmit={onFinish}
            validationSchema={validationSchema}
            enableReinitialize={true}
          >
            {({ values, handleChange, handleSubmit, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <div className="cmb-13">
                  <Select
                    required={true}
                    label="Question"
                    options={SECURITY_QUESTION_OPTIONS}
                    value={values.question}
                    onChange={(value) => setFieldValue('question', value)}
                    placeholder="Select a question"
                  />
                </div>

                <div className="cmb-13">
                  <Input
                    name="answer"
                    label="Answer"
                    value={values.answer}
                    onChange={handleChange}
                    required={true}
                  />
                </div>

                <div className="d-flex justify-content-end">
                  <Button
                    className="mr-3"
                    type="button"
                    variant="outline"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button type="submit">Save</Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>

      <ConfirmDialog
        visible={confirmModalOpen}
        handleClose={() => setConfirmModalOpen(false)}
        onConfirm={onConfirm}
        text="Are you sure you want to make this changes?"
        okText="Ok"
        cancelText="Cancel"
        type="info"
      />
    </>
  );
};

export default QuestionSecurityDialog;
