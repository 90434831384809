import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';
import { notification, Skeleton, Table } from 'antd';
import moment from 'moment';
import {
  API_SERVER,
  EMPLOYER_DETAILS_ROUTES,
  FREELANCER_DETAILS_ROUTES,
  INVOICE_DETAILS_ROUTES,
  nonSepaEURWithdrawFee,
  ROUTES,
  sepaEURWithdrawFee,
  usdWithdrawFee
} from '@/constants';
import { TagApi, TransactionApi } from '@/apis';
import {
  FileHelper,
  IQueryField,
  NameHelper,
  ObjectHelper,
  QueryHelper
} from '@/helpers';
import TransactionSearchFilter from '@/components/filters/TransactionSearchFilter';
import Pagination from '@/components/common/Pagination';
import TransactionStatus from '@/components/pages/Transaction/TransactionStatus';
import FilterChipList from '@/components/common/filters/FilterChipList';
import PageHeader from '@/components/common/PageHeader';
import { useHistory, useLocation } from 'react-router';
import Button from 'src/components/common/Button';
import Icon from 'src/components/common/Icon';
import {
  CURRENCY,
  TRANSACTION_METHOD,
  TRANSACTION_TYPE
} from '@/resources/enums';
import TransactionDetailSection from '@/components/pages/TransactionDetail';
import Badge, { BADGE_STATUS } from '@/components/common/Badge';
import IconButton from '@/components/common/IconButton';
import TransactionDetailModal from '@/components/pages/Transaction/TransactionDetailModal';
import {
  BankModel,
  FreelancerModel,
  TagModel,
  TransactionModel
} from '@/resources/models';
import './styles.scss';
import { IBank, ITransaction } from '@/resources/interfaces';
import sepaCountries from '../../../assets/sepa_countries.json';

const filterFields: IQueryField[] = [
  { name: 'search', type: 'string', default: '' },
  { name: 'status', type: 'string', default: undefined },
  { name: 'method', type: 'string', default: undefined },
  { name: 'page', type: 'number', default: 1 },
  { name: 'perPage', type: 'number', default: 10 },
  { name: 'sort', type: 'string', default: '-createdAt' },
  { name: 'type', type: 'string', default: undefined },
  { name: 'method', type: 'string', default: undefined },
  { name: 'startDate', type: 'dateRange', default: undefined },
  { name: 'endDate', type: 'dateRange', default: undefined },
  { name: 'isInternal', type: 'boolean', default: false },
  { name: 'tag', type: 'string', default: '' }
];

const getWithdrawFeeAmount = (bank?: IBank) => {
  if (!bank || bank.currency?.toLowerCase() === CURRENCY.USD) {
    return usdWithdrawFee;
  }
  const country = sepaCountries.find((item) => item.code === bank.bankCountry);
  if (country) {
    return sepaEURWithdrawFee;
  }

  return nonSepaEURWithdrawFee;
};

const Transactions: FC = () => {
  const [loading, setLoading] = useState(false);
  const [totalTransactionCount, setTotalTransactionCount] = useState(0);
  const [transactions, setTransactions] = useState<any[]>([]);
  const [isExporting, setIsExporting] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [selectedRecord, setSelectedRecord] = useState<any>();
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [tags, setTags] = useState<TagModel[]>([]);

  const onClickRow = (e: any, record: any) => {
    e.stopPropagation();
    setShowDetailModal(true);
    setSelectedRecord(record);
  };

  const columns = useMemo(
    () => [
      {
        title: 'Type',
        dataIndex: 'type',
        className: ' type-column',
        render: (type: string, record: any) => {
          return (
            <div className="d-flex align-items-center flex-wrap">
              <div>
                {NameHelper.getTransactionTypeLabel(
                  type || record.subTransactions[0]?.type
                )}
              </div>
              {record.type === TRANSACTION_TYPE.PAID && (
                <div className="cml-4">({record.subTransactions.length})</div>
              )}
              {record.internal && (
                <Badge
                  className="cml-4"
                  title="Internal"
                  status={BADGE_STATUS.SUCCESS}
                />
              )}
              {record.internal && (
                <Badge
                  className="cml-4"
                  title={record.tag}
                  status={BADGE_STATUS.SUCCESS}
                />
              )}
              {(record.type === TRANSACTION_TYPE.WITHDRAW ||
                record.type === TRANSACTION_TYPE.WITHDRAW_FEE) && (
                <>
                  {record.freelancer.tags.map((item: string, index: number) => (
                    <Badge
                      key={index}
                      className="cml-4"
                      title={item}
                      status={BADGE_STATUS.SUCCESS}
                    />
                  ))}
                </>
              )}
              {record.feeExemption && (
                <Badge className="cml-4" title="Exempt Month" />
              )}
            </div>
          );
        }
      },
      {
        title: 'Description',
        dataIndex: 'description',
        className: 'data-column',
        render: (item: any) => <div>{item}</div>
      },
      {
        title: 'Invoice',
        dataIndex: 'invoice',
        className: 'invoice-column',
        render: (invoice: any, record: any) => (
          <Link
            className="link-tag"
            to={`${ROUTES.ADMIN.INVOICES.PREFIX}/${record.invoice?._id}/${INVOICE_DETAILS_ROUTES.DETAILS}`}
          >
            {invoice?.invoiceId}
          </Link>
        )
      },
      {
        title: 'Currency',
        dataIndex: 'currency',
        className: 'amount-column cpx-20',
        render: (currency: string) => (
          <div>{NameHelper.getCurrencyLabel(currency)}</div>
        )
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        className: 'text-right amount-column cpx-20'
      },
      {
        title: 'Date',
        dataIndex: 'createdAt',
        className: 'cpx-20'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        className: 'cpx-20',
        render: (status: string) => <TransactionStatus status={status} />
      },
      {
        title: 'Action',
        className: 'action-column',
        dataIndex: 'action',
        render: (_: any, record: any) => (
          <IconButton
            icon="file-minus"
            onClick={(e) => onClickRow(e, record)}
          />
        )
      }
    ],
    []
  );

  const filter = useMemo(() => {
    return QueryHelper.parseQuery(location.search, filterFields, true);
  }, [location.search]);

  const searchTransactions = useCallback(
    (filter: any) => {
      const query: any = {
        page: filter.page,
        perPage: filter.perPage,
        search: filter.search,
        status: filter.status,
        method: filter.method,
        sort: filter.sort,
        type: filter.type,
        isInternal: filter.isInternal,
        tag: filter.tag,
        'createdAt>': filter.dateRange.startDate
          ? moment(filter.dateRange.startDate).startOf('day').toISOString()
          : undefined,
        'createdAt<': filter.dateRange.endDate
          ? moment(filter.dateRange.endDate).endOf('day').toISOString()
          : undefined,
        expands: ['employer.user', 'freelancer.user', 'bank']
      };

      setLoading(true);
      TransactionApi.search(ObjectHelper.trimQuery(query))
        .then((res) => {
          setTransactions(res.data);
          setTotalTransactionCount(res.totalCount);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          notification.error({ message: err.message });
        });
    },
    [setTransactions, setTotalTransactionCount]
  );

  const debounceFilter = useMemo(() => {
    return debounce(searchTransactions, 500);
  }, [searchTransactions]);

  useEffect(() => {
    debounceFilter(filter);
  }, [filter]);

  const onChangeFilter = (field: string, value: any) => {
    const newFilter = QueryHelper.getFilterToQuery(filter, field, value);

    const query = QueryHelper.stringifyQuery(newFilter);
    history.replace(`${location.pathname}?${query}`);
  };

  const clearFilter = () => {
    history.replace(location.pathname);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const sortColumns: any = {
      freelancerFullName: 'freelancer.user.fullName',
      employerFullName: 'employer.user.fullName',
      method: 'method',
      amount: 'amount',
      createdAt: 'createdAt',
      status: 'status'
    };
    let sort = '';
    if (sorter.order) {
      sort =
        sorter.order === 'ascend'
          ? sortColumns[sorter.field]
          : `-${sortColumns[sorter.field]}`;
    }

    onChangeFilter('sort', sort);
  };

  const getPaymentLabel = (
    method: TRANSACTION_METHOD,
    freelancer: FreelancerModel,
    transaction: TransactionModel
  ) => {
    const paymentMethod = freelancer.paymentMethods?.find(
      (item) => item.type === method
    );

    if (method === TRANSACTION_METHOD.PAYONEER) return paymentMethod?.payeeId;
    if (method === TRANSACTION_METHOD.KLARPAY && transaction.bank) {
      const bank = transaction.bank as BankModel;
      const arrs = [
        bank.bankName,
        bank.receivingAccount.slice(-4),
        bank.receiverName,
        bank.currency?.toUpperCase()
      ].filter((item) => !!item);
      return arrs.join(' - ');
    }
    return '';
  };

  const getDescription = (item: any) => {
    switch (item.type) {
      case TRANSACTION_TYPE.PAID_FREELANCER_FEE:
        return (
          <div>
            <span className="mr-2">Freelancer Service fee from</span>
            <Link
              to={`${ROUTES.ADMIN.FREELANCERS.PREFIX}/${item.freelancer?._id}/${FREELANCER_DETAILS_ROUTES.DETAILS}`}
            >
              {NameHelper.getFullName(item.freelancer?.user)}
            </Link>
            <span className="mx-2">for</span>
            <Link
              to={`${ROUTES.ADMIN.INVOICES.PREFIX}/${item.invoice?._id}/${INVOICE_DETAILS_ROUTES.DETAILS}`}
            >
              {item.invoice?.invoiceId}
            </Link>
          </div>
        );
      case TRANSACTION_TYPE.PAID_EMPLOYER_FEE:
        return (
          <div>
            <span className="mr-2">Employer Service fee from</span>
            <Link
              to={`${ROUTES.ADMIN.EMPLOYERS.PREFIX}/${item.employer?._id}/${EMPLOYER_DETAILS_ROUTES.DETAILS}`}
            >
              {NameHelper.getFullName(item.employer?.user)}
            </Link>
            <span className="mx-2">for</span>
            <Link
              to={`${ROUTES.ADMIN.INVOICES.PREFIX}/${item.invoice?._id}/${INVOICE_DETAILS_ROUTES.DETAILS}`}
            >
              {item.invoice?.invoiceId}
            </Link>
          </div>
        );
      case TRANSACTION_TYPE.PAYPAL_CHECKOUT_FEE:
        return (
          <div>
            <span className="mr-2">Paypal Checkout fee from</span>
            <Link
              to={`${ROUTES.ADMIN.INVOICES.PREFIX}/${item.invoice?._id}/${INVOICE_DETAILS_ROUTES.DETAILS}`}
            >
              {item.invoice?.invoiceId}
            </Link>
          </div>
        );
      case TRANSACTION_TYPE.WITHDRAW:
        return (
          <div>
            <span className="mr-2">Withdraw from</span>
            <Link
              to={`${ROUTES.ADMIN.FREELANCERS.PREFIX}/${item.freelancer?._id}/${FREELANCER_DETAILS_ROUTES.DETAILS}`}
            >
              {NameHelper.getFullName(item.freelancer?.user)}
            </Link>
            <span className="ml-2">
              to {NameHelper.getTransactionMethodLabel(item.method)} (
              {getPaymentLabel(item.method, item.freelancer, item)})
            </span>
          </div>
        );
      case TRANSACTION_TYPE.WITHDRAW_FEE:
        return (
          <div>
            <span className="mr-2">Withdraw fee from</span>
            <Link
              to={`${ROUTES.ADMIN.FREELANCERS.PREFIX}/${item.freelancer?._id}/${FREELANCER_DETAILS_ROUTES.DETAILS}`}
            >
              {NameHelper.getFullName(item.freelancer?.user)}
            </Link>
            <span className="ml-2">
              to {NameHelper.getTransactionMethodLabel(item.method)} (
              {getPaymentLabel(item.method, item.freelancer, item)})
            </span>
          </div>
        );
      case TRANSACTION_TYPE.PAID:
        return (
          <div>
            <span className="mr-2">Payment from</span>
            <Link
              to={`${ROUTES.ADMIN.EMPLOYERS.PREFIX}/${item.employer?._id}/${EMPLOYER_DETAILS_ROUTES.DETAILS}`}
            >
              {NameHelper.getFullName(item.employer?.user)}
            </Link>
            <span className="mx-2">to</span>
            <Link
              to={`${ROUTES.ADMIN.FREELANCERS.PREFIX}/${item.freelancer?._id}/${FREELANCER_DETAILS_ROUTES.DETAILS}`}
            >
              {NameHelper.getFullName(item.freelancer?.user)}
            </Link>
            <span className="ml-2">for {item.contract?.title}</span>
          </div>
        );
      default:
        return '';
    }
  };

  const getAmountDescription = (transaction: ITransaction) => {
    if (
      transaction.type === TRANSACTION_TYPE.WITHDRAW &&
      transaction.method === TRANSACTION_METHOD.KLARPAY
    ) {
      return `${transaction.currency === CURRENCY.USD ? '$' : '€'}${Math.abs(
        Number(
          transaction.exAmount - getWithdrawFeeAmount(transaction.bank as IBank)
        )
      ).toFixed(2)}`;
    }
    if (
      transaction.type === TRANSACTION_TYPE.WITHDRAW_FEE &&
      transaction.method === TRANSACTION_METHOD.KLARPAY
    ) {
      return `${transaction.currency === CURRENCY.USD ? '$' : '€'}${Math.abs(
        Number(transaction.exAmount)
      ).toFixed(2)}`;
    }

    return `$${Math.abs(Number(transaction.amount)).toFixed(2)}`;
  };

  const dataSource = useMemo(() => {
    return transactions
      .map((item) => {
        if (item._id.groupType === 'invoice') {
          return {
            ...(item.transactions.find(
              (item: any) => item.type === TRANSACTION_TYPE.PAID
            ) || item.transactions[0]),
            amount: item.transactions.reduce(
              (sum: number, el: any) => sum + el.amount,
              0
            ),
            subTransactions: item.transactions
          };
        }
        return item.transactions[0];
      })
      .map((item) => {
        return {
          ...item,
          key: item._id,
          currency: item.currency || CURRENCY.USD,
          description: getDescription(item),
          tag: item.employer?.tag?.name,
          freelancerFullName: NameHelper.getFullName(item.freelancer?.user),
          employerFullName: NameHelper.getFullName(item.employer?.user),
          amount: getAmountDescription(item),
          createdAt: moment(item.createdAt).format('DD MMM, YYYY')
        };
      });
  }, [transactions]);

  const onExport = async () => {
    try {
      setIsExporting(true);
      const res = await TransactionApi.exportTransactions(
        ObjectHelper.trimQuery({
          search: filter.search,
          status: filter.status,
          method: filter.method,
          sort: filter.sort,
          type: filter.type,
          tag: filter.tag,
          isInternal: filter.isInternal,
          'createdAt>': filter.dateRange.startDate
            ? moment(filter.dateRange.startDate).startOf('day').toISOString()
            : undefined,
          'createdAt<': filter.dateRange.endDate
            ? moment(filter.dateRange.endDate).endOf('day').toISOString()
            : undefined,
          expands: ['employer.user', 'freelancer.user', 'bank']
        })
      );

      FileHelper.download(
        `${API_SERVER}/public/download?token=${res}`,
        `transactions_${moment().format('YYYY-MM-DD')}.xlsx`
      );
      setIsExporting(false);
    } catch (err) {
      console.error(err);
      setIsExporting(false);
    }
  };

  const getTabLabel = (tagId: string) => {
    return tags.find((item) => item.id === tagId)?.name;
  };

  useEffect(() => {
    TagApi.search().then((res) => {
      setTags(res.data);
    });
  }, []);

  return (
    <div className="admin-transactions-page">
      <PageHeader title="Transactions" />

      <TransactionSearchFilter
        filter={filter}
        onChangeFilter={onChangeFilter}
      />

      <FilterChipList
        filter={filter}
        onFilterChange={onChangeFilter}
        clearFilter={clearFilter}
        filterType="transaction"
        getTabLabel={getTabLabel}
      />

      <div className="d-flex justify-content-end">
        <Button
          className="cml-12"
          icon={<Icon name="download" className="cmr-10" />}
          onClick={onExport}
          loading={isExporting}
        >
          Export
        </Button>
      </div>
      <Skeleton loading={loading} active={true} paragraph={{ rows: 0 }}>
        <p className="mt-3 mb-2 text-md">
          {totalTransactionCount} transactions found
        </p>
      </Skeleton>

      <Table
        className="custom-ant-table"
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        onChange={handleTableChange}
        expandable={{
          expandedRowKeys: expandedKeys,
          expandedRowRender: (record) => (
            <div key={`expand_${record._id}`}>
              <TransactionDetailSection transaction={record} />
            </div>
          ),
          expandRowByClick: true,
          onExpand: (expanded, record) => {
            if (expanded) {
              setExpandedKeys([record._id]);
            } else {
              setExpandedKeys([]);
            }
          },
          rowExpandable: (record) =>
            [
              TRANSACTION_TYPE.PAID,
              TRANSACTION_TYPE.PAID_EMPLOYER_FEE,
              TRANSACTION_TYPE.PAYPAL_CHECKOUT_FEE,
              TRANSACTION_TYPE.WITHDRAW
            ].includes(record.type),
          expandIconColumnIndex: 14,
          expandedRowClassName: (record) =>
            record.key === expandedKeys[0] ? 'expanded-row' : '',
          expandIcon: () => <div />
        }}
      />

      <div className="d-flex-center cmt-10">
        <Pagination
          total={totalTransactionCount}
          current={filter.page}
          pageSize={filter.perPage}
          onChange={(page) => onChangeFilter('page', page)}
        />
      </div>

      <TransactionDetailModal
        onClose={() => setShowDetailModal(false)}
        open={showDetailModal}
        transaction={selectedRecord}
      />
    </div>
  );
};

export default Transactions;
