import React, { FC, useEffect, useState } from 'react';
import { Spin } from 'antd';
import { fetchCommonData } from '@/apis/common.api';
import { setCommonData } from '@/redux/actions';
import { useActionDispatch } from '@/hooks';

export interface AuthProviderProps {
  children: React.ReactNode;
}

const Preloader: FC<AuthProviderProps> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useActionDispatch();

  const getCommonData = () => {
    fetchCommonData()
      .then((data) => {
        dispatch(setCommonData(data));
      })
      .catch((e) => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getCommonData();
  }, []);

  if (loading) {
    return (
      <div className="w-full h-full d-flex-center">
        <Spin size="large" />
      </div>
    );
  }

  return <>{children}</>;
};

Preloader.displayName = 'Preloader';

export default Preloader;
