import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { AuthProvider, Preloader } from '@/providers';
import { getAccount } from '@/selectors';
import { AdminRoutes, UnauthenticatedRoutes } from '@/routes';
import './App.scss';

const antIcon = <LoadingOutlined className="loading-indicator" spin={true} />;
Spin.setDefaultIndicator(antIcon);

const App: FC = memo(() => {
  const account = useSelector(getAccount);

  return (
    <div className="app">
      <AuthProvider>
        {!account ? (
          <UnauthenticatedRoutes />
        ) : (
          <Preloader>
            <AdminRoutes />
          </Preloader>
        )}
      </AuthProvider>
    </div>
  );
});

App.displayName = 'App';

export default App;
