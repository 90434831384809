import Http from '@/apis/http';
import { IAdminUser, ISearchApiResult } from '@/resources/interfaces';

export class AdminUserApi {
  static search(query: any = {}): Promise<ISearchApiResult<IAdminUser>> {
    return Http.get('/admin/admin-users', query).then(
      (res: ISearchApiResult<IAdminUser>) => ({
        totalCount: res?.totalCount,
        data: res?.data
      })
    );
  }

  static create(data: any) {
    return Http.post('/admin/admin-users', data);
  }

  static update(userId: string, data: any) {
    return Http.put(`/admin/admin-users/${userId}`, data);
  }

  static find(userId: string) {
    return Http.get(`/admin/admin-users/${userId}`);
  }

  static remove(userId: string) {
    return Http.delete(`/admin/admin-users/${userId}`);
  }

  static getRegistrationStatistics() {
    return Http.get('/admin/users/statistics/registration-month');
  }

  static getAdminActionHistories(
    adminUserId: string,
    query: any = {}
  ): Promise<ISearchApiResult<any>> {
    return Http.get(
      `/admin/admin-users/${adminUserId}/action-histories`,
      query
    );
  }
}
