import { UserModel } from '@/resources/models/user.model';
import {
  IEmployer,
  IEmployerTax,
  IFreelancer,
  IProject
} from '@/resources/interfaces';
import { FreelancerModel } from '@/resources/models/freelancer.model';
import { ProjectModel } from '@/resources/models/project.model';
import { USER_STATUS } from '@/resources/enums';
import { TagModel } from '@/resources/models/tag.model';

export class EmployerModel {
  user?: UserModel;
  favouriteFreelancers: string[];
  engagedFreelancers:
    | string[]
    | FreelancerModel[]
    | (string | FreelancerModel)[];
  completedProjects: string[] | ProjectModel[] | (string | ProjectModel)[];
  projects: string[] | ProjectModel[] | (string | ProjectModel)[];
  rating: number;
  bio: string;
  linkedin: string;
  facebook: string;
  companyName?: string;
  companyLink?: string;
  id: string;
  tax?: IEmployerTax;
  paymentVerified?: boolean;
  totalPaid?: number;
  totalPaidHours: number;
  status: USER_STATUS;
  isInternal: boolean;
  createdAt: string;
  tag?: TagModel;

  constructor(init?: IEmployer) {
    const data = {
      favouriteFreelancers: [],
      engagedFreelancers: [],
      completedProjects: [],
      projects: [],
      rating: 0,
      bio: '',
      linkedin: '',
      facebook: '',
      id: '',
      createdAt: '',
      status: USER_STATUS.OPENED,
      isInternal: false,
      ...init
    };

    if (data.user) this.user = new UserModel(data.user);

    this.favouriteFreelancers = data.favouriteFreelancers;
    this.engagedFreelancers = data.engagedFreelancers.map(
      (item: string | IFreelancer) => {
        if (typeof item === 'string') return item;
        return new FreelancerModel(item);
      }
    );
    this.completedProjects = data.completedProjects.map(
      (item: string | IProject) => {
        if (typeof item === 'string') return item;
        return new ProjectModel(item);
      }
    );
    this.projects = data.projects.map((item: string | IProject) => {
      if (typeof item === 'string') return item;
      return new ProjectModel(item);
    });

    this.rating = data.rating;
    this.bio = data.bio;
    this.linkedin = data.linkedin;
    this.facebook = data.facebook;
    this.id = data.id;
    this.tax = data.tax;
    this.companyLink = data.companyLink;
    this.companyName = data.companyName;
    this.createdAt = data.createdAt;
    this.paymentVerified = data.paymentVerified;
    this.totalPaid = data.totalPaid;
    this.totalPaidHours = data.totalPaidHours || 0;
    this.status = data.status;
    this.isInternal = data.isInternal;
    this.tag = data.tag ? new TagModel(data.tag) : undefined;
  }
}
