import { IWebhookLog } from '@/resources/interfaces';
import { TRANSACTION_METHOD, WEBHOOK_LOG_STATUS } from '../enums';

export class WebhookLogModel {
  id: string;
  type: TRANSACTION_METHOD;
  data: any;
  createdAt: string;
  updatedAt: string;
  status: WEBHOOK_LOG_STATUS;
  message: string;

  constructor(data: IWebhookLog) {
    this.id = data.id;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.type = data.type;
    this.data = data.data;
    this.status = data.status;
    this.message = data.message;
  }
}
