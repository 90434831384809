import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { Divider, Empty, notification, Skeleton } from 'antd';
import { ChannelApi, MessageApi } from '@/apis';
import { ChannelModel, MessageModel } from '@/resources/models';
import { DateHelper } from '@/helpers';
import MessageCard from '@/components/pages/Messages/MessageHistory/MessageCard';
import Ribbon from '@/components/common/Ribbon';
import Switch from '@/components/common/Switch';
import './styles.scss';

interface IMessageData {
  date: string;
  messages: MessageModel[];
}

interface MessageHistoryProps {
  channels: ChannelModel[];
  onChannelStatusChange: (channelId: string, disabled: boolean) => void;
}

const MessageHistory: FC<MessageHistoryProps> = ({
  channels,
  onChannelStatusChange
}) => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const { channelId = '' } = queryParams;
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState<MessageModel[]>([]);

  const historyContainer = useRef<HTMLDivElement | null>(null);

  const selectedChannel = useMemo(() => {
    return channels.find((item) => item.id === channelId);
  }, [channels, channelId]);

  const messageData = useMemo<IMessageData[]>(() => {
    const result: IMessageData[] = [];
    messages.forEach((message) => {
      const date = DateHelper.formatDate(message.createdAt, 'DD MMM yyyy');
      const dateMessages = result.find((item) => item.date === date);
      if (dateMessages) {
        dateMessages.messages.push(message);
      } else {
        result.push({
          date,
          messages: [message]
        });
      }
    });

    return result;
  }, [messages]);

  const toggleDisable = () => {
    return ChannelApi.toggleDisable(channelId as string)
      .then(({ disabled }) => {
        if (disabled) {
          notification.success({
            message: 'Channel is disabled successfully.'
          });
        } else {
          notification.success({ message: 'Channel is enabled successfully.' });
        }

        onChannelStatusChange(channelId as string, disabled as boolean);
      })
      .catch((err) => {
        notification.error({ message: err.message });
      });
  };

  const onRemoveMessage = (messageId: string) => {
    const updatedMessages = messages.filter((item) => item.id !== messageId);
    setMessages(updatedMessages);
  };

  useEffect(() => {
    if (channelId) {
      setLoading(true);
      MessageApi.search(channelId as string)
        .then((res) => {
          setMessages(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          notification.error({ message: err.message });
        });
    }
  }, [channelId]);

  useEffect(() => {
    if (historyContainer && historyContainer.current) {
      historyContainer.current.scrollTop =
        historyContainer.current.scrollHeight;
    }
  }, [messages.length, channelId]);

  return (
    <div className="message-history">
      <Skeleton loading={loading} active={true} paragraph={{ rows: 10 }}>
        {selectedChannel ? (
          <>
            <Ribbon
              type={selectedChannel.disabled ? 'danger' : 'normal'}
              content={selectedChannel.disabled ? 'Disabled' : 'Enabled'}
            />

            <div className="d-flex align-items-center justify-content-end cmb-7">
              <Switch
                className="cml-12"
                enabled={!selectedChannel.disabled}
                onToggle={toggleDisable}
                label="Enabled"
              />
            </div>

            {messageData.length > 0 ? (
              <div className="message-daily-list" ref={historyContainer}>
                {messageData.map((data, index) => (
                  <div className="message-daily-wrapper" key={index}>
                    <Divider className="text-blue mb-4">{data.date}</Divider>
                    <div className="message-list">
                      {data.messages.map((message) => (
                        <MessageCard
                          key={`message-${message.id}`}
                          message={message}
                          channel={selectedChannel}
                          onRemove={() => onRemoveMessage(message.id)}
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="d-flex-center h-100">
                <Empty />
              </div>
            )}
          </>
        ) : (
          <div className="d-flex-center h-100">
            <span className="text-center">Please select channel.</span>
          </div>
        )}
      </Skeleton>
    </div>
  );
};

export default MessageHistory;
