import React, { FC, useEffect, useState } from 'react';
import { EmployerModel, ReviewModel } from '@/resources/models';
import { ReviewApi } from '@/apis';
import { Empty, notification, Skeleton } from 'antd';
import { REVIEW_WRITER } from '@/resources/enums';
import ReviewItem from '@/components/pages/ReviewItem';
import Pagination from '@/components/common/Pagination';
import './styles.scss';

interface IReviewsProps {
  employer: EmployerModel;
}

const Reviews: FC<IReviewsProps> = ({ employer }) => {
  const [reviews, setReviews] = useState<ReviewModel[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    page: 1,
    perPage: 3
  });

  useEffect(() => {
    setLoading(true);

    const query = {
      employer: employer.id,
      writer: REVIEW_WRITER.FREELANCER,
      page: filters.page,
      perPage: filters.perPage,
      expands: ['contract'],
      sort: '-createdAt'
    };

    ReviewApi.search(query)
      .then((res) => {
        setLoading(false);
        setReviews(res.data);
        setTotal(res.totalCount);
      })
      .catch((err) => {
        setLoading(false);
        notification.error({ message: err.message });
      });
  }, [employer.id, filters]);

  return (
    <div className="employer-reviews-container">
      <div className="employer-reviews custom-card">
        <Skeleton loading={loading} active={true} paragraph={{ rows: 6 }}>
          <h3 className="cmb-10">Reviews ({total})</h3>

          {reviews.length > 0 ? (
            <div>
              <div className="review-list">
                {reviews.map((item) => (
                  <ReviewItem review={item} key={item.id} />
                ))}
              </div>

              <div className="d-flex-center cmt-10">
                <Pagination
                  total={total}
                  pageSize={filters.perPage}
                  current={filters.page}
                  onChange={(page, perPage) => setFilters({ page, perPage })}
                />
              </div>
            </div>
          ) : (
            <Empty />
          )}
        </Skeleton>
      </div>
    </div>
  );
};

export default Reviews;
