import React, { FC, useState } from 'react';
import './styles.scss';

export interface ILongTextProps {
  text: string;
  maxLength?: number;
  className?: string;
}

const LongText: FC<ILongTextProps> = ({
  text,
  className = '',
  maxLength = 300
}) => {
  const [showFullContent, setShowFullContent] = useState(false);

  if (!text) return <div />;

  return (
    <div className={`long-text-container ${className}`}>
      {text.length < maxLength ? (
        <p className="long-content">{text}</p>
      ) : (
        <>
          {showFullContent ? (
            <div>
              <p className="long-content mb-0">{text}</p>
              <span
                className="show-action-btn text-underline"
                onClick={() => setShowFullContent(false)}
              >
                less
              </span>
            </div>
          ) : (
            <div>
              <p className="long-content mb-0">{text.slice(0, maxLength)}...</p>
              <span
                className="show-action-btn"
                onClick={() => setShowFullContent(true)}
              >
                more
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default LongText;
