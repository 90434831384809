import { IDaemonLog } from '@/resources/interfaces';
import { DAEMON_STATUS, DAEMON_TYPE } from '../enums';

export class DaemonLogModel {
  id: string;
  type: DAEMON_TYPE;
  data: any;
  meta: any;
  createdAt: string;
  updatedAt: string;
  status: DAEMON_STATUS;

  constructor(data: IDaemonLog) {
    this.id = data.id;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.type = data.type;
    this.data = data.data;
    this.meta = data.meta;
    this.status = data.status;
  }
}
