import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { notification, Table } from 'antd';
import moment from 'moment';
import { PROJECT_DETAILS_ROUTES, ROUTES } from '@/constants';
import { ProjectModel } from '@/resources/models';
import { ObjectHelper } from '@/helpers';
import { ProjectApi } from '@/apis';
import { PROJECT_STATUS } from '@/resources/enums';
import Pagination from '@/components/common/Pagination';
import './styles.scss';

const columns = [
  {
    title: 'Title',
    dataIndex: 'title',
    sorter: true,
    showSorterTooltip: false,
    render: (title: string, record: any) => (
      <Link
        className="link-tag break-word"
        to={`${ROUTES.ADMIN.PROJECTS.PREFIX}/${record.id}/${PROJECT_DETAILS_ROUTES.DETAILS}`}
      >
        {title}
      </Link>
    )
  },
  {
    title: 'Level',
    dataIndex: 'level',
    sorter: true,
    showSorterTooltip: false,
    render: (level: string) => <span className="text-capitalize">{level}</span>
  },
  {
    title: 'Payment Type',
    dataIndex: 'paymentType',
    sorter: true,
    showSorterTooltip: false,
    render: (paymentType: string) => (
      <span className="text-capitalize">{paymentType}</span>
    )
  },
  {
    title: 'Created Date',
    dataIndex: 'createdAt',
    sorter: true,
    showSorterTooltip: false,
    render: (createdAt: string) => (
      <div>{moment(createdAt).format('DD MMM, YYYY')}</div>
    )
  }
];

const ProjectList: FC = () => {
  const params: { id: string } = useParams();
  const { id } = params;

  const [loading, setLoading] = useState(false);
  const [openProjects, setOpenProjects] = useState<ProjectModel[]>([]);
  const [totalProjectCount, setTotalProjectCount] = useState(0);

  const [filter, setFilter] = useState({
    page: 1,
    perPage: 5,
    sort: '-createdAt'
  });

  const handlePageChange = (page: number, perPage: number) => {
    setFilter({ ...filter, page, perPage });
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let sort = '';
    if (sorter.order) {
      sort = sorter.order === 'ascend' ? sorter.field : `-${sorter.field}`;
    }
    setFilter({
      ...filter,
      sort
    });
  };

  useEffect(() => {
    const query: any = {
      employer: id,
      status: PROJECT_STATUS.OPEN,
      page: filter.page,
      perPage: filter.perPage,
      sort: filter.sort
    };

    setLoading(true);
    ProjectApi.search(ObjectHelper.trimQuery(query))
      .then((res) => {
        setOpenProjects(res.data);
        setTotalProjectCount(res.totalCount);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        notification.error({ message: err.message });
      });
  }, [id, filter]);

  return (
    <div className="project-list-container">
      <h3 className="cmb-10">Open Projects</h3>

      <Table
        loading={loading}
        className="custom-ant-table"
        columns={columns}
        rowKey="id"
        dataSource={openProjects}
        pagination={false}
        onChange={handleTableChange}
      />

      <div className="d-flex-center cmt-10">
        <Pagination
          total={totalProjectCount}
          current={filter.page}
          pageSize={filter.perPage}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default ProjectList;
