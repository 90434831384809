import { IRootState } from '@/resources/interfaces';

export const getSkills = (state: IRootState) => state.commonReducer.skills;

export const getCategories = (state: IRootState) =>
  state.commonReducer.categories;

export const getSubCategories = (state: IRootState) =>
  state.commonReducer.subCategories;

export const getLanguages = (state: IRootState) =>
  state.commonReducer.languages;

export const getCountries = (state: IRootState) =>
  state.commonReducer.countries.sort((a, b) => {
    if (a.name > b.name) return 1;
    return -1;
  });

export const getLanguage = (state: IRootState) => state.commonReducer.lang;
