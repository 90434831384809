import React, { FC } from 'react';
import { Tooltip as AntTooltip } from 'antd';
import './styles.scss';

interface ITooltipProps {
  title: string;
}

const Tooltip: FC<ITooltipProps> = ({ title, children }) => {
  return (
    <AntTooltip
      overlayClassName="custom-tooltip"
      title={title}
      getPopupContainer={(triggerNode) => triggerNode as HTMLElement}
    >
      {children}
    </AntTooltip>
  );
};

export default Tooltip;
