import Http from '@/apis/http';

export class KycApi {
  static requestVerification(userId: string, reason: string): Promise<any> {
    return Http.post(`/admin/kyc/recheck`, { userId, reason });
  }

  static approve(userId: string): Promise<any> {
    return Http.post(`/admin/kyc/${userId}/approve`);
  }

  static deny(userId: string, data: any): Promise<any> {
    return Http.post(`/admin/kyc/${userId}/deny`, data);
  }
}
