import { IBankAccount } from '@/resources/interfaces';
import { BANK_ACCOUNT_TYPES, BANK_TYPES, CURRENCY } from '@/resources/enums';

export class BankAccountModel {
  id: string;
  bankName: string;
  bicSwift: string;
  bankAddress: string;
  accountHolder: string;
  iban: string;
  routingNumber?: string;
  accountType: BANK_ACCOUNT_TYPES;
  currency: CURRENCY;
  isPrimary?: boolean;
  bankType?: BANK_TYPES;
  accountNumber: string;

  constructor(data: IBankAccount) {
    this.id = data.id;
    this.bankName = data.bankName;
    this.bicSwift = data.bicSwift;
    this.bankAddress = data.bankAddress;
    this.accountHolder = data.accountHolder;
    this.iban = data.iban;
    this.routingNumber = data.routingNumber;
    this.accountType = data.accountType;
    this.currency = data.currency;
    this.isPrimary = data.isPrimary;
    this.bankType = data.bankType;
    this.accountNumber = data.accountNumber;
  }
}
