import React, { FC } from 'react';
import { Divider } from 'antd';
import {
  ContractModel,
  EmployerModel,
  FreelancerModel
} from '@/resources/models';
import { StringHelper } from '@/helpers';
import ContractInfoValues from '@/pages/Admin/ContractDetails/ContractInfo/ContractInfoValues';
import DeliveryDetailsView from '@/pages/Admin/ContractDetails/ContractInfo/DeliveryDetailsView';
import ProposalDetailsView from '@/pages/Admin/ContractDetails/ContractInfo/ProposalDetailsView';
import EmployerCard from '@/components/pages/EmployerCard';
import FreelancerInfoCard from '@/components/pages/FreelancerInfoCard';
import ContractStatusRibbon from '@/components/pages/ContractStatus/ContractStatusRibbon';
import { CONTRACT_STATUS } from '@/resources/enums';
import './styles.scss';

interface IContractInfoProps {
  contract: ContractModel;
  status: CONTRACT_STATUS;
}

const ContractInfo: FC<IContractInfoProps> = ({ contract, status }) => {
  return (
    <div className="row contract-info">
      <div className="col-lg-8">
        <div className="contract-view-container cmb-12">
          <ContractStatusRibbon status={status} />

          <h2 className="text-ellipsis text-blue cmb-9">{contract.title}</h2>

          <ContractInfoValues contract={contract} />

          <Divider className="cmy-12" />

          <div
            className="break-word"
            dangerouslySetInnerHTML={{
              __html: StringHelper.plain2Html(contract.description)
            }}
          />

          <Divider className="cmy-12" />

          <DeliveryDetailsView
            deliveryType={contract.deliveryType}
            deliveryPrice={contract.price}
            milestones={contract.milestones}
            deliveryTime={contract.deliveryTime}
          />

          <Divider className="cmy-12" />

          <ProposalDetailsView
            coverLetter={contract.coverLetter}
            attachments={contract.attachments}
          />
        </div>
      </div>
      <div className="col-lg-4">
        <div className="cmb-12">
          <EmployerCard employer={contract.employer as EmployerModel} />
        </div>

        <FreelancerInfoCard
          freelancer={contract.freelancer as FreelancerModel}
        />
      </div>
    </div>
  );
};

export default ContractInfo;
