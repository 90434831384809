import React, { FC, useEffect, useState } from 'react';
import { Modal, notification } from 'antd';
import './styles.scss';
import { TagModel } from '@/resources/models';
import Input from '@/components/forms/Input';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '@/components/common/Button';
import { TagApi } from '@/apis';

export interface ITagEditModalProps {
  open: boolean;
  onClose: () => void;
  tag?: TagModel;
  onRefresh: () => void;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required('Required Field')
});

const TagEditModal: FC<ITagEditModalProps> = ({
  open,
  onClose,
  tag,
  onRefresh
}) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      if (tag?.id) {
        await TagApi.update(tag?.id, values);
      } else {
        await TagApi.create(values);
      }
      onClose();
      onRefresh();
    } catch (err) {
      notification.error({ message: err.message });
    } finally {
      setLoading(false);
    }
  };

  const form = useFormik({
    validationSchema,
    onSubmit: handleSubmit,
    initialValues: {
      name: tag ? tag.name : ''
    }
  });

  useEffect(() => {
    if (tag?.id) {
      form.setValues({
        name: tag?.name
      });
    }
  }, [tag]);

  return (
    <Modal
      visible={open}
      onCancel={onClose}
      className="confirm-dialog dialog"
      footer={null}
      width={600}
    >
      <div className="dialog-body cpt-15">
        <h1 className="text-heading2 mb-4">
          {!tag ? 'Create Tag' : 'Edit Tag'}
        </h1>

        <div className="">
          <form onSubmit={form.handleSubmit}>
            <div className="mb-4">
              <Input
                className="form-input"
                label="Name"
                required={true}
                {...form.getFieldProps('name')}
                error={
                  form.errors.name && form.touched.name ? form.errors.name : ''
                }
              />
            </div>
            <div className="d-flex align-items-center justify-content-end">
              <Button type="submit" loading={loading}>
                Save
              </Button>

              <Button
                type="button"
                className="ml-3"
                variant="outline"
                onClick={onClose}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default TagEditModal;
