import React, { FC, useMemo } from 'react';
import Badge, { BADGE_STATUS } from '@/components/common/Badge';
import { USER_STATUS } from '@/resources/enums';
import { NameHelper } from '@/helpers';

export interface UserStatusProps {
  status: string;
  className?: string;
}

const UserStatus: FC<UserStatusProps> = ({ status, className = '' }) => {
  const badgeStatus = useMemo(() => {
    if (status === USER_STATUS.OPENED) return BADGE_STATUS.NORMAL;

    if (status === USER_STATUS.PENDING) return BADGE_STATUS.WARNING;

    return BADGE_STATUS.DANGER;
  }, [status]);

  return (
    <Badge
      className={className}
      title={NameHelper.getUserStatusLabel(status)}
      status={badgeStatus}
    />
  );
};

export default UserStatus;
