import React, { FC, useState } from 'react';
import { BANK_STATUS } from '@/resources/enums';
import Select, { ISelectOption } from '@/components/forms/Select';
import Search from '@/components/forms/Search';
import './styles.scss';
import AsyncSelect from '@/components/forms/AsyncSelect';
import { FreelancerApi } from '@/apis';
import { NameHelper } from '@/helpers';

const statusOptions = [
  { title: 'All', value: undefined },
  { title: 'Pending Approval', value: BANK_STATUS.PENDING },
  { title: 'Approved', value: BANK_STATUS.APPROVED },
  { title: 'Declined', value: BANK_STATUS.DECLINED }
];

export interface IFreelancerBankSearchFilterProps {
  filter: any;
  onChangeFilter: (field: string, value: any) => void;
  freelancer?: string;
}

const FreelancerBankSearchFilter: FC<IFreelancerBankSearchFilterProps> = ({
  filter,
  onChangeFilter
}) => {
  const [freelancerOptions, setFreelancerOptions] = useState<ISelectOption[]>(
    []
  );

  const searchFreelancers = (search: string) => {
    FreelancerApi.search({ search, perPage: 20 }).then((res) => {
      if (res.data) {
        setFreelancerOptions(
          res.data.map((freelancer) => ({
            title: NameHelper.getFullName(freelancer.user),
            value: freelancer.id
          }))
        );
      }
    });
  };

  return (
    <div className="invoice-filter-container">
      <div className="cmb-10">
        <Search
          className="page-search-box"
          placeholder="Search for bank accounts by title"
          value={filter.search}
          onChange={(e) => onChangeFilter('search', e.target.value)}
        />
      </div>

      <div className="filter-select-list">
        <div className="cmb-10 cmr-12">
          <AsyncSelect
            label="Freelancer"
            searchable={true}
            multiSelect={true}
            showChipList={false}
            value={filter.freelancer}
            onChange={(value) => onChangeFilter('freelancer', value)}
            options={freelancerOptions}
            onFetch={searchFreelancers}
          />
        </div>
        <div className="cmb-10">
          <Select
            label="Status"
            value={filter.status}
            onChange={(value) => onChangeFilter('status', value)}
            options={statusOptions}
          />
        </div>
      </div>
    </div>
  );
};

export default FreelancerBankSearchFilter;
