import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getAccount } from '@/selectors';
import { ROLE } from '@/resources/enums';

export const useCheckRole = () => {
  const account = useSelector(getAccount);

  const isSuperAdmin = useMemo(() => {
    return account?.role === ROLE.SUPER_ADMIN;
  }, [account?.role]);

  const isAdmin = useMemo(() => {
    return account?.role === ROLE.ADMIN;
  }, [account]);

  const isQATester = useMemo(() => {
    return account?.role === ROLE.QA_TESTER;
  }, [account?.role]);

  const isMarketingManager = useMemo(() => {
    return account?.role === ROLE.MARKETING_MANAGER;
  }, [account?.role]);

  const isAboveAdmin = useMemo(() => {
    return account?.role === ROLE.ADMIN || account?.role === ROLE.SUPER_ADMIN;
  }, [account?.role]);

  return {
    isSuperAdmin,
    isAdmin,
    isQATester,
    isMarketingManager,
    isAboveAdmin
  };
};
