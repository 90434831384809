import { IAppReport } from '@/resources/interfaces';
import { UserModel } from '@/resources/models/user.model';

export class AppReportModel {
  id: string;
  user: string | UserModel;
  error: string;
  createdAt: string;

  constructor(data: IAppReport) {
    this.id = data.id;
    this.user =
      typeof data.user === 'string' ? data.user : new UserModel(data.user);
    this.error = data.error;
    this.createdAt = data.createdAt;
  }
}
