import { ILanguage } from '@/resources/interfaces';

export const LANGUAGES: ILanguage[] = [
  { key: 'en', name: 'English' },
  { key: 'rs', name: 'Italian' },
  { key: 'de', name: 'German' },
  { key: 'fr', name: 'French' }
];

export const KYC_VERIFICATION_STATUS: { [key: string]: string } = {
  init: 'Sumsub applicant is created',
  pending: 'KYC verification is in progress',
  queued: 'The checks have been started for the applicant',
  completed: 'The KYC verification has been completed',
  'admin-requested':
    'Administrator re-requested KYC verification for the applicant'
};

export const KYC_VERIFICATION_STATUS_BADGE: { [key: string]: string } = {
  init: 'Init',
  pending: 'Pending',
  queued: 'Queued',
  completed: 'Completed',
  'admin-requested': 'Re-requested by admin'
};

export const EVENT_TYPES: { [key: string]: string } = {
  'user.created': 'USER.CREATED',
  'user.updated': 'USER.UPDATED',
  'freelancer.created': 'FREELANCER.CREATED',
  'freelancer.updated': 'FREELANCER.UPDATED',
  'employer.created': 'EMPLOYER.CREATED',
  'employer.updated': 'EMPLOYER.UPDATED',
  'admin_user.created': 'ADMIN_USER.CREATED',
  'admin_user.updated': 'ADMIN_USER.UPDATED',
  'project.created': 'PROJECT.CREATED',
  'project.updated': 'PROJECT.UPDATED',
  'proposal.created': 'PROPOSAL.CREATED',
  'proposal.updated': 'PROPOSAL.UPDATED',
  'contract.created': 'CONTRACT.CREATED',
  'contract.updated': 'CONTRACT.UPDATED',
  'invoice.created': 'INVOICE.CREATED',
  'invoice.updated': 'INVOICE.UPDATED',
  'transaction.created': 'TRANSACTION.CREATED',
  'transaction.updated': 'TRANSACTION.UPDATED'
};

export const OBJECT_TYPES: { [key: string]: string } = {
  user: 'User',
  freelancer: 'Freelancer',
  employer: 'Employer',
  admin_user: 'AdminUser',
  project: 'Project',
  proposal: 'Proposal',
  contract: 'Contract',
  invoice: 'Invoice',
  transaction: 'Transaction'
};

export const SECURITY_QUESTION_OPTIONS = [
  {
    title: "Your first pet's name",
    value: "Your first pet's name"
  },
  {
    title: "Your mother's maiden name",
    value: "Your mother's maiden name"
  },
  {
    title: 'The name of your elementary school',
    value: 'The name of your elementary school'
  },
  {
    title: 'Your elementary school mascot',
    value: 'Your elementary school mascot'
  },
  {
    title: "Your best friend's nickname",
    value: "Your best friend's nickname"
  },
  {
    title: 'Your favourite sports team',
    value: 'Your favourite sports team'
  },
  {
    title: 'Your favourite writer',
    value: 'Your favourite writer'
  }
];
