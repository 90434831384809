import { ProjectModel } from '@/resources/models/project.model';
import {
  IAttachment,
  ICategory,
  IContract,
  IMilestone,
  ISkill,
  ISubCategory
} from '@/resources/interfaces';
import {
  CONTRACT_STATUS,
  DELIVERY_TYPE,
  PAYMENT_TYPE
} from '@/resources/enums';
import { EmployerModel } from '@/resources/models/employer.model';
import { FreelancerModel } from '@/resources/models/freelancer.model';
import { ProposalModel } from '@/resources/models/proposal.model';

export class ContractModel {
  id: string;
  project: ProjectModel | string;
  employer: EmployerModel | string;
  freelancer: FreelancerModel | string;
  proposal: ProposalModel | string;
  status: CONTRACT_STATUS;
  title: string;
  level: string;
  category: ICategory;
  subCategory: ISubCategory | string;
  skills: ISkill[] | string[];
  paymentType: PAYMENT_TYPE;
  description: string;
  coverLetter: string;
  price: number;
  attachments: IAttachment[];
  totalPaid: number;
  totalPaidHours: number;
  deliveryTime: number;
  deliveryType: DELIVERY_TYPE;
  milestones?: IMilestone[];
  createdAt: string;
  updatedAt: string;
  endAt: string;

  constructor(data: IContract) {
    this.id = data.id;
    this.project =
      typeof data.project === 'string'
        ? data.project
        : new ProjectModel(data.project);
    this.employer =
      typeof data.employer === 'string'
        ? data.employer
        : new EmployerModel(data.employer);
    this.freelancer =
      typeof data.freelancer === 'string'
        ? data.freelancer
        : new FreelancerModel(data.freelancer);
    this.proposal =
      typeof data.proposal === 'string'
        ? data.proposal
        : new ProposalModel(data.proposal);
    this.status = data.status;
    this.title = data.title;
    this.level = data.level;
    this.category = data.category;
    this.subCategory = data.subCategory;
    this.skills = data.skills;
    this.paymentType = data.paymentType;
    this.description = data.description;
    this.coverLetter = data.coverLetter;
    this.price = data.price;
    this.attachments = data.attachments;
    this.totalPaid = data.totalPaid;
    this.totalPaidHours = data.totalPaidHours;
    this.deliveryTime = data.deliveryTime;
    this.deliveryType = data.deliveryType;
    this.milestones = data.milestones;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.endAt = data.endAt;
  }

  get projectId(): string {
    return typeof this.project === 'string' ? this.project : this.project.id;
  }

  get proposalId(): string {
    return typeof this.proposal === 'string' ? this.proposal : this.proposal.id;
  }
}
