import React, { FC, useMemo } from 'react';
import Badge, { BADGE_STATUS } from '@/components/common/Badge';
import { INVITATION_STATUS } from '@/resources/enums';
import { NameHelper } from '@/helpers';

export interface InvitationStatusProps {
  status: string;
  className?: string;
}

const InvitationStatus: FC<InvitationStatusProps> = ({
  status,
  className = ''
}) => {
  const badgeStatus = useMemo(() => {
    if (status === INVITATION_STATUS.ACCEPTED) return BADGE_STATUS.SUCCESS;

    if (status === INVITATION_STATUS.PENDING) return BADGE_STATUS.WARNING;
  }, [status]);

  const badgeText = useMemo(() => {
    return NameHelper.getInvitationStatusLabel(status);
  }, [status]);

  return <Badge className={className} title={badgeText} status={badgeStatus} />;
};

export default InvitationStatus;
