import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { notification, Skeleton, Table } from 'antd';
import Pagination from '@/components/common/Pagination';
import { TagApi } from '@/apis';
import { IQueryField, QueryHelper } from '@/helpers';
import PageHeader from '@/components/common/PageHeader';
import { useHistory, useLocation } from 'react-router';
import IconButton from '@/components/common/IconButton';
import Search from '@/components/forms/Search';
import Button from '@/components/common/Button';
import { PlusOutlined } from '@ant-design/icons';
import TagEditModal from '@/pages/Admin/Tags/TagEditModal';
import { TagModel } from '@/resources/models';
import ConfirmDialogButton from '@/components/pages/ConfirmDialogButton';
import './styles.scss';
import { debounce } from 'lodash';
import { useDebounce } from '@/hooks';

const filterFields: IQueryField[] = [
  { name: 'page', type: 'number', default: 1 },
  { name: 'perPage', type: 'number', default: 10 },
  { name: 'search', type: 'string', default: undefined }
];

const TagsPage = () => {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [tags, setTags] = useState<any>([]);
  const location = useLocation();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [tag, setTag] = useState<TagModel>();

  const openEditModal = (record: any) => {
    setIsOpen(true);
    setTag(record);
  };

  const filter = useMemo(() => {
    return QueryHelper.parseQuery(location.search, filterFields, true);
  }, [location.search]);

  const debounceFilter = useDebounce(filter, 500);

  const fetchTags = useCallback(async () => {
    try {
      setLoading(true);
      const res = await TagApi.search(filter);

      const { totalCount, data } = res;
      setTotal(totalCount);
      setTags(data);
    } catch (err) {
      notification.error({ message: err.message });
    } finally {
      setLoading(false);
    }
  }, [filter]);

  const onDeleteTag = async (record: any) => {
    try {
      await TagApi.delete(record.id);
      await fetchTags();
    } catch (err) {
      notification.error({ message: err.message });
    }
  };

  const dataSource = useMemo(() => {
    return tags.map((item: any) => ({
      key: item.id,
      ...item
    }));
  }, [tags]);

  const onChangeFilter = useCallback(
    (field: string, value: any, isPageUpdate = true) => {
      const newFilter = QueryHelper.getFilterToQuery(
        filter,
        field,
        value,
        isPageUpdate
      );

      const query = QueryHelper.stringifyQuery(newFilter);
      history.replace(`${location.pathname}?${query}`);
    },
    [filter, location.pathname]
  );

  useEffect(() => {
    fetchTags();
  }, [debounceFilter]);

  const columns = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'name',
        className: 'w-100',
        render: (name: string) => {
          return <div>{name}</div>;
        }
      },
      {
        title: 'Action',
        dataIndex: 'action',
        render: (_: string, record: any) => (
          <div className="d-flex align-items-center">
            <IconButton icon="edit" onClick={() => openEditModal(record)} />

            <ConfirmDialogButton
              onConfirm={() => onDeleteTag(record)}
              message="Are you sure you want to remove this tag?"
              okText="Confirm"
              cancelText="Cancel"
              buttonLabel="Approve"
              buttonProps={{
                icon: 'delete',
                className: 'mr-3'
              }}
              isIconButton={true}
            />
          </div>
        )
      }
    ],
    []
  );

  return (
    <div className="webhook-logs-page">
      <PageHeader title="Tags" />

      <div className="log-filters">
        <div className="cmb-10">
          <Search
            className="page-search-box"
            placeholder="Search for tags by title"
            value={filter.search}
            onChange={(e) => onChangeFilter('search', e.target.value)}
          />
        </div>
      </div>

      <div className="d-flex justify-content-between mt-4 mb-3 align-items-center">
        <Skeleton loading={loading} paragraph={{ rows: 0 }} active={true}>
          <p className="text-md mb-0">{total} logs found</p>
        </Skeleton>
        <Button
          icon={<PlusOutlined className="cmr-5" />}
          onClick={() => setIsOpen(true)}
        >
          Create Tag
        </Button>
      </div>

      <Table
        className="custom-ant-table"
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />

      <div className="d-flex-center cmt-10">
        <Pagination
          total={total}
          current={filter.page}
          pageSize={filter.perPage}
          onChange={(page) => onChangeFilter('page', page)}
        />
      </div>

      <TagEditModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onRefresh={fetchTags}
        tag={tag}
      />
    </div>
  );
};

export default TagsPage;
