import React, { FC, useEffect, useState } from 'react';
import { Modal, notification } from 'antd';
import './styles.scss';
import { BankAccountModel } from '@/resources/models';
import Input from '@/components/forms/Input';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '@/components/common/Button';
import { BankAccountApi } from '@/apis';
import {
  BANK_ACCOUNT_TYPES,
  BANK_ACCOUNT_TYPES_VALUES,
  BANK_TYPES,
  CURRENCY,
  CURRENCY_VALUES
} from '@/resources/enums';
import Select from '@/components/forms/Select';
import { NameHelper } from '@/helpers';
import { IBankAccount } from '@/resources/interfaces';

export interface IBankAccountEditModalProps {
  open: boolean;
  onClose: () => void;
  bankAccount?: BankAccountModel;
  onRefresh: () => void;
}

const validationSchema = Yup.object().shape({
  bankName: Yup.string().trim().required('Bank Name is required'),
  bankAddress: Yup.string().trim().required('Bank Address is required'),
  accountNumber: Yup.string().when('currency', {
    is: CURRENCY.USD,
    then: Yup.string().required('Account Number is required')
  }),
  routingNumber: Yup.string().when('currency', {
    is: CURRENCY.USD,
    then: Yup.string().required('Routing Number is required')
  }),
  iban: Yup.string().when('currency', {
    is: CURRENCY.EUR,
    then: Yup.string().required('Account Number is required')
  }),
  accountHolder: Yup.string().when('currency', {
    is: CURRENCY.EUR,
    then: Yup.string().required('Account Holder is required')
  }),
  bicSwift: Yup.string(),
  bankType: Yup.string(),
  accountType: Yup.string()
});

const BankAccountEditModal: FC<IBankAccountEditModalProps> = ({
  open,
  onClose,
  bankAccount,
  onRefresh
}) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      if (bankAccount?.id) {
        await BankAccountApi.update(bankAccount?.id, values);
      } else {
        await BankAccountApi.create(values);
      }
      onClose();
      onRefresh();
    } catch (err) {
      notification.error({ message: err.message });
    } finally {
      setLoading(false);
    }
  };

  const form = useFormik<Partial<IBankAccount>>({
    validationSchema,
    onSubmit: handleSubmit,
    initialValues: {
      bankName: '',
      bicSwift: '',
      bankAddress: '',
      accountHolder: '',
      iban: '',
      routingNumber: '',
      accountType: BANK_ACCOUNT_TYPES.SAVINGS,
      currency: CURRENCY.USD,
      bankType: BANK_TYPES.ACH,
      accountNumber: ''
    }
  });

  useEffect(() => {
    if (!open) return;

    if (bankAccount?.id) {
      form.setValues({
        bankName: bankAccount.bankName,
        bicSwift: bankAccount.bicSwift,
        bankAddress: bankAccount.bankAddress,
        accountHolder: bankAccount.accountHolder,
        iban: bankAccount.iban,
        routingNumber: bankAccount.routingNumber || '',
        accountType: bankAccount.accountType,
        currency: bankAccount.currency,
        bankType: bankAccount.bankType,
        accountNumber: bankAccount.accountNumber
      });
    } else {
      form.resetForm();
    }
  }, [bankAccount, open]);

  const accountTypeOptions = BANK_ACCOUNT_TYPES_VALUES.map((item) => ({
    title: NameHelper.getBankAccountTypeLabel(item) || '',
    value: item
  }));

  const bankTypeOptions = Object.entries(BANK_TYPES).map((item) => ({
    title: item[0],
    value: item[1]
  }));

  const currencyOptions = CURRENCY_VALUES.map((item) => ({
    title: NameHelper.getCurrencyLabel(item) || '',
    value: item
  }));

  return (
    <Modal
      visible={open}
      onCancel={onClose}
      className="bank-account-edit-dialog dialog"
      footer={null}
      width={600}
    >
      <div className="cpt-15">
        <div className="px-4">
          <h1 className="text-heading2 mb-4">
            {!bankAccount ? 'Create Bank Account' : 'Edit Bank Account'}
          </h1>
        </div>

        <div>
          <form onSubmit={form.handleSubmit}>
            <div className="content px-4">
              <div className="mb-4">
                <Select
                  label="Currency"
                  value={form.values.currency}
                  onChange={(value) => form.setFieldValue('currency', value)}
                  options={currencyOptions}
                />
              </div>
              <div className="mb-4">
                <Input
                  className="form-input"
                  label="Bank Name"
                  required={true}
                  {...form.getFieldProps('bankName')}
                  error={
                    form.errors.bankName && form.touched.bankName
                      ? form.errors.bankName
                      : ''
                  }
                />
              </div>
              <div className="mb-4">
                <Input
                  className="form-input"
                  label="Bank Address"
                  required={true}
                  {...form.getFieldProps('bankAddress')}
                  error={
                    form.errors.bankAddress && form.touched.bankAddress
                      ? form.errors.bankAddress
                      : ''
                  }
                />
              </div>
              {form.values.currency === CURRENCY.USD && (
                <div className="mb-4">
                  <Select
                    label="Type"
                    value={form.values.bankType}
                    onChange={(value) => form.setFieldValue('bankType', value)}
                    options={bankTypeOptions}
                  />
                </div>
              )}
              {form.values.currency === CURRENCY.EUR && (
                <div className="mb-4">
                  <Input
                    className="form-input"
                    label="Account Number"
                    required={true}
                    {...form.getFieldProps('iban')}
                    error={
                      form.errors.iban && form.touched.iban
                        ? form.errors.iban
                        : ''
                    }
                  />
                </div>
              )}
              {form.values.currency === CURRENCY.USD && (
                <div className="mb-4">
                  <Select
                    label="Account Type"
                    value={form.values.accountType}
                    onChange={(value) =>
                      form.setFieldValue('accountType', value)
                    }
                    options={accountTypeOptions}
                  />
                </div>
              )}
              {form.values.currency === CURRENCY.USD && (
                <div className="mb-4">
                  <Input
                    className="form-input"
                    label="Account Number"
                    required={true}
                    {...form.getFieldProps('accountNumber')}
                    error={
                      form.errors.accountNumber && form.touched.accountNumber
                        ? form.errors.accountNumber
                        : ''
                    }
                  />
                </div>
              )}
              {form.values.currency === CURRENCY.USD && (
                <div className="mb-4">
                  <Input
                    className="form-input"
                    label="Routing Number"
                    required={true}
                    {...form.getFieldProps('routingNumber')}
                    error={
                      form.errors.routingNumber && form.touched.routingNumber
                        ? form.errors.routingNumber
                        : ''
                    }
                  />
                </div>
              )}
              {form.values.currency === CURRENCY.EUR && (
                <div className="mb-4">
                  <Input
                    className="form-input"
                    label="Account Holder"
                    required={true}
                    {...form.getFieldProps('accountHolder')}
                    error={
                      form.errors.accountHolder && form.touched.accountHolder
                        ? form.errors.accountHolder
                        : ''
                    }
                  />
                </div>
              )}
              {form.values.currency === CURRENCY.EUR && (
                <div className="mb-4">
                  <Input
                    className="form-input"
                    label="Swift Code"
                    required={true}
                    {...form.getFieldProps('bicSwift')}
                    error={
                      form.errors.bicSwift && form.touched.bicSwift
                        ? form.errors.bicSwift
                        : ''
                    }
                  />
                </div>
              )}
            </div>
            <div className="d-flex align-items-center justify-content-end px-4 pb-4 pt-3">
              <Button type="submit" loading={loading}>
                Save
              </Button>

              <Button
                type="button"
                className="ml-3"
                variant="outline"
                onClick={onClose}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default BankAccountEditModal;
