import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { notification, Skeleton, Table } from 'antd';
import moment from 'moment';
import { FREELANCER_DETAILS_ROUTES, ROUTES } from '@/constants';
import { OrderApi, TagApi } from '@/apis';
import {
  DateHelper,
  IQueryField,
  NameHelper,
  ObjectHelper,
  QueryHelper
} from '@/helpers';
import Pagination from '@/components/common/Pagination';
import FilterChipList from '@/components/common/filters/FilterChipList';
import PageHeader from '@/components/common/PageHeader';
import { useHistory, useLocation } from 'react-router';
import { CURRENCY, TRANSACTION_METHOD } from '@/resources/enums';
import Badge, { BADGE_STATUS } from '@/components/common/Badge';
import IconButton from '@/components/common/IconButton';
import { FreelancerModel, OrderModel, TagModel } from '@/resources/models';
import './styles.scss';
import OrderStatus from '@/components/pages/OrderStatus';
import { Link } from 'react-router-dom';
import Avatar from '@/components/common/Avatar';
import Tooltip from '@/components/common/Tooltip';
import OrderSearchFilter from '@/components/filters/OrderSearchFilter';
import OrderDetailModal from '@/components/pages/Order/OrderDetailModal';
import qs from 'query-string';

const filterFields: IQueryField[] = [
  { name: 'search', type: 'string', default: '' },
  { name: 'type', type: 'string', default: undefined },
  { name: 'status', type: 'string', default: undefined },
  { name: 'method', type: 'string', default: undefined },
  { name: 'tag', type: 'string', default: '' },
  { name: 'startDate', type: 'dateRange', default: undefined },
  { name: 'endDate', type: 'dateRange', default: undefined },
  { name: 'isInternal', type: 'boolean', default: false },
  { name: 'page', type: 'number', default: 1 },
  { name: 'perPage', type: 'number', default: 10 }
];

const Orders: FC = () => {
  const [loading, setLoading] = useState(false);
  const [totalOrderCount, setTotalOrderCount] = useState(0);
  const [orders, setOrders] = useState<any[]>([]);
  const location = useLocation();
  const history = useHistory();
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [tags, setTags] = useState<TagModel[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<OrderModel>();

  useEffect(() => {
    const params = qs.parse(location.search);
    if (params.order) {
      OrderApi.find(params.order as string).then((res) => {
        setSelectedOrder(res);
        setShowDetailModal(true);
      });
    }
  }, [location]);

  const onShowDetailModal = (e: any, record: any) => {
    e.stopPropagation();
    setShowDetailModal(true);
    setSelectedOrder(record);
  };

  const columns = useMemo(
    () => [
      {
        title: 'Type',
        dataIndex: 'type',
        className: ' type-column',
        render: (type: string, record: any) => {
          return (
            <div className="d-flex align-items-center flex-wrap">
              <Badge
                title={NameHelper.getOrderTypeLabel(record.type)}
                status={BADGE_STATUS.NORMAL}
              />
            </div>
          );
        }
      },
      {
        title: 'Freelancer',
        dataIndex: 'freelancer',
        render: (freelancer: FreelancerModel) =>
          freelancer ? (
            <Link
              className="d-flex align-items-center"
              to={`${ROUTES.ADMIN.FREELANCERS.PREFIX}/${freelancer.id}/${FREELANCER_DETAILS_ROUTES.DETAILS}`}
            >
              <Avatar
                src={freelancer?.user?.avatar}
                name={NameHelper.getFullName(freelancer?.user)}
                size="2.5rem"
                className="cmr-6"
                round={true}
              />

              <Tooltip title={NameHelper.getFullName(freelancer?.user)}>
                <div>
                  <p className="mb-0 text-dark">
                    {NameHelper.getFullName(freelancer?.user)}
                  </p>
                  <p className="text-xs mb-0 text-blue">
                    {freelancer?.user?.email}
                  </p>
                </div>
              </Tooltip>
            </Link>
          ) : (
            <div />
          )
      },
      {
        title: 'Currency',
        dataIndex: 'currency',
        className: 'amount-column cpx-20',
        render: (currency: string) => (
          <div>{NameHelper.getCurrencyLabel(currency)}</div>
        )
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        className: 'text-right amount-column cpx-20'
      },
      {
        title: 'Method',
        dataIndex: 'method',
        className: 'amount-column cpx-20',
        render: (method: TRANSACTION_METHOD) => (
          <Badge title={NameHelper.getTransactionMethodLabel(method)} />
        )
      },
      {
        title: 'Status',
        dataIndex: 'status',
        className: 'cpx-20',
        render: (status: string) => <OrderStatus status={status} />
      },
      {
        title: 'Date',
        dataIndex: 'createdAt',
        className: 'cpx-20'
      },
      {
        title: 'Action',
        className: 'action-column',
        dataIndex: 'action',
        render: (_: any, record: any) => (
          <IconButton
            icon="file-minus"
            onClick={(e) => onShowDetailModal(e, record)}
          />
        )
      }
    ],
    []
  );

  const filter = useMemo(() => {
    return QueryHelper.parseQuery(location.search, filterFields, true);
  }, [location.search]);

  const searchOrders = useCallback((filter: any) => {
    const query: any = {
      page: filter.page,
      perPage: filter.perPage,
      search: filter.search,
      status: filter.status,
      method: filter.method,
      type: filter.type,
      isInternal: filter.isInternal,
      tag: filter.tag,
      'createdAt>': filter.dateRange.startDate
        ? moment(filter.dateRange.startDate).startOf('day').toISOString()
        : undefined,
      'createdAt<': filter.dateRange.endDate
        ? moment(filter.dateRange.endDate).endOf('day').toISOString()
        : undefined
    };

    setLoading(true);
    OrderApi.search(ObjectHelper.trimQuery(query))
      .then((res) => {
        setOrders(res.data);
        setTotalOrderCount(res.totalCount);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        notification.error({ message: err.message });
      });
  }, []);

  const debounceFilter = useMemo(() => {
    return debounce(searchOrders, 500);
  }, [searchOrders]);

  useEffect(() => {
    debounceFilter(filter);
  }, [filter]);

  const onChangeFilter = (field: string, value: any) => {
    const newFilter = QueryHelper.getFilterToQuery(filter, field, value);

    const query = QueryHelper.stringifyQuery(newFilter);
    history.replace(`${location.pathname}?${query}`);
  };

  const clearFilter = () => {
    history.replace(location.pathname);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const sortColumns: any = {
      freelancerFullName: 'freelancer.user.fullName',
      employerFullName: 'employer.user.fullName',
      method: 'method',
      amount: 'amount',
      createdAt: 'createdAt',
      status: 'status'
    };
    let sort = '';
    if (sorter.order) {
      sort =
        sorter.order === 'ascend'
          ? sortColumns[sorter.field]
          : `-${sortColumns[sorter.field]}`;
    }

    onChangeFilter('sort', sort);
  };

  const dataSource = useMemo(() => {
    return orders.map((item) => {
      return {
        ...item,
        key: item._id,
        currency: item.currency || CURRENCY.USD,
        createdAt: DateHelper.formatDate(item.createdAt, 'DD.MM.YYYY')
      };
    });
  }, [orders]);

  const getTabLabel = (tagId: string) => {
    return tags.find((item) => item.id === tagId)?.name;
  };

  useEffect(() => {
    TagApi.search().then((res) => {
      setTags(res.data);
    });
  }, []);

  const fetchOrders = useCallback(() => {
    searchOrders(filter);
  }, [searchOrders, filter]);

  return (
    <div className="admin-transactions-page">
      <PageHeader title="Orders" />

      <OrderSearchFilter filter={filter} onChangeFilter={onChangeFilter} />

      <FilterChipList
        filter={filter}
        onFilterChange={onChangeFilter}
        clearFilter={clearFilter}
        filterType="order"
        getTabLabel={getTabLabel}
      />

      <Skeleton loading={loading} active={true} paragraph={{ rows: 0 }}>
        <p className="mt-3 mb-2 text-md">{totalOrderCount} orders found</p>
      </Skeleton>

      <Table
        className="custom-ant-table"
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        onChange={handleTableChange}
      />

      <div className="d-flex-center cmt-10">
        <Pagination
          total={totalOrderCount}
          current={filter.page}
          pageSize={filter.perPage}
          onChange={(page) => onChangeFilter('page', page)}
        />
      </div>
      <OrderDetailModal
        onClose={() => setShowDetailModal(false)}
        open={showDetailModal}
        order={selectedOrder}
        refetch={fetchOrders}
      />
    </div>
  );
};

export default Orders;
