import React from 'react';
import { Drawer } from 'antd';
import Icon from '@/components/common/Icon';
import Badge, { BADGE_STATUS } from '@/components/common/Badge';
import { BANK_PAYMENT_ORDER_STATUS, CURRENCY } from '@/resources/enums';
import { DateHelper, NameHelper } from '@/helpers';
import './styles.scss';
import {
  BankAccountModel,
  BankPaymentOrderModel,
  EmployerModel,
  FreelancerModel,
  InvoiceModel
} from '@/resources/models';
import { Link } from 'react-router-dom';
import {
  EMPLOYER_DETAILS_ROUTES,
  FREELANCER_DETAILS_ROUTES,
  ROUTES
} from '@/constants';
import Button from '@/components/common/Button';
import { BankPaymentOrderApi } from '@/apis';

interface IBankPaymentDetailModalProps {
  onClose: () => void;
  open: boolean;
  bankPayment: BankPaymentOrderModel;
  fetchBankPaymentOrders: () => void;
}

const BankPaymentDetailModal: React.FC<IBankPaymentDetailModalProps> = ({
  onClose,
  open,
  bankPayment,
  fetchBankPaymentOrders
}) => {
  const onComplete = async () => {
    await BankPaymentOrderApi.complete(bankPayment.id);
    fetchBankPaymentOrders();
    onClose();
  };

  const onReject = async () => {
    await BankPaymentOrderApi.reject(bankPayment.id);
    fetchBankPaymentOrders();
    onClose();
  };

  const bankAccount = bankPayment?.bankAccountId as BankAccountModel;

  return (
    <Drawer
      className="bank-payment-detail-drawer"
      title={() => <div />}
      closeIcon={<Icon name="close" />}
      placement="right"
      onClose={onClose}
      visible={open}
    >
      <table className="mb-3">
        <tbody>
          <tr>
            <td>Employer:</td>
            <td>
              <Link
                className="d-flex align-items-center text-blue"
                to={`${ROUTES.ADMIN.EMPLOYERS.PREFIX}/${
                  (bankPayment?.employer as EmployerModel)?.id
                }/${EMPLOYER_DETAILS_ROUTES.DETAILS}`}
              >
                {NameHelper.getFullName(
                  (bankPayment?.employer as EmployerModel)?.user
                )}
              </Link>
            </td>
          </tr>
          <tr>
            <td>Freelancer:</td>
            <td>
              <Link
                className="d-flex align-items-center text-blue"
                to={`${ROUTES.ADMIN.FREELANCERS.PREFIX}/${
                  (bankPayment?.freelancer as FreelancerModel)?.id
                }/${FREELANCER_DETAILS_ROUTES.DETAILS}`}
              >
                {NameHelper.getFullName(
                  (bankPayment?.freelancer as FreelancerModel)?.user
                )}
              </Link>
            </td>
          </tr>
          <tr>
            <td>Invoice:</td>
            <td>
              <Link
                to={`${ROUTES.ADMIN.INVOICES.INDEX}/${
                  (bankPayment?.invoice as InvoiceModel)?.id
                }/details`}
              >
                {(bankPayment?.invoice as InvoiceModel)?.invoiceId}
              </Link>
            </td>
          </tr>
          <tr>
            <td>Ref Code:</td>
            <td>{bankPayment?.refCode}</td>
          </tr>
          <tr>
            <td>Status:</td>
            <td>
              <Badge
                status={
                  bankPayment?.status === BANK_PAYMENT_ORDER_STATUS.COMPLETED
                    ? BADGE_STATUS.SUCCESS
                    : bankPayment?.status ===
                      BANK_PAYMENT_ORDER_STATUS.CANCELLED
                    ? BADGE_STATUS.DANGER
                    : BADGE_STATUS.NORMAL
                }
                title={NameHelper.getBankPaymentOrderStatusLabel(
                  bankPayment?.status || ''
                )}
              />
            </td>
          </tr>
          <tr>
            <td>Timestamps:</td>
            <td>
              <div>
                {DateHelper.formatDate(
                  Number(bankPayment?.timestamps),
                  'DD MMM YYYY, hh:mm:ss A'
                )}
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <h3 className="font-weight-bold">Bank Information</h3>
      <table className="mb-3">
        <tbody>
          <tr>
            <td>Bank Name:</td>
            <td>{bankAccount?.bankName}</td>
          </tr>
          <tr>
            <td>Bank Address:</td>
            <td>{bankAccount?.bankAddress}</td>
          </tr>
          {bankAccount?.currency === CURRENCY.EUR && (
            <tr>
              <td>IBAN:</td>
              <td>{bankAccount?.iban}</td>
            </tr>
          )}
          {bankAccount?.currency === CURRENCY.USD && (
            <tr>
              <td>Type:</td>
              <td>{bankAccount?.bankType}</td>
            </tr>
          )}
          {bankAccount?.currency === CURRENCY.USD && (
            <tr>
              <td>Account Number:</td>
              <td>{bankAccount?.accountNumber}</td>
            </tr>
          )}
          {bankAccount?.currency === CURRENCY.EUR && (
            <tr>
              <td>Account Holder:</td>
              <td>{bankAccount?.accountHolder}</td>
            </tr>
          )}
          {bankAccount?.currency === CURRENCY.USD && (
            <tr>
              <td>Account Type:</td>
              <td className="text-capitalize">{bankAccount?.accountType}</td>
            </tr>
          )}
          {bankAccount?.currency === CURRENCY.USD && (
            <tr>
              <td>Routing Number:</td>
              <td className="text-capitalize">{bankAccount?.routingNumber}</td>
            </tr>
          )}
          {bankAccount?.currency === CURRENCY.EUR && (
            <tr>
              <td>Swift code:</td>
              <td>{bankAccount?.bicSwift}</td>
            </tr>
          )}
          <tr>
            <td>Currency:</td>
            <td className="text-uppercase">{bankAccount?.currency}</td>
          </tr>
        </tbody>
      </table>
      {bankPayment?.status === BANK_PAYMENT_ORDER_STATUS.PENDING && (
        <div className="flex-1 d-flex flex-column justify-content-end">
          <div className="d-flex justify-content-end">
            <Button className="cmr-12" onClick={onComplete}>
              Complete
            </Button>
            <Button variant="outline" onClick={onReject}>
              Reject
            </Button>
          </div>
        </div>
      )}
    </Drawer>
  );
};

export default BankPaymentDetailModal;
