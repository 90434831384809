import { GENDER, NOTIFICATION_TYPE, ROLE } from '@/resources/enums';
import {
  IAddress,
  ICountry,
  IPinOption,
  ISecurityQuestionOption,
  ITimezone,
  IUser,
  IKYC
} from '@/resources/interfaces';

export class UserModel {
  firstName: string;
  lastName: string;
  email: string;
  gender: GENDER;
  country?: ICountry;
  phone: string;
  role?: ROLE;
  avatar?: string;
  legalNameOfBusiness: string;
  notificationSettings: NOTIFICATION_TYPE[];
  id: string;
  timezone?: ITimezone;
  address?: IAddress;
  securityQuestion: ISecurityQuestionOption;
  pin: IPinOption;
  isActive?: boolean;
  kyc?: IKYC;
  tfaEnabled?: boolean;
  lastLogin?: Date;
  publicProfile?: boolean;

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  get avatarUrl(): string {
    return this.avatar ? this.avatar : '/images/user.svg';
  }

  constructor(init?: IUser) {
    const data = {
      firstName: '',
      lastName: '',
      email: '',
      gender: GENDER.MALE,
      phone: '',
      legalNameOfBusiness: '',
      id: '',
      notificationSettings: [],
      securityQuestion: {
        enabled: false,
        authenticated: false
      },
      pin: {
        enabled: false,
        authenticated: false
      },
      publicProfile: false,
      ...init
    };

    this.id = data.id;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.gender = data.gender;
    this.country = data.country;
    this.phone = data.phone;
    this.role = data.role;
    this.avatar = data.avatar;
    this.legalNameOfBusiness = data.legalNameOfBusiness;
    this.notificationSettings = data.notificationSettings;
    this.timezone = data.timezone;
    this.address = data.address;
    this.securityQuestion = data.securityQuestion as ISecurityQuestionOption;
    this.pin = data.pin as IPinOption;
    this.isActive = data.isActive;
    this.kyc = data.kyc;
    this.lastLogin = data.lastLogin;
    this.publicProfile = data.publicProfile;
  }
}
