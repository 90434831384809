import React, { FC, useMemo } from 'react';
import { INVITATION_STATUS } from '@/resources/enums';
import Select from '@/components/forms/Select';
import Search from '@/components/forms/Search';
import DateRangePicker from '@/components/forms/DateRangePicker';
import './styles.scss';

export interface IInvitationSearchFilterProps {
  filter: any;
  onChangeFilter: (field: string, value: any) => void;
  user?: string;
}

const InvitationSearchFilter: FC<IInvitationSearchFilterProps> = ({
  filter,
  onChangeFilter
}) => {
  const statusOptions = useMemo(() => {
    return [
      { title: 'All', value: undefined },
      { title: 'Pending', value: INVITATION_STATUS.PENDING },
      { title: 'Accepted', value: INVITATION_STATUS.ACCEPTED }
    ];
  }, []);

  return (
    <div className="invoice-filter-container">
      <div className="cmb-10">
        <Search
          className="page-search-box"
          placeholder="Search for invoices by invoice ID, project title, employer name or freelancer name"
          value={filter.search}
          onChange={(e) => onChangeFilter('search', e.target.value)}
        />
      </div>

      <div className="filter-select-list">
        <div className="cmr-12 cmb-10">
          <DateRangePicker
            label="Dates"
            dateRange={filter.dateRange}
            setDateRange={(value) => onChangeFilter('dateRange', value)}
          />
        </div>

        <div className="cmb-10">
          <Select
            label="Status"
            value={filter.status}
            onChange={(value) => onChangeFilter('status', value)}
            options={statusOptions}
          />
        </div>
      </div>
    </div>
  );
};

export default InvitationSearchFilter;
