import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Modal, notification, Skeleton } from 'antd';
import { PERMISSION, USER_STATUS } from '@/resources/enums';
import { EmployerModel } from '@/resources/models';
import { EmployerApi } from '@/apis';
import { ROUTES } from '@/constants';
import { useCheckPermission } from '@/hooks';
import Button from '@/components/common/Button';
import Icon from '@/components/common/Icon';
import Switch from '@/components/common/Switch';
import EmployerInfo from '@/pages/Admin/EmployerDetails/UserDetails/EmployerInfo';
import Reviews from '@/pages/Admin/EmployerDetails/UserDetails/Reviews';
import ProjectList from '@/pages/Admin/EmployerDetails/UserDetails/ProjectList';
import NoData from '@/components/common/NoData';
import SelectTagModal from '@/pages/Admin/EmployerDetails/SelectTagModal';

interface IUserDetailsProps {
  loading: boolean;
  employer?: EmployerModel;
  userStatus?: USER_STATUS;
  isInternalUser: boolean;
  setUserStatus: (status: USER_STATUS) => void;
  setIsInternalUser: (val: boolean) => void;
  onRefresh: () => void;
}

const UserDetails: React.FC<IUserDetailsProps> = ({
  loading,
  employer,
  userStatus,
  isInternalUser,
  setUserStatus,
  setIsInternalUser,
  onRefresh
}) => {
  const history = useHistory();
  const params: { id: string } = useParams();
  const { id } = params;
  const checkPermission = useCheckPermission();

  const [deleting, setDeleting] = useState(false);
  const [blocking, setBlocking] = useState(false);
  const [togglingInternal, setTogglingInternal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const blockEmployer = () => {
    setBlocking(true);
    EmployerApi.block(id)
      .then(() => {
        setBlocking(false);
        setUserStatus(USER_STATUS.BLOCKED);
        notification.success({
          message: 'Employer is blocked successfully.'
        });
      })
      .catch((err) => {
        setBlocking(false);
        notification.error({ message: err.message });
      });
  };

  const unblockEmployer = () => {
    setBlocking(true);
    EmployerApi.unblock(id)
      .then(() => {
        setBlocking(false);
        setUserStatus(USER_STATUS.OPENED);
        notification.success({
          message: 'Employer is unblocked successfully.'
        });
      })
      .catch((err) => {
        setBlocking(false);
        notification.error({ message: err.message });
      });
  };

  const deleteEmployer = () => {
    setDeleting(true);
    EmployerApi.delete(id)
      .then(() => {
        setDeleting(false);
        history.push(ROUTES.ADMIN.EMPLOYERS.INDEX);
        notification.success({
          message: 'Employer is deleted successfully.'
        });
      })
      .catch((err) => {
        setDeleting(false);
        notification.error({ message: err.message });
      });
  };

  const showDeleteEmployerModal = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this employer?',
      centered: true,
      onOk: deleteEmployer
    });
  };

  const confirmBlockEmployer = () => {
    Modal.confirm({
      title: 'Are you sure you want to block this user?',
      centered: true,
      onOk: blockEmployer
    });
  };

  const confirmUnblockEmployer = () => {
    Modal.confirm({
      title: 'Are you sure you want to unblock this user?',
      centered: true,
      onOk: unblockEmployer
    });
  };

  const enableInternalUser = () => {
    setTogglingInternal(true);
    EmployerApi.update(id, { isInternal: true })
      .then(() => {
        setTogglingInternal(false);
        setIsInternalUser(true);
        notification.success({
          message: 'Employer is added to the internal user list.'
        });
        onRefresh();
      })
      .catch((err) => {
        setTogglingInternal(false);
        notification.error({ message: err.message });
      });
  };

  const disableInternalUser = () => {
    setTogglingInternal(true);
    EmployerApi.update(id, { isInternal: false })
      .then(() => {
        setTogglingInternal(false);
        setIsInternalUser(false);
        notification.success({
          message: 'Employer is removed from the internal user list.'
        });
      })
      .catch((err) => {
        setTogglingInternal(false);
        notification.error({ message: err.message });
      });
  };

  const confirmMakeInternalUser = () => {
    Modal.confirm({
      title: 'Are you sure you want to add this user to internal user list?',
      centered: true,
      onOk: openSelectTagModal
    });
  };

  const openSelectTagModal = () => {
    setIsOpen(true);
  };

  const confirmUnMakeInternalUser = () => {
    Modal.confirm({
      title:
        'Are you sure you want to remove this user from internal user list?',
      centered: true,
      onOk: disableInternalUser
    });
  };

  const onStatusToggle = async () => {
    if (userStatus === USER_STATUS.BLOCKED) {
      confirmUnblockEmployer();
    } else {
      confirmBlockEmployer();
    }
  };

  const onToggleInternalUser = () => {
    if (isInternalUser) {
      confirmUnMakeInternalUser();
    } else {
      confirmMakeInternalUser();
    }
  };

  return (
    <div>
      {checkPermission(PERMISSION.DELETE_USER) && (
        <div className="d-flex justify-content-end align-items-center cmb-17">
          <Button
            variant="outline"
            disabled={userStatus === USER_STATUS.DELETED}
            onClick={showDeleteEmployerModal}
            icon={<Icon name="delete" className="cmr-6" />}
            loading={deleting}
          >
            Delete
          </Button>

          <Switch
            className="cml-12"
            enabled={isInternalUser}
            onToggle={onToggleInternalUser}
            label="Internal User"
            loading={togglingInternal}
          />

          <Switch
            className="cml-12"
            enabled={userStatus !== USER_STATUS.BLOCKED}
            onToggle={onStatusToggle}
            label="Enabled"
            loading={blocking}
          />
        </div>
      )}

      <Skeleton loading={loading} paragraph={{ rows: 10 }} active={true}>
        {employer ? (
          <div className="">
            <div className="cmb-12">
              <EmployerInfo employer={employer} status={userStatus} />
            </div>

            <div className="cmb-12">
              <Reviews employer={employer} />
            </div>

            <div className="cmb-12">
              <ProjectList />
            </div>
          </div>
        ) : (
          <NoData title="Not found employer" />
        )}
      </Skeleton>

      <SelectTagModal
        employer={employer}
        onConfirm={enableInternalUser}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
};

export default UserDetails;
