import React, { FC, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  REMEMBER_ME_KEY
} from '@/constants';
import { getAccount, getTokens } from '@/selectors';
import { AuthApi } from '@/apis';
import { AdminModel } from '@/resources/models';
import { setTokens, setAccount } from '@/redux/actions';

export interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [cookies, setCookie, deleteCookie] = useCookies([]);
  const tokens = useSelector(getTokens);
  const account = useSelector(getAccount);
  const [orgToken, setOrgToken] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setTokens({
        accessToken: cookies[ACCESS_TOKEN_KEY] || '',
        refreshToken: cookies[REFRESH_TOKEN_KEY] || '',
        remember: cookies[REMEMBER_ME_KEY] || false
      })
    );
  }, []);

  useEffect(() => {
    if (tokens === undefined || orgToken === tokens?.accessToken) return;

    const maxAge = tokens.remember ? 3600 * 24 : undefined;
    setCookie(ACCESS_TOKEN_KEY, tokens.accessToken || '', {
      path: '/',
      maxAge
    });
    setCookie(REFRESH_TOKEN_KEY, tokens.refreshToken || '', {
      path: '/',
      maxAge
    });
    if (tokens.remember) setCookie(REMEMBER_ME_KEY, 1, { path: '/', maxAge });
    else deleteCookie(REMEMBER_ME_KEY);

    if (!tokens.accessToken) {
      dispatch(setAccount(null));
      setLoading(false);
      return;
    }

    if (!account) setLoading(true);
    setOrgToken(tokens.accessToken);

    AuthApi.me()
      .then((data) => {
        const account = new AdminModel(data);
        dispatch(setAccount(account));
        setLoading(false);
      })
      .catch(() => {
        deleteCookie(ACCESS_TOKEN_KEY);
        deleteCookie(REFRESH_TOKEN_KEY);
        setLoading(false);
      });
  }, [tokens, account, orgToken]);

  if (loading) {
    return (
      <div className="w-full h-full d-flex-center">
        <Spin size="large" />
      </div>
    );
  }

  return <>{children}</>;
};

AuthProvider.displayName = 'AuthProvider';

export default AuthProvider;
