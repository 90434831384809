import React, { useState } from 'react';
import Button from '@/components/common/Button';
import IconButton from '@/components/common/IconButton';
import KycDeclineDialog from '@/components/pages/KycDeclineDialog';

interface IKycDeclineButtonProps {
  message: string;
  buttonProps?: any;
  buttonLabel?: string;
  isIconButton?: boolean;
  type?: string;
  userId?: string;
  onConfirm: () => void;
}

const KycDeclineButton: React.FC<IKycDeclineButtonProps> = ({
  message,
  buttonProps,
  buttonLabel,
  isIconButton,
  type = 'warning',
  userId,
  onConfirm
}) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const handleConfirm = () => {
    setShowConfirmDialog(false);
    onConfirm();
  };

  return (
    <>
      {isIconButton ? (
        <IconButton
          onClick={() => setShowConfirmDialog(true)}
          {...(buttonProps || {})}
        />
      ) : (
        <Button
          onClick={() => setShowConfirmDialog(true)}
          {...(buttonProps || {})}
        >
          {buttonLabel}
        </Button>
      )}
      <KycDeclineDialog
        visible={showConfirmDialog}
        handleClose={() => setShowConfirmDialog(false)}
        onConfirm={handleConfirm}
        text={message}
        type={type}
        userId={userId}
      />
    </>
  );
};

export default KycDeclineButton;
