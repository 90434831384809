export class StringHelper {
  static parseHtml(html: string) {
    return html
      .split(/<\/p>/gi)
      .map((text) => text.replace(/<p[^>]*>/gi, ''))
      .join('<br />');
  }

  static plain2Html(plain: string) {
    return plain.replace(/\r?\n/g, '<br />');
  }
}
