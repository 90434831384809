import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { ContractModel } from '@/resources/models';
import { ContractApi } from '@/apis';
import { Modal, notification, Skeleton, Tabs } from 'antd';
import { CONTRACT_STATUS, PERMISSION } from '@/resources/enums';
import { useCheckPermission, useCheckRole } from '@/hooks';
import BackButton from '@/components/common/BackButton';
import NoData from '@/components/common/NoData';
import ContractInfo from '@/pages/Admin/ContractDetails/ContractInfo';
import Switch from '@/components/common/Switch';
import PageHeader from '@/components/common/PageHeader';
import { ROUTES } from '@/constants';
import ChangeLogSection from '@/components/pages/ChangeLogSection';
import './styles.scss';

const ContractDetails = () => {
  const history = useHistory();
  const { id, tab: activeTab } = useParams<{ id: string; tab: string }>();
  const checkPermission = useCheckPermission();

  const [contractStatus, setContractStatus] = useState<CONTRACT_STATUS>(
    CONTRACT_STATUS.ACTIVE
  );
  const [loading, setLoading] = useState(false);
  const [contract, setContract] = useState<ContractModel | undefined>(
    undefined
  );
  const [blocking, setBlocking] = useState(false);
  const { isAboveAdmin } = useCheckRole();

  const blockContract = () => {
    if (contract) {
      setBlocking(true);
      ContractApi.disable(contract.id)
        .then(({ status }) => {
          setContractStatus(status as CONTRACT_STATUS);
          if (status === CONTRACT_STATUS.DISABLED) {
            notification.success({
              message: 'Contract is disabled successfully.'
            });
          } else {
            notification.success({
              message: 'Contract is enabled successfully.'
            });
          }
        })
        .catch((err) => {
          notification.error({ message: err.message });
        })
        .finally(() => {
          setBlocking(false);
        });
    }
  };

  const confirmBlockContract = () => {
    Modal.confirm({
      title: 'Are you sure you want to disable this contract?',
      centered: true,
      onOk: blockContract
    });
  };

  const confirmUnblockContract = () => {
    Modal.confirm({
      title: 'Are you sure you want to enable this contract?',
      centered: true,
      onOk: blockContract
    });
  };

  const onStatusToggle = async () => {
    if (contractStatus === CONTRACT_STATUS.DISABLED) {
      confirmUnblockContract();
    } else {
      confirmBlockContract();
    }
  };

  useEffect(() => {
    setLoading(true);
    ContractApi.find(id)
      .then((data) => {
        setLoading(false);
        setContract(data);
        setContractStatus(data.status);
      })
      .catch((err) => {
        setLoading(false);
        notification.error({ message: err.message });
      });
  }, [id]);

  return (
    <div className="contract-details-page">
      <div className="cmb-17">
        <BackButton />
      </div>

      <PageHeader title="Contract Details">
        {checkPermission(PERMISSION.DISABLE_CONTRACT) && (
          <Switch
            className="cml-12"
            enabled={contractStatus !== CONTRACT_STATUS.DISABLED}
            onToggle={() => onStatusToggle()}
            label="Enabled"
            loading={blocking}
          />
        )}
      </PageHeader>

      <Tabs
        activeKey={activeTab}
        onChange={(tab) =>
          history.replace(`${ROUTES.ADMIN.CONTRACTS.PREFIX}/${id}/${tab}`)
        }
      >
        <Tabs.TabPane key="details" tabKey="details" tab="Details" />

        {isAboveAdmin && (
          <Tabs.TabPane key="events" tabKey="events" tab="Change Logs" />
        )}
      </Tabs>

      {activeTab === 'details' && (
        <Skeleton loading={loading} paragraph={{ rows: 10 }} active={true}>
          {contract ? (
            <ContractInfo contract={contract} status={contractStatus} />
          ) : (
            <NoData title="Not found contract" />
          )}
        </Skeleton>
      )}
      {isAboveAdmin && activeTab === 'events' && (
        <ChangeLogSection objectType="contract" objectId={id} />
      )}
    </div>
  );
};

export default ContractDetails;
