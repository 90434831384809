import React, { FC, useState } from 'react';
import { useHistory } from 'react-router';
import { Modal, notification } from 'antd';
import { ROUTES } from '@/constants';
import { FreelancerApi } from '@/apis';
import IconButton from '@/components/common/IconButton';

interface FreelancerActionsProps {
  freelancerId: string;
  refresh?: () => void;
}

const FreelancerActions: FC<FreelancerActionsProps> = ({
  freelancerId,
  refresh
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const deleteFreelancer = () => {
    setLoading(true);
    FreelancerApi.delete(freelancerId)
      .then(() => {
        setLoading(false);
        notification.success({
          message: 'Freelancer is deleted successfully.'
        });
        if (refresh) refresh();
      })
      .catch((err) => {
        setLoading(false);
        notification.error({ message: err.message });
      });
  };

  const showDeleteFreelancerModal = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this freelancer?',
      centered: true,
      onOk: deleteFreelancer
    });
  };

  return (
    <div className="d-flex align-items-center">
      <IconButton
        icon="edit"
        onClick={() =>
          history.push(
            `${ROUTES.ADMIN.FREELANCERS.PREFIX}/${freelancerId}/edit`
          )
        }
      />
      <IconButton
        icon="delete"
        onClick={showDeleteFreelancerModal}
        loading={loading}
      />
    </div>
  );
};

export default FreelancerActions;
