import Http from '@/apis/http';
import { IEmployer, ISearchApiResult } from '@/resources/interfaces';
import { EmployerModel } from '@/resources/models';

export class EmployerApi {
  static search(query: any): Promise<ISearchApiResult<EmployerModel>> {
    return Http.get('/admin/employers', query).then(
      (res: ISearchApiResult<any>) => {
        return {
          totalCount: res.totalCount,
          data: res.data.map((item) => new EmployerModel(item))
        };
      }
    );
  }

  static find(id: string): Promise<EmployerModel> {
    return Http.get(`/admin/employers/${id}`).then((res: IEmployer) => {
      return new EmployerModel(res);
    });
  }

  static delete(id: string) {
    return Http.delete(`/admin/employers/${id}`);
  }

  static block(id: string) {
    return Http.post(`/admin/employers/${id}/block`);
  }

  static unblock(id: string) {
    return Http.post(`/admin/employers/${id}/unblock`);
  }

  static getTopEmployers() {
    return Http.get(
      '/admin/employers/statistics/top-employers'
    ).then((res: IEmployer[]) => res.map((item) => new EmployerModel(item)));
  }

  static getNewEmployers() {
    return Http.get('/admin/employers/statistics/new-employers');
  }

  static update(id: string, body: any) {
    return Http.put(`/admin/employers/${id}`, body);
  }

  static create(data: any): Promise<EmployerModel> {
    return Http.post(`/admin/employers`, data).then((res: IEmployer) => {
      return new EmployerModel(res);
    });
  }

  static getActionHistories(id: string, filters: any): Promise<any> {
    return Http.get(`/admin/employers/${id}/activity-logs`, filters);
  }

  static exportEmployers(filters: any): Promise<any> {
    return Http.post(`/admin/employers/export`, filters);
  }
}
