import React, { FC } from 'react';
import { SafetyCertificateFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import './styles.scss';

interface IKycVerifiedIconProps {
  className?: string;
}

const KycVerifiedIcon: FC<IKycVerifiedIconProps> = ({ className }) => {
  return (
    <Tooltip
      color="#108af9"
      title="This freelancer's identity has been already verified by government id checks and visual verification"
      className={className}
    >
      <SafetyCertificateFilled className="kyc-verified-icon" />
    </Tooltip>
  );
};

export default KycVerifiedIcon;
