import React from 'react';
import { Skeleton } from 'antd';
import './styles.scss';

interface IBalanceStatistics {
  statistics: any;
  loading: boolean;
}

const BalanceStatistics: React.FC<IBalanceStatistics> = ({
  statistics,
  loading = false
}) => {
  const formatNumber = (value: number) => Math.round((value || 0) * 100) / 100;

  return (
    <div className="custom-card cp-0">
      <div className="d-flex">
        <div className="statistic-card">
          <h2>Total User Balances</h2>

          <div className="d-flex justify-content-between">
            <div>Total balance</div>
            <div>${formatNumber(statistics.totalRealBalance)}</div>
          </div>
          <div className="d-flex justify-content-between">
            <div>Total pending balance</div>
            <div>${formatNumber(statistics.totalPendingBalance)}</div>
          </div>
          <div className="d-flex justify-content-between">
            <div>Amount from internal employers</div>
            <div>${formatNumber(statistics.internalAmount)}</div>
          </div>
          <div className="d-flex justify-content-between">
            <div>Amount received via PayPal</div>
            <div>${formatNumber(statistics.paypalBalance)}</div>
          </div>
          <div className="d-flex justify-content-between">
            <div>Amount received via CreditCard</div>
            <div>${formatNumber(statistics.receivedCreditCard || 0)}</div>
          </div>
          <div className="d-flex justify-content-between">
            <div>Total paid invoices</div>
            <div>${formatNumber(statistics.paidInvoiceAmount)}</div>
          </div>
          <div className="d-flex justify-content-between">
            <div>Total pending invoices</div>
            <div>${formatNumber(statistics.pendingInvoiceAmount)}</div>
          </div>
          <div className="d-flex justify-content-between">
            <div>Total pending withdraws</div>
            <div>${formatNumber(statistics.pendingWithdrawBalance)}</div>
          </div>
        </div>
        <div className="statistic-card">
          <h2>Total Platform Revenue</h2>

          <div className="d-flex justify-content-between">
            <div>Revenue</div>
            <div>${formatNumber(statistics.totalRevenue)}</div>
          </div>
          <div className="d-flex justify-content-between">
            <div>Revenue (From Employers)</div>
            <div>${formatNumber(statistics.employerRevenue)}</div>
          </div>
          <div className="d-flex justify-content-between">
            <div>Revenue (From Freelancers)</div>
            <div>${formatNumber(statistics.freelancerRevenue)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BalanceStatistics;
