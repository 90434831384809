import React, { FC, useEffect, useMemo, useState } from 'react';
import { notification, Table } from 'antd';
import { Link } from 'react-router-dom';
import { FreelancerModel } from '@/resources/models';
import { FreelancerApi } from '@/apis';
import { FREELANCER_DETAILS_ROUTES, ROUTES } from '@/constants';
import './styles.scss';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    render: (title: string, record: any) => (
      <Link
        className="link-tag"
        to={`${ROUTES.ADMIN.FREELANCERS.PREFIX}/${record.id}/${FREELANCER_DETAILS_ROUTES.DETAILS}`}
      >
        {title}
      </Link>
    )
  },
  { title: 'Email', dataIndex: 'email' },
  { title: 'Hourly Rate', dataIndex: 'price' },
  {
    title: 'Total Earned',
    dataIndex: 'totalEarned',
    render: (value: string) => <div>{`$${value}`}</div>
  }
];

const TopFreelancers: FC = () => {
  const [freelancers, setFreelancers] = useState<FreelancerModel[]>([]);
  const [loading, setLoading] = useState(false);

  const dataSource = useMemo(() => {
    return freelancers.map((freelancer) => ({
      id: freelancer.id,
      name: freelancer.user?.fullName,
      email: freelancer.user?.email,
      price: freelancer.hourlyPrice,
      totalEarned: freelancer.totalEarned.toFixed(2)
    }));
  }, [freelancers]);

  useEffect(() => {
    setLoading(true);
    FreelancerApi.getTopFreelancers()
      .then((res) => {
        setLoading(false);
        setFreelancers(res);
      })
      .catch((err) => {
        setLoading(false);
        notification.error({ message: err.message });
      });
  }, []);

  return (
    <div className="top-freelancers-card">
      <h3 className="text-lg mb-3">Top 10 Freelancers</h3>

      <div className="table-wrapper">
        <Table
          rowKey="id"
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default TopFreelancers;
