import React, { ChangeEvent, FC } from 'react';
import './styles.scss';

export interface TextAreaProps {
  label?: string;
  type?: string;
  placeholder?: string;
  error?: string;
  required?: boolean;
  value?: string | number | readonly string[];
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  name?: string;
  className?: string;
  maxLength?: number;
  rows?: number;
  description?: string;
}

const TextArea: FC<TextAreaProps> = ({
  label,
  value,
  onChange,
  placeholder,
  error,
  required,
  name,
  className = '',
  maxLength = 1000,
  rows = 5,
  description
}) => {
  return (
    <div
      className={`custom-textarea-container d-flex flex-column w-100 ${className}`}
    >
      <span className="custom-textarea-label text-sm mb-0">
        {label} {required ? '*' : ''}
      </span>
      <div className="textarea-wrapper">
        <textarea
          name={name}
          className={'custom-textarea w-100 ' + (error ? 'error' : '')}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          maxLength={maxLength}
          rows={rows}
        />
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <p className="text-red text-sm mb-0">{error || ''}</p>

        {!!description && (
          <p className="text-sm text-right text-grey mb-0">{description}</p>
        )}
      </div>
    </div>
  );
};

export default TextArea;
