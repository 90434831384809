import React from 'react';
import TfaCard from '@/components/pages/Settings/TfaCard';
import PageHeader from '@/components/common/PageHeader';

const TFA = () => {
  return (
    <div>
      <PageHeader title="Security" />

      <TfaCard />
    </div>
  );
};

export default TFA;
