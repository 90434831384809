import React, { FC, useMemo } from 'react';
import Badge, { BADGE_STATUS } from '@/components/common/Badge';
import { CONTRACT_STATUS } from '@/resources/enums';
import { NameHelper } from '@/helpers';

export interface IContractStatusProps {
  status: string;
  className?: string;
}

const ContractStatus: FC<IContractStatusProps> = ({
  status,
  className = ''
}) => {
  const badgeStatus = useMemo(() => {
    if (status === CONTRACT_STATUS.PENDING || status === CONTRACT_STATUS.PAUSED)
      return BADGE_STATUS.WARNING;

    if (status === CONTRACT_STATUS.COMPLETED) return BADGE_STATUS.SUCCESS;

    if (
      status === CONTRACT_STATUS.DISABLED ||
      status === CONTRACT_STATUS.CANCELLED ||
      status === CONTRACT_STATUS.REJECTED
    )
      return BADGE_STATUS.DANGER;

    return BADGE_STATUS.NORMAL;
  }, [status]);

  const badgeText = useMemo(() => {
    return NameHelper.getContractStatusLabel(status);
  }, [status]);

  return <Badge className={className} title={badgeText} status={badgeStatus} />;
};

export default ContractStatus;
