import Http from '@/apis/http';
import { ISearchApiResult, IBankAccount } from '@/resources/interfaces';
import { BankAccountModel } from '@/resources/models';

export class BankAccountApi {
  static search(query: any = {}): Promise<ISearchApiResult<BankAccountModel>> {
    return Http.get('/admin/bank-accounts', query).then(
      (res: ISearchApiResult<IBankAccount>) => {
        return {
          totalCount: res.totalCount,
          data: res.data.map((item) => new BankAccountModel(item))
        };
      }
    );
  }

  static find(id: string): Promise<BankAccountModel> {
    return Http.get(`/admin/bank-accounts/${id}`).then((res: IBankAccount) => {
      return new BankAccountModel(res);
    });
  }

  static create(data: any): Promise<BankAccountModel> {
    return Http.post(`/admin/bank-accounts`, data).then((res: IBankAccount) => {
      return new BankAccountModel(res);
    });
  }

  static update(id: string, body: any) {
    return Http.put(`/admin/bank-accounts/${id}`, body);
  }

  static setPrimaryAccount(id: string, body: any) {
    return Http.post(`/admin/bank-accounts/${id}/primary`, body);
  }

  static delete(id: string) {
    return Http.delete(`/admin/bank-accounts/${id}`);
  }
}
