import React, { FC, useMemo } from 'react';
import { Divider } from 'antd';
import { DELIVERY_TYPE } from '@/resources/enums';
import { IMilestone } from '@/resources/interfaces';
import MilestonesView from '@/pages/Admin/ContractDetails/ContractInfo/MilestonesView';
import './styles.scss';

export interface IDeliveryDetailsViewProps {
  deliveryType: DELIVERY_TYPE;
  deliveryPrice: number;
  milestones?: IMilestone[];
  deliveryTime?: number;
}

const DeliveryDetailsView: FC<IDeliveryDetailsViewProps> = ({
  deliveryType,
  deliveryPrice,
  milestones,
  deliveryTime
}) => {
  const priceUnit = useMemo(() => {
    return deliveryType === DELIVERY_TYPE.HOURLY ? '/Hr' : '';
  }, [deliveryType]);

  const price = useMemo(() => {
    let totalPrice = 0;
    if (deliveryType !== DELIVERY_TYPE.MILESTONE) {
      totalPrice = deliveryPrice;
    } else {
      if (milestones && milestones.length) {
        totalPrice = milestones.reduce((prev: number, curr: IMilestone) => {
          return Number(prev) + Number(curr && curr.amount ? curr.amount : 0);
        }, 0);
      }
    }
    return totalPrice;
  }, [deliveryPrice, milestones]);

  return (
    <div className="delivery-details-container">
      <p className="text-heading3 cmb-9">Delivery Details</p>

      <div className="delivery-details-wrapper">
        {deliveryType === DELIVERY_TYPE.HOURLY ? (
          <>
            <p className="text-md text-black cmb-5">
              What is the rate you'd like to bid for this project?
            </p>
            <p className="text-md text-bold cmb-19">
              {deliveryPrice.toFixed(2)} USD/Hr
            </p>
          </>
        ) : (
          <>
            {deliveryType === DELIVERY_TYPE.PROJECT && (
              <>
                <p className="text-md text-black cmb-5">
                  How long will it take you to deliver on what's requested?
                </p>
                <p className="text-md text-bold cmb-12">{deliveryTime} Day</p>
              </>
            )}

            <p className="text-md text-black cmb-5">
              How would you like to be paid?
            </p>
            <p className="text-md text-bold cmb-19">
              <span className="text-capitalize">{deliveryType} Delivery</span>
            </p>
          </>
        )}

        {deliveryType === DELIVERY_TYPE.MILESTONE && (
          <>
            <Divider className="cmy-12" />
            <MilestonesView milestones={milestones || []} />
            <Divider className="cmy-12" />
          </>
        )}

        <div className="price-section">
          <div className="d-flex align-items-center justify-content-between cmb-5">
            <span className="text-md text-black">
              {deliveryType !== DELIVERY_TYPE.HOURLY
                ? 'Total Project Price'
                : 'Hourly price'}
            </span>
            <span className="text-md text-black">
              ${Number(price).toFixed(2)}
              {priceUnit}
            </span>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <span className="text-md text-black">Service fees</span>
            <span className="text-md text-black">
              -${(Number(price) * 0.1).toFixed(2)}
              {priceUnit}
            </span>
          </div>
          <div className="total-price-container">
            <span className="text-lg text-black">Receive</span>
            <span className="text-lg text-black">
              ${(Number(price) * 0.9).toFixed(2)}
              {priceUnit}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryDetailsView;
