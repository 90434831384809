import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import { Modal, notification, Skeleton, Table } from 'antd';
import moment from 'moment';
import { debounce } from 'lodash';
import { FREELANCER_DETAILS_ROUTES, ROUTES } from '@/constants';
import { FreelancerApi, TagApi } from '@/apis';
import { useCheckPermission } from '@/hooks';
import { IQueryField, ObjectHelper, QueryHelper } from '@/helpers';
import { FreelancerModel, TagModel } from '@/resources/models';
import { PERMISSION, USER_STATUS } from '@/resources/enums';
import UserSearchFilter, {
  IUserSearchFilter,
  USER_FILTER_TYPE
} from '@/components/filters/UserSearchFilter';
import Badge, { BADGE_STATUS } from '@/components/common/Badge';
import Pagination from '@/components/common/Pagination';
import Avatar from '@/components/common/Avatar';
import UserStatus from '@/components/pages/UserStatus';
import FilterChipList from '@/components/common/filters/FilterChipList';
import Tooltip from '@/components/common/Tooltip';
import Button from '@/components/common/Button';
import Icon from '@/components/common/Icon';
import FreelancerActions from '@/pages/Admin/Freelancers/FreelancerActions';
import '../Employers/styles.scss';
import PageHeader from '@/components/common/PageHeader';

const getTagInfo = (freelancer: FreelancerModel) => {
  if (
    freelancer?.user?.kyc?.reviewStatus === 'completed' &&
    freelancer?.user?.kyc?.reviewAnswer === 'GREEN'
  )
    return <Badge title="Verified" status={BADGE_STATUS.SUCCESS} />;

  if (
    freelancer?.user?.kyc?.reviewStatus === 'completed' &&
    freelancer?.user?.kyc?.reviewAnswer === 'RED'
  )
    return <Badge title="Declined" status={BADGE_STATUS.DANGER} />;

  return <Badge title="Pending" status={BADGE_STATUS.WARNING} />;
};

const filterFields: IQueryField[] = [
  { name: 'title', type: 'string', default: '' },
  { name: 'country', type: 'country-array', default: [] },
  { name: 'page', type: 'number', default: 1 },
  { name: 'perPage', type: 'number', default: 10 },
  { name: 'status', type: 'string', default: undefined },
  { name: 'tag', type: 'string', default: '' },
  { name: 'sort', type: 'string', default: '-createdAt' },
  { name: 'isInternal', type: 'boolean', default: false },
  { name: 'withBalance', type: 'boolean', default: false },
  { name: 'withSumsubReview', type: 'boolean', default: false }
];

const Freelancers: FC = () => {
  const checkPermission = useCheckPermission();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [totalFreelancerCount, setTotalFreelancerCount] = useState(0);
  const [freelancers, setFreelancers] = useState<FreelancerModel[]>([]);
  const location = useLocation();
  const [tags, setTags] = useState<TagModel[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const filter = useMemo(() => {
    return QueryHelper.parseQuery(location.search, filterFields, true);
  }, [location.search]);

  const searchFreelancers = useCallback(
    (filter: IUserSearchFilter) => {
      const query: any = {
        search: filter.title,
        country: filter.country,
        page: filter.page,
        perPage: filter.perPage,
        sort: filter.sort,
        status: filter.status,
        tag: filter.tag,
        isInternal: filter.isInternal,
        withBalance: filter.withBalance,
        withSumsubReview: filter.withSumsubReview,
        expands: ['user.country']
      };

      setLoading(true);
      FreelancerApi.search(ObjectHelper.trimQuery(query))
        .then((res) => {
          setFreelancers(res.data);
          setTotalFreelancerCount(res.totalCount);
        })
        .catch((err) => {
          notification.error({ message: err.message });
        })
        .finally(() => setLoading(false));
    },
    [setFreelancers, setTotalFreelancerCount]
  );

  const debounceFilter = useMemo(() => {
    return debounce(searchFreelancers, 500);
  }, [searchFreelancers]);

  useEffect(() => {
    debounceFilter(filter);
  }, [filter]);

  const onChangeFilter = (field: string, value: any) => {
    const newFilter = QueryHelper.getFilterToQuery(filter, field, value);

    const query = QueryHelper.stringifyQuery(newFilter);
    history.replace(`${location.pathname}?${query}`);
  };

  const clearFilter = () => {
    history.replace(location.pathname);
  };

  const getTabLabel = (tagId: string) => {
    return tags.find((item) => item.id === tagId)?.name;
  };

  useEffect(() => {
    TagApi.search().then((res) => {
      setTags(res.data);
    });
  }, []);

  const dataSource = useMemo(() => {
    return freelancers.map((item) => {
      return {
        ...item,
        key: item.id,
        fullName: item.user?.fullName,
        email: item.user?.email,
        country: item.user?.country?.name,
        status:
          item.status === USER_STATUS.OPENED && !item.isSignupCompleted
            ? 'inactive'
            : item.status,
        avatarUrl: item.user?.avatar,
        availability: item.availability.type,
        reviewStatus: item?.user?.kyc?.reviewStatus,
        balance: item?.balance,
        tags: item?.tags || [],
        createdAt: moment(item.createdAt).format('DD MMM, YYYY')
      };
    });
  }, [freelancers]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'fullName',
      render: (title: string, record: any) => (
        <Link
          className="d-flex align-items-center"
          to={`${ROUTES.ADMIN.FREELANCERS.PREFIX}/${record.id}/${FREELANCER_DETAILS_ROUTES.DETAILS}`}
        >
          <Avatar
            src={record?.user?.avatar}
            name={record?.user?.fullName}
            size="2.5rem"
            className="cmr-6"
            round={true}
          />

          <Tooltip title={title}>
            <div>
              <div className="d-flex align-items-center cmb-2">
                <p className="mb-0 text-dark">{title}</p>
                <div className="d-flex align-items-center">
                  {record.tags.map((item: string, index: number) => (
                    <Badge
                      className="cml-6"
                      title={item}
                      status={BADGE_STATUS.SUCCESS}
                      key={index}
                    />
                  ))}
                </div>
              </div>
              <p className="text-xs mb-0 text-blue">{record.email}</p>
            </div>
          </Tooltip>
        </Link>
      )
    },
    {
      title: 'Country',
      dataIndex: 'country'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: string) => <UserStatus status={status} />
    },
    {
      title: 'Identity Verified',
      dataIndex: 'reviewStatus',
      render: (reviewStatus: string, freelancer: any) => {
        return getTagInfo(freelancer);
      }
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      render: (balance: number) => <div>${balance?.toFixed(2)}</div>
    },
    {
      title: 'Hourly Price',
      dataIndex: 'hourlyPrice',
      render: (value: string) => <div className="text-center">${value}</div>
    },
    {
      title: 'Member Since',
      dataIndex: 'createdAt'
    },
    ...(checkPermission([PERMISSION.EDIT_USER, PERMISSION.DELETE_USER])
      ? [
          {
            title: 'Actions',
            dataIndex: 'id',
            render: (id: string) => {
              return (
                <FreelancerActions
                  freelancerId={id}
                  refresh={() => onChangeFilter('page', 1)}
                />
              );
            }
          }
        ]
      : [])
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedIds(selectedRowKeys as string[]);
    }
  };

  const confirmBlockFreelancer = () => {
    Modal.confirm({
      title: `Are you sure you want to block the selected ${selectedIds.length} freelancers?`,
      centered: true,
      onOk: blockFreelancers
    });
  };

  const confirmUnblockFreelancer = () => {
    Modal.confirm({
      title: `Are you sure you want to unblock the selected ${selectedIds.length} freelancers?`,
      centered: true,
      onOk: unblockFreelancers
    });
  };

  const confirmDeleteFreelancers = () => {
    Modal.confirm({
      title: `Are you sure you want to delete the selected ${selectedIds.length} freelancers?`,
      centered: true,
      onOk: onBulkDelete
    });
  };

  const blockFreelancers = async () => {
    if (!selectedIds.length) return;
    await FreelancerApi.bulkBlock(selectedIds);
    notification.success({
      message: 'Freelancers are blocked successfully.'
    });
    searchFreelancers(filter);
    setSelectedIds([]);
  };
  const unblockFreelancers = async (checked: boolean) => {
    if (!selectedIds.length) return;
    await FreelancerApi.bulkUnblock(selectedIds);
    notification.success({
      message: 'Freelancers are unblocked successfully.'
    });
    searchFreelancers(filter);
    setSelectedIds([]);
  };

  const onBulkDelete = async () => {
    if (!selectedIds.length) return;
    await FreelancerApi.bulkDelete(selectedIds);
    searchFreelancers(filter);
    setSelectedIds([]);
  };

  return (
    <div className="admin-users-page">
      <PageHeader title="Freelancers" />

      <UserSearchFilter
        type={USER_FILTER_TYPE.FREELANCER}
        filter={filter}
        onChangeFilter={onChangeFilter}
      />

      <FilterChipList
        filter={filter}
        onFilterChange={onChangeFilter}
        clearFilter={clearFilter}
        filterType="user"
        getTabLabel={getTabLabel}
      />

      {checkPermission(PERMISSION.CREATE_USER) && (
        <div className="d-flex align-items-end justify-content-end">
          <Button
            onClick={confirmUnblockFreelancer}
            disabled={!selectedIds.length}
          >
            Activate
          </Button>
          <Button
            variant="outline"
            className="border-danger text-danger mx-2"
            onClick={confirmBlockFreelancer}
            disabled={!selectedIds.length}
          >
            Deactivate
          </Button>
          <Button
            variant="outline"
            icon={<Icon name="delete" className="cmr-6" />}
            className="border-danger text-danger mx-2"
            onClick={confirmDeleteFreelancers}
            disabled={!selectedIds.length}
          >
            Delete
          </Button>
          <Button
            icon={<Icon name="plus" className="cmr-10" size="small" />}
            onClick={() => history.push(ROUTES.ADMIN.FREELANCERS.CREATE)}
          >
            Add New Freelancer
          </Button>
        </div>
      )}

      <Skeleton loading={loading} active={true} paragraph={{ rows: 0 }}>
        <p className="mt-3 mb-2 text-md">
          {totalFreelancerCount} freelancers found
        </p>
      </Skeleton>

      <Table
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: selectedIds,
          ...rowSelection
        }}
        loading={loading}
        className="custom-ant-table"
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />

      <div className="d-flex-center cmt-10">
        <Pagination
          total={totalFreelancerCount}
          current={filter.page}
          pageSize={filter.perPage}
          onChange={(page) => onChangeFilter('page', page)}
        />
      </div>
    </div>
  );
};

export default Freelancers;
