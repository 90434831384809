import React, { ChangeEvent, FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getCountries } from '@/selectors';
import { ROLE, USER_STATUS } from '@/resources/enums';
import Search from '@/components/forms/Search';
import Select from '@/components/forms/Select';
import Switch from 'src/components/common/Switch';
import TagSelect from '@/components/pages/TagSelect';
import './styles.scss';

export interface IUserSearchFilter {
  title: string;
  country: string[];
  status?: USER_STATUS;
  page?: number;
  perPage?: number;
  sort?: string;
  role?: ROLE;
  isInternal?: boolean;
  tag?: string;
  withBalance?: boolean;
  withSumsubReview?: boolean;
}

export enum USER_FILTER_TYPE {
  FREELANCER = 'freelancer',
  EMPLOYER = 'employer',
  USER = 'user'
}

const statusOptions = [
  { title: 'All', value: undefined },
  { title: 'Opened', value: USER_STATUS.OPENED },
  { title: 'Blocked', value: USER_STATUS.BLOCKED },
  { title: 'Closed', value: USER_STATUS.CLOSED }
];

const roleOptions = [
  { title: 'All', value: undefined },
  { title: 'Admin', value: ROLE.ADMIN },
  { title: 'QA Tester', value: ROLE.QA_TESTER },
  { title: 'Marketing Manager', value: ROLE.MARKETING_MANAGER }
];

const sortOptions = [
  { title: 'Date', value: '-createdAt' },
  { title: 'Rating', value: '-rating' }
];

export interface IUserSearchFilterProps {
  type?: USER_FILTER_TYPE;
  filter: IUserSearchFilter;
  onChangeFilter: (field: string, value: any) => void;
}

const UserSearchFilter: FC<IUserSearchFilterProps> = ({
  filter,
  onChangeFilter,
  type
}) => {
  const { title, country, status, sort, role, tag } = filter;
  const countries = useSelector(getCountries);

  const countryOptions = useMemo(() => {
    return countries.map((item) => ({
      title: item.name,
      value: item.id
    }));
  }, [countries]);

  return (
    <div className="user-filter-container">
      <div className="cmb-10">
        <Search
          className="page-search-box"
          value={title}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChangeFilter('title', e.target.value)
          }
          placeholder="Search for users by name or email"
        />
      </div>

      <div className="filter-select-list">
        <div className="cmr-12 cmb-10">
          <Select
            className="country-filter-select"
            label="Country"
            searchable={true}
            multiSelect={true}
            showChipList={false}
            value={country}
            onChange={(value) => onChangeFilter('country', value)}
            options={countryOptions}
          />
        </div>

        {type !== USER_FILTER_TYPE.USER && (
          <div className="cmr-12 cmb-10">
            <Select
              className="user-filter-select"
              label="Status"
              value={status}
              onChange={(value) => onChangeFilter('status', value)}
              options={statusOptions}
            />
          </div>
        )}

        {type !== USER_FILTER_TYPE.USER && (
          <div className="cmb-10">
            <Select
              className="user-filter-select"
              label="Sort by"
              value={sort}
              onChange={(value) => onChangeFilter('sort', value)}
              options={sortOptions}
            />
          </div>
        )}
        {type === USER_FILTER_TYPE.FREELANCER && (
          <div className="cml-12 cmb-10">
            <TagSelect
              label="Tag"
              value={tag || ''}
              onChange={(value) => onChangeFilter('tag', value)}
            />
          </div>
        )}

        {type === USER_FILTER_TYPE.USER && (
          <div className="cmb-10">
            <Select
              className="user-filter-select"
              label="Role"
              value={role}
              onChange={(value) => onChangeFilter('role', value)}
              options={roleOptions}
            />
          </div>
        )}

        {(type === USER_FILTER_TYPE.EMPLOYER ||
          type === USER_FILTER_TYPE.FREELANCER) && (
          <div className="cml-12 cmb-15">
            <Switch
              enabled={filter.isInternal}
              onToggle={() => onChangeFilter('isInternal', !filter.isInternal)}
              label="Show Internal Data"
              className="user-filter-select"
            />
          </div>
        )}
        {type === USER_FILTER_TYPE.FREELANCER && (
          <div className="cml-12 cmb-15">
            <Switch
              enabled={filter.withBalance}
              onToggle={() =>
                onChangeFilter('withBalance', !filter.withBalance)
              }
              label="With Balance"
              className="user-filter-select"
            />
          </div>
        )}
        {type === USER_FILTER_TYPE.FREELANCER && (
          <div className="cml-12 cmb-15">
            <Switch
              enabled={filter.withSumsubReview}
              onToggle={() =>
                onChangeFilter('withSumsubReview', !filter.withSumsubReview)
              }
              label="With Sumsub review"
              className="user-filter-select"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default UserSearchFilter;
