import React, { FC, useEffect, useState } from 'react';
import { Modal } from 'antd';
import Button from '@/components/common/Button';
import './styles.scss';
import Icon from '@/components/common/Icon';
import Select from '@/components/forms/Select';
import TextArea from '@/components/forms/TextArea';
import { IBank } from '@/resources/interfaces';

export interface IBankDeclineModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (declineReasons: string[], declineNote: string) => void;
  loading?: boolean;
  bank?: IBank;
}

const possibleReasons = [
  'Bank information is invalid',
  'Beneficiary address is invalid',
  'Beneficiary name is different',
  'Currency is invalid',
  'Other reason'
];

const BankDeclineModal: FC<IBankDeclineModalProps> = ({
  open,
  onClose,
  onConfirm,
  bank,
  loading
}) => {
  const [declineReasons, setDeclineReasons] = useState<string[]>([]);
  const [declineNote, setDeclineNote] = useState('');

  const onDecline = async () => {
    if (!declineReasons.length) {
      return;
    }
    onConfirm(declineReasons, declineNote);
    onClose();
  };

  useEffect(() => {
    if (open) {
      setDeclineReasons(bank?.declineReasons || []);
      setDeclineNote(bank?.declineNote || '');
    }
  }, [bank, open]);

  return (
    <Modal
      visible={open}
      onCancel={onClose}
      className="confirm-dialog dialog"
      footer={null}
      width={600}
    >
      <div className="dialog-header">
        <h3 className="py-2 mb-0">
          Are you sure to decline this payment method?
        </h3>
        <span className="close-btn" onClick={onClose}>
          <Icon name="close-big" />
        </span>
      </div>
      <div className="dialog-body cpt-15">
        <div>
          <div className="cmb-6">
            <Select
              label="Declined Reason"
              placeholder="Select Declined Reason"
              value={declineReasons}
              onChange={setDeclineReasons}
              options={possibleReasons.map((label) => ({
                value: label,
                title: label
              }))}
              multiSelect={true}
            />
          </div>
          <div className="cmb-6">
            <TextArea
              label="Additional note"
              value={declineNote}
              onChange={(e) => setDeclineNote(e.target.value)}
            />
          </div>
          <div className="cmt-10 d-flex align-items-center justify-content-end">
            <Button
              type="button"
              className="mr-3 text-danger border-danger"
              variant="outline"
              onClick={onDecline}
              loading={loading}
            >
              Decline
            </Button>
            <Button onClick={onClose} variant="outline">
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BankDeclineModal;
