import React from 'react';
import { Rate } from 'antd';
import { ContractModel, ReviewModel } from '@/resources/models';
import { DateHelper } from '@/helpers';
import { PAYMENT_TYPE } from '@/resources/enums';
import LongText from '@/components/common/LongText';
import './styles.scss';

interface IReviewItemProps {
  review: ReviewModel;
  showProject?: boolean;
}

const ReviewItem: React.FC<IReviewItemProps> = ({
  review,
  showProject = true
}) => {
  const contract = review.contract as ContractModel;

  return (
    <div className="freelancer-history-item">
      <div className="review-detail">
        {showProject && (
          <p className="text-lg text-blue text-bold cmb-4">{contract.title}</p>
        )}
        <div className="review-content-wrapper">
          <div className="rate-container">
            <Rate
              className="cmb-4"
              allowHalf={true}
              disabled={true}
              defaultValue={review.averageRating}
            />

            {!!contract && (
              <p className="text-xs mb-0">
                {DateHelper.formatDate(contract.createdAt, 'MMM YYYY')} -{' '}
                {DateHelper.formatDate(contract.endAt, 'MMM YYYY')}
              </p>
            )}
            {contract.paymentType === PAYMENT_TYPE.FIXED ? (
              <p className="text-xs mb-0">
                Fixed Price:{' '}
                <span className="text-bold">
                  ${Number(contract.price || 0).toFixed(2)}
                </span>
              </p>
            ) : (
              <>
                <p className="text-xs mb-0">
                  Hourly Price:{' '}
                  <span className="text-bold">
                    ${Number(contract?.price || 0).toFixed(2)}
                  </span>
                  /hr
                </p>

                <p className="text-xs mb-0">
                  Total Hours: {review.totalHours || 0}
                </p>
              </>
            )}
          </div>

          <LongText text={review.comment} maxLength={120} />
        </div>
      </div>
    </div>
  );
};

export default ReviewItem;
