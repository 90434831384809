import { IAttachment, IMessage } from '@/resources/interfaces';

export class MessageModel {
  id: string;
  channel: string;
  sender: string;
  receiver: string;
  content: string;
  favourite: string[];
  read: boolean;
  spam: string[];
  attachment?: IAttachment;
  createdAt: string;

  constructor(data: IMessage) {
    this.id = data.id || '';
    this.channel = data.channel || '';
    this.sender = data.sender || '';
    this.receiver = data.receiver || '';
    this.content = data.content || '';
    this.favourite = data.favourite || [];
    this.read = data.read;
    this.spam = data.spam || [];
    this.createdAt = data.createdAt || '';
    this.attachment = data.attachment;
  }
}
