import React, { FC, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { AuthApi } from '@/apis';
import Input from '@/components/forms/Input';
import Button from '@/components/common/Button';
import './styles.scss';
import { ROUTES } from 'src/constants';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { notification } from 'antd';
import queryString from 'query-string';

export interface ILoginForm {
  email: string;
  password: string;
  rememberMe: boolean;
}

const ResetPassword: FC = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const { token = '' } = queryParams;
  const history = useHistory();

  const resetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required('Password is required.')
      .matches(/[a-zA-Z]/, 'Password should contain at least one alphabet.')
      .matches(/\d/, 'Password should contain at least one number.')
      .min(8, 'Password should contain at least 8 characters.'),
    passwordConfirm: Yup.string()
      .required('Password confirmation is required.')
      .matches(
        /[a-zA-Z]/,
        'Password confirmation should contain at least one alphabet.'
      )
      .matches(
        /\d/,
        'Password confirmation should contain at least one number.'
      )
      .min(8, 'Password confirmation should contain at least 8 characters.')
      .oneOf([Yup.ref('password')], 'Password is not matched.')
  });

  const handleSubmit = (values: any) => {
    setLoading(true);
    AuthApi.resetPassword(values.password, token as string)
      .then(() => {
        setLoading(false);
        notification.success({
          message: 'Password has been reset successfully.'
        });
        history.push(ROUTES.AUTH.LOGIN);
      })
      .catch((err) => {
        setLoading(false);

        if (err.code === 400) {
          notification.error({
            message: 'Token is expired.'
          });
        } else {
          notification.error({ message: err.message });
        }

        history.replace(ROUTES.AUTH.FORGOT_PASSWORD);
      });
  };

  return (
    <div className="login-page py-5">
      <div className="logo-wrapper">
        <img src="/images/diligentspace_logo.svg" alt="logo" />
      </div>

      <div className="login-form-wrapper">
        <h1 className="page-title text-center text-4xl font-weight-normal cmb-16">
          Reset Password
        </h1>

        <p className="text-md cmb-16">Please input your new password.</p>

        <Formik
          initialValues={{ password: '', passwordConfirm: '' }}
          validationSchema={resetPasswordSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => (
            <Form className="login-form" onSubmit={handleSubmit}>
              <Input
                name="password"
                type="password"
                label="Password"
                value={values.password}
                onChange={handleChange}
                error={
                  errors.password && touched.password ? errors.password : ''
                }
                placeholder="Password"
                required={true}
              />
              <Input
                name="passwordConfirm"
                type="password"
                label="Confirm password"
                value={values.passwordConfirm}
                onChange={handleChange}
                error={
                  errors.passwordConfirm && touched.passwordConfirm
                    ? errors.passwordConfirm
                    : ''
                }
                placeholder="Confirm password"
                required={true}
              />

              <Button
                className="w-100 cmt-16 cmb-12"
                type="submit"
                loading={loading}
              >
                Send
              </Button>
            </Form>
          )}
        </Formik>
        <p className="text-center">
          Already have an account?&nbsp;
          <Link className="sign-in-link" to={ROUTES.AUTH.LOGIN}>
            Sign in
          </Link>
        </p>
      </div>
    </div>
  );
};

export default ResetPassword;
