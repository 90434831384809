import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { Checkbox, notification } from 'antd';
import * as Yup from 'yup';
import { AuthApi } from '@/apis';
import { setTokens } from '@/redux/actions';
import Input from '@/components/forms/Input';
import Login2FA from '@/pages/Auth/Login/Login2FA';
import Button from '@/components/common/Button';
import './styles.scss';
import { Link } from 'react-router-dom';
import { ROUTES } from 'src/constants';

export interface ILoginForm {
  email: string;
  password: string;
  rememberMe: boolean;
}

const Login: FC = () => {
  const [tfaEnabled, setTfaEnabled] = useState(false);
  const dispatch = useDispatch();
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState({
    login: false,
    TFA: false
  });

  const onChangeLoading = (field: string, value: boolean) => {
    setLoading({
      ...loading,
      [field]: value
    });
  };

  const loginValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email is not valid.')
      .required('Email is required.'),
    password: Yup.string().required('Password is required.')
  });

  const handleTFALogin = ({ code }: { code: string }) => {
    onChangeLoading('TFA', true);
    AuthApi.loginTFA(code, token)
      .then((data) => {
        onChangeLoading('TFA', false);
        dispatch(
          setTokens({
            accessToken: data.accessToken,
            refreshToken: data.refreshToken
          })
        );
      })
      .catch((err) => {
        notification.error({ message: err.message });
        onChangeLoading('TFA', false);
      });
  };

  const handleSubmit = ({ email, password, rememberMe }: ILoginForm) => {
    onChangeLoading('login', true);
    AuthApi.login(email, password)
      .then((data) => {
        onChangeLoading('login', false);

        if (data.tfaEnabled) {
          setTfaEnabled(true);
          setToken(data.token);
        } else {
          dispatch(
            setTokens({
              accessToken: data.accessToken,
              refreshToken: data.refreshToken,
              remember: rememberMe
            })
          );
        }
      })
      .catch((error) => {
        onChangeLoading('login', false);
        notification.error({ message: error.message });
      });
  };

  return (
    <div className="login-page py-5">
      <div className="logo-wrapper">
        <img src="/images/diligentspace_logo.svg" alt="logo" />
      </div>

      {tfaEnabled ? (
        <Login2FA onSubmit={handleTFALogin} loading={loading.TFA} />
      ) : (
        <div className="login-form-wrapper">
          <h1 className="page-title text-center text-4xl font-weight-normal mb-5">
            Log In
          </h1>
          <Formik
            initialValues={{ email: '', password: '', rememberMe: false }}
            validationSchema={loginValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
              <Form className="login-form" onSubmit={handleSubmit}>
                <Input
                  name="email"
                  type="email"
                  label="Email Address"
                  value={values.email}
                  onChange={handleChange}
                  error={errors.email && touched.email ? errors.email : ''}
                  placeholder="Work email address"
                  required={true}
                />
                <Input
                  name="password"
                  type="password"
                  label="Password"
                  value={values.password}
                  onChange={handleChange}
                  error={
                    errors.password && touched.password ? errors.password : ''
                  }
                  placeholder={'**********'}
                  required={true}
                />

                <div className="form-actions">
                  <Checkbox
                    className="remember-check"
                    name="rememberMe"
                    value={values.rememberMe}
                    onChange={handleChange}
                  >
                    Remember me
                  </Checkbox>
                </div>
                <Button
                  className="w-100 cmt-12 cmb-12"
                  type="submit"
                  loading={loading.login}
                >
                  Login
                </Button>

                <div className="text-center">
                  <Link to={ROUTES.AUTH.FORGOT_PASSWORD}>Forgot Password?</Link>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default Login;
