import Http from '@/apis/http';
import { IFreelancer, ISearchApiResult } from '@/resources/interfaces';
import { FreelancerModel } from '@/resources/models';
import { VERIFICATION_STATUS } from '@/resources/enums';

export class FreelancerApi {
  static search(query: any): Promise<ISearchApiResult<FreelancerModel>> {
    return Http.get('/admin/freelancers', query).then(
      (res: ISearchApiResult<IFreelancer>) => {
        return {
          totalCount: res.totalCount,
          data: res.data.map((item) => new FreelancerModel(item))
        };
      }
    );
  }

  static find(id: string): Promise<FreelancerModel> {
    return Http.get(`/admin/freelancers/${id}`).then((res: IFreelancer) => {
      return new FreelancerModel(res);
    });
  }

  static delete(id: string) {
    return Http.delete(`/admin/freelancers/${id}`);
  }

  static bulkDelete(ids: string[]) {
    return Http.delete(`/admin/freelancers/bulk-delete`, {
      ids
    });
  }

  static block(id: string) {
    return Http.post(`/admin/freelancers/${id}/block`);
  }

  static bulkBlock(ids: string[]) {
    return Http.post(`/admin/freelancers/bulk-block`, {
      ids
    });
  }

  static unblock(id: string) {
    return Http.post(`/admin/freelancers/${id}/unblock`);
  }

  static bulkUnblock(ids: string[]) {
    return Http.post(`/admin/freelancers/bulk-unblock`, {
      ids
    });
  }

  static approveIDVerification(freelancerId: string) {
    return Http.post(
      `/admin/freelancers/${freelancerId}/approve-verification`,
      { status: VERIFICATION_STATUS.VERIFIED }
    );
  }

  static getTopSkillsStatistics() {
    return Http.get('/admin/freelancers/statistics/top-skills');
  }

  static getTopFreelancers() {
    return Http.get(
      '/admin/freelancers/statistics/top-freelancers'
    ).then((res: IFreelancer[]) =>
      res.map((item) => new FreelancerModel(item))
    );
  }

  static update(id: string, body: any) {
    return Http.put(`/admin/freelancers/${id}`, body);
  }

  static create(data: any): Promise<FreelancerModel> {
    return Http.post(`/admin/freelancers`, data).then((res: IFreelancer) => {
      return new FreelancerModel(res);
    });
  }

  static getActionHistories(id: string, filters: any): Promise<any> {
    return Http.get(`/admin/freelancers/${id}/activity-logs`, filters);
  }

  static getWidthdrawHistories(id: string, filters: any): Promise<any> {
    return Http.get(`/admin/freelancers/${id}/withdraw-histories`, filters);
  }
}
