import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { notification, Skeleton, Table } from 'antd';
import Pagination from '@/components/common/Pagination';
import { AppReportApi } from '@/apis';
import { IQueryField, QueryHelper } from '@/helpers';
import PageHeader from '@/components/common/PageHeader';
import { useHistory, useLocation } from 'react-router';
import IconButton from '@/components/common/IconButton';
import Search from '@/components/forms/Search';
import { UserModel } from '@/resources/models';
import { useDebounce } from '@/hooks';
import Avatar from '@/components/common/Avatar';
import Tooltip from '@/components/common/Tooltip';
import moment from 'moment';
import './styles.scss';
import AppReportDetailModal from '@/components/pages/AppReports/AppReportDetailModal';

const filterFields: IQueryField[] = [
  { name: 'page', type: 'number', default: 1 },
  { name: 'perPage', type: 'number', default: 10 },
  { name: 'search', type: 'string', default: undefined }
];

const AppReportsPage = () => {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [appReports, setAppReports] = useState<any>([]);
  const location = useLocation();
  const history = useHistory();
  const filter = useMemo(() => {
    return QueryHelper.parseQuery(location.search, filterFields, true);
  }, [location.search]);
  const [selectedRecord, setSelectedRecord] = useState<any>();
  const [showDetailModal, setShowDetailModal] = useState(false);

  const debounceFilter = useDebounce(filter, 500);

  const onClickRow = (e: any, record: any) => {
    e.stopPropagation();
    setShowDetailModal(true);
    setSelectedRecord(record);
  };

  const fetchAppReports = useCallback(async () => {
    try {
      setLoading(true);
      const res = await AppReportApi.search(filter);

      const { totalCount, data } = res;
      setTotal(totalCount);
      setAppReports(data);
    } catch (err) {
      notification.error({ message: err.message });
    } finally {
      setLoading(false);
    }
  }, [filter]);

  const dataSource = useMemo(() => {
    return appReports.map((item: any) => ({
      key: item.id,
      ...item
    }));
  }, [appReports]);

  const onChangeFilter = useCallback(
    (field: string, value: any, isPageUpdate = true) => {
      const newFilter = QueryHelper.getFilterToQuery(
        filter,
        field,
        value,
        isPageUpdate
      );

      const query = QueryHelper.stringifyQuery(newFilter);
      history.replace(`${location.pathname}?${query}`);
    },
    [filter, location.pathname]
  );

  useEffect(() => {
    fetchAppReports();
  }, [debounceFilter]);

  const columns = useMemo(
    () => [
      {
        title: 'User',
        dataIndex: 'user',
        render: (user: UserModel) => (
          <div className="d-flex align-items-center">
            <Avatar
              src={user?.avatar}
              name={user?.fullName}
              size="2.5rem"
              className="cmr-6"
              round={true}
            />

            <Tooltip title={user?.fullName || ''}>
              <div>
                <p className="mb-0 text-dark">{user?.fullName}</p>
                <p className="text-xs mb-0 text-blue">{user?.email}</p>
              </div>
            </Tooltip>
          </div>
        )
      },
      {
        title: 'Error',
        dataIndex: 'error',
        render: (value: string) => <div className="error">{value}</div>
      },
      {
        title: 'Timestamps',
        dataIndex: 'createdAt',
        render: (createdAt: string) => {
          return <div>{moment(createdAt).format('DD MMM YYYY')}</div>;
        }
      },
      {
        title: 'Action',
        dataIndex: 'action',
        render: (_: string, record: any) => (
          <div className="d-flex align-items-center">
            <IconButton
              icon="file-minus"
              onClick={(e) => onClickRow(e, record)}
            />
          </div>
        )
      }
    ],
    []
  );

  return (
    <div className="app-reports-page">
      <PageHeader title="App Reports" />

      <div className="log-filters">
        <div className="cmb-10">
          <Search
            className="page-search-box"
            placeholder="Search for app reports by title"
            value={filter.search}
            onChange={(e) => onChangeFilter('search', e.target.value)}
          />
        </div>
      </div>

      <div className="d-flex justify-content-between mt-4 mb-3 align-items-center">
        <Skeleton loading={loading} paragraph={{ rows: 0 }} active={true}>
          <p className="text-md mb-0">{total} payment orders found</p>
        </Skeleton>
      </div>

      <Table
        className="custom-ant-table"
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />

      <div className="d-flex-center cmt-10">
        <Pagination
          total={total}
          current={filter.page}
          pageSize={filter.perPage}
          onChange={(page) => onChangeFilter('page', page)}
        />
      </div>
      <AppReportDetailModal
        onClose={() => setShowDetailModal(false)}
        open={showDetailModal}
        appReport={selectedRecord}
      />
    </div>
  );
};

export default AppReportsPage;
