import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import store, { history } from '@/redux/store';
import App from '@/pages';
import './index.scss';

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </Router>
  </Provider>,
  document.getElementById('root')
);
