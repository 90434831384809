import React, { FC, useMemo } from 'react';
import { Divider, Table } from 'antd';
import {
  EmployerModel,
  FreelancerModel,
  InvoiceModel,
  ContractModel
} from '@/resources/models';
import { DateHelper, NameHelper } from '@/helpers';
import { DELIVERY_TYPE } from '@/resources/enums';
import InvoiceHeader from '@/components/pages/InvoiceDetail/InvoiceHeader';
import InvoiceFooter from '@/components/pages/InvoiceDetail/InvoiceFooter';
import './styles.scss';

export interface IInvoiceDetailProps {
  invoice: InvoiceModel;
  getInvoice?: () => void;
}

const InvoiceDetail: FC<IInvoiceDetailProps> = ({ invoice, getInvoice }) => {
  const contract = invoice.contract as ContractModel;
  const freelancer = invoice.freelancer as FreelancerModel;
  const employer = invoice.employer as EmployerModel;

  const columns = useMemo(() => {
    if (invoice.deliveryType === DELIVERY_TYPE.HOURLY) {
      return [
        {
          title: 'Description',
          dataIndex: 'description'
        },
        {
          title: 'Quantity',
          dataIndex: 'quantity',
          width: 150
        },
        {
          title: 'Rate',
          dataIndex: 'price',
          width: 150
        },
        {
          title: 'Price',
          dataIndex: 'total',
          width: 150
        }
      ];
    }

    return [
      {
        title: 'Description',
        dataIndex: 'description'
      },
      {
        title: 'Price',
        dataIndex: 'price',
        width: 150
      }
    ];
  }, [invoice]);

  const dataSource = useMemo(() => {
    return invoice.details.map((item) => {
      if (invoice.deliveryType === DELIVERY_TYPE.HOURLY) {
        return {
          ...item,
          quantity: DateHelper.formatTime(item.quantity, 'hh:mm:ss'),
          price: `$${item.price.toFixed(2)}/hr`,
          total: `$${(
            (Number(item.price) * Number(item.quantity)) /
            3600
          ).toFixed(2)}`
        };
      }

      return {
        ...item,
        price: `$${item.price.toFixed(2)}`
      };
    });
  }, [invoice]);

  return (
    <div className="invoice-detail-info">
      <InvoiceHeader
        invoice={invoice}
        employer={employer}
        freelancer={freelancer}
        getInvoice={getInvoice}
      />

      <Divider className="cmy-12" />

      {(invoice.deliveryType === DELIVERY_TYPE.HOURLY ||
        invoice.deliveryType === DELIVERY_TYPE.MILESTONE) && (
        <div className="cmb-20">
          <p className="text-heading3 cmb-8">
            {invoice.deliveryType === DELIVERY_TYPE.MILESTONE
              ? `Milestones for project: ${contract.title}`
              : `Timesheet for project: ${contract.title}`}
          </p>
          <Table
            className="custom-ant-table"
            rowKey={'_id'}
            dataSource={dataSource}
            columns={columns}
            pagination={false}
          />
        </div>
      )}

      <InvoiceFooter total={Number(invoice.total)} invoice={invoice} />
    </div>
  );
};

export default InvoiceDetail;
