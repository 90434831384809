import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Rate } from 'antd';
import { FreelancerModel } from '@/resources/models';
import Avatar from '@/components/common/Avatar';
import { FREELANCER_DETAILS_ROUTES, ROUTES } from '@/constants';
import { DateHelper } from '@/helpers';

export interface IEngagedFreelancerCardProps {
  freelancer: FreelancerModel;
}

const EngagedFreelancerCard: FC<IEngagedFreelancerCardProps> = ({
  freelancer
}) => {
  return (
    <div className="engaged-freelancer-card">
      <div className="d-flex w-100 cmb-9">
        <Avatar
          src={freelancer.user?.avatarUrl}
          name={freelancer.user?.fullName}
          size="3.5rem"
          className="cmr-10"
          round={true}
        />
        <div className="freelancer-info">
          <Link
            className="text-md mb-0 text-ellipsis"
            to={`${ROUTES.ADMIN.FREELANCERS.PREFIX}/${freelancer.id}/${FREELANCER_DETAILS_ROUTES.DETAILS}`}
          >
            {freelancer?.user?.fullName}
          </Link>
          <p className="text-xs text-ellipsis mb-0">{freelancer.title}</p>

          <Rate allowHalf={true} disabled={true} value={freelancer.rating} />
        </div>
      </div>

      <div className="d-flex align-items-center cmb-9">
        <p className="text-sm mb-0 cmr-13">
          Hourly Rate:{' '}
          <span className="text-bold">${freelancer.hourlyPrice}</span>
        </p>
        <p className="text-sm mb-0 cmr-13">
          Projects Completed:{' '}
          <span className="text-bold">
            {freelancer.completedProjects.length}
          </span>
        </p>
        <p className="text-sm mb-0 cmr-13">
          Earned:{' '}
          <span className="text-bold">
            ${Number(freelancer.totalEarned).toFixed(2)}
          </span>
        </p>
        <p className="text-sm mb-0">
          Member Since:{' '}
          <span className="text-bold">
            {DateHelper.formatDate(freelancer.createdAt, 'MMM, YYYY')}
          </span>
        </p>
      </div>
    </div>
  );
};

export default EngagedFreelancerCard;
