import Http from '@/apis/http';
import { ISearchApiResult } from '@/resources/interfaces';
import { IInvoice } from '@/resources/interfaces/invoice.interface';
import { InvoiceModel } from '@/resources/models';

export class InvoiceApi {
  static search(query: any = {}): Promise<ISearchApiResult<InvoiceModel>> {
    return Http.get('/admin/invoices', query);
  }

  static find(invoiceId: string): Promise<InvoiceModel> {
    return Http.get(`/admin/invoices/${invoiceId}`).then(
      (data: IInvoice) => new InvoiceModel(data)
    );
  }

  static exportAsPDF(invoiceId: string) {
    return Http.post(`/admin/invoices/${invoiceId}/export/pdf`);
  }

  static update(invoiceId: string, data: any) {
    return Http.put(`/admin/invoices/${invoiceId}`, data);
  }
}
