import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { ChevronDown } from 'react-feather';
import classnames from 'classnames';
import {
  CloseOutlined as CloseIcon,
  CheckOutlined as CheckIcon
} from '@ant-design/icons';
import Search from '@/components/forms/Search';
import usePopup from '@/hooks/usePopup';
import Icon from '@/components/common/Icon';
import { useDebounce } from 'src/hooks';
import './styles.scss';

export interface ISelectOption {
  title: string;
  value: any;
}

export interface IAsyncSelectProps {
  label?: string;
  className?: string;
  value: any;
  onChange: (value: any) => void;
  multiSelect?: boolean;
  prefix?: string;
  placeholder?: string;
  error?: string;
  showSearchIcon?: boolean;
  searchable?: boolean;
  disabled?: boolean;
  required?: boolean;
  showChipList?: boolean;
  showArrowIcon?: boolean;
  options: ISelectOption[];
  onFetch: any;
}

const AsyncSelect: FC<IAsyncSelectProps> = ({
  value: selectedValue,
  onChange,
  error,
  className = '',
  prefix = '',
  placeholder = 'Select',
  multiSelect = false,
  showSearchIcon = false,
  searchable = false,
  disabled = false,
  label,
  required,
  showChipList = true,
  showArrowIcon = true,
  options,
  onFetch
}) => {
  const [searchWord, setSearchWord] = useState('');
  const toggleContainer = useRef<HTMLDivElement | null>(null);
  const { toggleMenu, closeMenu, isOpen } = usePopup(toggleContainer);
  const debounceSearch = useDebounce(searchWord, 500);

  const onSelectItem = (value: any) => {
    if (multiSelect) {
      if (selectedValue.includes(value)) {
        const newValue = selectedValue.filter((item: any) => item !== value);
        onChange(newValue);
      } else {
        onChange([...selectedValue, value]);
      }
    } else {
      closeMenu();
      onChange(value);
    }
  };

  const isSelected = (value: any) => {
    if (multiSelect) {
      return selectedValue.includes(value);
    } else {
      return selectedValue === value;
    }
  };

  const selectedContent = useMemo(() => {
    const selectedOptions = options.filter((item) => {
      if (multiSelect) return selectedValue.includes(item.value);
      return selectedValue === item.value;
    });

    if (multiSelect) return placeholder;

    return selectedOptions.map((item) => item.title).join(', ');
  }, [options, selectedValue, multiSelect, placeholder]);

  const multiSelectedItems = useMemo(() => {
    if (multiSelect) {
      return options.filter((item) => {
        return selectedValue.includes(item.value);
      });
    }

    return [];
  }, [options, selectedValue]);

  const removeChip = (value: any) => {
    const newValue = selectedValue.filter((item: any) => item !== value);
    onChange(newValue);
  };

  useEffect(() => {
    setSearchWord('');
  }, [isOpen]);

  useEffect(() => {
    onFetch(debounceSearch);
  }, [debounceSearch]);

  return (
    <>
      {!!label && (
        <p className="text-sm mb-0">
          {label} {required ? '*' : ''}
        </p>
      )}
      <div
        className={classnames(`custom-select-container ${className}`, {
          disabled
        })}
        ref={toggleContainer}
      >
        <div
          className={classnames('custom-select-box', {
            error: !!error,
            opened: isOpen
          })}
        >
          {isOpen && searchable ? (
            <div className="filter-container">
              <Search
                className="dropdown-filter"
                showSearchIcon={true}
                value={searchWord}
                placeholder={placeholder}
                autoFocus={true}
                onChange={(e) => setSearchWord(e.target.value)}
              />
            </div>
          ) : (
            <div className="selected-content-wrapper" onClick={toggleMenu}>
              {showSearchIcon && (
                <span
                  className={classnames('select-search-icon-wrapper', {
                    'has-value': !!selectedContent
                  })}
                >
                  <Icon name="search" />
                </span>
              )}
              {!!selectedContent && !multiSelect ? (
                <span className="selected-content text-ellipsis">{`${prefix}${selectedContent}`}</span>
              ) : (
                <span className="selected-content__placeholder text-ellipsis">
                  {placeholder}
                </span>
              )}
              {showArrowIcon && (
                <ChevronDown
                  className={classnames('arrow-down-icon', {
                    opened: isOpen
                  })}
                />
              )}
            </div>
          )}
        </div>

        {isOpen && (
          <div className="dropdown-list-container">
            <div className="dropdown-list-wrapper">
              <div className="dropdown-list">
                {options.length > 0 ? (
                  <>
                    {options.map((item, index) => (
                      <div
                        key={index}
                        className={classnames('dropdown-list__item', {
                          selected: isSelected(item.value)
                        })}
                        onClick={() => onSelectItem(item.value)}
                      >
                        <span className="dropdown-list__item__title whitespace-nowrap">
                          {item.title}
                        </span>
                        {isSelected(item.value) && (
                          <CheckIcon className="check-icon" />
                        )}
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="cpy-6">
                    <p className="text-md text-red text-center m-0">No data</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {showChipList && multiSelect && multiSelectedItems.length > 0 && (
          <div className="multi-select-chip-container">
            {multiSelectedItems.map((item) => (
              <div className="multi-select-ship" key={item.value}>
                <span className="chip-title">{item.title}</span>
                <CloseIcon
                  className="chip-icon"
                  onClick={() => removeChip(item.value)}
                />
              </div>
            ))}
          </div>
        )}
      </div>

      {!!error && <p className="text-red text-sm mb-0">{error}</p>}
    </>
  );
};

export default AsyncSelect;
