export enum PERMISSION {
  CREATE_ADMIN = 'CREATE_ADMIN',
  EDIT_ADMIN = 'EDIT_ADMIN',
  VIEW_ADMIN = 'VIEW_ADMIN',
  CREATE_USER = 'CREATE_USER',
  EDIT_USER = 'EDIT_USER',
  VIEW_USER = 'VIEW_USER',
  DELETE_USER = 'DELETE_USER',
  APPROVE_PROJECT = 'APPROVE_PROJECT',
  DISABLE_PROJECT = 'DISABLE_PROJECT',
  VIEW_PROJECT = 'VIEW_PROJECT',
  DISABLE_CONTRACT = 'DISABLE_CONTRACT',
  VIEW_CONTRACT = 'VIEW_CONTRACT',
  VIEW_TRANSACTIONS = 'VIEW_TRANSACTIONS',
  VIEW_INVOICES = 'VIEW_INVOICES',
  VIEW_EVENTS = 'VIEW_EVENTS'
}

export enum CONTRACT_STATUS {
  PENDING = 'pending',
  ACTIVE = 'active',
  PAUSED = 'paused',
  DISABLED = 'disabled',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  REJECTED = 'rejected'
}

export const CONTRACT_STATUS_VALUES = Object.values(CONTRACT_STATUS);

export enum TAXPAYER_ID_NUMBER_TYPE {
  SSN = 'SSN',
  EIN = 'EIN'
}

export const TAXPAYER_ID_NUMBER_TYPE_VALUES = Object.values(
  TAXPAYER_ID_NUMBER_TYPE
);

export enum TAX_TYPE {
  W8BEN = 'W-8BEN',
  W9 = 'W-9'
}

export const TAX_TYPE_VALUES = Object.values(TAX_TYPE);

export enum VERIFICATION_TYPE {
  PASSPORT = 'passport',
  ID_CARD = 'idCard'
}
export const VERIFICATION_TYPE_VALUES = Object.values(VERIFICATION_TYPE);

export enum VERIFICATION_STATUS {
  APPROVED = 'approved',
  PENDING = 'pending',
  VERIFIED = 'verified',
  DECLINED = 'declined'
}
export const VERIFICATION_STATUS_VALUES = Object.values(VERIFICATION_STATUS);

export enum DAY {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday'
}

export const DAY_VALUES = Object.values(DAY);

export enum PROJECT_DETAIL_PAGE_TAB {
  DETAIL = 'detail',
  INVITE_FREELANCERS = 'invite',
  PITCHES = 'pitches',
  SHORTLISTED = 'shortlisted',
  MESSAGED = 'messaged'
}

export const PROJECT_DETAIL_PAGE_TAB_VALUES = Object.values(
  PROJECT_DETAIL_PAGE_TAB
);

export enum FREELANCER_EDUCATION_LEVEL {
  NEVER = 'never',
  SELF_LEARNING = 'selfLearning',
  PROFESSIONAL = 'professional'
}

export const FREELANCER_EDUCATION_LEVEL_VALUES = Object.values(
  FREELANCER_EDUCATION_LEVEL
);

export enum FREELANCER_EXPERIENCE_LEVEL {
  BEGINNER = 'beginner',
  INTERMEDIATE = 'intermediate',
  PROFESSIONAL = 'professional'
}

export const FREELANCER_EXPERIENCE_LEVEL_VALUES = Object.values(
  FREELANCER_EXPERIENCE_LEVEL
);

export enum PAYMENT_TYPE {
  HOURLY = 'hourly',
  FIXED = 'fixed'
}

export const PAYMENT_TYPE_VALUES = Object.values(PAYMENT_TYPE);

export enum PROJECT_LEVEL {
  BEGINNER = 'beginner',
  INTERMEDIATE = 'intermediate',
  EXPERT = 'expert'
}

export const PROJECT_LEVEL_VALUES = Object.values(PROJECT_LEVEL);

export enum USER_STATUS {
  PENDING = 'pending',
  OPENED = 'opened',
  BLOCKED = 'blocked',
  DELETED = 'deleted',
  CLOSED = 'closed',
  INTERNAL = 'internal',
  INACTIVE = 'inactive'
}

export enum TRANSACTION_TYPE {
  WITHDRAW = 'withdraw',
  PAID = 'paid',
  PAID_EMPLOYER_FEE = 'paid_employer_fee',
  PAID_FREELANCER_FEE = 'paid_freelancer_fee',
  PAYPAL_CHECKOUT_FEE = 'paypal_checkout_fee',
  WITHDRAW_FEE = 'withdraw_fee',

  OTHER = 'other'
}
export const TRANSACTION_TYPE_VALUES = Object.values(TRANSACTION_TYPE);

export enum TRANSACTION_STATUS {
  CREATED = 'CREATED',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  UNCLAIMED = 'UNCLAIMED',
  RETURNED = 'RETURNED',
  REFUNDED = 'REFUNDED',
  ONHOLD = 'ONHOLD',
  FAILED = 'FAILED',
  DENIED = 'DENIED',
  CANCELED = 'CANCELED',
  BLOCKED = 'BLOCKED',
  COMPLETED = 'COMPLETED',
  DECLINED = 'DECLINED',
  RETRYING = 'retrying',
  UNKNOWN = 'UNKNOWN'
}
export const TRANSACTION_STATUS_VALUES = Object.values(TRANSACTION_STATUS);

export enum TRANSACTION_METHOD {
  STRIPE = 'stripe',
  PAYPAL = 'paypal',
  PAYONEER = 'payoneer',
  FASTERPAY = 'fasterpay',
  KLARPAY = 'klarpay'
}
export const TRANSACTION_METHOD_VALUES = Object.values(TRANSACTION_METHOD);

export enum DELIVERY_TYPE {
  HOURLY = 'hourly',
  MILESTONE = 'milestone',
  PROJECT = 'project'
}

export const DELIVERY_TYPE_VALUES = Object.values(DELIVERY_TYPE);

export enum FREELANCER_AVAILABILITY {
  FULL_TIME = 'fullTime',
  PART_TIME = 'partTime'
}

export const FREELANCER_AVAILABILITY_VALUES = Object.values(
  FREELANCER_AVAILABILITY
);

export enum GENDER {
  MALE = 'male',
  FEMALE = 'female',
  UNKNOWN = 'unknown'
}

export const GENDER_VALUES = Object.values(GENDER);

export enum JOB_TYPE {
  HOURLY = 'hourly',
  FIXED = 'fixed'
}

export const JOB_TYPE_VALUES = Object.values(JOB_TYPE);

export enum NOTIFICATION_TYPE {
  CREATE_PITCH = 'CREATE_PITCH',
  UPDATE_PITCH = 'UPDATE_PITCH',
  CANCEL_PITCH = 'CANCEL_PITCH',
  OFFER = 'OFFER',
  ACCEPT_OFFER = 'ACCEPT_OFFER',
  REJECT_OFFER = 'REJECT_OFFER',
  INVITED = 'INVITED',
  REJECT_INVITATION = 'REJECT_INVITATION',
  RECEIVE_MESSAGE = 'RECEIVE_MESSAGE',
  RECEIVE_INVOICE = 'RECEIVE_INVOICE',
  PAY_INVOICE = 'PAY_INVOICE',
  DECLINE_INVOICE = 'DECLINE_INVOICE',
  STOP_CONTRACT = 'STOP_CONTRACT',
  RESUME_CONTRACT = 'RESUME_CONTRACT',
  END_CONTRACT = 'END_CONTRACT',
  RECEIVE_FEEDBACK = 'RECEIVE_FEEDBACK',
  CLOSE_PROJECT = 'CLOSE_PROJECT',
  ENABLE_PROJECT = 'ENABLE_PROJECT',
  DISABLE_PROJECT = 'DISABLE_PROJECT',
  APPROVE_PROJECT = 'APPROVE_PROJECT',
  ACCEPT_WITHDRAW = 'ACCEPT_WITHDRAW',
  REJECT_WITHDRAW = 'REJECT_WITHDRAW'
}

export const NOTIFICATION_TYPE_VALUES = Object.values(NOTIFICATION_TYPE);

export enum PAYMENT_STATUS {
  NONE = 'none',
  PENDING = 'pending',
  PROCESSED = 'processed',
  PAID = 'paid',
  DECLINED = 'declined',
  DISABLED = 'disabled',
  WAITING_FOR_PAYMENT = 'waiting-for-payment'
}

export const PAYMENT_STATUS_VALUES = Object.values(PAYMENT_STATUS);

export enum PROJECT_CLOSED_REASON {
  COMPLETED = 'completed',
  CANCELLED = 'cancelled'
}

export const PROJECT_CLOSED_REASON_VALUES = Object.values(
  PROJECT_CLOSED_REASON
);

export enum PROJECT_STATUS {
  PENDING = 'pending',
  OPEN = 'open',
  CLOSED = 'closed',
  AWARDED = 'awarded',
  DRAFT = 'draft',
  COMPLETED = 'completed',
  STOPPED = 'stopped',
  DISABLED = 'disabled',
  OFFERED = 'offered' // only used in search query
}

export const PROJECT_STATUS_VALUES = Object.values(PROJECT_STATUS);

export enum PROPOSAL_CLOSED_REASON {
  COMPLETED = 'completed',
  ARCHIVED = 'archived'
}

export const PROPOSAL_CLOSED_REASON_VALUES = Object.values(
  PROPOSAL_CLOSED_REASON
);

export enum PROPOSAL_STATUS {
  APPLIED = 'applied',
  CLOSED = 'closed',
  AWARDED = 'awarded',
  INVITED = 'invited',
  OFFERED = 'offered',
  ACTIVE = 'active',
  REJECTED = 'rejected'
}
export const PROPOSAL_STATUS_VALUES = Object.values(PROPOSAL_STATUS);

export enum REVIEW_WRITER {
  FREELANCER = 'freelancer',
  EMPLOYER = 'employer'
}

export const REVIEW_WRITER_VALUES = Object.values(REVIEW_WRITER);

export enum ROLE {
  FREELANCER = 'freelancer',
  EMPLOYER = 'employer',
  ADMIN = 'admin',
  SUPER_ADMIN = 'super_admin',
  QA_TESTER = 'qa_tester',
  MARKETING_MANAGER = 'marketing_manager'
}

export const ROLE_VALUES = Object.values(ROLE);

export enum TIME_METHOD {
  AUTO = 'auto',
  MANUAL = 'manual'
}

export const TIME_METHOD_VALUES = Object.values(TIME_METHOD);

export enum STATIC_PERIOD {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly'
}

export const STATIC_PERIOD_VALUES = Object.values(STATIC_PERIOD);

export enum ACTION_HISTORY_TYPE {
  LOGIN = 'login',
  REGISTER = 'register',
  RESET_PASSWORD = 'reset_password',

  WITHDRAW = 'withdraw'
}

export const ACTION_HISTORY_TYPE_VALUES = Object.values(ACTION_HISTORY_TYPE);

export enum INVITATION_STATUS {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected'
}
export const INVITATION_STATUS_VALUES = Object.values(INVITATION_STATUS);

export enum DAEMON_TYPE {
  WITHDRAW = 'withdraw',
  PAYONEER = 'payoneer',
  KLARPAY = 'klarpay',
  TRANSACTION = 'transaction'
}

export const DAEMON_TYPE_VALUES = Object.values(DAEMON_TYPE);

export enum DAEMON_STATUS {
  SUCCESS = 'success',
  FAILED = 'failed',
  PARTIALLY_FAILED = 'partially_failed'
}

export const DAEMON_STATUS_VALUES = Object.values(DAEMON_STATUS);

export enum WEBHOOK_LOG_STATUS {
  SUCCESS = 'success',
  FAILED = 'failed'
}

export const WEBHOOK_LOG_STATUS_VALUES = Object.values(WEBHOOK_LOG_STATUS);

export enum REFEREE_SOURCE {
  INVITATION = 'invitation',
  REFERRAL_LINK = 'referral_link'
}

export const REFEREE_SOURCE_VALUES = Object.values(REFEREE_SOURCE);

export enum REFEREE_ACTION {
  REGISTERED = 'registered',
  INVITED = 'invited',
  IDENTITY_VERIFIED = 'identity_verified',
  HIRED_FREELANCER = 'hired_freelancer'
}

export const REFEREE_ACTION_VALUES = Object.values(REFEREE_ACTION);

export enum BANK_ACCOUNT_TYPES {
  SAVINGS = 'savings',
  CHECKING = 'checking'
}
export const BANK_ACCOUNT_TYPES_VALUES = Object.values(BANK_ACCOUNT_TYPES);

export enum CURRENCY {
  USD = 'usd',
  EUR = 'eur'
}
export const CURRENCY_VALUES = Object.values(CURRENCY);

export enum BANK_PAYMENT_ORDER_STATUS {
  PENDING = 'pending',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled'
}
export const BANK_PAYMENT_ORDER_STATUS_VALUES = Object.values(
  BANK_PAYMENT_ORDER_STATUS
);

export enum BANK_TYPES {
  ACH = 'ACH',
  Swift = 'Swift'
}
export const BANK_TYPES_VALUES = Object.values(BANK_TYPES);

export enum BANK_RECEIVER_TYPE {
  PERSONAL = 'personal',
  COMPANY = 'company'
}

export const BANK_RECEIVER_TYPE_VALUES = Object.values(BANK_RECEIVER_TYPE);

export enum BANK_RECEIVING_ACCOUNT_TYPE {
  ACCOUNT_NUMBER = 'accountNumber',
  IBAN = 'iban'
}

export const BANK_RECEIVING_ACCOUNT_TYPE_VALUES = Object.values(
  BANK_RECEIVING_ACCOUNT_TYPE
);

export enum BANK_STATUS {
  PENDING = 'pending',
  APPROVED = 'approved',
  DECLINED = 'declined',
  DEACTIVATED = 'deactivated'
}
export const BANK_STATUS_VALUES = Object.values(BANK_STATUS);

export enum ORDER_TYPE {
  WITHDRAW = 'withdraw'
}

export const ORDER_TYPE_VALUES = Object.values(ORDER_TYPE);

export enum ORDER_STATUS {
  CREATED = 'created',
  PENDING = 'pending',
  PROCESSED = 'processed',
  CANCELLED = 'cancelled',
  REJECTED = 'rejected'
}

export const ORDER_STATUS_VALUES = Object.values(ORDER_STATUS);
