import React, { FC } from 'react';
import { FreelancerModel } from '@/resources/models';
import './styles.scss';

export interface ICoverPhotoProps {
  freelancer?: FreelancerModel;
}

const CoverPhoto: FC<ICoverPhotoProps> = ({ freelancer }) => {
  return (
    <div className="freelancer-cover-photo">
      <img src={freelancer?.coverPhotoUrl} alt="coverPhoto" />
    </div>
  );
};

export default CoverPhoto;
