import React, { FC } from 'react';
import Avatar from '@/components/common/Avatar';
import { UserModel } from '@/resources/models';
import './styles.scss';

export interface IAccountAvatarProps {
  user?: UserModel;
}

const AccountAvatar: FC<IAccountAvatarProps> = ({ user }) => {
  return (
    <div className="account-avatar-container">
      <Avatar
        src={user?.avatarUrl}
        name={user?.fullName}
        size="10rem"
        round={true}
      />
    </div>
  );
};

export default AccountAvatar;
