import React, { FC, useMemo } from 'react';
import { FreelancerModel } from '@/resources/models';
import { FREELANCER_AVAILABILITY } from '@/resources/enums';
import './styles.scss';

export interface IPreferenceProps {
  freelancer?: FreelancerModel;
}

const Preference: FC<IPreferenceProps> = ({ freelancer }) => {
  const hoursPerWeek = useMemo(() => {
    return freelancer?.availability?.hoursPerWeek || 0;
  }, [freelancer?.availability?.hoursPerWeek]);

  return (
    <div className="freelancer-preferences cmb-12">
      <div className="d-flex justify-content-between align-items-center cmb-7">
        <p className="text-heading3 mb-0">Availability</p>
      </div>

      <div className="preference-wrapper">
        <div className="cmb-4">
          <span className="text-sm">FT/PT:&nbsp;</span>
          <span className="text-sm text-bold">
            {freelancer?.availability?.type ===
            FREELANCER_AVAILABILITY.FULL_TIME
              ? 'Full Time'
              : 'Part Time'}
          </span>
        </div>
        <div className="cmb-4">
          <span className="text-sm">Weekly Availability (Days):&nbsp;</span>
          <span className="text-sm text-bold">
            {freelancer?.availability?.daysOfWork &&
              `${freelancer?.availability?.daysOfWork.length} ${
                freelancer?.availability?.daysOfWork.length > 1 ? 'days' : 'day'
              }`}
          </span>
        </div>
        <div className="">
          <span className="text-sm">Weekly Availability (Hours):&nbsp;</span>
          <span className="text-sm text-bold">
            {hoursPerWeek} {hoursPerWeek > 0 ? 'hrs' : 'hr'}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Preference;
