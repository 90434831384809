import React, { FC } from 'react';
import ReactAvatar from 'react-avatar';
import classnames from 'classnames';
import KycVerifiedIcon from '../KycVerifiedIcon';
import './styles.scss';

interface IAvatarProps {
  src?: string;
  name?: string;
  size?: string;
  round?: boolean;
  maxInitials?: number;
  className?: string;
  textSizeRatio?: number;
  onClick?: () => void;
  kycVerified?: boolean;
}

const Avatar: FC<IAvatarProps> = ({
  src,
  name,
  size = 'md',
  round,
  maxInitials,
  textSizeRatio,
  className,
  kycVerified,
  ...props
}) => {
  return (
    <div className={classnames('react-avatar-container', className)}>
      <ReactAvatar
        src={src}
        name={name}
        size={size}
        round={round}
        maxInitials={maxInitials}
        textSizeRatio={textSizeRatio}
        {...props}
      />
      {kycVerified && <KycVerifiedIcon className="kyc-badge-icon" />}
    </div>
  );
};

export default Avatar;
