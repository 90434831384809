import React, { FC, useEffect, useMemo, useState } from 'react';
import { Skeleton } from 'antd';
import BarChart from '@/components/charts/BarChart';
import './styles.scss';
import { AccountApi } from '@/apis';
import { DateHelper } from '@/helpers';
import Select from '@/components/forms/Select';

const EmployerStatistics: FC = () => {
  const [period, setPeriod] = useState('week');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>({});

  const periodOptions = [
    { title: '1 Week', value: 'week' },
    { title: '1 Month', value: 'month' },
    { title: '1 Year', value: 'year' }
  ];

  useEffect(() => {
    setLoading(true);
    AccountApi.getDashboardEmployerStatistics({ period })
      .then((res) => {
        setData(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [period]);

  const categories = useMemo(() => {
    if (period !== 'month') {
      return Object.keys(data);
    }
    return Object.keys(data).map((item) => DateHelper.formatDate(item, 'DD'));
  }, [data, period]);

  return (
    <div className="employer-statistics-container custom-card">
      <div className="d-flex justify-content-between align-items-center cmb-12">
        <h3 className="cmb-9">Employers</h3>
        <Select value={period} onChange={setPeriod} options={periodOptions} />
      </div>

      <Skeleton active={true} loading={loading} paragraph={{ rows: 5 }}>
        <BarChart
          title="Employers"
          categories={categories}
          data={Object.values(data)}
        />
      </Skeleton>
    </div>
  );
};

export default EmployerStatistics;
