import React, { FC, useMemo } from 'react';
import Ribbon from '@/components/common/Ribbon';
import { CONTRACT_STATUS } from '@/resources/enums';

export interface ContractStatusProps {
  status: string;
}

const ContractStatusRibbon: FC<ContractStatusProps> = ({ status }) => {
  const ribbonType = useMemo(() => {
    if (status === CONTRACT_STATUS.PENDING || status === CONTRACT_STATUS.PAUSED)
      return 'warning';

    if (status === CONTRACT_STATUS.COMPLETED) return 'success';

    if (
      status === CONTRACT_STATUS.DISABLED ||
      status === CONTRACT_STATUS.CANCELLED ||
      status === CONTRACT_STATUS.REJECTED
    )
      return 'danger';

    return 'normal';
  }, [status]);

  return <Ribbon type={ribbonType} content={status} />;
};

export default ContractStatusRibbon;
