import React from 'react';
import { BANK_STATUS, TRANSACTION_METHOD } from 'src/resources/enums';
import { IPaymentMethod } from 'src/resources/interfaces';
import { NameHelper } from '@/helpers';

interface IPaymentMethodDetailProps {
  paymentMethod: IPaymentMethod;
}

const PaymentMethodDetail: React.FC<IPaymentMethodDetailProps> = ({
  paymentMethod
}) => {
  if (
    paymentMethod.type !== TRANSACTION_METHOD.PAYPAL &&
    paymentMethod.type !== TRANSACTION_METHOD.PAYONEER &&
    (paymentMethod.type !== TRANSACTION_METHOD.KLARPAY ||
      paymentMethod.bank?.status === BANK_STATUS.DECLINED)
  ) {
    return null;
  }

  if (paymentMethod.type === TRANSACTION_METHOD.KLARPAY) {
    return (
      <div className="d-flex align-items-center justify-content-between w-100 mb-2">
        Bank Account: {paymentMethod.bank?.receivingAccount.slice(-4)} (
        {NameHelper.getCurrencyLabel(paymentMethod.bank?.currency)}) -{' '}
        {paymentMethod.bank?.status === 'approved' ? 'Approved' : 'Pending'}
      </div>
    );
  }

  return (
    <div className="d-flex align-items-center justify-content-between w-100 mb-2">
      <div>
        <span className="text-capitalize">{paymentMethod.type}</span>
        &nbsp; Payment Method -{' '}
        {paymentMethod.type === TRANSACTION_METHOD.PAYPAL
          ? paymentMethod.email
          : paymentMethod.status
          ? 'Connected'
          : 'Pending'}
      </div>
    </div>
  );
};

interface IPaymentMethodsProps {
  paymentMethods: IPaymentMethod[];
  balance?: number;
}

const PaymentMethods: React.FC<IPaymentMethodsProps> = ({
  paymentMethods,
  balance
}) => {
  return (
    <div className="certificates cmb-12">
      <div className="d-flex justify-content-between align-items-center cmb-7">
        <p className="text-heading3 mb-0">Payment Methods</p>
      </div>
      <div>
        {paymentMethods.map((paymentMethod, index) => (
          <PaymentMethodDetail key={index} paymentMethod={paymentMethod} />
        ))}
      </div>
      <div className="d-flex align-items-center">
        <div className="cmr-4">Balance: </div>
        <div className="text-bold">${balance?.toFixed(2)}</div>
      </div>
    </div>
  );
};

export default PaymentMethods;
