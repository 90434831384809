import React, { FC, useEffect, useState } from 'react';
import { Empty, notification, Skeleton } from 'antd';
import { FreelancerApi } from '@/apis';
import { FreelancerModel } from '@/resources/models';
import EngagedFreelancerCard from './EngagedFreelancerCard';
import Pagination from '@/components/common/Pagination';
import './styles.scss';

export interface IEngagedFreelancersProps {
  freelancers?: string[];
}

const EngagedFreelancers: FC<IEngagedFreelancersProps> = ({ freelancers }) => {
  const [engagedFreelancers, setEngagedFreelancers] = useState<
    FreelancerModel[]
  >();
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    page: 1,
    perPage: 5
  });

  useEffect(() => {
    if (freelancers?.length) {
      const query = {
        _id: freelancers,
        sort: '-rating',
        page: filters.page,
        perPage: filters.perPage,
        expands: ['user', 'skills']
      };

      setLoading(true);
      FreelancerApi.search(query)
        .then((res) => {
          setEngagedFreelancers(res.data);
          setTotal(res.totalCount);
        })
        .catch((err) => {
          notification.error({ message: err.message });
        })
        .finally(() => setLoading(false));
    }
  }, [freelancers, filters]);

  return (
    <div className="engaged-freelancers-container">
      <h3 className="cmb-12">Engaged Freelancers ({total})</h3>

      <Skeleton active={true} loading={loading} paragraph={{ rows: 5 }}>
        {engagedFreelancers && engagedFreelancers.length > 0 ? (
          <>
            <div>
              {engagedFreelancers.map((freelancer) => (
                <EngagedFreelancerCard
                  freelancer={freelancer}
                  key={freelancer.id}
                />
              ))}
            </div>

            <div className="d-flex-center cmt-10">
              <Pagination
                total={total}
                current={filters.page}
                pageSize={filters.perPage}
                onChange={(page, perPage) => setFilters({ page, perPage })}
              />
            </div>
          </>
        ) : (
          <Empty description="This project does not have any engaged freelancers" />
        )}
      </Skeleton>
    </div>
  );
};

export default EngagedFreelancers;
