import { Table } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { AdminUserApi, EmployerApi, FreelancerApi } from 'src/apis';
import Pagination from 'src/components/common/Pagination/Pagination';
import { DateHelper, IQueryField, NameHelper, QueryHelper } from 'src/helpers';
import { IEvent } from 'src/resources/interfaces';

const filterFields: IQueryField[] = [
  { name: 'page', type: 'number', default: 1 },
  { name: 'perPage', type: 'number', default: 10 }
];

const columns = [
  {
    title: 'No',
    dataIndex: 'no'
  },
  {
    title: 'Type',
    dataIndex: 'type'
  },
  {
    title: 'IP Address',
    dataIndex: 'ip'
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
    render: (date: string) => (
      <div>{DateHelper.formatDate(date, 'DD MMM YYYY, hh:mm:ss A')}</div>
    )
  }
];

interface IActionHistoriesProps {
  type?: string;
}

const ActionHistories: React.FC<IActionHistoriesProps> = ({
  type = 'adminUser'
}) => {
  const { id }: { id: string } = useParams();
  const location = useLocation();
  const history = useHistory();

  const filter = useMemo(() => {
    return QueryHelper.parseQuery(location.search, filterFields);
  }, [location.search]);

  const [events, setEvents] = useState<IEvent[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (type === 'adminUser') {
      AdminUserApi.getAdminActionHistories(id, filter)
        .then((res: any) => {
          if (res) {
            setEvents(res.data);
            setTotalCount(res.totalCount);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (type === 'employeer') {
      EmployerApi.getActionHistories(id, filter)
        .then((res: any) => {
          if (res) {
            setEvents(res.data);
            setTotalCount(res.totalCount);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (type === 'freelancer') {
      FreelancerApi.getActionHistories(id, filter)
        .then((res: any) => {
          if (res) {
            setEvents(res.data);
            setTotalCount(res.totalCount);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [filter]);

  const dataSource = useMemo(() => {
    return events.map((item: any, index: number) => ({
      key: item.id,
      ...item,
      ip: item?.request?.request?.ip,
      no: (filter.page - 1) * filter.perPage + index + 1,
      type: NameHelper.getActionHistoryTypeLabel(item.type)
    }));
  }, [events, filter]);

  const onChangeFilter = useCallback(
    (field: string, value: any) => {
      const newFilter = QueryHelper.getFilterToQuery(filter, field, value);

      const query = QueryHelper.stringifyQuery(newFilter);
      history.replace(`${location.pathname}?${query}`);
    },
    [filter, location.pathname]
  );

  return (
    <div className="custom-card">
      <h2 className="cmb-12">Action Histories</h2>

      <Table
        className="custom-ant-table"
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />

      <div className="d-flex-center cmt-10">
        <Pagination
          total={totalCount}
          current={filter.page}
          pageSize={filter.perPage}
          onChange={(page) => onChangeFilter('page', page)}
        />
      </div>
    </div>
  );
};

export default ActionHistories;
