import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Modal, notification, Skeleton } from 'antd';
import { PERMISSION, PROJECT_STATUS } from '@/resources/enums';
import { ProjectModel } from '@/resources/models';
import { ProjectApi } from '@/apis';
import { useCheckPermission } from '@/hooks';
import WorkStatus from '@/pages/Admin/ProjectDetails/DetailSection/WorkStatus';
import ProjectInfo from '@/pages/Admin/ProjectDetails/DetailSection/ProjectInfo';
import EngagedFreelancers from '@/pages/Admin/ProjectDetails/DetailSection/EngagedFreelancers';
import EmployerCard from '@/components/pages/EmployerCard';
import ProjectStatus from '@/pages/Admin/ProjectDetails/DetailSection/ProjectStatus';
import NoData from '@/components/common/NoData';
import Button from '@/components/common/Button';
import './styles.scss';

const DetailSection = () => {
  const params: { id: string } = useParams();
  const { id } = params;

  const checkPermission = useCheckPermission();

  const [project, setProject] = useState<ProjectModel | undefined>(undefined);
  const [pending, setPending] = useState(true);
  const [projectDisabled, setProjectDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabling, setDisabling] = useState(false);
  const [approving, setApproving] = useState(false);

  const toggleDisableStatus = async () => {
    setDisabling(true);
    try {
      const { disabled } = await ProjectApi.toggleDisableProject(id);
      setDisabling(false);
      await fetchProject();
      setProjectDisabled(disabled);
      notification.success({
        message: disabled
          ? 'Project is disabled successfully.'
          : 'Project is enabled successfully.'
      });
    } catch (err) {
      setDisabling(false);
      notification.error({ message: err.message });
    }
  };

  const approve = async () => {
    try {
      setApproving(true);
      await ProjectApi.approveProject(id as string);
      setApproving(false);
      await fetchProject();
      notification.success({ message: 'Project is approved successfully.' });
      setPending(false);
    } catch (err) {
      setApproving(false);
      notification.error({ message: err.message });
    }
  };

  const confirmToggleStatus = () => {
    Modal.confirm({
      title: projectDisabled
        ? 'Are you sure you want to enable this project?'
        : 'Are you sure you want to disable this project?',
      centered: true,
      onOk: toggleDisableStatus
    });
  };

  const confirmApprove = () => {
    Modal.confirm({
      title: 'Are you sure you want to approve this project?',
      centered: true,
      onOk: approve
    });
  };

  const fetchProject = () => {
    setLoading(true);
    ProjectApi.find(id)
      .then((data) => {
        setLoading(false);
        setProject(data);
        setPending(data.status === PROJECT_STATUS.PENDING);
        setProjectDisabled(data.status === PROJECT_STATUS.DISABLED);
      })
      .catch((err) => {
        setLoading(false);
        notification.error({ message: err.message });
      });
  };

  useEffect(() => {
    fetchProject();
  }, [id]);

  return (
    <div className="project-detail-section">
      <div className="d-flex align-items-center justify-content-end">
        {checkPermission(PERMISSION.APPROVE_PROJECT) && pending && (
          <Button
            variant="outline"
            className="cmb-17"
            onClick={confirmApprove}
            loading={approving}
          >
            Approve
          </Button>
        )}
        {checkPermission(PERMISSION.DISABLE_PROJECT) && (
          <Button
            className="cml-6 cmb-17"
            variant="outline"
            onClick={confirmToggleStatus}
            loading={disabling}
          >
            {projectDisabled ? 'Enable' : 'Disable'}
          </Button>
        )}
      </div>

      <Skeleton loading={loading} active={true} paragraph={{ rows: 10 }}>
        {project ? (
          <div className="row">
            <div className="col-xl-8">
              <div className="cmb-12">
                <ProjectInfo project={project} />
              </div>

              <div className="cmb-12">
                <EngagedFreelancers
                  freelancers={project?.awardedFreelancers as string[]}
                />
              </div>
            </div>
            <div className="col-xl-4">
              <div className="cmb-12">
                <EmployerCard employer={project.employer} />
              </div>

              {(project.status === PROJECT_STATUS.AWARDED ||
                project.status === PROJECT_STATUS.COMPLETED) && (
                <div className="cmb-12">
                  <WorkStatus project={project} />
                </div>
              )}

              <ProjectStatus project={project} />
            </div>
          </div>
        ) : (
          <NoData title="Not found project" />
        )}
      </Skeleton>
    </div>
  );
};

export default DetailSection;
