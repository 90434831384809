import { RouteProps } from 'react-router-dom';
import { Login } from '@/pages/Auth';
import { NotFound, ServerError } from '@/pages/Public';
import SetPassword from '@/pages/Auth/SetPassword';
import ForgotPassword from 'src/pages/Auth/ForgotPassword';
import ResetPassword from 'src/pages/Auth/ResetPassword';

export const ROUTES = {
  HOME: '/',
  AUTH: {
    LOGIN: '/login',
    SET_PASSWORD: '/set-password',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password'
  },
  ADMIN: {
    PREFIX: '/',
    DASHBOARD: {
      PREFIX: '/dashboard',
      PLATFORM_STATS: '/dashboard/platform',
      FINANCIAL_STATS: '/dashboard/financial'
    },
    PROJECTS: {
      PREFIX: '/projects',
      INDEX: '/projects',
      DETAIL: '/projects/:id/:tab'
    },
    EMPLOYERS: {
      PREFIX: '/employers',
      INDEX: '/employers',
      DETAIL: '/employers/:id/:tab',
      CREATE: '/employers/create',
      EDIT: '/employers/:id/edit'
    },
    FREELANCERS: {
      PREFIX: '/freelancers',
      INDEX: '/freelancers',
      DETAIL: '/freelancers/:id/:tab',
      CREATE: '/freelancers/create',
      EDIT: '/freelancers/:id/edit'
    },
    CONTRACTS: {
      PREFIX: '/contracts',
      INDEX: '/contracts',
      DETAIL: '/contracts/:id/:tab'
    },
    INVOICES: {
      PREFIX: '/invoices',
      INDEX: '/invoices',
      DETAIL: '/invoices/:id/:tab'
    },
    ORDERS: {
      PREFIX: '/orders',
      INDEX: '/orders',
      DETAIL: '/orders/:id/:tab'
    },
    INVITATIONS: {
      PREFIX: '/invitations',
      INDEX: '/invitations'
    },
    TRANSACTIONS: {
      PREFIX: '/transactions',
      INDEX: '/transactions',
      DETAIL: '/transactions/:id/:tab'
    },
    SETTINGS: {
      PREFIX: '/settings',
      INDEX: '/settings',
      SECURITY: {
        PREFIX: '/settings/security',
        INDEX: '/settings/security'
      },
      ACCOUNTS: {
        PREFIX: '/settings/accounts',
        INDEX: '/settings/accounts',
        CREATE: '/settings/accounts/create',
        EDIT: '/settings/accounts/:id/edit',
        DETAIL: '/settings/accounts/:id'
      },
      TRACKER: {
        PREFIX: '/settings/tracker',
        INDEX: '/settings/tracker'
      }
    },
    EVENTS: {
      PREFIX: '/events',
      INDEX: '/events'
    },
    DAEMON_LOGS: {
      PREFIX: '/daemon-logs',
      INDEX: '/daemon-logs'
    },
    WEBHOOK_LOGS: {
      PREFIX: '/webhook-logs',
      INDEX: '/webhook-logs'
    },
    TAGS: {
      PREFIX: '/tags',
      INDEX: '/tags'
    },
    BANK_ACCOUNTS: {
      PREFIX: '/bank-accounts',
      INDEX: '/bank-accounts',
      RECEIVING_BANKS: '/bank-accounts/receiving',
      FREELANCER_BANKS: '/bank-accounts/freelancers'
    },
    BANK_PAYMENT_ORDERS: {
      PREFIX: '/bank-payment-orders',
      INDEX: '/bank-payment-orders'
    },
    APP_REPORTS: {
      PREFIX: '/app-reports',
      INDEX: '/app-reports'
    }
  },
  SERVER_ERROR: '/server-error',
  NOT_FOUND: 'not-found'
};

export enum EMPLOYER_DETAILS_ROUTES {
  DETAILS = 'details',
  MESSAGES = 'messages',
  EVENTS = 'events',
  ACTION_HISTORIES = 'action-histories',
  FEE_EXEMPTIONS = 'fee-exemptions',
  REFERRALS = 'referrals',
  SETTINGS = 'settings'
}

export enum FREELANCER_DETAILS_ROUTES {
  DETAILS = 'details',
  MESSAGES = 'messages',
  EVENTS = 'events',
  ACTION_HISTORIES = 'action-histories',
  WITHDRAW_HISTORIES = 'withdraw-histories',
  FEE_EXEMPTIONS = 'fee-exemptions',
  REFERRALS = 'referrals',
  SETTINGS = 'settings',
  BANK_ACCOUNTS = 'bank-accounts'
}

export enum PROJECT_DETAILS_ROUTES {
  DETAILS = 'details',
  MESSAGES = 'messages',
  EVENTS = 'events'
}

export enum INVOICE_DETAILS_ROUTES {
  DETAILS = 'details',
  EVENTS = 'events'
}

export enum SETTING_DETAILS_ROUTES {
  ACCOUNTS = 'accounts',
  TWO_FACTOR_AUTH = 'two-fa'
}

export const AUTH_ROUTES: RouteProps[] = [
  { path: ROUTES.AUTH.LOGIN, component: Login },
  { path: ROUTES.AUTH.SET_PASSWORD, component: SetPassword },
  { path: ROUTES.AUTH.FORGOT_PASSWORD, component: ForgotPassword },
  { path: ROUTES.AUTH.RESET_PASSWORD, component: ResetPassword }
];

export const PUBLIC_ROUTES: RouteProps[] = [
  { path: ROUTES.SERVER_ERROR, exact: true, component: ServerError },
  { path: '*', component: NotFound }
];
