import React, { FC, useMemo } from 'react';
import { CONTRACT_STATUS, PAYMENT_TYPE } from '@/resources/enums';
import Select from '@/components/forms/Select';
import Search from '@/components/forms/Search';
import DateRangePicker from '@/components/forms/DateRangePicker';
import './styles.scss';

export interface IInvoiceSearchFilterProps {
  filter: any;
  onChangeFilter: (field: string, value: any) => void;
  user?: string;
}

const ContractSearchFilter: FC<IInvoiceSearchFilterProps> = ({
  filter,
  onChangeFilter
}) => {
  const statusOptions = useMemo(
    () => [
      { title: 'Active', value: CONTRACT_STATUS.ACTIVE },
      { title: 'Pending', value: CONTRACT_STATUS.PENDING },
      { title: 'Paused', value: CONTRACT_STATUS.PAUSED },
      { title: 'Completed', value: CONTRACT_STATUS.COMPLETED },
      { title: 'Cancelled', value: CONTRACT_STATUS.CANCELLED },
      { title: 'Rejected', value: CONTRACT_STATUS.REJECTED }
    ],
    []
  );

  const paymentTypeOptions = useMemo(
    () => [
      { title: 'All', value: undefined },
      { title: 'Hourly', value: PAYMENT_TYPE.HOURLY },
      { title: 'Fixed', value: PAYMENT_TYPE.FIXED }
    ],
    []
  );

  return (
    <div className="contract-filter-container">
      <div className="cmb-10">
        <Search
          className="page-search-box"
          placeholder="Search for contracts by title"
          value={filter.search}
          onChange={(e) => onChangeFilter('search', e.target.value)}
        />
      </div>

      <div className="filter-select-list">
        <div className="cmr-12 cmb-10">
          <DateRangePicker
            label="Dates"
            dateRange={filter.dateRange}
            setDateRange={(value) => onChangeFilter('dateRange', value)}
          />
        </div>

        <div className="cmr-12 cmb-10">
          <Select
            label="Payment Type"
            value={filter.paymentType}
            onChange={(value) => onChangeFilter('paymentType', value)}
            options={paymentTypeOptions}
          />
        </div>

        <div className="cmb-10">
          <Select
            multiSelect={true}
            showChipList={false}
            label="Status"
            value={filter.status}
            onChange={(value) => onChangeFilter('status', value)}
            options={statusOptions}
          />
        </div>
      </div>
    </div>
  );
};

export default ContractSearchFilter;
