import React, { FC } from 'react';
import Badge, { BADGE_STATUS } from '@/components/common/Badge';
import { TRANSACTION_STATUS } from '@/resources/enums';
import { NameHelper } from '@/helpers';

export interface ITransactionStatusProps {
  status: string;
  className?: string;
}

const TransactionStatus: FC<ITransactionStatusProps> = ({
  status,
  className = ''
}) => {
  const getBadgeStatus = (status: string) => {
    if (
      status === TRANSACTION_STATUS.SUCCESS ||
      status === TRANSACTION_STATUS.COMPLETED
    )
      return BADGE_STATUS.SUCCESS;

    if (
      status === TRANSACTION_STATUS.PENDING ||
      status === TRANSACTION_STATUS.UNCLAIMED ||
      status === TRANSACTION_STATUS.RETURNED ||
      status === TRANSACTION_STATUS.REFUNDED ||
      status === TRANSACTION_STATUS.ONHOLD
    )
      return BADGE_STATUS.WARNING;

    if (
      status === TRANSACTION_STATUS.FAILED ||
      status === TRANSACTION_STATUS.DENIED ||
      status === TRANSACTION_STATUS.CANCELED ||
      status === TRANSACTION_STATUS.DECLINED ||
      status === TRANSACTION_STATUS.BLOCKED
    )
      return BADGE_STATUS.DANGER;

    return BADGE_STATUS.NORMAL;
  };

  return (
    <Badge
      className={className}
      title={NameHelper.getTransactionStatusLabel(status)}
      status={getBadgeStatus(status)}
    />
  );
};

export default TransactionStatus;
