import React, { FC } from 'react';
import Messages from '@/components/pages/Messages';

const FreelancerMessages: FC<{}> = () => {
  return (
    <div>
      <Messages type="freelancer" />
    </div>
  );
};

export default FreelancerMessages;
