import { IBankPaymentOrder } from '@/resources/interfaces';
import { BANK_PAYMENT_ORDER_STATUS } from '@/resources/enums';
import { EmployerModel } from '@/resources/models/employer.model';
import { FreelancerModel } from '@/resources/models/freelancer.model';
import { InvoiceModel } from '@/resources/models/invoice.model';
import { BankAccountModel } from '@/resources/models/bankAccount.model';

export class BankPaymentOrderModel {
  id: string;
  employer: string | EmployerModel;
  freelancer: string | FreelancerModel;
  invoice: string | InvoiceModel;
  refCode: string;
  timestamps: string;
  status?: BANK_PAYMENT_ORDER_STATUS;
  bankAccountId: string | BankAccountModel;

  constructor(data: IBankPaymentOrder) {
    this.id = data.id;
    this.employer =
      typeof data.employer === 'string'
        ? data.employer
        : new EmployerModel(data.employer);
    this.freelancer =
      typeof data.freelancer === 'string'
        ? data.freelancer
        : new FreelancerModel(data.freelancer);
    this.invoice =
      typeof data.invoice === 'string'
        ? data.invoice
        : new InvoiceModel(data.invoice);
    this.refCode = data.refCode;
    this.timestamps = data.timestamps;
    this.status = data.status;
    if (data.bankAccountId) {
      this.bankAccountId =
        typeof data.bankAccountId === 'string'
          ? data.bankAccountId
          : new BankAccountModel(data.bankAccountId);
    } else {
      this.bankAccountId = '';
    }
  }
}
