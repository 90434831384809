import React, { ChangeEvent, FC } from 'react';
import { Input } from 'antd';
import Icon from '@/components/common/Icon';
import './styles.scss';

export interface ISearchProps {
  className?: string;
  allowClear?: boolean;
  maxLength?: number;
  placeholder?: string;
  value?: string | number | readonly string[];
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  showSearchIcon?: boolean;
  autoFocus?: boolean;
}

const Search: FC<ISearchProps> = ({
  className = '',
  allowClear = true,
  maxLength = 60,
  showSearchIcon = true,
  placeholder,
  value,
  onChange,
  autoFocus,
  ...props
}) => {
  return (
    <Input
      className={`custom-search ${className}`}
      prefix={showSearchIcon ? <Icon name="search" /> : ''}
      maxLength={maxLength}
      placeholder={placeholder}
      allowClear={allowClear}
      value={value}
      onChange={onChange}
      autoFocus={autoFocus}
      {...props}
    />
  );
};

export default Search;
