import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Checkbox, notification, Skeleton, Table } from 'antd';
import Pagination from '@/components/common/Pagination';
import { BankAccountApi } from '@/apis';
import { IQueryField, NameHelper, QueryHelper } from '@/helpers';
import PageHeader from '@/components/common/PageHeader';
import { useHistory, useLocation } from 'react-router';
import IconButton from '@/components/common/IconButton';
import Search from '@/components/forms/Search';
import Button from '@/components/common/Button';
import { PlusOutlined } from '@ant-design/icons';
import BankAccountEditModal from '@/pages/Admin/ReceivingBankAccounts/BankAccountEditModal';
import { BankAccountModel } from '@/resources/models';
import ConfirmDialogButton from '@/components/pages/ConfirmDialogButton';
import { useDebounce } from '@/hooks';
import './styles.scss';
import { CURRENCY } from '@/resources/enums';

const filterFields: IQueryField[] = [
  { name: 'page', type: 'number', default: 1 },
  { name: 'perPage', type: 'number', default: 10 },
  { name: 'search', type: 'string', default: undefined }
];

const ReceivingBankAccountsPage = () => {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [bankAccounts, setBankAccounts] = useState<any>([]);
  const location = useLocation();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [bankAccount, setBankAccount] = useState<BankAccountModel>();

  const openEditModal = (record: any) => {
    setIsOpen(true);
    setBankAccount(record);
  };

  const filter = useMemo(() => {
    return QueryHelper.parseQuery(location.search, filterFields, true);
  }, [location.search]);

  const debounceFilter = useDebounce(filter, 500);

  const fetchBankAccounts = useCallback(async () => {
    try {
      setLoading(true);
      const res = await BankAccountApi.search(filter);

      const { totalCount, data } = res;
      setTotal(totalCount);
      setBankAccounts(data);
    } catch (err) {
      notification.error({ message: err.message });
    } finally {
      setLoading(false);
    }
  }, [filter]);

  const onDeleteBankAccount = async (record: any) => {
    try {
      await BankAccountApi.delete(record.id);
      await fetchBankAccounts();
    } catch (err) {
      notification.error({ message: err.message });
    }
  };

  const dataSource = useMemo(() => {
    return bankAccounts.map((item: any) => ({
      key: item.id,
      ...item
    }));
  }, [bankAccounts]);

  const onChangeFilter = useCallback(
    (field: string, value: any, isPageUpdate = true) => {
      const newFilter = QueryHelper.getFilterToQuery(
        filter,
        field,
        value,
        isPageUpdate
      );

      const query = QueryHelper.stringifyQuery(newFilter);
      history.replace(`${location.pathname}?${query}`);
    },
    [filter, location.pathname]
  );

  useEffect(() => {
    fetchBankAccounts();
  }, [debounceFilter]);

  const onChangePrimary = async (
    record: BankAccountModel,
    isPrimary: boolean
  ) => {
    await BankAccountApi.setPrimaryAccount(record.id, { isPrimary });
    fetchBankAccounts();
  };

  const columns = useMemo(
    () => [
      {
        title: 'Is Primary',
        dataIndex: 'isPrimary',
        render: (isPrimary: boolean, record: any) => {
          return (
            <Checkbox
              checked={isPrimary}
              onChange={(e) => onChangePrimary(record, e.target.checked)}
            />
          );
        }
      },
      {
        title: 'Bank Name',
        dataIndex: 'bankName',
        render: (bankName: string) => {
          return <div>{bankName}</div>;
        }
      },
      {
        title: 'Bank Address',
        dataIndex: 'bankAddress',
        render: (bankAddress: string) => {
          return <div>{bankAddress}</div>;
        }
      },
      {
        title: 'Type',
        dataIndex: 'bankType',
        render: (bankType: string, row) => {
          return <div>{row.currency === CURRENCY.USD ? bankType : ''}</div>;
        }
      },
      {
        title: 'IBAN',
        dataIndex: 'iban',
        render: (iban: string) => {
          return <div>{iban}</div>;
        }
      },
      {
        title: 'Account Number',
        dataIndex: 'accountNumber',
        render: (accountNumber: string) => {
          return <div>{accountNumber}</div>;
        }
      },
      {
        title: 'Routing Number',
        dataIndex: 'routingNumber',
        render: (routingNumber: string) => {
          return <div>{routingNumber}</div>;
        }
      },
      {
        title: 'Swift Code',
        dataIndex: 'bicSwift',
        render: (bicSwift: string) => {
          return <div>{bicSwift}</div>;
        }
      },
      {
        title: 'Account Holder',
        dataIndex: 'accountHolder',
        render: (accountHolder: string) => {
          return <div>{accountHolder}</div>;
        }
      },
      {
        title: 'Account Type',
        dataIndex: 'accountType',
        render: (accountType: string, row) => {
          return (
            <div>
              {row.currency === CURRENCY.USD
                ? NameHelper.getBankAccountTypeLabel(accountType)
                : ''}
            </div>
          );
        }
      },
      {
        title: 'Currency',
        dataIndex: 'currency',
        render: (currency: string) => {
          return <div>{NameHelper.getCurrencyLabel(currency)}</div>;
        }
      },
      {
        title: 'Action',
        dataIndex: 'action',
        render: (_: string, record: any) => (
          <div className="d-flex align-items-center">
            <IconButton icon="edit" onClick={() => openEditModal(record)} />

            <ConfirmDialogButton
              onConfirm={() => onDeleteBankAccount(record)}
              message="Are you sure you want to remove this bank account?"
              okText="Confirm"
              cancelText="Cancel"
              buttonLabel="Approve"
              buttonProps={{
                icon: 'delete',
                className: 'mr-3'
              }}
              isIconButton={true}
            />
          </div>
        )
      }
    ],
    []
  );

  return (
    <div className="webhook-logs-page">
      <PageHeader title="Receiving Bank Accounts" />

      <div className="log-filters">
        <div className="cmb-10">
          <Search
            className="page-search-box"
            placeholder="Search for bank accounts by title"
            value={filter.search}
            onChange={(e) => onChangeFilter('search', e.target.value)}
          />
        </div>
      </div>

      <div className="d-flex justify-content-between mt-4 mb-3 align-items-center">
        <Skeleton loading={loading} paragraph={{ rows: 0 }} active={true}>
          <p className="text-md mb-0">{total} bank accounts found</p>
        </Skeleton>
        <Button
          icon={<PlusOutlined className="cmr-5" />}
          onClick={() => setIsOpen(true)}
        >
          Create Bank Account
        </Button>
      </div>

      <Table
        className="custom-ant-table"
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />

      <div className="d-flex-center cmt-10">
        <Pagination
          total={total}
          current={filter.page}
          pageSize={filter.perPage}
          onChange={(page) => onChangeFilter('page', page)}
        />
      </div>

      <BankAccountEditModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onRefresh={fetchBankAccounts}
        bankAccount={bankAccount}
      />
    </div>
  );
};

export default ReceivingBankAccountsPage;
