import React, { FC, useState } from 'react';
import { Modal, notification } from 'antd';
import Button from '@/components/common/Button';
import { EmployerApi } from '@/apis';
import { EmployerModel } from '@/resources/models';
import TagSelect from '@/components/pages/TagSelect';
import './styles.scss';

export interface ISelectTagModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  employer?: EmployerModel;
}

const SelectTagModal: FC<ISelectTagModalProps> = ({
  open,
  onClose,
  onConfirm,
  employer
}) => {
  const [loading, setLoading] = useState(false);
  const [tag, setTag] = useState('');

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (employer?.id) {
        await EmployerApi.update(employer.id, { tag });
      }
      if (onConfirm) {
        onConfirm();
      }
      onClose();
    } catch (err) {
      notification.error({ message: err.message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={open}
      onCancel={onClose}
      className="confirm-dialog dialog"
      footer={null}
      width={600}
    >
      <div className="dialog-body cpt-15">
        <h1 className="text-heading2 mb-4">Select Tag</h1>

        <div className="">
          <TagSelect className="mb-4" value={tag} onChange={setTag} />
          <div className="d-flex align-items-center justify-content-end">
            <Button type="submit" loading={loading} onClick={handleSubmit}>
              Save
            </Button>

            <Button
              type="button"
              className="ml-3"
              variant="outline"
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SelectTagModal;
