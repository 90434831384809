import React, { useEffect, useMemo, useState } from 'react';
import { TagApi } from '@/apis';
import { TagModel } from '@/resources/models';
import Select from '@/components/forms/Select';

interface ITagSelectProps {
  value: string;
  onChange: (value: string) => void;
  label?: string;
  className?: string;
}

const TagSelect = ({ label, value, onChange, className }: ITagSelectProps) => {
  const [tags, setTags] = useState<TagModel[]>([]);

  useEffect(() => {
    TagApi.search().then((res) => {
      setTags(res.data);
    });
  }, []);

  const options = useMemo(
    () =>
      tags.map((item) => ({
        title: item.name,
        value: item.id
      })),
    [tags]
  );

  return (
    <Select
      className={className}
      label={label}
      value={value}
      onChange={onChange}
      options={options}
    />
  );
};

export default TagSelect;
