import React, { FC, useState } from 'react';
import { Modal, notification } from 'antd';
import Button from '@/components/common/Button';
import './styles.scss';
import { IBank } from '@/resources/interfaces';
import { BankApi } from '@/apis';
import { NameHelper } from '@/helpers';
import { BANK_STATUS } from '@/resources/enums';
import BankDeclineModal from '../BankDeclineModal';
import Alert from '@/components/common/Alert';
import Icon from '@/components/common/Icon';

export interface IReviewBankAccountModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  bank?: IBank;
}

const ReviewBankAccountModal: FC<IReviewBankAccountModalProps> = ({
  open,
  onClose,
  onConfirm,
  bank
}) => {
  const [loading, setLoading] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);

  const handleApprove = async () => {
    if (!bank) {
      return;
    }

    try {
      setLoading(true);
      await BankApi.approve(bank.id);

      if (onConfirm) {
        onConfirm();
      }
      onClose();
      notification.success({
        message: 'Bank account is approved successfully'
      });
    } catch (err) {
      notification.error({ message: err.message });
    } finally {
      setLoading(false);
    }
  };

  const openDeclineModal = () => {
    setShowDeclineModal(true);
  };

  const handleDecline = async (
    declineReasons: string[],
    declineNote: string
  ) => {
    if (!bank) return;

    try {
      setLoading(true);
      await BankApi.decline(bank.id, {
        declineReasons,
        declineNote
      });

      if (onConfirm) {
        onConfirm();
      }
      onClose();
      notification.success({
        message: 'Bank account is declined successfully'
      });
    } catch (err) {
      notification.error({ message: err.message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={open}
      onCancel={onClose}
      className="confirm-dialog dialog"
      footer={null}
      width={600}
    >
      <div className="dialog-body cpt-15">
        <h1 className="text-heading2 mb-4">Review Bank Details</h1>

        <div className="">
          {!!bank?.declineReasons?.length &&
            bank.status === BANK_STATUS.DECLINED && (
              <Alert type="danger" className="cmb-4" showIcon={true}>
                <div className="font-weight-bold text-lg">
                  Your bank payment method is declined
                </div>
                <div className="text-sm">
                  Reason: {bank?.declineReasons?.join(', ')}
                </div>
              </Alert>
            )}
          <div>
            <table>
              <tbody>
                <tr>
                  <td className="pr-4 py-2">Beneficiary Name</td>
                  <td className="px-4 py-2">{bank?.receiverName}</td>
                </tr>
                <tr>
                  <td className="pr-4 py-2">Country</td>
                  <td className="px-4 py-2">{bank?.country}</td>
                </tr>
                <tr>
                  <td className="pr-4 py-2">Currency</td>
                  <td className="px-4 py-2">
                    {NameHelper.getCurrencyLabel(bank?.currency)}
                  </td>
                </tr>
                <tr>
                  <td className="pr-4 py-2">Bank Name</td>
                  <td className="px-4 py-2">{bank?.bankName}</td>
                </tr>
                <tr>
                  <td className="pr-4 py-2">SWIFT/BIC</td>
                  <td className="px-4 py-2">
                    {bank?.bankBic}
                    {bank?.validation?.swift ? (
                      <Icon name="check-circle" className="text-success" />
                    ) : (
                      ''
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="pr-4 py-2">IBAN/Account Number</td>
                  <td className="px-4 py-2">
                    {bank?.receivingAccount}
                    {bank?.validation?.accountNumber ? (
                      <Icon name="check-circle" className="text-success" />
                    ) : (
                      ''
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="pr-4 py-2">ZIP Code</td>
                  <td className="px-4 py-2">{bank?.postalCode}</td>
                </tr>
              </tbody>
            </table>
          </div>
          {bank?.status !== BANK_STATUS.DEACTIVATED && (
            <div className="cmt-10 d-flex align-items-center justify-content-end">
              <Button type="submit" loading={loading} onClick={handleApprove}>
                Approve
              </Button>

              <Button
                type="button"
                className="ml-3 text-danger border-danger"
                variant="outline"
                onClick={openDeclineModal}
              >
                Decline
              </Button>
            </div>
          )}
        </div>
      </div>
      <BankDeclineModal
        open={showDeclineModal}
        onClose={() => setShowDeclineModal(false)}
        loading={loading}
        onConfirm={handleDecline}
        bank={bank}
      />
    </Modal>
  );
};

export default ReviewBankAccountModal;
