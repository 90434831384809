import Http from '@/apis/http';
import { ISearchApiResult, IBankPaymentOrder } from '@/resources/interfaces';
import { BankPaymentOrderModel } from '@/resources/models';

export class BankPaymentOrderApi {
  static search(
    query: any = {}
  ): Promise<ISearchApiResult<BankPaymentOrderModel>> {
    return Http.get('/admin/bank-payment-orders', query).then(
      (res: ISearchApiResult<IBankPaymentOrder>) => {
        return {
          totalCount: res.totalCount,
          data: res.data.map((item) => new BankPaymentOrderModel(item))
        };
      }
    );
  }

  static find(id: string): Promise<BankPaymentOrderModel> {
    return Http.get(`/admin/bank-payment-orders/${id}`).then(
      (res: IBankPaymentOrder) => {
        return new BankPaymentOrderModel(res);
      }
    );
  }

  static create(data: any): Promise<BankPaymentOrderModel> {
    return Http.post(`/admin/bank-payment-orders`, data).then(
      (res: IBankPaymentOrder) => {
        return new BankPaymentOrderModel(res);
      }
    );
  }

  static update(id: string, body: any) {
    return Http.put(`/admin/bank-payment-orders/${id}`, body);
  }

  static complete(id: string) {
    return Http.post(`/admin/bank-payment-orders/${id}/complete`);
  }

  static reject(id: string) {
    return Http.post(`/admin/bank-payment-orders/${id}/reject`);
  }

  static delete(id: string) {
    return Http.delete(`/admin/bank-payment-orders/${id}`);
  }
}
