import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { MessagePageType } from '@/components/pages/Messages/Messages';
import { ChannelApi } from '@/apis';
import { Empty, notification, Skeleton } from 'antd';
import { ChannelModel } from '@/resources/models';
import Pagination from '@/components/common/Pagination';
import ChannelCard from '@/components/pages/Messages/ChannelList/ChannelCard';
import './styles.scss';

interface ChannelListProps {
  type: MessagePageType;
  channels: ChannelModel[];
  setChannels: (value: ChannelModel[]) => void;
}

const ChannelList: FC<ChannelListProps> = ({ type, channels, setChannels }) => {
  const { id } = useParams<{ id: string }>();

  const [filters, setFilters] = useState({
    page: 1,
    perPage: 10
  });
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  const handlePageChange = (page: number, perPage: number) => {
    setFilters({
      ...filters,
      page,
      perPage
    });
  };

  useEffect(() => {
    const query = {
      [type]: id,
      sort: '-createdAt',
      expands: ['project', 'employer.user', 'freelancer.user']
    };

    setLoading(true);
    ChannelApi.search(query)
      .then((res) => {
        setLoading(false);
        setTotal(res.totalCount);
        setChannels(res.data);
      })
      .catch((err) => {
        setLoading(false);
        notification.error({ message: err.message });
      });
  }, [type, id]);

  return (
    <>
      <h3 className="cmb-9">Channels</h3>

      <Skeleton active={true} loading={loading} paragraph={{ rows: 10 }}>
        {channels.length > 0 ? (
          <>
            <div className="message-channels">
              {channels.map((channel) => (
                <ChannelCard key={channel.id} type={type} channel={channel} />
              ))}
            </div>

            <div className="d-flex-center channel-pagination">
              <Pagination
                total={total}
                current={filters.page}
                pageSize={filters.perPage}
                onChange={handlePageChange}
              />
            </div>
          </>
        ) : (
          <div className="no-channels d-flex-center">
            <Empty />
          </div>
        )}
      </Skeleton>
    </>
  );
};

export default ChannelList;
