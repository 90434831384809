import { COMMON_ACTION } from '@/redux/action-types';
import { ICommonState } from '@/resources/interfaces';

const initialState: ICommonState = {
  skills: [],
  countries: [],
  categories: [],
  subCategories: [],
  languages: [],
  lang: { key: 'en', name: 'language.english' }
};

const commonReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case COMMON_ACTION.SET_COMMON_DATA: {
      const { data = {} } = action.payload;

      return {
        ...state,
        skills: data.skills,
        countries: data.countries,
        categories: data.categories,
        subCategories: data.subCategories,
        languages: data.languages
      };
    }

    case COMMON_ACTION.SET_LANGUAGE: {
      return {
        ...state,
        lang: action.payload.lang
      };
    }

    default:
      return state;
  }
};

export default commonReducer;
