import React from 'react';
import { useHistory } from 'react-router';
import { notification } from 'antd';
import { ObjectHelper } from '@/helpers';
import { ROUTES, SETTING_DETAILS_ROUTES } from '@/constants';
import AccountForm from '@/components/pages/AccountForm';
import BackButton from '@/components/common/BackButton';
import PageHeader from '@/components/common/PageHeader';
import { AdminUserApi } from '@/apis/adminUser.api';

const CreateAccount = () => {
  const history = useHistory();

  const createAccount = async (data: any) => {
    try {
      await AdminUserApi.create(ObjectHelper.trimQuery(data));
      notification.success({ message: 'User is created successfully.' });
      history.push(
        `${ROUTES.ADMIN.SETTINGS.PREFIX}/${SETTING_DETAILS_ROUTES.ACCOUNTS}`
      );
    } catch (err) {
      notification.error({ message: err.message });
    }
  };

  return (
    <div>
      <div className="cmb-17">
        <BackButton />
      </div>

      <PageHeader title="Create a new account" />

      <AccountForm onSubmit={createAccount} />
    </div>
  );
};

export default CreateAccount;
