import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';
import { notification, Rate, Skeleton, Table } from 'antd';
import moment from 'moment';
import { API_SERVER, EMPLOYER_DETAILS_ROUTES, ROUTES } from '@/constants';
import { EmployerApi } from '@/apis';
import { useCheckPermission } from '@/hooks';
import { FileHelper, IQueryField, ObjectHelper, QueryHelper } from '@/helpers';
import { EmployerModel } from '@/resources/models';
import { PERMISSION, USER_STATUS } from '@/resources/enums';
import Badge, { BADGE_STATUS } from '@/components/common/Badge';
import UserSearchFilter, {
  IUserSearchFilter,
  USER_FILTER_TYPE
} from '@/components/filters/UserSearchFilter';
import Pagination from '@/components/common/Pagination';
import Avatar from '@/components/common/Avatar';
import UserStatus from '@/components/pages/UserStatus';
import FilterChipList from '@/components/common/filters/FilterChipList';
import Tooltip from '@/components/common/Tooltip';
import Button from '@/components/common/Button';
import Icon from '@/components/common/Icon';
import EmployerActions from '@/pages/Admin/Employers/EmployerActions';
import PageHeader from '@/components/common/PageHeader';
import './styles.scss';

const filterFields: IQueryField[] = [
  { name: 'title', type: 'string', default: '' },
  { name: 'country', type: 'country-array', default: [] },
  { name: 'page', type: 'number', default: 1 },
  { name: 'perPage', type: 'number', default: 10 },
  { name: 'status', type: 'string', default: undefined },
  { name: 'sort', type: 'string', default: '-createdAt' },
  { name: 'isInternal', type: 'boolean', default: false }
];

const Employers: FC = () => {
  const history = useHistory();
  const checkPermission = useCheckPermission();

  const [loading, setLoading] = useState(false);
  const [totalEmployerCount, setTotalEmployerCount] = useState(0);
  const [employers, setEmployers] = useState<EmployerModel[]>([]);
  const location = useLocation();

  const filter = useMemo(() => {
    return QueryHelper.parseQuery(location.search, filterFields, true);
  }, [location.search]);

  const dataSource = useMemo(() => {
    return employers.map((item) => {
      return {
        ...item,
        key: item.id,
        fullName: item.user?.fullName,
        email: item.user?.email,
        country: item.user?.country?.name,
        engagedFreelancers: item.engagedFreelancers.length,
        completedProjects: item.completedProjects.length,
        status:
          item.status === USER_STATUS.OPENED && !item.user?.isActive
            ? 'InActive'
            : item.status,
        avatarUrl: item.user?.avatar,
        createdAt: moment(item.createdAt).format('DD MMM, YYYY')
      };
    });
  }, [employers]);

  const searchEmployers = useCallback(
    (filter: IUserSearchFilter) => {
      const query: any = {
        search: filter.title,
        country: filter.country,
        page: filter.page,
        perPage: filter.perPage,
        sort: filter.sort,
        isInternal: filter.isInternal,
        expands: ['user.country']
      };

      setLoading(true);
      EmployerApi.search(ObjectHelper.trimQuery(query))
        .then((res) => {
          setEmployers(res.data);
          setTotalEmployerCount(res.totalCount);
        })
        .catch((err) => {
          notification.error({ message: err.message });
        })
        .finally(() => setLoading(false));
    },
    [setEmployers, setTotalEmployerCount]
  );

  const debounceFilter = useMemo(() => {
    return debounce(searchEmployers, 500);
  }, [searchEmployers]);

  useEffect(() => {
    debounceFilter(filter);
  }, [filter]);

  const onChangeFilter = (field: string, value: any) => {
    const newFilter = QueryHelper.getFilterToQuery(filter, field, value);

    const query = QueryHelper.stringifyQuery(newFilter);
    history.replace(`${location.pathname}?${query}`);
  };

  const clearFilter = () => {
    history.replace(location.pathname);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'fullName',
      sorter: {
        multiple: 1
      },
      render: (title: string, record: any) => (
        <Link
          className="d-flex align-items-center"
          to={`${ROUTES.ADMIN.EMPLOYERS.PREFIX}/${record.id}/${EMPLOYER_DETAILS_ROUTES.DETAILS}`}
        >
          <Avatar
            src={record?.user?.avatar}
            name={record?.user?.fullName}
            size="2.5rem"
            className="cmr-6"
            round={true}
          />

          <Tooltip title={title}>
            <div>
              <p className="mb-0 text-dark">{title}</p>
              <p className="text-xs mb-0 text-blue">{record.email}</p>
            </div>
          </Tooltip>
        </Link>
      )
    },
    {
      title: 'Country',
      dataIndex: 'country',
      sorter: {
        multiple: 2
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: {
        multiple: 3
      },
      render: (status: string) => <UserStatus status={status} />
    },
    {
      title: 'Internal User',
      dataIndex: 'isInternal',
      sorter: {
        multiple: 4
      },
      render: (isInternal: boolean) => {
        return isInternal ? (
          <Badge
            title="Internal User"
            status={isInternal ? BADGE_STATUS.SUCCESS : BADGE_STATUS.NORMAL}
          />
        ) : (
          ''
        );
      }
    },
    {
      title: 'Payment Verified',
      dataIndex: 'paymentVerified',
      sorter: {
        multiple: 5
      },
      render: (verified: boolean) => {
        return (
          <Badge
            title={verified ? 'Verified' : 'Not verified'}
            status={verified ? BADGE_STATUS.SUCCESS : BADGE_STATUS.DANGER}
          />
        );
      }
    },
    {
      title: 'Rating',
      dataIndex: 'rating',
      sorter: {
        multiple: 6
      },
      render: (rating: number) => (
        <Rate value={rating} allowHalf={true} disabled={true} />
      )
    },
    {
      title: 'Completed Projects',
      dataIndex: 'completedProjects',
      sorter: {
        multiple: 7
      },
      render: (value: string) => <div className="text-center">{value}</div>
    },
    {
      title: 'Member Since',
      dataIndex: 'createdAt',
      sorter: {
        multiple: 8
      }
    },
    ...(checkPermission([PERMISSION.EDIT_USER, PERMISSION.DELETE_USER])
      ? [
          {
            title: 'Actions',
            dataIndex: 'id',
            render: (id: string) => {
              return (
                <EmployerActions
                  employerId={id}
                  refresh={() => onChangeFilter('page', 1)}
                />
              );
            }
          }
        ]
      : [])
  ];

  const onExport = async () => {
    try {
      const res = await EmployerApi.exportEmployers(
        ObjectHelper.trimQuery(filter)
      );

      FileHelper.download(
        `${API_SERVER}/public/download?token=${res}`,
        `employers_${moment().format('YYYY-MM-DD')}.xlsx`
      );
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="admin-users-page">
      <PageHeader title="Employers" />

      <UserSearchFilter
        filter={filter}
        onChangeFilter={onChangeFilter}
        type={USER_FILTER_TYPE.EMPLOYER}
      />

      <FilterChipList
        filter={filter}
        onFilterChange={onChangeFilter}
        clearFilter={clearFilter}
        filterType="user"
      />

      {checkPermission(PERMISSION.CREATE_USER) && (
        <div className="d-flex align-items-end justify-content-end">
          <Button
            icon={<Icon name="plus" className="cmr-10" />}
            onClick={() => history.push(ROUTES.ADMIN.EMPLOYERS.CREATE)}
          >
            Add New Employer
          </Button>
          <Button
            className="cml-12"
            icon={<Icon name="download" className="cmr-10" />}
            onClick={onExport}
          >
            Export
          </Button>
        </div>
      )}

      <Skeleton loading={loading} paragraph={{ rows: 0 }} active={true}>
        <p className="mt-3 mb-2 text-md">
          {totalEmployerCount} employers found
        </p>
      </Skeleton>

      <Table
        className="custom-ant-table"
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />

      <div className="d-flex-center cmt-10">
        <Pagination
          total={totalEmployerCount}
          current={filter.page}
          pageSize={filter.perPage}
          onChange={(page) => onChangeFilter('page', page)}
        />
      </div>
    </div>
  );
};

export default Employers;
