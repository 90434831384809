import React from 'react';
import { Drawer } from 'antd';
import Icon from '@/components/common/Icon';
import Badge, { BADGE_STATUS } from '@/components/common/Badge';
import { DAEMON_STATUS } from '@/resources/enums';
import { DateHelper, NameHelper } from '@/helpers';
import ReactJson from 'react-json-view';
import './styles.scss';

interface IDaemonLogDetailModalProps {
  onClose: () => void;
  open: boolean;
  daemonLog: any;
}

const DaemonLogDetailModal: React.FC<IDaemonLogDetailModalProps> = ({
  onClose,
  open,
  daemonLog
}) => {
  return (
    <Drawer
      className="daemon-log-detail-drawer"
      title={() => <div />}
      closeIcon={<Icon name="close" />}
      placement="right"
      onClose={onClose}
      visible={open}
    >
      <table>
        <tbody>
          <tr>
            <td>DaemonLog ID:</td>
            <td>{daemonLog?.id}</td>
          </tr>
          <tr>
            <td>Type:</td>
            <td>
              <Badge title={NameHelper.getDaemonTypeLabel(daemonLog?.type)} />
            </td>
          </tr>
          <tr>
            <td>Status:</td>
            <td>
              <Badge
                status={
                  daemonLog?.status === DAEMON_STATUS.SUCCESS
                    ? BADGE_STATUS.SUCCESS
                    : BADGE_STATUS.DANGER
                }
                title={NameHelper.getDaemonStatusLabel(daemonLog?.status)}
              />
            </td>
          </tr>
          <tr>
            <td>Date:</td>
            <td>
              <div>
                {DateHelper.formatDate(
                  daemonLog?.createdAt,
                  'DD MMM YYYY, hh:mm:ss A'
                )}
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div className="cmt-12">
        <ReactJson name="data" src={daemonLog?.data} />
      </div>
      <div className="cmt-12">
        <ReactJson name="meta" src={daemonLog?.meta} />
      </div>
    </Drawer>
  );
};

export default DaemonLogDetailModal;
