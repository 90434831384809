import React, { FC, useMemo } from 'react';
import { Table } from 'antd';
import { IMilestone } from '@/resources/interfaces';
import { DateHelper } from '@/helpers';
import './styles.scss';

export interface IMilestonesViewProps {
  milestones: IMilestone[];
}

const MilestonesView: FC<IMilestonesViewProps> = ({ milestones }) => {
  const columns = useMemo(() => {
    return [
      {
        title: 'Description',
        dataIndex: 'description'
      },
      {
        title: 'Date',
        dataIndex: 'date'
      },
      {
        title: 'Amount',
        dataIndex: 'amount'
      }
    ];
  }, []);

  const dataSource = useMemo(() => {
    return milestones.map((item) => ({
      key: item._id,
      description: item.description,
      amount: `$${item.amount.toFixed(2)}`,
      date: DateHelper.formatDate(item.date, 'MMM DD, YYYY')
    }));
  }, [milestones]);

  return (
    <div className="milestones-list">
      <p className="text-lg text-black cmb-9">Milestones</p>

      <div>
        <Table
          className="custom-ant-table"
          columns={columns}
          dataSource={dataSource}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default MilestonesView;
