import Http from '@/apis/http';

export class AccountApi {
  static getStatistics(): Promise<any> {
    return Http.get(`/admin/account/statistics`);
  }
  static getDashboardStatistics(): Promise<any> {
    return Http.get(`/admin/account/dashboard/statistics`);
  }
  static getDashboardWithdrawStatistics(filters: any = {}): Promise<any> {
    return Http.get(`/admin/account/dashboard/statistics/withdraw`, filters);
  }
  static getDashboardPayStatistics(filters: any = {}): Promise<any> {
    return Http.get(`/admin/account/dashboard/statistics/pay`, filters);
  }
  static getDashboardHoldStatistics(filters: any = {}): Promise<any> {
    return Http.get(`/admin/account/dashboard/statistics/hold`, filters);
  }
  static getDashboardRevenueStatistics(filters: any = {}): Promise<any> {
    return Http.get(`/admin/account/dashboard/statistics/revenue`, filters);
  }
  static getDashboardFreelancerStatistics(filters: any = {}): Promise<any> {
    return Http.get(`/admin/account/dashboard/freelancers`, filters);
  }
  static getDashboardEmployerStatistics(filters: any = {}): Promise<any> {
    return Http.get(`/admin/account/dashboard/employers`, filters);
  }
  static getDashboardProjectStatistics(filters: any = {}): Promise<any> {
    return Http.get(`/admin/account/dashboard/projects`, filters);
  }
}
