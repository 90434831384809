import { Row, Col, notification } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { FreelancerApi } from 'src/apis';
import ResetPasswordForm from 'src/components/pages/ResetPasswordForm';
import { FreelancerModel } from 'src/resources/models';
import SecuritySettings from './SecuritySettings';
import ShareProfile from './ShareProfile';

interface IFreelancerSettingsProps {
  freelancer?: FreelancerModel;
  onRefresh: () => void;
}

const FreelancerSettings: React.FC<IFreelancerSettingsProps> = ({
  freelancer,
  onRefresh
}) => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams() as any;

  const onResetPassword = (values: any) => {
    setLoading(true);
    FreelancerApi.update(id, { user: { password: values.password } })
      .then(() => {
        notification.success({ message: 'Password is reset successfully.' });
      })
      .catch((error) => {
        notification.error({ message: error.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Row gutter={[24, 24]}>
      <Col span={12}>
        <div className="custom-card">
          <ResetPasswordForm loading={loading} onSubmit={onResetPassword} />
        </div>
      </Col>
      <Col span={12}>
        <SecuritySettings freelancer={freelancer} onRefresh={onRefresh} />
      </Col>
      <Col span={24}>
        <ShareProfile freelancer={freelancer} onRefresh={onRefresh} />
      </Col>
    </Row>
  );
};

export default FreelancerSettings;
